import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const BackgroundPage = styled.div`
    height:100vh;
    width:100vw;
    background-image:url('https://capprdstportalorteador.blob.core.windows.net/b2crelacionamento/BG_portal3.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    position:fixed;
    filter: blur(8px);
    -webkit-filter: blur(8px);
`;
export const PageContent = styled.div`
    position: absolute;
    z-index: 2;
    width:100vw;
    height:100vh;
    display:flex !important;
    flex-direction:row !important;
    align-items:center !important;
    justify-content:center !important;
`;
export const ModalDialog = styled.div`
    background-color:white;
    height: 65%;
    width:70%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 39px;
    opacity: 1;
    display:flex;
    flex-direction:column;
    align-items:center;
`;
export const PageHeader = styled.div`
    display:flex;
    margin-top:3%;
    width:90%;
    height:20%;
    justify-content:space-between;
`
export const Logo = styled.img`
    height:100%;
`
export const HeaderText = styled.label`
    text-align: left;
    font: Bold 2em Roboto;
    letter-spacing: -0.74px;
    color: #E27800;
    text-transform: uppercase;
    display:flex;
    align-items:center;
    opacity: 1;
    color:#E27800;
`;
export const ModalBody = styled.div`
    height:70%;
    width:90%;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;

`
export const RamalContato = styled.div<{borderRight:string}>`
    width:29%;
    padding-right:1%;
    height:100%;
    border-right:${props => props.borderRight};
    margin-right:3%;
    overflow-y:auto;
`
export const Icone = styled(FontAwesomeIcon)`
    text-align: left;
    letter-spacing: 0px;
    color: #E27800;
    opacity: 1;
`;
export const TituloNome = styled.label`
    text-align: left;
    font: Bold 1rem Roboto;
    letter-spacing: -0.44px;
    color: #E27800;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom:0px;
`
export const SubTitulo = styled.label`
    text-align: left;
    font: Bold Italic 0.6rem Roboto;
    letter-spacing: -0.28px;
    color: #E27800;
    text-transform: uppercase;
`
export const Numero = styled.label`
    margin-left:4%;
    text-align: left;
    font: Bold 0.9rem Roboto;
    letter-spacing: -0.66px;
    color: #636363;
    opacity: 1;
`
export const Area = styled.label`
    text-align: left;
    font:Roboto;
    font-size:0.7rem;
    font-weight:bold;
    letter-spacing: -0.34px;
    color: #636363;
    text-transform: uppercase;
    opacity: 1;
`
export const Descricao = styled.label`
    text-align: left;
    font: Regular Roboto;
    font-size:0.7rem;
    letter-spacing: -0.34px;
    color: #636363;
    opacity: 1;
`