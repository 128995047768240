import React, { useState, useEffect, } from "react";
import { useHistory } from "react-router-dom";
import {GetConvenioNOTOKEN,GetUF, POSTAssistenciaFinanceira} from '../../API/usuario';
import {TestaCPF} from '../../functions/CpfTest';
import {TextInput, TextInputEspecific} from '../../components/TextInput';
import { LoaderModal } from '../Loaders/LoaderDots';
import Modal from 'react-bootstrap/Modal';
import {DadosCadastraisLabel, EspacoModalFinal, MensagemCadastrado,EnviarButton, PageHalfSpace, PageTotalSpace,LineSpace,CancelarButton,ButtonsSpace} from './style';
import EmptyTest from '../../functions/EmptyTest';
import {IPreUser} from '../../interfaces/IUser';
import StyledAlert from "../StyledAlert";
const AFComponent : React.FC<{userData?:IPreUser}> = (props) : React.ReactElement =>{
    const userData = props.userData;
    const history = useHistory();
    const [nome,setNome] = useState<string>(userData?.nome??"");
    const [cpf,setCpf] = useState<string>(userData?.cpf??"");
    const [celular,setCelular] = useState<string>((userData?.dddTelefone1??"00")+(userData?.telefone1??""));
    const [email,setEmail] = useState<string>((userData?.email??"").replace(" ",""));
    const [emailConfirm,setEmailConfirm] = useState<string>("");
    const [estado,setEstado] = useState<string>(userData?.uf??"");

    const [allEstados,setAllEstados] = useState<string[]>([]);
    
    const [showModalFinal,setShowModalFinal] = useState<boolean>(false);
    const [showModalLoader,setShowModalLoader] = useState<boolean>(false);

    const [convenio,setConvenio] = useState<string>("");
    const [conveniosItens,setConveniosItens] = useState<{id:number,nome:string}[]>([]);
    const [otherConvenio,setOtherConvenio] = useState<string>("");
    const [valorDesejado,setValorDesejado] = useState<string>("");
    const [prazoPagamento,setPrazoPagamento] = useState<string>("");
    useEffect(()=>{
        GetConvenioNOTOKEN().then((response)=>{
            setConveniosItens(response.data);
        })
        GetUF().then((response)=>{
            setAllEstados(response.data.map((item:{sigla:string})=>{
                return(item.sigla);
            }))
        }).catch(()=>{
            setAllEstados(["AC","AL","AP","AM","BA","CE","ES","GO","MA","MT","MS","MG","PA","PB","PR","PE","PI","RJ","RN","RS","RO","RR","SC","SP","SE","TO","DF"]);
        });
    },[])
    function AtualizarValor(evento:{target:{value:string}},setValor:Function)
    {
        setValor(evento.target.value);
        console.log(evento.target.value);
    }
    function VerificaEmail(){
        if(email===emailConfirm)return true; else {StyledAlert("Os emails estão diferentes!");return false;}
    }
    function TestaConvenios()
    {
        console.log(convenio.search("OUTROS"));
        if((convenio!=="" && convenio.search("OUTROS")===-1) || otherConvenio!=="")
        return true;
        else
        {
            StyledAlert("Escolha um convênio ou digite outra opção!")
            return false
        }
    }
    return(
    <PageTotalSpace>
            <PageHalfSpace>
                <LineSpace>
                    <TextInputEspecific rotulo="Nome" valor={nome} onChange={(e:any)=>{AtualizarValor(e,setNome)}} show disabled={false}/>
                </LineSpace>
                <LineSpace>
                    <TextInputEspecific rotulo="CPF" width="48%" valor={cpf} onChange={(e:any)=>{AtualizarValor(e,setCpf)}} show disabled={false}/>
                    <TextInputEspecific rotulo="Telefone Celular" valor={celular} onChange={(e:any)=>{AtualizarValor(e,setCelular)}}  width="48%" show disabled={false}/>
                </LineSpace>
                <LineSpace>
                    <TextInputEspecific rotulo="E-mail" valor={email} onChange={(e:any)=>{AtualizarValor(e,setEmail)}} show disabled={false}/>
                </LineSpace>
                <LineSpace>
                    <form style={{width:"100%"}} autoComplete="none" >
                        <TextInputEspecific rotulo="Confirme o E-mail" valor={emailConfirm} onChange={(e:any)=>{AtualizarValor(e,setEmailConfirm)}} show disabled={false}/>
                    </form>
                </LineSpace>
                <LineSpace>
                    <TextInputEspecific rotulo="Estado" options={allEstados} valor={estado} onChange={(e:any)=>{AtualizarValor(e,setEstado)}} show disabled={false}/>
                </LineSpace>
            </PageHalfSpace>
            <PageHalfSpace>
                <DadosCadastraisLabel>Dados Cadastrais</DadosCadastraisLabel>
                <LineSpace>
                    <TextInputEspecific rotulo="Convênio" options={conveniosItens.map((item:{nome:string})=>{return item.nome})} valor={convenio} onChange={(e:any)=>{setOtherConvenio("");AtualizarValor(e,setConvenio)}} show disabled={false}/>
                </LineSpace>
                <LineSpace>
                    <TextInputEspecific rotulo="Outro Convênio" valor={otherConvenio} onChange={(e:any)=>{AtualizarValor(e,setOtherConvenio)}} show={(convenio==="OUTROS"?true:false)} disabled={false}/>
                </LineSpace>
                <LineSpace>
                    <TextInputEspecific width="48%" valor={valorDesejado} onChange={(e:any)=>{AtualizarValor(e,setValorDesejado)}} rotulo="Valor Desejado" show disabled={false}/>
                    <TextInput width="48%" valor={prazoPagamento} rotulo="Prazo de Pagamento" onChange={(e:any)=>{if(((parseInt(e.target.value)<=95))|| e.target.value===""){setPrazoPagamento(e.target.value.replace(/\D+/g,""))}}} show disabled={false}/>
                </LineSpace>
                <LoaderModal show={showModalLoader}/>
                <Modal centered show={showModalFinal}>
                    <EspacoModalFinal>
                        <MensagemCadastrado>Assistencia Financeira Solicitada!</MensagemCadastrado>
                        <EnviarButton onClick={()=>{history.push("/inicio/")}}>Sair</EnviarButton>
                    </EspacoModalFinal>
                </Modal>
                <ButtonsSpace>
                    <EnviarButton onClick={()=>
                    {
                        if(EmptyTest(nome,"Preencha seu nome!") && EmptyTest(cpf,"Preencha seu CPF!") && EmptyTest(celular,"Preencha ao menos um celular!") && EmptyTest(email.replace("_",""),"Preencha um email para contato!") && EmptyTest(emailConfirm.replace("_",""),"Preencha a confirmação de email!") && VerificaEmail() && EmptyTest(estado,"Escolha o seu estado!"))
                        if(EmptyTest(convenio,"Escolha uma opção de convênio") && TestaConvenios() && EmptyTest(valorDesejado,"Digite um valor para solicitar!") && EmptyTest(prazoPagamento,"Escolha um prazo para pagamento!"))
                        if(TestaCPF(cpf.replace(/-|\./g,"")))
                        {
                            let resultado:{id:number,nome:string}={id:0,nome:""};
                            conveniosItens.map((item:{id:number,nome:string})=>{
                                if(item.nome===convenio)
                                {
                                resultado=item;
                                }
                                return 0;
                            });
                            console.log(valorDesejado);
                            console.log(valorDesejado.replace("R$",""));
                            console.log(parseInt(valorDesejado.replace("R$","").replace(",","")));
                            setShowModalLoader(true);
                            POSTAssistenciaFinanceira(nome,cpf.replace(/-|\./g,""),celular.replace(/-|\(|\)/g,""),emailConfirm,estado,resultado,otherConvenio,parseFloat(valorDesejado.replace(/R|\$|\./g,"").replace(",",".")),parseInt(prazoPagamento),JSON.parse(sessionStorage.getItem("auth")?? '{"TOKEN": "", "isAuth": false}').TOKEN?true:false).then(
                                ()=>{
                                    setShowModalFinal(true);
                                }
                            ).finally(()=>{
                                setShowModalLoader(false);
                            })
                        }
                        else
                        StyledAlert("CPF inválido!")
                    }
                    }>Enviar</EnviarButton>
                    <CancelarButton onClick={()=>{history.push("/inicio/")}}>Cancelar</CancelarButton>    
                </ButtonsSpace>
            </PageHalfSpace>
        </PageTotalSpace>
    )
}
export default AFComponent;