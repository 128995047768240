import React from 'react';
import { BrowserRouter, Switch, useRouteMatch, Redirect } from "react-router-dom";
import Home from '../../pages/Portal/Home';
import Perfil from '../../pages/Portal/Perfil';
import PerfilAtualizarDados from'../../pages/Portal/PerfilAtualizarDados';
import Informativo from '../../pages/Portal/Informativo';
import MyDocuments from '../../pages/Portal/Documentos'
import Header from '../../components/Header';
import { Container, PortalLGPDLink, PortalLGPDLinkSpace } from './styles';
import FaleConosco from '../../pages/Portal/FaleConosco';
import NovoAtendimento from '../../pages/Portal/NovoAtendimento';
import MeusAtendimentos from '../../pages/Portal/Atendimentos';
import MeusProdutos from '../../pages/Portal/MeusProdutos';
import Ajuda from '../../pages/Portal/Ajuda';
import AjudaEspecifica from '../../pages/Portal/AjudaEspecifica';
import Capnews from '../../pages/Portal/CapNEWS';
import CapnewsEspecifico from '../../pages/Portal/CapNEWSEspecifica';
import BuscaResultsScreen from '../../pages/Portal/Busca';
import TrabalhoSocial from '../../pages/Portal/TrabalhoSocial';
import DocumentsModel from '../../components/DocumentsModel';
import AssistenciaFinanceira from '../../pages/Portal/AssistenciaFinanceiraInterna';
import Boleto from '../../pages/Portal/Boleto';
import CustomRoute from '../../components/CustomRoute';
import SinistroRoute from '../../routes/Sinistro';
import PerfilConfirmacaoDadosSegurado from '../../pages/Portal/PerfilConfirmacaoDadosSeguros';
import { LGPDURL } from '../../env_settings';
import StatusSinistro from '../../pages/Portal/StatusSinistro';
import PageTracker from '../../services/googleAnalytics/PageTrackerComponent';
export default function Portal(){
    const { path } = useRouteMatch();
    type RouteReduzida = {path:string,component:React.FC,exact?:boolean};
    const rotas : RouteReduzida[] = [
        {path:path,component:Home},
        {path:`${path}/assistenciafinanceira`,component:AssistenciaFinanceira},
        {path:`${path}/perfil`,component:Perfil},
        {path:`${path}/perfil/atualizardados`,component:PerfilAtualizarDados},
        {path:`${path}/perfil/confirmardados`,component:PerfilConfirmacaoDadosSegurado},
        {path:`${path}/produtos`,component:MeusProdutos},
        {path:`${path}/documentos`,component:MyDocuments},
        {path:`${path}/documentos/informerendimentos`,component:DocumentsModel},
        {path:`${path}/documentos/extratos`,component:DocumentsModel},
        {path:`${path}/documentos/boletos`,component:Boleto},
        {path:`${path}/falecomagente`,component:FaleConosco},
        {path:`${path}/falecomagente/novoatendimento`,component:NovoAtendimento},
        {path:`${path}/falecomagente/atendimentos`,component:MeusAtendimentos},
        {path:`${path}/falecomagente/ajuda`,component:Ajuda},
        {path:`${path}/falecomagente/ajuda/:topico/:index?`,component:AjudaEspecifica},
        {path:`${path}/capnews`,component:Capnews},
        {path:`${path}/capnews/:id`,component:CapnewsEspecifico},
        {path:`${path}/destaques`,component:() => <Redirect to={path}/>},
        {path:`${path}/destaques/:id`,component:Informativo},
        {path:`${path}/busca/:busca`,component:BuscaResultsScreen},
        {path:`${path}/trabalhosocial`,component:TrabalhoSocial},
        {path:`${path}/sinistro`, component:SinistroRoute,exact:false},
        {path:`${path}/statussinistros`,component:StatusSinistro}
    ]
    return (
        <BrowserRouter>
            <Header/>
            <Container>
                <Switch>
                    {
                        rotas.map((rotaAtual:RouteReduzida,i:number)=>
                            <CustomRoute path={rotaAtual.path} exact={typeof(rotaAtual.exact)!=="undefined" ? rotaAtual.exact :true} key={"rotaPagina"+i} component={rotaAtual.component}/>
                        )
                    }
                    <Redirect from={path} to="/inicio" />
                </Switch>
                <PageTracker/>
                <PortalLGPDLinkSpace>
                    <PortalLGPDLink href={LGPDURL}>Portal de Privacidade</PortalLGPDLink>
                </PortalLGPDLinkSpace>
            </Container>
        </BrowserRouter>
    )
}