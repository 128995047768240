import styled from "styled-components";

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    gap: 20px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
`;

export const PrimaryButton = styled.button`
    height: 40px;
    align-self: center;
    justify-self: center;
    background-color: #E27800;
    border-radius: 10px; 
    border: none; 
    font-size:1em;
    color: #FFFFFF;
    width:40%;
    ::after, ::before, :focus{
        outline:0px;
    };

    :hover{
        background-color: rgb(226 120 0 / 87%);
    }

    :active{
        background-color: #E27800;
    }
`;

export const DefaultButton = styled.button`
    height:40px;
    border-radius:21px;
    background-color:#DADADA; 
    border:none;
    font-size:1em;
    color:#707070;
    font-family:"Roboto";
    width:40%;
    ::after, ::before, :focus{
        outline:0px;
    };
`;

export const LinkButton = styled.button`
    color: #E27800;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    ::after, ::before, :focus{
        outline:0px;
    };

    :hover{
        text-decoration: underline;
    }
    
`;