
import { SinistroTypes, IDocsNecessarios } from "./reduxTypes";
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from 'typesafe-actions';
import { AxiosResponse } from "axios";
import { Sinistro, Endpoints } from "../../../services/Sinistro.api";
import { obterDocsNecessariosVariosEventosFailure, obterDocsNecessariosVariosEventosSuccess, obterDocsNecessariosUmEventoSuccess, obterAvisoSinistroFailure, obterAvisoSinistroSuccess, obterPendenciasAvisoSinistroFailure, obterPendenciasAvisoSinistroSuccess } from "./actions";
import { IEvento, IEventoDocumento } from "../../../interfaces/storage/contexts/ISinistroContext";
import IAvisoSinistro, { IPendenciasAvisoSinistro } from "../../../interfaces/IAvisoSinistro";

export function* ObterDocsNecessariosDoEvento(action: PayloadAction<SinistroTypes.OBTER_DOCS_NECESSARIOS_UM_EVENTO_REQUEST, number>) {
    try {
        const response: AxiosResponse<IEventoDocumento[]> = yield call(Sinistro().get, Endpoints.DocumentoSinistro + action.payload)   ;
        yield put(obterDocsNecessariosUmEventoSuccess(response.data));
    } catch (e) {
        yield (e as any).response
    }
}

export function* ObterDocsNecessariosVariosEventos(action: PayloadAction<SinistroTypes.OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_REQUEST, IEvento[]>) {
    try {
        const todosEventos:IDocsNecessarios[] = yield all(
            action.payload.map( function* (e){
                const response: AxiosResponse<IDocsNecessarios> = yield call(Sinistro().get, Endpoints.DocumentoSinistro + e.codigo);
                return {
                    evento: e,
                    docs: response?.data
                }
            }) 
        )
        yield put(obterDocsNecessariosVariosEventosSuccess(todosEventos));
    } catch (e) {
        yield put(obterDocsNecessariosVariosEventosFailure((e as any).response));
    }
}

export function* ObterAvisoSinistro(action: PayloadAction<SinistroTypes.OBTER_AVISO_SINISTRO_REQUEST, string>) {
    try {
        const response: AxiosResponse<IAvisoSinistro> = yield call(Sinistro().get, Endpoints.AvisoDeSinistro(action.payload))   ;
        yield put(obterAvisoSinistroSuccess(response.data));
    } catch (e) {
        yield put(obterAvisoSinistroFailure((e as any).response))
    }
}



export function* ObterPendenciasAvisoSinistro(action: PayloadAction<SinistroTypes.OBTER_PENDENCIAS_AVISO_SINISTRO_REQUEST, string>) {
    try {
        const response: AxiosResponse<IPendenciasAvisoSinistro[]> = yield call(Sinistro().get, Endpoints.PendenciasAvisoDeSinistro(action.payload))   ;
        yield put(obterPendenciasAvisoSinistroSuccess(response.data));
    } catch (e) {
        yield put(obterPendenciasAvisoSinistroFailure((e as any).response))
    }
}

export default function* (){
    yield all([
        takeLatest(SinistroTypes.OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_REQUEST, ObterDocsNecessariosVariosEventos),
        takeLatest(SinistroTypes.OBTER_DOCS_NECESSARIOS_UM_EVENTO_REQUEST, ObterDocsNecessariosDoEvento),
        takeLatest(SinistroTypes.OBTER_AVISO_SINISTRO_REQUEST, ObterAvisoSinistro),
        takeLatest(SinistroTypes.OBTER_PENDENCIAS_AVISO_SINISTRO_REQUEST, ObterPendenciasAvisoSinistro)
    ])
}