import styled from "styled-components";
export const RoundedBigNews = styled.div<{backgroundImage:string}>`
    width:100%;
    height:16rem;
    border: 1px inherit solid;
    border-radius: 35px;
    background-image:url(${props => props.backgroundImage});
    background-size: cover;
    background-position: center;
    display:flex;
    flex-direction:column-reverse;
    cursor:pointer;
`
export const BigNewsLabel = styled.label`
    margin-left:6%;
    margin-bottom:6%;
    text-align: left;
    font: Bold 1.5em Roboto;
    letter-spacing: -0.74px;
    color: #FDFDFD;
    opacity: 1;
    background-color:#E27800;
`
export const RoundedNews = styled.div<{backgroundImage:string}>`
    height:10rem;
    border: 1px inherit solid;
    border-radius: 35px 35px 0px 0px;
    background-image:url(${props => props.backgroundImage});
    background-size: cover;
    background-position: center;
    display:flex;
    flex-direction:column-reverse;
`
export const AllPageOrientation = styled.div`
    height:100%;
    width:100%;
    display:flex;
    flex-wrap:wrap;
`
export const NoticiaSmallContainer = styled.div`
    width:48%;
    margin-top:4%;
    margin-left:1%;
    margin-right:1%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 35px 35px 20px 20px;
    opacity: 1;
    max-height:500px;
`
export const MensagemCentralNoticiaContainer = styled.div`
    position:relative;
    display:flex;
    bottom:10%;
    width:100%;
    justify-content:center;
`
export const MensagemCentralNoticia = styled.div`
    width:80%;
    background: #F2C800 0% 0% no-repeat padding-box;
    opacity: 1;
`
export const MensagemCentralNoticiaText = styled.label`
    margin-left:2%;
    text-align: left;
    font: Bold 1.2em Roboto;
    letter-spacing: -0.44px;
    color: #636363;
    opacity: 1;
    margin-bottom:0px;
`
export const SubtituloNoticiaText = styled.label`
    text-align: left;
    font: Regular 17px/30px Roboto;
    letter-spacing: -0.34px;
    color: #636363;
    opacity: 1;
    width:90%;
    margin-bottom:3%;
    justify-self:center;
    margin-left:5%;
    margin-right:5%;
    position:relative;
    bottom:1rem;
`
export const ButtonNoticiaContainer = styled.div`
    position:relative;
    bottom:1rem;
    margin-left:20%;
    margin-right:20%;
    width:60%;
`
export const ButtonNoticiaContainerInside = styled.button`
    width:100%;
    border: 1px solid #E27800;
    border-radius: 39px;
    opacity: 1;
    text-align: center;
    font: Regular 1em Roboto;
    letter-spacing: -0.26px;
    color: #E27800;
    text-transform: uppercase;
    opacity: 1;
    background-color:inherit;
    ::after
    {
        outline:0px;
    }
    ::before
    {
        outline:0px;
    }
    :focus{
        outline:0px;
    }
`