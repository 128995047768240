import styled from 'styled-components';
export const HomeContainer = styled.div`
    //margin-left:10px;
`
export const TituloContainer = styled.div`
    text-align: left;
    font: normal normal bold 29px/38px Roboto;
    color: #e27800;
    padding: 0;
    margin-bottom: 13px;
`
export const TextContainer = styled.label`
    margin: 0px;
    padding: 0px;
    text-align: left;
    font: normal normal normal 14px/19px Roboto;
    color: #636363;
`
export const PageModelSpace = styled.div`
    color: #636363;
    @media screen and (min-width: 1040px) {    
        padding-left:20px;
    }
    @media screen and (max-width: 1039px) {
        padding-left:10px;
        padding-right:10px;
    }
    height:fit-content;
    width:100%
    
`