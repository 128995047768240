import React, { useRef } from 'react';
import { BulletPointList, PageContent, MainContentSpace, PrintDocsLabel, PrintBackdrop, PrintInsideSpace, CapemisaImage, SubDocHeader } from './style';
import { IEvento, IEventoDocumento } from '../../../interfaces/storage/contexts/ISinistroContext';
import { SelectedRowTitle } from '../../../components/CustomDropdown/style';
import { useReactToPrint } from 'react-to-print';

const IdentificarDocumento: React.FC<{ isToPrint?: boolean, documentos?: IEventoDocumento[] }> = (props): React.ReactElement => {
    const {isToPrint, documentos } = props;
    
    const printablePageRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => printablePageRef.current,
    })

    const TitleAndOutlinedComponent: React.FC<{ title: React.ReactElement | string }> = (props) => {
        return (
            <div style={{ display: "flex", flexDirection: "column", marginTop: "10px", width: "100%" }}>
                <SelectedRowTitle>{props.title}</SelectedRowTitle>
                {
                    props.children
                }
            </div>
        )
    }
    const PrintablePage: React.FC<{ documentos: IEventoDocumento[] }> = ({ documentos }) => {
        return (
            <PrintBackdrop ref={printablePageRef}>
                <PrintInsideSpace>
                    <CapemisaImage />
                    <IdentificarDocumento documentos={documentos} isToPrint/>
                </PrintInsideSpace>
            </PrintBackdrop>
        )
    }

    const PrintableDocuments: React.FC<{documentos: IEventoDocumento[]}> = ({documentos}) => {
        const documentosObrigatorios = documentos?.filter((doc) => doc.isDocObrigatorio) ?? [];
        const documentosNaoObrigatorios = documentos?.filter((doc) => !doc.isDocObrigatorio) ?? [];
        
        
            return (<>
                <SubDocHeader>
                    <TitleAndOutlinedComponent title={
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            Documentos obrigatórios
                            {
                                !isToPrint ?
                                    <PrintDocsLabel onClick={handlePrint}>Imprimir listagem</PrintDocsLabel>
                                    :
                                    <></>
                            }
                        </div>
                    }>
                    </TitleAndOutlinedComponent>
                </SubDocHeader>
                <BulletPointList>
                    {
                        documentosObrigatorios.map((documento) => {
                            return (
                                <li><p style={{ color: "#7C7C8A" }}>{documento.descDocComunicadoTela}</p></li>
                            )
                        })
                    }
                </BulletPointList>

                <SubDocHeader>
                    <TitleAndOutlinedComponent title={"Demais documentos"}>
                    </TitleAndOutlinedComponent>
                </SubDocHeader>
                {
                    documentosNaoObrigatorios.length > 0 ?
                        <       BulletPointList>
                            {
                                documentosNaoObrigatorios.map((documento) =>
                                    <li><p style={{ color: "#7C7C8A" }}>{documento.descDocComunicadoTela}</p></li>
                                )
                            }
                        </BulletPointList>
                        :
                        <></>
                }
            </>)
    }

    return (
        <PageContent>
            {
                !isToPrint ?
                    <div style={{ display: "none" }}>
                        <PrintablePage documentos={documentos ?? []}/>
                    </div>
                    :
                    <></>
            }
            <MainContentSpace>
                <PrintableDocuments documentos={documentos ?? []}/>
            </MainContentSpace>
        </PageContent>
    )
}

export default IdentificarDocumento;