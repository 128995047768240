import axios, { AxiosInstance, CancelTokenSource } from 'axios';
const axiosInstance = () : {axios:AxiosInstance,cancelToken:CancelTokenSource} => {
    const storageToken:string=sessionStorage.getItem("auth") ?? '{"TOKEN": "", "isAuth": false}';
    let axiosInheritInstance = axios;
    let source = axiosInheritInstance.CancelToken.source();
    const axiosInstance = axiosInheritInstance.create({
        headers:JSON.parse(storageToken).TOKEN?{
            Authorization:"Bearer " + JSON.parse(storageToken).TOKEN,
        }:undefined,
        responseType:"json",
        cancelToken:source.token,
    })
    return {axios:axiosInstance,cancelToken:source};
}
export default axiosInstance;