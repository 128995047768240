import React, { useState, useContext, useEffect } from 'react';
import { DotsContainer, ImageButton, Dot,Image } from '../../../components/widgets/Tips/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ITip } from '../../../interfaces/components/ITips';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { GlobalContext } from '../../../storage/contexts/GlobalContext';
import { PageSpace, RightPage, LeftPage, SearchText, SearchHighlightedText, SearchResultsText, Linha, RoundedResults, InsideSugestionText, CentralizeBox, DashedDiv, Url} from './style';
import { useParams, useHistory } from 'react-router-dom';
import { IFavoriteAdd } from '../../../interfaces/components/IFavorite';
const BuscaResultsScreen : React.FC<{}> = () : React.ReactElement =>
{
    let itensMenu = useContext(GlobalContext).globalContext.AllFavorites;
    const history = useHistory(); 
    const {busca} = useParams<{busca: string}>();
    let searchedItensMenu:{url:string,nome:string}[]=[];
    itensMenu?.map((item:IFavoriteAdd)=>{
        console.log(item.message.search(busca ?? "")>-1);
        if(item.message.toUpperCase().search(busca?.toUpperCase() ?? "")>-1)
        searchedItensMenu.push({url:item.url,nome:item.message});
    })
    useEffect(()=>{// eslint-disable-next-line
        searchedItensMenu=[];
        itensMenu?.map((item:IFavoriteAdd)=>{
            console.log(item.message.search(busca ?? "")>-1);
            if(item.message.toUpperCase().search(busca?.toUpperCase() ?? "")>-1)
            searchedItensMenu.push({url:item.url,nome:item.message});
        })
        setResultados(searchedItensMenu);
    }// eslint-disable-next-line
    ,[busca])
    const [resultados,setResultados] = useState<{url:string,nome:string}[]>(searchedItensMenu);
    
    return(
        <PageSpace>
            <LeftPage>
                <SearchText>Resultado de pesquisa para "{<SearchHighlightedText>{busca}</SearchHighlightedText>}"</SearchText>
                <SearchResultsText>Exibindo {resultados.length} resultados</SearchResultsText>
                <Linha width={'50%'}/>
                <RoundedResults>
                    <CentralizeBox>
                        <InsideSugestionText><FontAwesomeIcon icon={faSearch} style={{marginRight:"1%"}}/> O que você procura pode estar em:</InsideSugestionText>
                        <div style={{display:"flex",flexDirection:"column"}}>
                        {
                            resultados.map((value:{url:string,nome:string},i:number)=>{
                                if(i===0)
                                return(
                                    <Url onClick={()=>{history.push(value.url)}}>{value.nome}</Url>
                                )
                                else
                                return(
                                    <DashedDiv>
                                        <Url onClick={()=>{history.push(value.url)}}>{value.nome}</Url>
                                    </DashedDiv>    
                                )
                            })
                        }
                        </div>
                    </CentralizeBox>
                </RoundedResults>
            </LeftPage>
            <RightPage>
                <TipComponent/>
            </RightPage>
        </PageSpace>
    );
}
const TipComponent :React.FC = () : React.ReactElement =>
{
    const data = useContext(GlobalContext).globalContext.Informativos??[{id:0,titulo:"",subtitulo:"",texto:"",dataCriacao:"",urlImagemHome:"",urlImagemDestaque:"",urlImagemLateral:""}]
    const [selected , setSelected] = useState<number>(0);
    const [tip, setTip] = useState<ITip>(data[selected]);

    const changeTip = (tipo: "previous" | "next") =>{
        if(tipo === "next")
            selected === data?.length - 1 ? setSelected(0) : setSelected(selected + 1);
        else if( tipo === "previous")
            selected === 0 ? setSelected(data?.length - 1) : setSelected(selected - 1);
    };

    useEffect(() => {
        setTip(data[selected]);// eslint-disable-next-line
    }, [selected])

    React.useEffect(() => {
        const timer = setInterval(()=>{changeTip("next")},5000);

        return () => {
            clearInterval(timer);
        };
    })
    return(
        <Image src={tip?.urlImagemLateral}>
                <ImageButton 
                    tipo="previous" 
                    onClick={(ev) => {
                        changeTip("previous");
                        ev.preventDefault();
                    }}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{fontSize:14}}/>
                </ImageButton>

                <DotsContainer> 
                    {data?.map( (e, i )=> {
                        return <Dot index={i} isSelected={i === selected} onClick={()=> setSelected(i)}/>
                    })}
                </DotsContainer>

                <ImageButton 
                    tipo="next" 
                    onClick = {(ev) => {
                        changeTip("next");
                        ev.preventDefault();
                    }}>
                    <FontAwesomeIcon icon={faChevronRight} style={{fontSize:14}} />
                </ImageButton>
            </Image>
    )
}
export default BuscaResultsScreen