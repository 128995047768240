import React, { useEffect, useState } from 'react';
import PageModel from '../../../components/PageModel';
import { GetNovidade } from '../../../API/usuario';
import {INovidade} from '../../../interfaces/pages/INovidade';
import {RoundedBigNews, BigNewsLabel, RoundedNews, AllPageOrientation, NoticiaSmallContainer, MensagemCentralNoticiaContainer, MensagemCentralNoticia, MensagemCentralNoticiaText, SubtituloNoticiaText, ButtonNoticiaContainer, ButtonNoticiaContainerInside} from './style';
import Pagination from '../../../components/Paginacao';
import { useHistory } from 'react-router-dom';
import { LoaderRotatorMessage } from '../../../components/Loaders/LoaderRotator';
const Capnews : React.FC<{}> = () : React.ReactElement =>
{   
    let history = useHistory();
    const [novidades,setNovidades] = useState<INovidade[]>([])
    const [actualPage,setActualPage] = useState<number>(1);
    const maximoPagina=5;
    useEffect(()=>{
        GetNovidade().then((response)=>{
            setNovidades(response.data);
        })
    },[]);

    return(
        <PageModel>
            <AllPageOrientation>
                {
                    novidades.length>0?
                    novidades.map((item:INovidade,i:number)=>
                    {
                        if((actualPage*maximoPagina)-maximoPagina<=i&&i<=((actualPage*maximoPagina)-1))
                        {
                            if(i%maximoPagina===0)
                            {
                                return(
                                    <RoundedBigNews onClick={()=>{history.push("/inicio/capnews/"+item.id)}} backgroundImage={item.urlImagemDestaque}>
                                        <div style={{width:"50%"}}>
                                            <BigNewsLabel>{item.titulo}</BigNewsLabel>
                                        </div>
                                    </RoundedBigNews>
                                )
                            }
                            else
                            {
                                return(
                                    <NoticiaSmallContainer>
                                        <RoundedNews backgroundImage={item.urlImagemDestaque}/>
                                        <MensagemCentralNoticiaContainer>
                                            <MensagemCentralNoticia>
                                                <MensagemCentralNoticiaText>{item.titulo}</MensagemCentralNoticiaText>
                                            </MensagemCentralNoticia>
                                        </MensagemCentralNoticiaContainer>
                                        <SubtituloNoticiaText>{item.subtitulo}</SubtituloNoticiaText>
                                        <ButtonNoticiaContainer onClick={()=>{history.push("/inicio/capnews/"+item.id)}}>
                                            <ButtonNoticiaContainerInside>LEIA A MATERIA COMPLETA</ButtonNoticiaContainerInside>
                                        </ButtonNoticiaContainer>
                                    </NoticiaSmallContainer>
                                )
                            }
                        }
                        else
                        {
                            return ""
                        }
                    })
                    :
                    <div style={{display:"flex",width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
                        <LoaderRotatorMessage/>
                    </div>
                }
                {
                    novidades.length>maximoPagina?
                    <div style={{marginTop:"2%"}}>
                    <Pagination actualPage={actualPage} setActualPage={setActualPage} maxPerPage={maximoPagina} length={novidades.length}/>
                    </div>
                    :""
                }
            </AllPageOrientation>
            
        </PageModel>
    )
}
export default Capnews;