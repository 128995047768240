import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import SinistroPageModel from '../../../components/SinistroComponents/SinistroNewPageModel';
import { TopRounded, TopRoundedRow, TextSuspended, InsideRowColumnName, InsideSelectedText, AlignSpace, SingleLine, Switch, Checkbox, Slider, BtnLaranja, BtnLaranjaSend, BtnCinza } from './style';
import { SelectedRowTitle } from '../../../components/CustomDropdown/style';
import CustomDropdown from '../../../components/CustomDropdown';
import './style.css';
import { useHistory } from 'react-router-dom';
import { useCustomDispatch, useTypedSelector } from '../../../storage/ducks';
import Modal from '../../../components/Modal';
import ModalBootstrap from 'react-bootstrap/Modal';
import { TextInput } from '../../../components/TextInput';
import { IPropsDetalhesSinistro } from '../../../interfaces/pages/IDetalhesSinistro';
import useAPI from '../../../storage/custom_hooks/API';
import IAvisoSinistro, { IAPIDocumentoPendencia, IPendenciaDocumentoAvisoSinistro } from '../../../interfaces/IAvisoSinistro';
import { GetAtendimentoByCPFAndProtocolo, GetDocumentosSinistro, GetListarPendenciaSinistro, GetListarPendenciaSinistroUser, PostInserirDocumentosPendencia } from '../../../services/APICallableActions/sinistro';
import { UploadRow, SinistroDetalhesUploadRowComponent as UploadRowComponent, UploadRowComponent as OriginalUploadRowComponent } from '../../../components/DragAndDropUpload';
import { EnviarArquivoIndexadoAsync, ITagsAnexo } from '../../../API/file';
import { ATUALIZARROW, ESVAZIARTODOSOSGRUPOS, ESVAZIARSTATE, ATUALIZARGRUPO, IDetalheSinistro, ATUALIZARVARIOSGRUPOS, ICreateDetalheGroupObject, DetalheSinistroTiposDocumento, IUploadRowDetalheSinistro } from '../../../storage/ducks/DetalheSinistroDocumentos';
import { IEventoDocumento } from '../../../interfaces/storage/contexts/ISinistroContext';
import { IDocumentoEvento } from '../../../interfaces/pages/IEnviarDocumentos';
import { LoaderRotatorModal } from '../../../components/Loaders/LoaderRotator';
import StyledAlert from '../../../components/StyledAlert';
import { GlobalContext } from '../../../storage/contexts/GlobalContext';

const defaultValues: IAvisoSinistro = {
    alcada: '',
    cnpjEmpresaEmpregador: '',
    codigo: '',
    codigoRef: '',
    comunicante: {
        b2cId: '',
        bairro: '',
        cep: '',
        cidade: '',
        complemento: '',
        cpf: '',
        dataBloqueio: '',
        dataCriacao: '',
        dataNascimento: '',
        dataUltimaTentativaValidacao: '',
        dddTelefone1: '',
        dddTelefone2: '',
        dddTelefone3: '',
        email: '',
        endereco: '',
        id: 0,
        isBeneficiario: false,
        isComunicante: true,
        isSegurado: false,
        nome: '',
        nomeMae: '',
        nomePai: '',
        numeroDocumento: '',
        numeroEndereco: '',
        quantidadeTentativaValidacao: 0,
        sexo: '',
        telefone1: '',
        telefone2: '',
        telefone3: '',
        uf: '',
        valido: true,
    },
    cpfSegurado: '',
    dataAlteracao: '',
    dataCriacao: '',
    dataEvento: '',
    dataNascimentoSegurado: '',
    enviadoLiqcon: true,
    evento: {
        codigo: 0,
        descricao: '',
        dtAlteracao: '',
        dtCriacao: '',
        id: 0,
        isAtivo: true,
        nome: ''
    },
    global: false,
    id: 0,
    idAtendimento: 0,
    idParentesco: 0,
    idTipoAtendimento: 0,
    idUsuario: 0,
    listaAnexo: [],
    listaStatusAtendimento: [],
    nomeBuscaSegurado: '',
    nomeCompletoSegurado: '',
    nomeEmpresaEmpregador: '',
    nomeEmpresaTransporte: '',
    nomeInstituicaoEnsino: '',
    nomeLojaVarejo: '',
    numeroBilhete: '',
    numeroDocumento: '',
    relacionamento: {
        ativo: true,
        codigo: 0,
        dataAlteracao: '',
        dataCriacao: '',
        id: 0,
        nome: '',
        ordem: 0,
    },
    relato: '',
    tipoAtendimento: {
        assunto: '',
        assuntoRef: '',
        codAssunto: 0,
        codProvidencia: 0,
        codigo: '',
        descricao: '',
        id: 0,
        isAtivo: true,
        ordem: 0,
    }
}

const beneficiarioInitKey = 'Beneficiario';

const formatarData = (data: string | null | undefined) => data ? new Date(data).toLocaleDateString("pt-br") : '';

const numeroCaracteresObservacao = 1000;
const validarObservacao = (obsField: string) => {
    if(obsField.length <= numeroCaracteresObservacao)
        return true;
    else{
        StyledAlert("Insira no máximo " + numeroCaracteresObservacao + " caracteres");
        return false;
    }
    
}

const DetalheAviso: React.FC<IPropsDetalhesSinistro> = (props): React.ReactElement => {
    const {
        location: {
            state: {
                data: propsAtendimentoData,
                userType,
            },
        },
    } = props;
    // const propsAtendimentoData = props.location.state.data;
    const {
        codigo: propsProtocolo,
        cpfSegurado: propsCpfSegurado,
    } = propsAtendimentoData as {
        codigo: string,
        cpfSegurado: string,
    };
    const history = useHistory();
    const { globalContext } = useContext(GlobalContext);

    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [observacaoAvisoAdded, setObservacaoAvisoAdded] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [leiaMaisAviso, setLeiaMaisAviso] = useState(false);
    const [isModalDocumentacaoEnviadaOpen, setIsModalDocumentacaoEnviadaOpen] = useState(false);
    const [documentoSelecionado, setDocumentoSelecionado] = useState<IEventoDocumento>();
    const [documentosOptions, setDocumentosOptions] = useState<IEventoDocumento[]>([]);
    const [isMessageAlreadyShown, setIsMessageAlreadyShown] = useState<boolean>(false);

    const [atendimentoData, initAtendimentoQuery] = useAPI<IAvisoSinistro>(
        GetAtendimentoByCPFAndProtocolo(propsCpfSegurado, propsProtocolo)
    );

    const propertiesObj = useMemo<IAvisoSinistro>(() => {
        if(atendimentoData.status === 'FAILED'){
            alert('Aviso não localizado! Verifique os dados e tente novamente');
            history.replace(`/inicio/sinistro/acompanhamentoSinistro`);
        }

        if (userType === "protocolo" && atendimentoData.status !== 'COMPLETED')
            return (propsAtendimentoData as IAvisoSinistro);
        else
            return (atendimentoData.status === "COMPLETED" && atendimentoData.data) || defaultValues;
    }, [atendimentoData.status]);

    const Pendencias = useTypedSelector((selector) => selector.DetalheSinistroDocumentos);

    const {
        Adicional: docsAdicionaisObj,
        Sinistro: sinistroPendenciaObj,
    } = Pendencias;

    const sinistroAllFilesToSend = [
        ...sinistroPendenciaObj.files.filter(row => row.status === "completed" && !!!row.doesNotHaveDoc),
        ...docsAdicionaisObj.files.filter(row => row.status === "completed" && !!!row.doesNotHaveDoc)
    ];

    const {
        codigo,
        cpfSegurado,
        dataCriacao,
        dataEvento,
        dataNascimentoSegurado,
        evento,
        idAtendimento,
        listaStatusAtendimento,
        nomeCompletoSegurado,
    } = propertiesObj;

    console.log("props", props);
    console.log("propriedades atribuidas", propertiesObj);
    const avisoPendenciasDataToSend: IAPIDocumentoPendencia = {
        idAtendimento,
        listaAnexo: sinistroAllFilesToSend.flatMap(row =>
            row.fileAPIReferences?.map(
                ({ guid, nome }): IDocumentoEvento => ({
                    codDocCapemisa: row.tipoDocumento,
                    isObrigatorioDocCapemisa: row.obligatory ?? false,
                    nomeDocCapemisa: row.nomeColuna,
                    guid,
                    nome,
                })
            ) ?? []
        ),
        listaIdPendencia: sinistroPendenciaObj.pendenciaIdList ?? [],
        observacao: observacaoAvisoAdded
    }

    const [resolverPendenciaQuery, enviarDocumentosParaResolverPendenciasSinistro, disposeEnviarPendencias] = useAPI<IAPIDocumentoPendencia>(
        PostInserirDocumentosPendencia(avisoPendenciasDataToSend)
    );
    const [eventoDocumentos, runDocumentosQuery, disposeEventoDocumentosQuery] = useAPI<IEventoDocumento[]>(
        GetDocumentosSinistro(evento.codigo)
    );
    const [documentosToSend, initDocumentosQuery, disposeDocumentosQuery] = useAPI<IPendenciaDocumentoAvisoSinistro[]>(
        userType === 'beneficiario' ? GetListarPendenciaSinistroUser(idAtendimento?.toString()) : GetListarPendenciaSinistro(idAtendimento?.toString())
    );

    const dispatch = useCustomDispatch();

    const clearEffectsAndRestartPage = () => {
        disposeDocumentosQuery();
        initDocumentosQuery();
        setIsModalDocumentacaoEnviadaOpen(true);
        initAtendimentoQuery();
    }

    useEffect(() => {
        if (userType === "beneficiario" || userType === "protocoloNoData")
            initAtendimentoQuery();
    }, [userType])

    useEffect(() => {
        if (resolverPendenciaQuery.status === "COMPLETED") {
            disposeEnviarPendencias();
            disposeDocumentosQuery();
            disposeEventoDocumentosQuery();
            dispatch(ESVAZIARSTATE(DetalheSinistroTiposDocumento.ADICIONAL));
            setIsModalDocumentacaoEnviadaOpen(true);
            runDocumentosQuery();
            initDocumentosQuery();
            initAtendimentoQuery();
        }
        if (
            resolverPendenciaQuery.status === "FAILED"
        )
            alert(resolverPendenciaQuery.errorResponse)
        if (
            resolverPendenciaQuery.status === "COMPLETED"
            || resolverPendenciaQuery.status === "FAILED"
        )
            setIsLoading(false);
    }, [resolverPendenciaQuery.status])

    useEffect(() => {
        runDocumentosQuery();
    }, [evento]);

    useEffect(() => {
        if (idAtendimento) {
            initDocumentosQuery();
            dispatch(ESVAZIARTODOSOSGRUPOS());
        }
    }, [idAtendimento])

    useEffect(() => {
        if (documentosToSend.status === "COMPLETED") {
            dispatch(ESVAZIARTODOSOSGRUPOS());
            const allDocumentsToSend: {
                data: UploadRow[],
                codigoHabiIndivBeneficio: number,
                numSinistro: number,
                codigoPlano: number,
                idContrato: number,
                idHabilitacaoIndivReversao: number,
                nomeBeneficiario: string,
                obsCliente: string,
                id: number,
            }[] = documentosToSend.data?.map(
                ({
                    codigoHabiIndivBeneficio,
                    documentoPendenteSinistros,
                    numSinistro,
                    codigoPlano,
                    idContrato,
                    idHabilitacaoIndivReversao,
                    nomeBeneficiario,
                    obsCliente,
                    id,
                }) => (
                    {
                        codigoHabiIndivBeneficio,
                        numSinistro,
                        codigoPlano,
                        idContrato,
                        idHabilitacaoIndivReversao,
                        nomeBeneficiario,
                        obsCliente,
                        id,
                        data: documentoPendenteSinistros.map(
                            ({
                                documentoSinistro: {
                                    descDocComunicadoTela,
                                    codDocumentoComunicado
                                }
                            }) => (
                                {
                                    files: [],
                                    nomeColuna: descDocComunicadoTela,
                                    status: "editable",
                                    tipoDocumento: codDocumentoComunicado,
                                    obligatory: true,
                                }
                            )
                        )
                    }
                )
            ) ?? [];

            let groupsToInclude: {
                group: ICreateDetalheGroupObject,
                codigoHabiIndivBeneficio: number,
                numSinistro: number,
                codigoPlano: number,
                idContrato: number,
                idHabilitacaoIndivReversao: number,
                nomeBeneficiario: string,
            }[] = [];

            for (let item of allDocumentsToSend) {
                const foundExistingGroupIndex = groupsToInclude.findIndex((group) =>
                    group.nomeBeneficiario === item.nomeBeneficiario
                    && group.codigoHabiIndivBeneficio === item.codigoHabiIndivBeneficio
                    && group.numSinistro === item.numSinistro
                    && group.codigoPlano === item.codigoPlano
                    && group.idContrato === item.idContrato
                    && group.idHabilitacaoIndivReversao === item.idHabilitacaoIndivReversao
                )
                if (foundExistingGroupIndex >= 0) {
                    groupsToInclude[foundExistingGroupIndex].group.detalhe.files.push(...item.data);
                    groupsToInclude[foundExistingGroupIndex].group.detalhe.pendenciaIdList?.push(item.id);
                }
                else {
                    const isBeneficiario = !!item.nomeBeneficiario;
                    const whereIsMeantToBe = isBeneficiario ?
                        `${beneficiarioInitKey} ${groupsToInclude.length}`
                        :
                        DetalheSinistroTiposDocumento.SINISTRO;
                    groupsToInclude.push(
                        {
                            codigoHabiIndivBeneficio: item.codigoHabiIndivBeneficio,
                            codigoPlano: item.codigoPlano,
                            group: {
                                detalhe: {
                                    files: item.data,
                                    titulo: isBeneficiario ? item.nomeBeneficiario : undefined,
                                    observacao: item.obsCliente,
                                    pendenciaIdList: [item.id]
                                },
                                where: whereIsMeantToBe,
                            },
                            idContrato: item.idContrato,
                            idHabilitacaoIndivReversao: item.idHabilitacaoIndivReversao,
                            nomeBeneficiario: item.nomeBeneficiario,
                            numSinistro: item.numSinistro,
                        }
                    )
                }
            }

            dispatch(
                ATUALIZARVARIOSGRUPOS(
                    groupsToInclude.map(
                        ({ group: { where, detalhe } }) => {
                            const {
                                files
                            } = detalhe;
                            return ({
                                where,
                                detalhe: {
                                    ...detalhe,
                                    files: files.filter((file, i) =>
                                        !!!files.slice(0, i).find(item => item.tipoDocumento === file.tipoDocumento)
                                    )
                                }
                            })
                        }
                    )
                )
            )
        }
        // eslint-disable-next-line
    }, [documentosToSend.status])

    const ReplacePositionArray = useCallback((rowAtualizada: UploadRow, where?: string) => {
        dispatch(ATUALIZARROW(rowAtualizada, where));
    }, [dispatch]);

    const statusAtual = listaStatusAtendimento.length > 0 && listaStatusAtendimento.reduce(
        (prevVal, currentVal) => (
            new Date(prevVal.dataCriacao) >= new Date(currentVal.dataCriacao) ? prevVal : currentVal
        ),
    ) || undefined

    const handleChangeTipoDocumento = (props: { target: { value: string } }) => {
        const {
            target: {
                value,
            },
        } = props;
        const documentoRelacionado = documentosOptions?.find((evento) =>
            evento.codDocumentoComunicado.toString() === value
        ) ?? {
            codDocumentoComunicado: 0,
            descDocComunicadoTela: '',
            idEventoSinistro: 0,
            isDocObrigatorio: false,
            nomeEvento: '',
        };
        setDocumentoSelecionado(documentoRelacionado);
    };

    const handleInserirNovoDocumento = () => {
        if (documentoSelecionado) {
            const novoGrupo: IDetalheSinistro = {
                files: [
                    ...docsAdicionaisObj.files,
                    {
                        files: [],
                        nomeColuna: documentoSelecionado.descDocComunicadoTela,
                        status: 'editable',
                        tipoDocumento: documentoSelecionado.codDocumentoComunicado,
                        obligatory: true,

                    }
                ],
            };
            const documentoIndex = documentosOptions.findIndex(
                (item) => item.codDocumentoComunicado === documentoSelecionado.codDocumentoComunicado
            );
            let novasOpcoes = [...documentosOptions];
            novasOpcoes.splice(documentoIndex, 1);
            setDocumentosOptions(novasOpcoes);
            setDocumentoSelecionado(novasOpcoes.length ? novasOpcoes[documentoIndex] : undefined);
            dispatch(
                ATUALIZARGRUPO(
                    novoGrupo,
                    DetalheSinistroTiposDocumento.ADICIONAL
                )
            );
        }
    };

    const handleEnviarAvisoPendenciaArquivos = () => {

        const EnviarDoc = () => {
            setIsLoading(true);
            enviarDocumentosParaResolverPendenciasSinistro();
            setIsMessageAlreadyShown(false);
            setObservacaoAvisoAdded("");
        }

        const autorizarEnvioDocumento = () => {
            StyledAlert(mensagemAnexoDocVazio, "Ok");
            setIsMessageAlreadyShown(true);
        }

        const mensagemAnexoDocVazio = `Atenção, você não anexou nenhum documento. Caso queira, pode retornar a 
        tela anterior e refazer o envio ou se desejar 
        prosseguir basta clicar no botão "enviar documentação".`

        const filesArray = [
            ...sinistroPendenciaObj.files.filter(row => !!!row.doesNotHaveDoc),
            ...(checkboxChecked ? docsAdicionaisObj.files.filter(row => !!!row.doesNotHaveDoc) : [])
        ];

        if (
            filesArray.every(item => item.status === "completed")
        ) {
            if (
                filesArray.filter(item => item.status === "completed").length <= 0
                && observacaoAvisoAdded.length <= 0
                && !isMessageAlreadyShown
                && statusAtual?.idStatus === 4
            ) {
                autorizarEnvioDocumento()
            }
            else {
                if (
                    (filesArray.every(item => item.status == 'completed') && !filesArray.every(item => item.doesNotHaveDoc))
                    || isMessageAlreadyShown
                    || (!docsAdicionaisObj.files.every(item => item.doesNotHaveDoc) && docsAdicionaisObj.files.every(item => item.status == 'completed'))
                ) {
                    if(validarObservacao(observacaoAvisoAdded)){
                        EnviarDoc();
                    }
                }
                else if (observacaoAvisoAdded.length > 0) {
                    autorizarEnvioDocumento()
                }
            }
        }
        else
            alert("Insira os documentos pendentes!");
    }

    const createHandlerForNaoTerDocumento = (rowAtualizada: UploadRow, where?: string) => {
        const handleNaoTerDocumento = (doesNotHaveDoc: boolean) => {
            const newRowWithDetailConfig: IUploadRowDetalheSinistro = {
                ...rowAtualizada,
                doesNotHaveDoc,
            }
            dispatch(ATUALIZARROW(newRowWithDetailConfig, where));
        }
        return handleNaoTerDocumento;
    }

    useEffect(() => {
        if (eventoDocumentos.status === "COMPLETED")
            setDocumentosOptions(eventoDocumentos.data ?? []);
    }, [eventoDocumentos.status])

    console.log(Pendencias);
    const beneficiarioRows = Object.entries(Pendencias).filter(
        ([key]) => (
            key.startsWith(beneficiarioInitKey)
        )
    )
    const enviarDocsText = <>
        Os arquivos podem ser enviados através de foto ou arquivo digitalizado
        (formatos <b>.jpg</b>, <b>.png</b>, ou <b>.pdf</b> e não podem ultrapassar <b>20MB</b>)
    </>

    const arquivoTags = useMemo<any>(() => {
        return {
            nome: nomeCompletoSegurado,
        }
    }, []);

    console.log(statusAtual);

    return (
        <SinistroPageModel tituloPagina={`${codigo} - ${evento?.nome}`}>
            <TitleAndOutlinedComponent title={"Detalhes"}>
                <div style={{ marginTop: "10px" }}>
                    <TopRounded>
                        <TopRoundedRow>
                            <TextSuspended width={"33%"}>
                                <InsideRowColumnName>Segurado/Participante:</InsideRowColumnName>
                                <InsideSelectedText>{nomeCompletoSegurado} - {cpfSegurado?.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, "$1.$2.$3-$4")}</InsideSelectedText>
                            </TextSuspended>
                            <TextSuspended width={"33%"}>
                                <InsideRowColumnName>Data de nascimento:</InsideRowColumnName>
                                <InsideSelectedText>{formatarData(dataNascimentoSegurado)}</InsideSelectedText>
                            </TextSuspended>
                            <TextSuspended width={"33%"}>
                                <InsideRowColumnName>Data da Comunicação:</InsideRowColumnName>
                                <InsideSelectedText>{formatarData(dataCriacao)}</InsideSelectedText>
                            </TextSuspended>
                        </TopRoundedRow>
                        <TopRoundedRow>
                            <AlignSpace>
                                <SingleLine>
                                    <TextSuspended width={"33%"}>
                                        <InsideRowColumnName>Evento:</InsideRowColumnName>
                                        <InsideSelectedText>{evento.nome}</InsideSelectedText>
                                    </TextSuspended>
                                    <TextSuspended width={"33%"}>
                                        <InsideRowColumnName>Data do evento:</InsideRowColumnName>
                                        <InsideSelectedText>{formatarData(dataEvento)}</InsideSelectedText>
                                    </TextSuspended>
                                    <TextSuspended width={"33%"}>
                                        <InsideRowColumnName>Status:</InsideRowColumnName>
                                        <InsideSelectedText>{statusAtual?.status.nome}</InsideSelectedText>
                                    </TextSuspended>
                                </SingleLine>
                            </AlignSpace>
                        </TopRoundedRow>
                    </TopRounded>
                </div>
            </TitleAndOutlinedComponent>

            {
                sinistroPendenciaObj.files.length > 0
                && (
                    <>
                        <TitleAndOutlinedComponent title={"Documentos pendentes do Aviso"}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <p style={
                                    {
                                        margin: "5px 0",
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        justifyContent: 'space-between',
                                        width: 'calc(89%)'
                                    }
                                }>
                                    {
                                        sinistroPendenciaObj.observacao
                                    }
                                </p>
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: "#E27800",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        width: '10%',
                                    }}
                                    onClick={
                                        () => {
                                            setLeiaMaisAviso(true);
                                        }
                                    }
                                >
                                    Leia mais
                                </span>
                            </div>
                            {
                                sinistroPendenciaObj.files.map((row) => {
                                    return (
                                        <div style={{ marginTop: "10px" }}>
                                            <UploadRowComponent
                                                setFiles={ReplacePositionArray}
                                                sendFiles={EnviarArquivoIndexadoAsync}
                                                sendFilesProps={[arquivoTags]}
                                                row={row}
                                                checked={row.doesNotHaveDoc}
                                                setChecked={createHandlerForNaoTerDocumento(row)}
                                            />
                                        </div>
                                    )
                                })
                            }

                            {leiaMaisAviso && (
                                <Modal
                                    size='xl'
                                    alignButtons="flex-end"
                                    hasSubmit
                                    onSubmit={() => setLeiaMaisAviso(false)}
                                >
                                    <p>
                                        {
                                            sinistroPendenciaObj.observacao
                                        }
                                    </p>
                                </Modal>
                            )}
                        </TitleAndOutlinedComponent>
                    </>
                )
            }
            <h6 style={{ display: "inline-block", marginTop: "20px", marginBottom: "20px", fontWeight: "bold" }}>Deseja enviar mais documentos?</h6>
            {
                statusAtual?.idStatus === 4 &&
                <Switch style={{ marginBottom: '5px' }}>
                    <Checkbox checked={checkboxChecked} onClick={() => setCheckboxChecked(!checkboxChecked)} />
                    <Slider checked={checkboxChecked} />
                </Switch>
            }
            {
                (checkboxChecked || statusAtual?.idStatus !== 4) && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                width: '30%',
                                alignItems: 'center',
                            }}>
                                <TextInput
                                    width='calc(100% - 90px)'
                                    type="combotextvalue"
                                    rotulo=""
                                    options={
                                        (documentosOptions?.map((opcao) => ({
                                            text: opcao.descDocComunicadoTela,
                                            value: (opcao.codDocumentoComunicado ?? "").toString()
                                        })) ?? []) as {
                                            text?: string | undefined;
                                            value?: string | undefined;
                                        }[]
                                    }
                                    valor={documentoSelecionado?.codDocumentoComunicado}
                                    onChange={handleChangeTipoDocumento}
                                />
                                <BtnLaranja
                                    onClick={handleInserirNovoDocumento}
                                    style={{ marginTop: '4px' }}
                                >
                                    Adicionar
                                </BtnLaranja>
                            </div>
                            <div style={{
                                width: '60%',
                            }}>
                                {
                                    enviarDocsText
                                }
                            </div>
                        </div>
                        {
                            docsAdicionaisObj.files.map((row) =>
                                <div style={{ margin: '5px 0' }}>
                                    <OriginalUploadRowComponent
                                        setFiles={(rowAtualizada) =>
                                            ReplacePositionArray(
                                                rowAtualizada,
                                                DetalheSinistroTiposDocumento.ADICIONAL
                                            )
                                        }
                                        sendFiles={EnviarArquivoIndexadoAsync}
                                        sendFilesProps={[arquivoTags]}
                                        row={row}
                                    />
                                </div>
                            )
                        }
                    </>
                )
            }
            <TitleAndOutlinedComponent title={"Observação"}>
                <TextInput rotulo={""} valor={observacaoAvisoAdded} maxLength={numeroCaracteresObservacao}
                //onChange={(e: { target: { value: string } }) => { setObservacaoAvisoAdded(e.target.value) }} 
                onChange={
                    (e:{target:{value:string}})=>{
                        setObservacaoAvisoAdded(e.target.value);
                        validarObservacao(observacaoAvisoAdded);
                    }
                } 
                onBlur={()=>validarObservacao(observacaoAvisoAdded)}
                type={"bigtext"} placeHolder={"Caso haja alguma observação, escreva aqui"} />
                <div style={{display:"flex", justifyContent:"flex-end"}}>
                    <span>{numeroCaracteresObservacao - observacaoAvisoAdded.length} caracteres restantes</span>
                </div>
            </TitleAndOutlinedComponent>
            {
                (
                    resolverPendenciaQuery.status === "NOTINITIALIZED"
                    || resolverPendenciaQuery.status === "FAILED"
                )
                && statusAtual?.idStatus === 4
                && <BtnLaranjaSend onClick={handleEnviarAvisoPendenciaArquivos}>ENVIAR DOCUMENTAÇÃO</BtnLaranjaSend>
            }
            {
                beneficiarioRows.length > 0
                && (
                    <>
                        <TitleAndOutlinedComponent title={"Documentos pendentes do Beneficiário"}>
                            {
                                beneficiarioRows.map(([beneficiarioReduxReference, beneficiarioRow]) => {
                                    return (
                                        <BeneficiarioPendenciaComponent
                                            nomeSegurado={nomeCompletoSegurado}
                                            beneficiarioRow={beneficiarioRow}
                                            ReplacePositionArray={
                                                (rowAtualizada: UploadRow) =>
                                                    ReplacePositionArray(rowAtualizada, beneficiarioReduxReference)
                                            }
                                            createHandleNaoTerDocumento={(uploadRow: UploadRow) =>
                                                createHandlerForNaoTerDocumento(
                                                    uploadRow,
                                                    beneficiarioReduxReference
                                                )
                                            }
                                            idAtendimento={idAtendimento}
                                            clearEffectsAndRestartPage={clearEffectsAndRestartPage}
                                            setIsLoading={setIsLoading}
                                        />
                                    )
                                })
                            }
                        </TitleAndOutlinedComponent>
                    </>
                )
            }
            {
                statusAtual?.idStatus !== 4 &&
                <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                    <div style={{ display: 'flex', width: '70%' }}>
                        <BtnCinza
                            style={{ marginTop: "10px", marginLeft: '0', width: '50%' }}
                            onClick={() => history.push('/inicio/falecomagente/atendimentos/')}
                        >
                            VOLTAR
                        </BtnCinza>
                        <BtnLaranjaSend
                            onClick={handleEnviarAvisoPendenciaArquivos}
                            style={{ marginLeft: '10px', width: 'calc(50% - 5px)' }}
                        >
                            ENVIAR DOCUMENTAÇÃO
                        </BtnLaranjaSend>
                    </div>
                </div>
            }
            {
                statusAtual?.idStatus === 4 &&
                <BtnCinza
                    style={{ marginTop: "10px" }}
                    onClick={() => history.push('/inicio/falecomagente/atendimentos/')}
                >
                    VOLTAR
                </BtnCinza>
            }
            {
                isModalDocumentacaoEnviadaOpen
                && <Modal alignButtons="center">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <p style={{
                            textAlign: 'center',
                            font: 'normal normal bold 1.6em Roboto',
                            letterSpacing: '0px',
                            color: '#E27800',
                            textTransform: 'uppercase',
                            opacity: 1,
                        }}>
                            DOCUMENTAÇÃO ENVIADA
                        </p>
                        <BtnLaranjaSend onClick={() => setIsModalDocumentacaoEnviadaOpen(false)} marginLeft={'0px'}>Ok</BtnLaranjaSend>
                    </div>
                </Modal>
            }
            <LoaderRotatorModal show={isLoading} />
        </SinistroPageModel>
    )
}

const BeneficiarioPendenciaComponent = (props: {
    beneficiarioRow: IDetalheSinistro,
    ReplacePositionArray: (rowAtualizada: UploadRow) => void,
    createHandleNaoTerDocumento: (rowAtualizada: UploadRow) => (doesNotHaveDoc: boolean) => void,
    idAtendimento: number,
    clearEffectsAndRestartPage: Function,
    setIsLoading: (newVal: boolean) => void,
    nomeSegurado: string
}) => {
    const {
        beneficiarioRow,
        ReplacePositionArray,
        createHandleNaoTerDocumento,
        idAtendimento,
        clearEffectsAndRestartPage,
        setIsLoading,
        nomeSegurado,
    } = props;
    const { files, observacao: obs, titulo } = beneficiarioRow;
    const [leiaMaisBenef, setLeiaMaisBenef] = useState(false);
    const [observacao, setObservacao] = useState("");

    const avisoPendenciasDataToSend: IAPIDocumentoPendencia = {
        idAtendimento,
        listaAnexo: beneficiarioRow.files.flatMap(row =>
            row.fileAPIReferences?.map(
                ({ guid, nome }): IDocumentoEvento => ({
                    codDocCapemisa: row.tipoDocumento,
                    isObrigatorioDocCapemisa: row.obligatory ?? false,
                    nomeDocCapemisa: row.nomeColuna,
                    guid,
                    nome,
                })
            ) ?? []
        ),
        listaIdPendencia: beneficiarioRow.pendenciaIdList ?? [],
        observacao: observacao
    }

    const [resolverPendenciaQuery, enviarDocumentosParaResolverPendenciasSinistro, disposeEnviarPendencias] = useAPI<IAPIDocumentoPendencia>(
        PostInserirDocumentosPendencia(avisoPendenciasDataToSend)
    )

    const handleEnviarAvisoPendenciaArquivos = () => {
        if (
            beneficiarioRow.files
                .filter(item => !!!item.doesNotHaveDoc)
                .every(item => item.status === "completed")
            && (
                resolverPendenciaQuery.status === "NOTINITIALIZED"
                || resolverPendenciaQuery.status === "FAILED"
            )
        ) {
            if(validarObservacao(observacao)){
                setIsLoading(true);
                enviarDocumentosParaResolverPendenciasSinistro();
            }
        }
        else
            alert(`Insira os documentos pendentes no beneficiário ${titulo}!`);
    }

    useEffect(() => {
        if (resolverPendenciaQuery.status === "COMPLETED") {
            setIsLoading(false);
            clearEffectsAndRestartPage();
            disposeEnviarPendencias();
        } else if (resolverPendenciaQuery.status === "FAILED") {
            setIsLoading(false);
            alert(resolverPendenciaQuery.errorResponse);
        }
    }, [resolverPendenciaQuery.status])

    const { globalContext } = useContext(GlobalContext);

    const arquivoTags = useMemo<any>(() => {
        return {
            nome: nomeSegurado,
        }
    }, []);

    return (
        <div style={{ marginTop: "10px" }}>
            <CustomDropdown bodyIsClickable titulo={`Beneficiário(a) ${titulo}`}>
                <div style={{ padding: "0 25px 0px 25px" }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <p style={
                            {
                                margin: "5px 0",
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                justifyContent: 'space-between',
                                width: 'calc(89%)'
                            }
                        }>
                            {
                                obs
                            }
                        </p>
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                color: "#E27800",
                                cursor: "pointer",
                                textDecoration: "underline",
                                width: '10%',
                            }}
                            onClick={
                                () => {
                                    setLeiaMaisBenef(true)
                                }
                            }
                        >
                            Leia mais
                        </span>
                    </div>
                    {
                        files.map((row) =>
                            <div style={{ marginTop: "10px" }}>
                                <UploadRowComponent
                                    setFiles={ReplacePositionArray}
                                    sendFiles={EnviarArquivoIndexadoAsync}
                                    sendFilesProps={[arquivoTags]}
                                    row={row}
                                    checked={row.doesNotHaveDoc}
                                    setChecked={createHandleNaoTerDocumento(row)}
                                />
                            </div>
                        )
                    }
                    <TitleAndOutlinedComponent title={"Observação"}>
                        <TextInput maxLength={numeroCaracteresObservacao}
                            rotulo={""}
                            valor={observacao}
                            //onChange={(e: { target: { value: string } }) => { setObservacao(e.target.value) }}
                            onChange={
                                (e:{target:{value:string}})=>{
                                    setObservacao(e.target.value);
                                    validarObservacao(observacao);
                                }
                            } 
                            onBlur={()=>validarObservacao(observacao)}
                            type={"bigtext"}
                            placeHolder={"Caso haja alguma observação, escreva aqui"}
                        />
                        <div style={{display:"flex", justifyContent:"flex-end"}}>
                            <span>{numeroCaracteresObservacao - observacao.length} caracteres restantes</span>
                        </div>
                    </TitleAndOutlinedComponent>
                    
                </div>
                <BtnLaranjaSend onClick={handleEnviarAvisoPendenciaArquivos}>ENVIAR DOCUMENTAÇÃO</BtnLaranjaSend>
                <LoaderRotatorModal show={resolverPendenciaQuery.status === "PENDING"} />
                {leiaMaisBenef && (<Modal size='xl' alignButtons="flex-end" hasSubmit onSubmit={() => setLeiaMaisBenef(false)}>
                    <p>
                        {
                            obs
                        }
                    </p>
                </Modal>)}
            </CustomDropdown>
        </div>
    )
}

const TitleAndOutlinedComponent: React.FC<{ title: string }> = (props) => {
    return (
        <div style={{ display: "flex", marginTop: "10px", flexDirection: "column" }}>
            <SelectedRowTitle>{props.title}</SelectedRowTitle>
            {
                props.children
            }
        </div>
    )
}

export default DetalheAviso;