import ReactGA from "react-ga4";
import { GA_TRACKING_ID } from "../../../env_settings";
export function StartAnalytics() {
  const trackingId = GA_TRACKING_ID ?? "";
  ReactGA.initialize([
    {
      trackingId,
    },
  ]);
}
export default StartAnalytics;
