import styled from 'styled-components';
import '../../styles/fonts/roboto/index.css';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import IcomoonReact from "icomoon-react";
export const MenuSpace = styled.div<{ width: string | number,marginLeft: string | number,height: string|number,marginTop:number|string}>`
    position:absolute;
    top:${props => props.marginTop};
    left:${props => props.marginLeft};
    width:${props => props.width};
    height:${props => props.height};
    background-color:white;
    z-index:10;
    border: 2px solid #B9B9B9;
    border-radius: 0px 0px 19px 19px;
    opacity: 1;
    border-top:0px;
`;
export const MenuTitle = styled.label`
    color:black;
`;
export const Linha = styled.hr`
    margin:0px;
    background-color:#E27800;
`;
export const EspacoPraLista = styled.div`
    display:flex;
    width:100%;
    height:100%;
`;
export const Coluna= styled.div<{width:string}>`
    width:${props=>props.width};
    height:100%;
`
export const Atalho = styled.div`
    display:flex;
    align-items:center;
    height:100%;
    margin-left:1%;
    :hover{
        background-color:#B9B9B9;
    }
`;
export const AtalhoMessage= styled.label<{weight:string,color:any}>`
    margin: 0px;
    font-weight: ${props => props.weight};
    color: ${props => props.color} !important;
    cursor:pointer;
    text-align: left;
    font-size: 12px;
    letter-spacing: -0.36px;
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    opacity: 1;
    width:90%;
`;
export const UserItem = styled.div`
    height:20%;
    display:flex;
    justify-content:space-around;
    align-items:center;
    cursor:pointer;
    :hover{
        background-color:#B9B9B9;
    }
`;
export const UserItemRedirect = styled(Link)`
    height:20%;
    display:flex;
    justify-content:space-around;
    align-items:center;
    cursor:pointer;
    :hover{
        background-color:#B9B9B9;
    }
`;
export const UserTexto=styled.label`
    width: 60%;
    text-align:start;
    margin-bottom:0px;
    cursor:pointer;
`
export const UserIcoFabric=styled(Icon)`
    width:10%;
`
export const UserIcoFA=styled(FontAwesomeIcon)`
    width:10%;
`
export const UserIcoMoon=styled(IcomoonReact)`
    width:10%;
    margin-right:3%;
`
export const FullPage= styled.div<{show:boolean}>`
    width:100vw;
    min-width:980px;
    height:100vh;
    min-height:500px;
    position:absolute;
    left:0;
    top:0;
    z-index:10;
    display:${props => props.show?"initial":"none"}
`