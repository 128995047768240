import IAvisoSinistro, { IPendenciasAvisoSinistro } from "../../../interfaces/IAvisoSinistro";
import { IEvento, IEventoDocumento } from "../../../interfaces/storage/contexts/ISinistroContext";

export enum SinistroTypes{
    OBTER_DOCS_NECESSARIOS_UM_EVENTO_REQUEST = "@Sinistro/OBTER_DOCS_NECESSARIOS_UM_EVENTO_REQUEST",
    OBTER_DOCS_NECESSARIOS_UM_EVENTO_SUCCESS = "@Sinistro/OBTER_DOCS_NECESSARIOS_UM_EVENTO_SUCCESS",
    OBTER_DOCS_NECESSARIOS_UM_EVENTO_FAILURE = "@Sinistro/OBTER_DOCS_NECESSARIOS_UM_EVENTO_FAILURE",
    
    OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_REQUEST = "@Sinistro/OBTER_DOCS_NECESSARIOS_VARIOS_EVENTOS_REQUEST",
    OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_SUCCESS = "@Sinistro/OBTER_DOCS_NECESSARIOS_VARIOS_EVENTOS_SUCCESS",
    OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_FAILURE = "@Sinistro/OBTER_DOCS_NECESSARIOS_VARIOS_EVENTOS_FAILURE",
    
    OBTER_AVISO_SINISTRO_REQUEST = "@Sinistro/OBTER_AVIS_SINISTRO_REQUEST",
    OBTER_AVISO_SINISTRO_SUCCESS = "@Sinistro/OBTER_AVIS_SINISTRO_SUCCESS",
    OBTER_AVISO_SINISTRO_FAILURE = "@Sinistro/OBTER_AVIS_SINISTRO_FAILURE",

    OBTER_PENDENCIAS_AVISO_SINISTRO_REQUEST = "@Sinistro/OBTER_PENDENCIAS_AVISO_SINISTRO_REQUEST",
    OBTER_PENDENCIAS_AVISO_SINISTRO_SUCCESS = "@Sinistro/OBTER_PENDENCIAS_AVISO_SINISTRO_SUCCESS",
    OBTER_PENDENCIAS_AVISO_SINISTRO_FAILURE = "@Sinistro/OBTER_PENDENCIAS_AVISO_SINISTRO_FAILURE",
    
    CLEANATTEMPTS = "@Sinistro/CLEAN_ATTEMPTS"
}

export interface ISinistroState{
    readonly docsNecessariosVariosEventosData: IDocsNecessarios[],
    readonly docsNecessariosVariosEventosLoading: boolean,
    readonly docsNecessariosVariosEventosError: any,
    
    readonly avisoSinistroData: IAvisoSinistro | null,
    readonly avisoSinistroLoading: boolean,
    readonly avisoSinistroError: any,

    readonly pendenciasAvisoSinistroData: IPendenciasAvisoSinistro[] | null,
    readonly pendenciasAvisoSinistroLoading: boolean,
    readonly pendenciasAvisoSinistroError: any,
} 

export interface IDocsNecessarios{
    evento: IEvento,
    docs: IEventoDocumento[]
}