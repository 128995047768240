import styled from 'styled-components';
export const TelMaskedInputComponent = styled.input<{type?:"text",isNegado?:boolean}>`
    width: 100%;
    height: 45px;
    border-radius: 35px;
    border:${props=>props.isNegado?"1px solid red":"1px solid #B9B9B9"};
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    &:disabled {   
        background-color: #EEE; 
    }
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`