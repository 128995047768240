import React from 'react';
import { Container, Check} from './style';
import { ICustonCheckBoxProps } from './types';
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const StyledCheckbox: React.FC<ICustonCheckBoxProps> = (props): React.ReactElement => {
    const { label, ...divProps} = props;

    return (
        <Container {...divProps} >
            <span className="CheckBoxInput">
                <Check icon={faCheck}/>
            </span>
            <label>{props?.children ?? label}</label>
        </Container >

    )
}
export default StyledCheckbox;