import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const DragAndDropSpace = styled.div<{isDisabled?:boolean}>`
    min-height:120px;
    min-width:400px;
    width:90%;
    max-width:90%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background: ${props=>props.isDisabled?"#f2f2f2 0% 0% no-repeat padding-box":"#F8F5F5 0% 0% no-repeat padding-box"};
    border: 1px dashed #707070;
    border-radius: 8px;
    opacity: ${props=>props.isDisabled?"0.8":"1"};
    cursor:pointer;
`
export const DragMessage = styled.label`
    margin:0;
    height:fit-content;
    text-align: left;
    font: normal normal normal 1em Roboto;
    letter-spacing: 0px;
    opacity: 1;
    cursor:pointer;
`
export const DragMessageOrange = styled.label`
    margin:0;
    color: var(--unnamed-color-e27800);
    text-align: left;
    font: normal normal normal 1em Roboto;
    letter-spacing: 0px;
    color: #E27800;
    cursor:pointer;
`
export const Icon = styled(FontAwesomeIcon)`
    font-size:20px;
    cursor:pointer;
`
export const SuspenseFileInput = styled.input<{type:"file",width?:string|number,height?:string|number}>`
    position: absolute;
    width:${props=>(props.width?(props.width.toString()+"px"):"400px")};
    height:${props=>(props.height?(props.height.toString()+"px"):"120px")};
    opacity:0;
    z-index:2;
`
export const DocumentItem = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    min-width:150px;
    padding-right:5px;
`
export const DocumentSpace = styled.div<{width?:string|number}>`
    display:flex;
    z-index:3;
    flex-wrap:nowrap;
    overflow-x:auto;
    max-width:${props=>(props.width?(props.width.toString()+"px"):"400px")};
    cursor:auto;
`
export const DeleteIco = styled(FontAwesomeIcon)`
    font-size:40px;
    color: #E27800;
    padding:0px 10px;
    cursor:pointer;
`
export const DisabledDiv = styled.div<{width?:string|number,height?:string|number}>`
    width:${props=>(props.width?(props.width.toString()+"px"):"400px")};
    height:${props=>(props.height?(props.height.toString()+"px"):"120px")};
    position:absolute;
    z-index: 4;
    //background-color: grey;
    cursor:not-allowed;
`
export const DropdownSpace = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    padding:10px 0px;
`
export const EnviarDocumentosButton = styled.button<{buttonSize?:"default"|"big",canSend?:boolean}>`
    ${props=>props.canSend?"background: #E27800 0% 0% no-repeat padding-box;letter-spacing: 0px;color: #FFFFFF;cursor:pointer;"
    :"background: #D8D8D8 0% 0% no-repeat padding-box;text-align: left;font: normal normal bold 13px/18px Roboto;letter-spacing: 0px;color: #FFFFFF;opacity: 1;cursor:auto!important;"}
    
    ::after, ::before, :focus{
        outline:0px;
    };
    ${props=>props.buttonSize==="big"?"min-width:300px;max-width:400px;border-radius:7px;":"min-width:200px;max-width:300px;border-radius:5px;"}
    border:0;
    margin-top:10px;
    min-height:30px;
    text-align:center;
` 