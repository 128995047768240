import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import RootReducer from './index';
import RootSaga from './saga';

const middlewares = [];
const rootSagaMiddleware = createSagaMiddleware();
middlewares.push(rootSagaMiddleware);
middlewares.push(logger);


const store = createStore(RootReducer,applyMiddleware(...middlewares));
rootSagaMiddleware.run(RootSaga);
export default store;