import styled from 'styled-components';
export const TituloLabel = styled.label`
    text-align: left;
    font: Bold 1rem Roboto;
    letter-spacing: -0.44px;
    color: #E27800;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom:0px;
    padding-right:1%;
`