import React from 'react';
import AFComponent from '../../components/AFComponent';
import SinistroPageModel from '../../components/SinistroComponents/SinistroPageModel';
const AssistenciaFinanceira:React.FC = () : React.ReactElement =>
{
    return(
        <SinistroPageModel flexWrap="nowrap" maxHeight="600px" titulo="Assistência Financeira">
            <AFComponent/>
        </SinistroPageModel>
    )
}
export default AssistenciaFinanceira;