import React, { useCallback, useEffect, useMemo } from 'react';
import CustomDropdown from '../../../components/CustomDropdown';
import { LoaderError } from '../../../components/Loaders/LoaderDots';
import { LoaderRotatorMessage } from '../../../components/Loaders/LoaderRotator';
import { DownContentRow } from '../../../components/OneLineExpand';
import { GetBeneficiarioStatus, GetBeneficiarioStatusDetalhe } from '../../../services/APICallableActions/sinistro';
import { DetailsIcoRowTitle, DetailsIcoTitle } from '../../../components/OneLineExpand/style';
import PageModel from '../../../components/PageModel';
import CorrigirVariavelMonetaria from '../../../functions/CorrigirValorPontuacao';
import { IDownContentItem } from '../../../interfaces/components/IOneLineExpand';
import IStatusAnalise, { IStatusDownContent } from '../../../interfaces/pages/IStatusSinistro';
import useAPI from '../../../storage/custom_hooks/API';
import {HeaderAnaliseSpace, HistoricoRowSpace, LabelAndTextSpace, StyledText, StyledTextColor} from './style';
import { useHistory } from 'react-router-dom';
import { IPropsDetalhesSinistro, IStatePropsDetalhesSinistro } from '../../../interfaces/pages/IDetalhesSinistro';

const StatusSinistro :React.FC<{}> = (): React.ReactElement =>{
    const [statusResponse,initEventos] = useAPI<IStatusAnalise[]>(GetBeneficiarioStatus());
    const eventos = useMemo(()=>statusResponse.data ?? [],[statusResponse.data]);
    useEffect(()=>{
        initEventos();
        // eslint-disable-next-line
    },[])
    const items = useMemo(()=>eventos.map((evento,i)=>{
        return(
            <CustomDropdown key={evento.numContrato+"report"}
                height={"80px"} 
                bodyIsClickable
                keepHead
                titulo={
                    <HeaderAnaliseSpace>
                        <LabelAndTextSpace width={"10%"}>
                            <StyledTextColor>
                                Contrato
                            </StyledTextColor>
                            <StyledText>
                                {
                                    evento.numContrato
                                }
                            </StyledText>
                        </LabelAndTextSpace>
                        <LabelAndTextSpace width={"15%"}>
                            <StyledTextColor>
                                Nome do Plano
                            </StyledTextColor>
                            <StyledText>
                                {
                                    evento.nomePlano
                                }
                            </StyledText>
                        </LabelAndTextSpace>
                        <LabelAndTextSpace width={"15%"}>
                            <StyledTextColor>
                                Segurado(a)/Participante
                            </StyledTextColor>
                            <StyledText>
                                {
                                    evento.nomeSegurado
                                }
                            </StyledText>
                        </LabelAndTextSpace>
                        <LabelAndTextSpace width={"20%"}>
                            <StyledTextColor>
                                Garantia
                            </StyledTextColor>
                            <StyledText>
                                {
                                    evento.nomeGarantia
                                }
                            </StyledText>
                        </LabelAndTextSpace>
                        <LabelAndTextSpace width={"15%"}>
                            <StyledTextColor>
                                Último Status
                            </StyledTextColor>
                            <StyledText>
                                {
                                    evento.situacaoAtual
                                }
                            </StyledText>
                        </LabelAndTextSpace>
                        <LabelAndTextSpace width={"15%"}>
                            <StyledTextColor>
                                Data da Análise
                            </StyledTextColor>
                            <StyledText>
                                {
                                   evento.dataAnalise? new Date(evento.dataAnalise).toLocaleDateString('pt-BR').slice(0,10) : ""
                                }
                            </StyledText>
                        </LabelAndTextSpace>
                    </HeaderAnaliseSpace>
                }
                FunctionChildren={
                    ()=><StatusSinistroDownContent numeroContrato={evento.numContrato ?? 0} numeroSinistro={evento.numSinistro ?? 0}/>
                }
            />
        )
    }),[eventos]);
    return(
        <PageModel>
            <label>Evento/Sinistro</label>
            {
                statusResponse.status==="COMPLETED"?
                    items.length>0 ? items : "Não foram encontrados items."
                :
                (
                    statusResponse.status==="FAILED"?
                        <LoaderError erro={statusResponse.errorResponse??"Ocorreu um erro! Recarregue a página, se não resolver contate a CAPEMISA!"} />
                    :
                    <LoaderRotatorMessage width={"100%"}/>
                )
            }
        </PageModel>
    )
}

const pendenteKeyText = 'Pendente de documentação';
const AnaliseHistoricoRow : React.FC<{status?:string,obs?:string,data?:string,cpfSegurado?:string,protocolo?:string,idContrato?:number}>= (props)=>{
    const history = useHistory();
    const {
        cpfSegurado,
        protocolo,
        obs,
        idContrato,
    } = props;
    return(
        <HistoricoRowSpace>
            <StyledText>{props.status}</StyledText>
            {
                props.status === pendenteKeyText &&
                <StyledText
                    onClick={()=>{
                        if(cpfSegurado && protocolo)
                            history.replace(
                                `/inicio/sinistro/detalheaviso`,
                                {
                                    userType:"beneficiario",
                                    data: {
                                        cpfSegurado,
                                        codigo:protocolo,
                                    },
                                    beneficiarioData:{
                                        idContrato: idContrato ?? 0,
                                    }
                                } as IStatePropsDetalhesSinistro
                            );
                        else{
                            alert(obs??"Entre em contato com a CAPEMISA para resolver as pendências.");
                            history.replace(
                                '/inicio/falecomagente/'
                            );
                        }
                    }}
                    isBolder
                    isLink
                >
                    Enviar documentos pendentes
                </StyledText>
            }
            <StyledText>{new Date(props.data??"").toLocaleDateString("pt-br").slice(0,10)}</StyledText>
        </HistoricoRowSpace>
    )
}

const StatusSinistroDownContent :React.FC<{numeroSinistro:number, numeroContrato:number}> = (props): React.ReactElement =>{
    const DownContentRowInstance : React.FC<{title:string,columns:{title:string,response?:string}[]}> = useCallback((props)=>{
        return(
            <DownContentRow row={{width:"96%",margin:"1% 2% 1% 2%",title:props.title,itens:props.columns.map((column) : IDownContentItem=>{
                return({
                    type:"twoText",
                    valueTitleandValue:{
                        title:column.title,
                        answer:column.response
                    },
                })
            })}}/>
        )
    },[])
    const [statusDetalhesResponse,initQuery] = useAPI<IStatusDownContent>(GetBeneficiarioStatusDetalhe(props.numeroSinistro));
    const defaultVal : IStatusDownContent = useMemo(()=>{return{dataAviso:"",dataEvento:"",dataPagamento:"",evento:"",garantia:"",listaHistoricoStatusBeneficio:[],listaPagamento:[],situacaoPagamento:"",statusPagamento:"",valorPagamento:0}},[]);
    useEffect(()=>{
        initQuery();
        // eslint-disable-next-line
    },[])
    const evento = useMemo(()=>statusDetalhesResponse.data ?? defaultVal,[defaultVal,statusDetalhesResponse.data]);
    return (
        statusDetalhesResponse.status==="COMPLETED"?
        <div style={{fontSize:"1.2em",display:"flex",flexDirection:"column",alignItems:"center",marginBottom:"20px"}}>
            <DownContentRowInstance  title={"Informações do Evento"} columns={[
                {title:"Evento",response:evento.evento},
                {title:"Data do Evento",response:evento.dataEvento?new Date(evento.dataEvento).toLocaleDateString("pt-br").slice(0,10):""},
                // {title:"Garantia",response:evento.garantia},
                {title:"Data de Aviso",response:evento.dataAviso?new Date(evento.dataAviso).toLocaleDateString("pt-br").slice(0,10):""}
            ]}/>
            {
                evento.listaPagamento?.map((itemPagamento,i)=>{
                    return(
                        <DownContentRowInstance title={i===0?"Informações de Pagamento":""} columns={[
                            {title:"Status",response:itemPagamento.statusPagamento},
                            {title:"Valor do Pagamento",response:itemPagamento.valorPagamento?"R$ "+CorrigirVariavelMonetaria(itemPagamento.valorPagamento.toString()):""},
                            {title:"Situação do Pagamento",response:itemPagamento.situacaoPagamento},
                            {title:"Data do Pagamento", response:itemPagamento.dataPagamento?new Date(itemPagamento.dataPagamento).toLocaleDateString("pt-br").slice(0,10):""},
                        ]}/>
                    )
                })
            }
            {
                evento.observacao 
                && <DownContentRow row={{width:"96%",margin:"1% 2% 1% 2%",title:"Observações",itens:[
                    {
                        type:"bigText",
                        title:evento.observacao,
                    }
                ]}}/>
            }
            <div style={{display:"flex",width:"96%",flexDirection:"column",margin:"1% 2% 0% 2%"}}>
                <DetailsIcoRowTitle>Histórico de Análise</DetailsIcoRowTitle>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <DetailsIcoTitle>Status da Análise</DetailsIcoTitle>
                    <DetailsIcoTitle>Data da Análise</DetailsIcoTitle>
                </div>
                {
                    evento.listaHistoricoStatusBeneficio?.map((historicoStatus)=>{
                        return(
                            <AnaliseHistoricoRow
                                data={historicoStatus.dataAnalise}
                                status={historicoStatus.situacao}
                                obs={historicoStatus.observacao}
                                cpfSegurado={statusDetalhesResponse.data?.cpfSegurado}
                                protocolo={statusDetalhesResponse.data?.protocolo}
                                idContrato={props.numeroContrato}
                            />
                        )
                    })
                }
            </div>
        </div>
        :
        (
            statusDetalhesResponse.status==="FAILED"?
                <LoaderError erro={statusDetalhesResponse.errorResponse??"Ocorreu um erro! Recarregue a página, se não resolver contate a CAPEMISA!"} />
            :
            <LoaderRotatorMessage width={"100%"}/>
        )
    )
}

export default StatusSinistro;