import styled from 'styled-components';
import '../../../styles/fonts/roboto/index.css';
export const BackgroundPage = styled.div`
    height:100vh;
    width:100vw;
    background-image:url('https://capprdstportalorteador.blob.core.windows.net/b2crelacionamento/BG_portal3.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    position:fixed;
    filter: blur(8px);
    -webkit-filter: blur(8px);
`;
export const PageContent = styled.div`
    position: absolute;
    z-index: 2;
    width:100vw;
    height:100vh;
    display:flex !important;
    flex-direction:row !important;
    align-items:center !important;
    justify-content:center !important;
`;
export const ModalDialog = styled.div`
    background-color:white;
    height: 90%;
    width:70%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 39px;
    opacity: 1;
    display:flex;
    flex-direction:column;
    align-items:center;
`;
export const PageHeader = styled.div`
    display:flex;
    margin-top:3%;
    width:90%;
    height:10%;
    justify-content:space-between;
`
export const Logo = styled.img`
    height:100%;
`
export const HeaderText = styled.label`
    text-align: left;
    font: Bold 2em Roboto;
    letter-spacing: -0.74px;
    color: #E27800;
    text-transform: uppercase;
    opacity: 1;
    color:#E27800;
`;
export const ModalBody = styled.div`
    height:80%;
    width:90%;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    margin-bottom:2%;
`
export const Linha = styled.div`
    height:14%;
    width:100%;
    display:flex;
    justify-content:space-between;
`
export const RoundedInput = styled.input`
    border:1px solid #B9B9B9;
    border-radius: 29px;
    width: 98%;
    height: 80%;
    padding-left:2%;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const RoundedInputSpace = styled.div<{ width: string | number }>`
    width:${props => props.width};
    display:flex;
    flex-direction:column;
`
export const RoundedInputText = styled.label`
    margin-left:24px;
    text-align: left;
    font-size: 0.8em;
    letter-spacing: -0.34px;
    color: #B9B9B9;
    margin-bottom:0px;
`
export const DoubleLinha = styled.div`
    height:100%;
    width:50%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
`
export const HalfLinha = styled.div`
    height:48%;
    width:100%;
    display:flex;
    justify-content:space-between;
`
export const EspacoConfirm = styled.div`
    height:28%;
    display:flex;
    flex-direction:row;
`
export const MensagemTermosCondicoes = styled.label`
    width:98%;
    text-align: center;
    font-size:0.6rem;
    letter-spacing: -0.28px;
    color: #636363;
    align-self:center;
    height:30%;
    margin-bottom:0px;
`
export const BackToLogin = styled.label`
    text-align: left;
    letter-spacing: -0.34px;
    color: #E27800;
    text-transform: uppercase;
    opacity: 1;
    width:90%;
    align-self:center;
    margin-bottom:0px;
    :hover
    {
        cursor:pointer;
    }
`
export const SubmitLaunch = styled.input`
    height:30%;
    width:90%;
    align-self:center;
    background: #E27800 0% 0% no-repeat padding-box;
    border-radius: 29px;
    opacity: 1;
    color: #FFFFFF;
    border:0px !important;
    font-size:1.4em;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`