import {APIUSER} from '../../APICallableActions/Adresses';
import { QueryConfig } from '../../../interfaces/storage/IReduxQuery';
export function GetProdutos() : QueryConfig
{
    return {
        endpoint:APIUSER.Produto.GetProdutos,
        queryName:"queryProdutos"
    };
}
export function GetContratos(codigoProduto:string,tipoProduto:string,sistemaOrigem:string) : QueryConfig
{
    return {
        endpoint:APIUSER.Produto.GetContratos,
        config:{
            params:{
                codigoProduto,
                tipoProduto,
                sistemaOrigem,
                
            }
        },
        queryName:"queryProdutosContratos"
    }
}

export function GetContratoDetalhes(codigoProduto:string,numeroContrato:string,tipoProduto:string,sistemaOrigem:string,numeroCertificado:string,dataSaldoDevedor?:string) : QueryConfig
{
    return {
        endpoint:APIUSER.Produto.GetContratoDetalhe,
        config:{
            params:{
                codigoProduto,
                numeroContrato,
                tipoProduto,
                sistemaOrigem,
                numeroCertificado,
                dataSaldoDevedor:dataSaldoDevedor ?? undefined
            }
        },
        queryName:"queryContratosDetalhes"
    }
}