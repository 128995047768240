import React, {useState,useEffect} from 'react';
import PageModel from '../../../components/PageModel';
import { useParams } from 'react-router-dom';
import {SearchSpace, PerguntaText, RoundedInput} from './style';
import { RenderList } from '../../../components/DocumentList';
import { IColuna, IColunaItem } from '../../../interfaces/components/IColunaItem';
import Pagination from '../../../components/Paginacao';
import { ITrilha } from '../../../interfaces/components/IPageModel';
import { GetDuvidas } from '../../../API/usuario';
import { ApiPerguntas } from '../../../interfaces/pages/IAjuda';
const AjudaEspecifica :React.FC = () : React.ReactElement =>
{
    let { topico,index } = useParams<{topico:string,index:string}>();
    const [ColunasAjuda,setColunasAjuda]=useState<IColuna[]>([])
    const [isQueryDone,setisQueryDone]=useState<boolean>(false);
    let titulo=(topico==="sc"?"Dúvidas sobre a Capemisa":(topico==="sp"?"Dúvidas sobre o portal":"??"));
    console.log(topico);
    const trilha:ITrilha[] =
    [
        {
            titulo:"INICIO",
            path:"/inicio/",
        },
        {
            titulo:"FALE COM A GENTE",
            path:"/inicio/falecomagente",
        },
        {
            titulo:"AJUDA",
            path:"/inicio/falecomagente/ajuda",
        },
        {
            titulo:titulo ?? "",
            path:"",
        }
    ];
    
    useEffect(()=>
    {
        GetDuvidas([topico??""]).then((response)=>{
                setColunasAjuda(response.data.map((duvida:ApiPerguntas,i:number)=>{
                     return ({coluna:[{width:"100%",textAlign:"flex-start",message:duvida.pergunta,title:true,colorTitle:"#E27800"},{width:"100%",message:duvida.resposta,title:false,dontShow:true},{width:"0%",message:duvida.id,title:false,dontShow:true}]})
                }))
                setisQueryDone(true);
            }
        )// eslint-disable-next-line
    },[])
    return(
        <PageModel titulo={titulo} trilha={trilha}>
            <AjudaEspecificaComponent perguntas={ColunasAjuda} isQueryDone={isQueryDone} index={index}/>
        </PageModel>
    )
}
const AjudaEspecificaComponent: React.FC<{index?:string,isQueryDone:boolean;perguntas:IColuna[]}> = (props) : React.ReactElement =>
{
    const {index, perguntas,isQueryDone}=props;
    const [pesquisaPergunta,setPesquisaPergunta] = useState<string>("");
    const maxPerPage= 9;
    let initialPage=1;
    if(index && pesquisaPergunta==="")
    {
        (perguntas.find((tabela:IColuna)=>tabela.coluna[2].message.toString() === index.toString()) ?? {coluna:[],isOpen:false}).isOpen=true;
        initialPage = 1;
        perguntas.map((atualTabela:IColuna,i:number)=>{if(atualTabela.coluna[2].message===index)initialPage=Math.ceil(i/maxPerPage); return 0});
    }
    const [paginaAtual,setPaginaAtual] = useState<number>(initialPage);
    function stringEscape(s:string) {// eslint-disable-next-line
        return s ? s.replace(/\\|\n|\t|\v|'|\[|\]|"/g,'').replace(/[\x00-\x1F\x80-\x9F]/g,hex) : s;
        function hex(c:string) { var v = '0'+c.charCodeAt(0).toString(16); return '\\x'+v.substr(v.length-2); }
    }
    const TestIfPerguntasAreSearched= ():IColuna[]=>{
        let searchedPerguntas:IColuna[] = [];
        if(pesquisaPergunta==="")
        {
            searchedPerguntas=perguntas;
        }
        else
        {
            perguntas.map((item:IColuna)=>{
                if(typeof(item.coluna[0].message) === "string"?item.coluna[0].message.search(pesquisaPergunta)>-1:false)
                {
                    searchedPerguntas.push(item);
                }
                return ""    
            })
        }
        if(searchedPerguntas.length>0)
        return searchedPerguntas;
        else if(isQueryDone)
        return [{coluna:[{width:"100%",message:"Nenhuma pergunta foi encontrada"}]}];
        else
        return [];
    }
    const PerguntasToShow=TestIfPerguntasAreSearched();
    return(
        <div style={{height:"100%",width:"100%"}}>
            <SearchSpace>
                <PerguntaText>Qual a sua dúvida?</PerguntaText>
                <RoundedInput value={pesquisaPergunta} onChange={(e)=>{setPesquisaPergunta(stringEscape(e.target.value))}} placeholder="Digite aqui a sua dúvida"/>
            </SearchSpace>
            <RenderList actualPage={paginaAtual} maxPerPage={maxPerPage} cantDownload view={{cantView:(PerguntasToShow.length>0?PerguntasToShow[0].coluna[0].message==="Nenhuma pergunta foi encontrada":false),type:"dropdown"}} columnNames={[]} AditionalViewComponent={PerguntasToShow?.map((item:{coluna:IColunaItem[]})=>{return(<div style={{display:"flex",width:"98%",marginLeft:"2%",marginBottom:"10px"}}>{item?.coluna[1]?.message}</div>)})} contents={PerguntasToShow}/>
            {
                PerguntasToShow.length>maxPerPage?
                <div style={{marginTop:"2%",marginBottom:"4%"}}>
                <Pagination actualPage={paginaAtual} setActualPage={setPaginaAtual} maxPerPage={maxPerPage} length={PerguntasToShow.length}/>
                </div>:""
            }
        </div>
    )
}
export default AjudaEspecifica;