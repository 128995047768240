import {IQueryStatus} from '../../../interfaces/services/IQuery';
import { action, Reducer } from 'typesafe-actions';
import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import axios from '../../../services/axiosConfig';
import { AxiosPromise, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { ExtraAllowedAxiosConfig } from '../../../interfaces/storage/IReduxQuery';
import StyledAlert from '../../../components/StyledAlert';
import { ClearStorage } from '../../../functions/Logout';
import FindErrorMessageOnAPIReturn from '../../../functions/FindErrorMessageOnAPIReturn';

//types

export enum APIActions {
    APISTARTQUERY = "@APIRedux/STARTQUERY",
    APILOADINGQUERY = "@APIRedux/LOADINGQUERY",
    APIFAILEDQUERY = "@APIRedux/FAILEDQUERY",
    APICANCELQUERY = "@APIRedux/CANCELQUERY",
    APISUCESSQUERY = "@APIRedux/SUCESSQUERY",
    APIDISPOSEQUERYDATA = "@APIRedux/DISPOSEQUERYDATA",
    APIRETURNCURRENTSTATUS = "@APIRedux/RETURNCURRENTSTATUS",
}

export type AllMethods = "GET"|"POST"|"PUT"|"PATCH"|"DELETE"|"OPTIONS";

export interface IAPIStateInstance<ResponseType> extends IQueryStatus{
    readonly data?:ResponseType,
    readonly responseCode?:number,
    readonly method?:AllMethods,
    readonly queryUrl?:string,
    readonly cancelTokenSource?:CancelTokenSource,
    readonly queryPromise?:AxiosPromise<any>,
    readonly config?: ExtraAllowedAxiosConfig,
} 

export interface IAPIState<ResponseType>{
    readonly [id:string]:IAPIStateInstance<ResponseType>,
}

//actions

export const APISTARTQUERY = (queryUrl:string, method:AllMethods, config?:ExtraAllowedAxiosConfig, queryKey?:string)=> action(APIActions.APISTARTQUERY,{queryUrl,method,config:config??{},key:queryKey??"undefinedQuery"});
const APILOADINGQUERY = (cancelationTokenSource:CancelTokenSource,key:string,queryPromise:Promise<AxiosResponse<any>>)=> action(APIActions.APILOADINGQUERY,{cancelTokenSource:cancelationTokenSource,key,queryPromise});
const APIFAILEDQUERY = (queryError:string,key:string)=> action(APIActions.APIFAILEDQUERY,{errorResponse:queryError,key});
export const APICANCELQUERY = (key:string)=> action(APIActions.APICANCELQUERY,{key});
const APISUCESSQUERY = (data:any,key:string)=> action(APIActions.APISUCESSQUERY,{data,key})
export const APIDISPOSEQUERYDATA = (key:string) => action(APIActions.APIDISPOSEQUERYDATA,{key});

export type APIDispatchActions = ReturnType<typeof APISTARTQUERY> | ReturnType<typeof APILOADINGQUERY> | ReturnType<typeof APIFAILEDQUERY> | ReturnType<typeof APICANCELQUERY> | ReturnType<typeof APISUCESSQUERY> | ReturnType<typeof APIDISPOSEQUERYDATA>

//reducer

const initialVal : IAPIState<any> = {};

const reducer : Reducer<IAPIState<any>,APIDispatchActions> = (state = initialVal,action) =>{
    switch(action.type)
    {
        case APIActions.APISTARTQUERY:
            return({...state,[action.payload.key]:{status:"NOTINITIALIZED",...(action.payload)}});
        case APIActions.APILOADINGQUERY:
            return({...state,[action.payload.key]:{status:"PENDING",cancelTokenSource:action.payload.cancelTokenSource,queryPromise:action.payload.queryPromise}});
        case APIActions.APISUCESSQUERY:
            return({...state,[action.payload.key]:{status:"COMPLETED",data:action.payload.data}});
        case APIActions.APICANCELQUERY:
            return({...state,[action.payload.key]:{...(state?state[action.payload.key]:{}),status:"CANCEL"}});
        case APIActions.APIFAILEDQUERY:
            return({...state,[action.payload.key]:{status:"FAILED",errorResponse:action.payload.errorResponse}})
        case APIActions.APIDISPOSEQUERYDATA:
            let newState : {[id:string]:any} = {...state};
            delete newState[action.payload.key];
            return(newState)
        default:
            return(state);
    }
}

export default reducer;

//sagas

function* StartRequest(action:ReturnType<typeof APISTARTQUERY>)
{
    try{
        const axiosInstance = axios();
        const axiosToCall = (params:AxiosRequestConfig)=> axiosInstance.axios(params);
        const promiseToDeal = axiosToCall({url:action.payload.queryUrl,method:action.payload.method,...action.payload.config});
        const awaitPromiseToBeDealt = async () => await promiseToDeal;
        yield put(APILOADINGQUERY(axiosInstance.cancelToken,action.payload.key,promiseToDeal));
        const response : AxiosResponse = yield call(awaitPromiseToBeDealt);
        yield put(APISUCESSQUERY(response.data,action.payload.key));
    }
    catch(e) {
        if((e as any).response?.status===401)
        {
            StyledAlert("Seu tempo de sessão está esgotado! Entre novamente no portal para corrigir.");
            setTimeout(()=>{
                ClearStorage();
            },3000)
        }
        const currentState:IAPIState<any> = yield select(state=>state.APIRedux);
        if(currentState[action.payload.key].status!=="CANCEL")
            yield put(APIFAILEDQUERY(FindErrorMessageOnAPIReturn(e),action.payload.key))
    }
    const state:IAPIState<any> = yield select(state=>state.APIRedux);
    console.log(state);
}

function* CancelQuery(action:ReturnType<typeof APICANCELQUERY>)
{
    const state:IAPIState<any> = yield select(state=>state.APIRedux);
    yield call((state[action.payload.key].cancelTokenSource ?? {cancel:()=>{}}).cancel);
}

function* querySelectorSaga(){
    yield takeEvery(APIActions.APISTARTQUERY,StartRequest)
}

function* cancelQuerySaga(){
    yield takeEvery(APIActions.APICANCELQUERY,CancelQuery)
}

export function* APIDuckRootSaga(){
    yield all({
        query:fork(querySelectorSaga),
        cancel:fork(cancelQuerySaga)
    })
}