import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-maskedinput';
export const BilhetePremiavelInsideRowSpace = styled.div`
    width:95%;
    display:flex;
    flex-direction:column;
`
export const DoubleTextSpace = styled.div`
    height:3rem;
    display:flex;
    width:100%;
    justify-content:space-between;
`
export const DoubleTextItem = styled.div<{flexDirection?:string}>`
    margin-top:1%;
    display:flex;
    flex-direction:${props=>props.flexDirection??"column"};
    margin-left:2%;
    align-items:center;
`
export const InsideRowColumnName = styled.label`
    text-align: left;
    font: 70% Roboto;
    letter-spacing: -0.34px;
    color: #B9B9B9;
    opacity: 1;
    margin-bottom:0px;
`
export const InsideSelectedText = styled.label`
    margin-bottom:0px;
    text-align: left;
    font: 80% Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
`
export const PDFText = styled.label`
    font-size:60%;
    color:#636363;
    margin-bottom:0px;
    cursor:pointer;
`
export const IcoPDF = styled(FontAwesomeIcon)`
    color:#E27800;
    font-Size: 0.7rem;
    align-Self:center;
    margin-Left:8%;
    cursor:pointer;
`
export const CustomSizeDetails = styled.div<{height?:string,width?:string}>`
    height:${props=>props.height??"13rem"};
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
`
export const InnerDetailsDiv = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    flex-wrap:wrap;
`
export const LinhaEntreComponentes = styled.hr<{width?:string,borderWidth?:string,borderColor?:string}>`
    border:${props=>props.borderWidth??"0.5px"} solid #B9B9B9;
    width:${props=>props.width??"98%"};
    margin-top:0px;
    margin-bottom:0px;
    border-color:${props=>props.borderColor?? "#B9B9B9"};
`
export const DetailsIcoRow = styled.div`
    width:100%;
    overflow-x:auto;
    display:flex;
`
export const DetailsRow = styled.div<{width?:string,margin?:string}>`
    width:${props=>props.width??"100%"};
    display:flex;
    flex-direction:column;
    margin:${props=> props.margin ?? `1% 0 1% ${props.width?"2%":"0%"}`};
`
export const DetailsIcoRowTitle = styled.label`
    font:bold 0.9em Roboto;
    color:#ed7203;
    width:max-content;
`
export const DetailsIcoTitle = styled.label<{isLink?:boolean}>`
    margin-bottom:0px;
    color:grey;
    font:bold 0.9em Roboto;
    text-align:center;
    :hover{
        ${props=>props.isLink?"color:"+props.theme.cores.primaria+";cursor:pointer;":""}
    };
    
`
export const DetailsIcoValueText = styled.label`
    font:bold 0.9em Roboto;
    text-align:center;
    margin-bottom:0px;
`
export const DetailsRowColumn = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-content:center;
    margin-right:2%;
    flex-shrink:0;
`
export const DetailsTitle = styled.label`
    margin-bottom:0px;
    color:grey;
    font:bold 0.9em Roboto;
`
export const BeneficioIcoSpace = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    align-content:center;
    width:auto;
    max-width:200px;
    margin-right:2%;
    flex-shrink:0;
`
export const RoundedOrangeBorder = styled.div`
    border: 1.5px solid #E27800;
    border-radius: 15px;
    opacity: 1;
    display:flex;
    justify-content:center;
    align-items:center;
    height:40px;
    width:60px;
`
export const RoundedInputSpace = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    align-content:center;
    width:auto;
    max-width:300px;
    margin-right:6%;
    flex-shrink:0;
    min-width:120px;
`
export const RoundedInputRounded = styled.div`
    text-align:center;
    padding:1px;
    border-radius:20px;
    width:80%;
    border:solid 1px #B9B9B9;
`
export const RoundedMaskedInput = styled(MaskedInput)`
    font:bold 0.7em Roboto;
    border:0px;
    :focus{
        outline:0px;
    }
    width:auto;
    padding-left:8px;   
`
export const  NormalText = styled.label`
    font: normal 1em Roboto;
`