import React, { useContext, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PageModel from '../PageModel';
import DocumentList from '../DocumentList';
import { GlobalContext } from '../../storage/contexts/GlobalContext';
import { GetDocumentos } from '../../API/documentos';
import IInfRend from '../../interfaces/pages/IInfRend';
import { IColunaItem, IOcultDataToSend, IColuna } from '../../interfaces/components/IColunaItem';
import { DocumentType } from '../DocumentList/DocumentType';
const DocumentsModel : React.FC<{}> = () : React.ReactElement =>
{
    const { path } = useRouteMatch();
    const fixedpath = path+"/";
    const { globalContext: {PreUser}}=useContext(GlobalContext);
    const [registros, setRegistros] = useState<IInfRend[]>([]);
    const [busca,setBusca] = useState<string>("");
    useEffect(()=>{
        GetDocumentos(fixedpath)?.then((response:any)=>{
            console.log(response);
            setRegistros(
                (response.data.length!==0?
                response.data.map((item:any,i:number)=>{
                    return{
                        "competencia":item.competencia,
                        "nome": PreUser?.nome,
                        "documento":item.numeroDocumento,
                        "descricao": item.nomeDocumento,
                        "situacao":item.situacaoDocumento,
                        "dataGeracao":item.dataCriacao,
                        "idDoc":i,
                        "codigoDocumento":item.codigoDocumento,
                        "caminhoJasper":item.caminhoRelatorioJasper,
                        "tipoDocumento":item.tipoDocumento,
                    };
                }):()=>{return(false)}
                )
            );
        })// eslint-disable-next-line
    },[]);
    const ColumnNames:IColunaItem[]=[{width:"14%",message:"Competência",hasIco:true},{width:"13%",message:"Nome",hasIco:true},{width:"14%",message:"Documento",hasIco:true},{width:"15%",message:"Descrição",hasIco:true,textAlign:"flex-start"},{width:"13.5%",message:"Data de Geração",hasIco:true},{width:"12%",message:"Situação",hasIco:true},{width:"18%",message:"Ação"}];
    const OcultDataToSend:IOcultDataToSend[]=[];

    let documentType: DocumentType | undefined;
    if(fixedpath.indexOf('extratos') > 0){
        documentType = DocumentType.extratosPrevidenciarios;
    }

    let Columns: IColuna[]=[];
    if(registros)
    {
        registros.map((item:IInfRend)=>{
            console.log(item);
            Columns.push({
                coluna:[
            {width:"10%",message:item.competencia},
            {width:"18%",message:item.nome},
            {width:"10%",message:item.documento},
            {width:"18%",message:item.descricao},
            {width:"10%",message:new Date(item.dataGeracao).toLocaleDateString()},
            {width:"14%",message:item.situacao},
            {width:"0%",dontShow:true,message:item.codigoDocumento},]
            });
            OcultDataToSend.push({
                tipoDocumento:item.tipoDocumento,
                codigoDocumento:item.codigoDocumento,
                caminhoJasper:item.caminhoJasper,
            })
            return ""
        });
    }
    else
    {
        Columns.push({coluna:[{width:"100%",message:"Não há itens"}]})
    }
    console.log(Columns);
    return(
        <PageModel Conteudo={<DocumentList dataSend={OcultDataToSend} searchTerm={busca} onChangeSearchTerm={(e)=>{setBusca(e.target.value)}} columnNames={ColumnNames} view={{cantView:(registros?false:true),type:"document"}} cantDownload={registros?false:true} documentsDinamic={Columns} documentType={documentType}/>}/>
    )
}
export default DocumentsModel;