import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)<{height?: string}>`
    
    width: 550px;
    height: 120px;
    display: flex;
    flex-direction: column;
    border:none;
    overflow: hidden;
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

export const Title = styled.p`
    display: flex;
    background-color: ${ props => props.theme.cores.primaria};
    height: auto;
    min-height: 1rem;
    align-items: center;
    padding: 0 5%;
    color: white;
    font-size: 100%;
    text-transform: uppercase;
    font-weight:bold;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 5px;
    ::after{
        text-decoration:none;
    }
    :focus{
        text-decoration:none;
    }
`;

export const Description = styled.p`
    display: flex;
    background-color: #666;
    align-items: center;
    padding: 10px 5%;
    color: white;
    font-size: 80%;
    margin: 0;
    min-width: 30%;
    max-width: 65%;
    margin-top: 5px;
    height: auto;
`;

export const DotsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1%;
`;

export const Dot = styled.button<{index: number,isSelected: boolean}>`
    background-color: ${ props => props.isSelected ? 'white' : 'transparent' };
    border-radius: 50%;
    width: 7px;
    height: 7px;
    border: 1px white solid;
    margin: 2.5px;
`;

export const Image = styled.div<{src: string}>`
    background-color: #666;
    background-image: url("${ props => props.src}");
    background-size: cover;
    background-position: top;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    opacity: 1;
`;

export const ImageButton = styled.button<{tipo: string}>`
    font-size: 22px;
    background: linear-gradient(to ${props => props.tipo === "previous" ? "right" : "left"}, #00000060 , transparent);
    opacity: 0.2;
    border: none;
    height:100%;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
        opacity: 1;
        color: white;
    }

    &:focus{
        outline:none;
    }
`;

export const TextContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0;
`
