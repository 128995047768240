import styled from 'styled-components';
export const AlertBackdrop = styled.div`
    position:fixed;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    z-index:11;
    left:0px;
    top:0px;
    background:rgba(0,0,0,0.1);
    opacity: 1;
    overflow-y:auto;
`
export const AlertSpace = styled.div`
    @keyframes fromAtoB{
        from{
            transform:translateY(-40px);
        }
        to{
            transform: 0;
        }
    }
    @media screen and (max-width: 600px) {
        margin-top:0px;
    }
    @media screen and (min-width: 601px) {
        margin-top:20px;
    }
    animation: fromAtoB 0.5s;
    animation-iteration-count:1;
    min-height:100px;
    min-width:150px;
    max-width:500px;
    height:fit-content;
    border:1px solid #E27800;
    border-radius:10px;
    display:flex;
    flex-direction:column;
    align-items:center;
    background-color:white;
    padding:10px;
`
export const AlertButton = styled.div`
    @keyframes fromAtoB{
        from{
            transform:translateY(-40px);
        }
        to{
            transform: 0;
        }
    }
    @media screen and (max-width: 600px) {
        margin-top:0px;
    }
    @media screen and (min-width: 601px) {
        margin-top:20px;
    }
    animation: fromAtoB 0.5s;
    animation-iteration-count:1;
    cursor:pointer;
    border:1px solid #E27800;
    max-height:30px;
    min-width:100px;
    max-width:200px;
    background-color:#E27800;
    border-radius:5px;
    color:white;
    text-align:center;
    margin-top:5px;
    margin-bottom:5px;
`
export const AlertTextSpace = styled.div`
    flex-grow:1;
    width:fit-content;
    padding:5px;
    display:flex;
    justify-content:center;
    align-items:center;
`
export const AlertText = styled.div`
    height:fit-content;
    width:100%;
    text-align:center;
`