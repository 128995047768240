import React, { useState } from 'react';
import { DownContentRow } from '../../components/OneLineExpand';
import { GetContratoDetalhes} from '../../services/APICallableActions/usuario';
import APIRequestFunction from '../../services/APIRequestFunction';
import IProdutosEspecificsGeneral, { IAssistenciaFinanceiraEspecifics, ICoberturasEspecifics, ICorretorEspecifics, IEstipulanteEspecifics, InformacoesContratoEspecific, IServicosEspecifics, ISorteiosEspecifics } from '../../interfaces/pages/IProdutos';
import CorrigirVariavelMonetaria from '../../functions/CorrigirValorPontuacao';
import StyledAlert from '../StyledAlert';
import { IDownContentItem } from '../../interfaces/components/IOneLineExpand';

export function AFRowComponent(informacoesContrato:InformacoesContratoEspecific ,assistenciaFinanceirasDados:IAssistenciaFinanceiraEspecifics) : React.ReactElement[] 
{
     return(
        [
            <DownContentRow row={
            {
                title:"Detalhes",
                width:"98%",
                itens:  [
                        {
                            type:"twoText",
                            valueTitleandValue:{
                                title:"Valor Solicitado",
                                answer:"R$ "+CorrigirVariavelMonetaria(assistenciaFinanceirasDados.valorSolicitado),
                            }
                        },
                        {
                            type:"twoText",
                            valueTitleandValue:{
                                title:"Taxa de Juros",
                                answer:CorrigirVariavelMonetaria(assistenciaFinanceirasDados.taxaJuros)+"%",
                            }
                        },
                        {
                            type:"twoText",
                            valueTitleandValue:{
                                title:"CET Anual",
                                answer:CorrigirVariavelMonetaria(assistenciaFinanceirasDados.valorCetAnual)+"%",
                            }
                        },
                        {
                            type:"twoText",
                            valueTitleandValue:{
                                title:"CET Mensal",
                                answer:CorrigirVariavelMonetaria(assistenciaFinanceirasDados.valorCetMensal)+"%",
                            }
                        },
                        {
                            type:"twoText",
                            valueTitleandValue:{
                                title:"IOF",
                                answer:"R$ "+CorrigirVariavelMonetaria(assistenciaFinanceirasDados.valorIof),
                            }
                        }
                ]
            }}/>
            ,
            <DownContentRow row={{
                title:"Quantidade de Parcelas",
                width:"60%",
                itens:[
                    {
                        type:"twoText",
                        valueTitleandValue:{
                            title:"Contratadas",
                            answer:assistenciaFinanceirasDados.quantidadeParcelasContratadas,
                        }
                    },
                    {
                        type:"twoText",
                        valueTitleandValue:{
                            title:"Pagas",
                            answer:assistenciaFinanceirasDados.quantidadeParcelasPagas,
                        }
                    },
                    {
                        type:"twoText",
                        valueTitleandValue:{
                            title:"Pendentes",
                            answer:assistenciaFinanceirasDados.quantidadeParcelasPendentes,
                        }
                    }
                ]
            }}/>
            ,
            <DataSaldoDevedorEspecificComponent {...informacoesContrato} valorSaldoDevedor={assistenciaFinanceirasDados.valorSaldoDevedor} />
            ,
            <DownContentRow row={{
                title:"Data do Recebimento",
                width:"30%",
                itens:[
                    {
                        type:"twoText",
                        valueTitleandValue:
                        {
                            title:new Date(assistenciaFinanceirasDados.dataRecebimento).toLocaleDateString("pt-br"),
                        }
                    }
                ]
            }}/>
            ,
            <DownContentRow row={{
                title:"Início do Desconto",
                width:"30%",
                itens:[
                    {
                        type:"twoText",
                        valueTitleandValue:
                        {
                            title:new Date(assistenciaFinanceirasDados.dataInicioDesconto).toLocaleDateString("pt-br"),
                        }
                    }
                ]
            }}/>
        ]
     )
}

const DataSaldoDevedorEspecificComponent : React.FC<{codigoProduto:string,numeroContrato:string,tipoProduto:string,sistemaOrigem:string,numeroCertificado:string,valorSaldoDevedor:string}> = (props):React.ReactElement =>
{
    const {codigoProduto,numeroContrato,tipoProduto,sistemaOrigem,numeroCertificado} = props;
    const [valorSaldoDevedor,setValorSaldoDevedor] = useState<string>(props.valorSaldoDevedor);

    return(
        <DownContentRow row={{
            title:"Data do Saldo Devedor",
            width:"35%",
            itens:[
                {   
                    type:"timePickerInput",
                    title:"Insira a data desejada",
                    valueTitleandValue:{
                        title:new Date().toLocaleDateString("pt-br").replace("/",""),
                    },
                    inputOnchange:(e:any)=>{
                        const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
                        const DataMax = new Date();
                        DataMax.setDate(new Date().getDate() + 11);
                        DataMax.setMinutes(0);
                        DataMax.setHours(0);
                        DataMax.setSeconds(0);
                        DataMax.setMilliseconds(0);
                        console.log(DataMax);
                        console.log(new Date(e.target.value.slice(6,10)+"/"+e.target.value.slice(3,5)+"/"+e.target.value.slice(0,2)));
                        if(dateRegex.test(e.target.value) && new Date(e.target.value.slice(6,10)+"/"+e.target.value.slice(3,5)+"/"+e.target.value.slice(0,2)) < DataMax)
                        {
                            APIRequestFunction(GetContratoDetalhes(codigoProduto,numeroContrato,tipoProduto,sistemaOrigem,numeroCertificado,e.target.value.slice(6,10)+"-"+e.target.value.slice(3,5)+"-"+e.target.value.slice(0,2))).then(
                                (resposta)=>{
                                    setValorSaldoDevedor((resposta.data.assistenciaFinanceiras ?? [{dataInicioDesconto:new Date().toDateString(),dataRecebimento:new Date().toDateString(),quantidadeParcelasContratadas:"10",quantidadeParcelasPagas:"5",quantidadeParcelasPendentes:"5",taxaJuros:"100%",valorCetAnual:"100%",valorCetMensal:"100%",valorIof:"10000000",valorSaldoDevedor:"1000",valorSolicitado:"9999999"}])[0].valorSaldoDevedor);
                                }
                            )
                        }
                        else if(new Date(e.target.value.slice(6,10)+"/"+e.target.value.slice(3,5)+"/"+e.target.value.slice(0,2)) >= DataMax)
                        {
                            StyledAlert("Data muito distante para calcular!");
                        } 
                    }
                },
                {
                    type:"twoText",
                    valueTitleandValue:{
                        title:"Saldo Devedor",
                        answer:"R$ " +CorrigirVariavelMonetaria(valorSaldoDevedor),
                    }
                }
            ]
        }}/>
    )
}

export function CoberturasRowComponent(coberturas:ICoberturasEspecifics[]) : React.ReactElement[] 
{
    return(
        [
            <DownContentRow row={{
                title:"Coberturas",
                itens:isNotZero(coberturas.map((cobertura:ICoberturasEspecifics)=>{
                        return(
                            {
                                type:"imageTextValue",
                                valueTitleandValue:{
                                    title:cobertura.nome,
                                    answer:((cobertura.valor && cobertura.valor !=="0") ?"R$ "+CorrigirVariavelMonetaria(cobertura.valor):undefined),
                                }    
                            }
                        )
                }))
            }}/>
        ]
    )
}

export function SorteiosRowComponent(numeroSortes:ISorteiosEspecifics[]): React.ReactElement[]
{
    return (
        [
            <DownContentRow row={
                {
                    title:"Sorteio",
                    itens:isNotZero(numeroSortes.map((item:ISorteiosEspecifics)=>{
                        return(
                        {
                            type:"textText",
                            title:"R$ "+CorrigirVariavelMonetaria(item.valorPremioSorteio)+ " - " + item.periodicidadeSorteio,
                            valueTitleandValue:{
                                title:"Números da Sorte",
                                answer:item.numero,
                            }
                        }
                        )
                    }))
                }}
            />
        ]
    )
}

const isNotZero = (array:IDownContentItem[]) : IDownContentItem[] =>{
        if(array.length===0)
        return [{
            type:"textText",
            title:"Não foram encontrados itens"
        }]
        else
        return array;
}

export function ServicosRowComponent(servicos:IServicosEspecifics[]) : React.ReactElement[]
{
    return(
        [
            <DownContentRow row={{
                title:"Serviços",
                itens:isNotZero(servicos.map((servico:IServicosEspecifics) : IDownContentItem =>{
                    return(
                        {
                            type:"imageTextSuspenseInfoLink",
                            title:servico.linkOrientacaoPrestadora,
                            valueTitleandValue:{
                                title:servico.descricaoGrupoServico,
                                answer:servico.txtContatoFornecedor,
                            }
                        }
                    )
                })),
            }}/>
        ]
    )
}

export function CorretorRowComponent(corretorDados:ICorretorEspecifics) : React.ReactElement[]
{
    let cellsToShow :IDownContentItem[] = [
        {
            type:"twoText",
            valueTitleandValue:{
                title:"Nome",
                answer:corretorDados.nomeCorretor
            }
        }
    ]
    if(corretorDados.emailCorretor)
        cellsToShow.push({
            type:"twoText",
            valueTitleandValue:{
                title:"Email",
                answer:corretorDados.emailCorretor
            }
        })
    return(
        [
            <DownContentRow row={{
                title:"Corretor",
                itens:isNotZero(cellsToShow),
            }}/>,
        ]
    )
}  

export function EstipulanteRowComponent(estipulanteDados:IEstipulanteEspecifics) : React.ReactElement[]
{
    //hardcodado a pedido da capemisa
    const mensagemEstipulante = "Pessoa física ou jurídica que propõe a contratação de plano coletivo, ficando investida de poderes de representação do segurado, nos termos da legislação e regulamentação em vigor, sendo identificada como estipulante-instituidor quando participar, total ou parcialmente, do custeio e como estipulante-averbador quando não participar do custeio."
    const mensagemSubEstipulante = "Pessoa física ou jurídica, vinculada ao Estipulante, que propõe a contratação de plano coletivo, ficando investida de poderes de representação do segurado, nos termos da legislação e regulamentação em vigor."
    return(
        [
            <DownContentRow row={{
                hoverMessage:mensagemEstipulante,
                title:"Estipulante",
                itens:isNotZero([
                    {
                        type:"textNormal",
                        title:estipulanteDados.nomeEstipulante,
                    }
                ]),
            }}/>,
            <DownContentRow row={{
                title:"Subestipulante",
                hoverMessage:mensagemSubEstipulante,
                itens:isNotZero([
                    {
                        type:"textNormal",
                        title:estipulanteDados.nomeSubEstipulante,
                    }
                ]),
            }}/>,
        ]
    )
}

function GeneralProdutoRowsComponent(produtosGeneral:IProdutosEspecificsGeneral) : React.ReactElement[]
{
    let rowsFinal:React.ReactElement[] = [];
    if((produtosGeneral.assistenciaFinanceiras?.length ?? 0)>0 && produtosGeneral.assistenciaFinanceiras)
    {
        rowsFinal=rowsFinal.concat(AFRowComponent(produtosGeneral.informacoesContrato,produtosGeneral.assistenciaFinanceiras[0]))
    }
    if((produtosGeneral.coberturas?.length ?? 0)>0 && produtosGeneral.coberturas)
    {
        rowsFinal=rowsFinal.concat(CoberturasRowComponent(produtosGeneral.coberturas));
    }   
    if((produtosGeneral.servicos?.length ?? 0)>0 && produtosGeneral.servicos)
    {
        rowsFinal=rowsFinal.concat(ServicosRowComponent(produtosGeneral.servicos));
    }
    if((produtosGeneral.numeroSortes?.length ?? 0)>0 && produtosGeneral.numeroSortes)
    {
        rowsFinal=rowsFinal.concat(SorteiosRowComponent(produtosGeneral.numeroSortes));
    }
    if(produtosGeneral.nomeCorretor)
    {
        rowsFinal=rowsFinal.concat(CorretorRowComponent({...produtosGeneral}));
    }
    if(produtosGeneral.nomeEstipulante && produtosGeneral.nomeSubEstipulante)
    {
        rowsFinal=rowsFinal.concat(EstipulanteRowComponent({...produtosGeneral}))
    }
    return rowsFinal;
}
export default GeneralProdutoRowsComponent;