import React, { useContext } from 'react';
import { GlobalContext } from '../../../storage/contexts/GlobalContext'
import UserForm from '../../../components/UserForm';
import PageModel from '../../../components/PageModel';

export default function PerfilAtualizarDados(){
    const {globalContext: {PreUser}} = useContext(GlobalContext);
    return(
        <PageModel trilha={[]} cantRedirect>
            <UserForm pagePurpose={"insertdata"} data={PreUser}/>
        </PageModel>
    )
}