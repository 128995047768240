import React from 'react';
import { useLocation } from 'react-router-dom';

import { IRouteNode } from '../../interfaces/routes/IRouteNode';
import { ITrilha } from '../../interfaces/components/IPageModel';

export const useGetTrilhas = (path?: string) => {
    const { pathname } = useLocation();

    const [rotas, obterTrilha] = React.useMemo(() => {
        const rotas: { [key: string]: IRouteNode } = {};

        const addNodeAoPai = (pai: IRouteNode, novoFilho: IRouteNode) => { pai.nosFilhos.push(novoFilho) };
        const addNodeAsRotas = (novaRota: IRouteNode) => { rotas[novaRota.rota] = novaRota };

        const inicio: IRouteNode = {
            titulo: "início",
            rota: "inicio",
            nodePai: undefined,
            nosFilhos: [],
        };
        addNodeAsRotas(inicio);

        const perfil: IRouteNode = {
            titulo: "meu perfil",
            rota: "perfil",
            nodePai: inicio,
            nosFilhos: [],
        };
        addNodeAsRotas(perfil);
        addNodeAoPai(inicio, perfil);

        const documentos: IRouteNode = {
            titulo: "meus documentos",
            rota: "documentos",
            nodePai: inicio,
            nosFilhos: [],
        };
        addNodeAsRotas(documentos);
        addNodeAoPai(inicio, documentos);

        const produtos: IRouteNode = {
            titulo: "Meus Produtos",
            rota: "produtos",
            nodePai: inicio,
            nosFilhos: [],
        };
        addNodeAsRotas(produtos);
        addNodeAoPai(inicio, produtos);

        const faleConosco: IRouteNode = {
            titulo: "fale com a gente",
            rota: "falecomagente",
            nodePai: inicio,
            nosFilhos: [],
        };
        addNodeAsRotas(faleConosco);
        addNodeAoPai(inicio, faleConosco);

        const novoAtendimento: IRouteNode = {
            titulo: "novo atendimento",
            rota: "novoatendimento",
            nodePai: faleConosco,
            nosFilhos: [],
        };
        addNodeAsRotas(novoAtendimento);
        addNodeAoPai(faleConosco, novoAtendimento);
        const atendimentos: IRouteNode = {
            titulo: "meus atendimentos",
            rota: "atendimentos",
            nodePai: faleConosco,
            nosFilhos: [],
        };
        addNodeAsRotas(atendimentos);
        addNodeAoPai(faleConosco, atendimentos);

        const informeRendimentos: IRouteNode = {
            titulo: "informe de rendimentos",
            rota: "informerendimentos",
            nodePai: documentos,
            nosFilhos: [],
        };
        addNodeAsRotas(informeRendimentos)
        addNodeAoPai(documentos, informeRendimentos);

        const extratosPrevidenciarios: IRouteNode = {
            titulo: "extratos previdenciários",
            rota: "extratos",
            nodePai: documentos,
            nosFilhos: [],
        };
        addNodeAsRotas(extratosPrevidenciarios)
        addNodeAoPai(documentos, extratosPrevidenciarios);

        const boletos: IRouteNode = {
            titulo: "Boleto",
            rota: "boletos",
            nodePai: documentos,
            nosFilhos: [],
        };
        addNodeAsRotas(boletos)
        addNodeAoPai(documentos, boletos);

        const informativo: IRouteNode = {
            titulo: "Destaques",
            rota: "destaques",
            nodePai: inicio,
            nosFilhos: [],
        };
        addNodeAsRotas(informativo);
        addNodeAoPai(inicio, informativo);

        const ajuda: IRouteNode = {
            titulo: "Ajuda",
            rota: "ajuda",
            nodePai: faleConosco,
            nosFilhos:[],
        }
        addNodeAsRotas(ajuda);
        addNodeAoPai(faleConosco,ajuda);
        
        const capnews: IRouteNode = {
            titulo: "Capnews",
            rota: "capnews",
            nodePai:inicio,
            nosFilhos:[],
        }
        addNodeAsRotas(capnews);
        addNodeAoPai(capnews,inicio);

        const trabalhosocial : IRouteNode = {
            titulo: "Trabalho Social",
            rota: "trabalhosocial",
            nodePai:inicio,
            nosFilhos:[],
        }
        addNodeAsRotas(trabalhosocial);
        addNodeAoPai(trabalhosocial,inicio);

        const perfilAtualizarDados : IRouteNode = {
            titulo: "Atualizar Dados",
            rota: "atualizardados",
            nodePai:perfil,
            nosFilhos:[]
        }
        addNodeAsRotas(perfilAtualizarDados);
        addNodeAoPai(perfilAtualizarDados,perfil);

        const statusSinistro : IRouteNode = {
            titulo: "Status de Benefícios - Função para Beneficiários",
            rota: "statussinistros",
            nodePai: inicio,
            nosFilhos:[]
        }
        addNodeAsRotas(statusSinistro);
        addNodeAoPai(statusSinistro,inicio)

        return [rotas, (rotaAtual: string): [ITrilha[], string] => {
            const obterUltimaRota = rotaAtual?.split('/')?.filter(rotas => rotas)?.reverse?.()?.[0];
            let trilhaAtual: IRouteNode | undefined = rotas[obterUltimaRota];
            const todasTrilhasEmNos: IRouteNode[] = [];

            console.log(obterUltimaRota);
            console.log(trilhaAtual);

            if(typeof trilhaAtual === 'undefined') return [[], ''];
            const titulo = trilhaAtual.titulo;

            while(typeof trilhaAtual !== 'undefined') {
                todasTrilhasEmNos.push(trilhaAtual);
                trilhaAtual = trilhaAtual.nodePai;
            }

            const trilha = todasTrilhasEmNos.reverse().map((no, i, arrNos) => ({
                path: `/${arrNos.slice(0, i + 1).map(n => n.rota).join('/')}/`,
                titulo: no.titulo,
            } as ITrilha));

            return [trilha, titulo];
        }];
    }, []);
    // eslint-disable-next-line
    const [trilhaAtual, tituloAtual] = React.useMemo(() => obterTrilha(path ?? pathname), [pathname, path]);

    const retornoHook = React.useMemo(() => ({
        trilhaAtual,
        tituloAtual,
        obterTrilha,
        rotas,// eslint-disable-next-line
    }), [trilhaAtual, tituloAtual]);

    return retornoHook;
};