import styled from 'styled-components';
export const SearchSpace = styled.div`
    height:3rem;
    display:flex;
    align-items:center;
    justify-content:space-around;
`
export const PerguntaText = styled.label`
    text-align: left;
    font: Bold 1.0rem Roboto;
    letter-spacing: -0.36px;
    color: #636363;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom:0px;
`
export const RoundedInput = styled.input`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 15px;
    opacity: 1;
    height:40px;
    width:68%;
    padding-left:1%;
    padding-right:1%;
    ::after
    {
        outline:0px;
    }
    ::before
    {
        outline:0px;
    }
    :focus{
        outline:0px;
    }
`