import styled from 'styled-components';
export const LoaderBarSpace = styled.div<{width?:string,height?:string}>`
    min-width:300px;
    min-height:20px;
    height:${prop=>prop.width??"100%"};
    width:${prop=>prop.height??"100%"};
`
export const MaxGreyBar = styled.div`
    width:100%;
    height:20px;
    border:1px solid grey;
    border-radius:5px;
    display:flex;
    align-items:center;
    padding:0px 1px;
`
export const LoaderColoredBar = styled.div<{color?:string,width?:string}>`
    background-color:${props=>props.color??props.theme.cores.primaria};
    height:17px;
    -moz-transition: width 1s ease;
    -webkit-transition: width 1s ease;
    -o-transition: width 1s ease;
    transition: width 1s ease;
    width:${props=>props.width??"0%"};
    border-radius:5px;
`