import styled from 'styled-components';
import '../../../styles/fonts/roboto/index.css';
import PhoneInput from 'react-phone-number-input';
import MaskedInput from 'react-maskedinput';
export const BackgroundPage = styled.div`
    height:100vh;
    width:100vw;
    background-image:url('https://capprdstportalorteador.blob.core.windows.net/b2crelacionamento/BG_portal3.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    position:fixed;
    filter: blur(8px);
    -webkit-filter: blur(8px);
`;
export const PageContent = styled.div`
    position: absolute;
    z-index: 2;
    width:100vw;
    height:100vh;
    display:flex !important;
    flex-direction:row !important;
    align-items:center !important;
    justify-content:center !important;
`;
export const ModalDialog = styled.div`
    background-color:white;
    height: 90%;
    width:35%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 39px;
    opacity: 1;
    display:flex;
    flex-direction:column;
    align-items:center;
`;
export const Logo = styled.img`
    height:16%;
`
export const HeaderText = styled.label`
    text-align: left;
    font: Bold 2em Roboto;
    letter-spacing: -0.74px;
    color: #E27800;
    text-transform: uppercase;
    opacity: 1;
    color:#E27800;
`;
export const RoundedPhone = styled(PhoneInput)`
        border:1px solid #B9B9B9;
    border-radius: 29px;
    width: 100%;
    height: 80%;
    padding-left:4%;
    padding-right:4%;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const RoundedMasked = styled(MaskedInput)<{isNegado?:boolean}>`
    border:${props=>props.isNegado?"1px solid red":"1px solid #B9B9B9"};
    border-radius: 29px;
    width: 100%;
    height: 80%;
    padding-left:4%;
    padding-right:4%;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const RoundedInput = styled.input<{isNegado?:boolean}>`
    border:${props=>props.isNegado?"1px solid red":"1px solid #B9B9B9"};
    border-radius: 29px;
    width: 100%;
    height: 80%;
    padding-left:4%;
    padding-right:4%;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const RoundedSelect = styled.select<{isNegado?:boolean}>`
    border:0px;
    width: 95%;
    height: 80%;
    margin-left:4%;
    -webkit-appearance: none;
    -moz-appearance: none;
    z-index:0;
    text-indent: 1px;
    text-overflow: '';
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    };

`
export const RoundedInputSpace = styled.div<{ width: string | number }>`
    width:${props => props.width};
    display:flex;
    flex-direction:column;
`
export const RoundedInputText = styled.label`
    margin-left:24px;
    text-align: left;
    font-size: 0.8em;
    letter-spacing: -0.34px;
    color: #B9B9B9;
    margin-bottom:0px;
`
export const Linha = styled.div`
    margin-bottom:3%;
    height:12%;
    width:100%;
    display:flex;
    justify-content:center;
`
export const SubmitLaunch = styled.input`
    height:100%;
    width:100%;
    align-self:center;
    background: #E27800 0% 0% no-repeat padding-box;
    border-radius: 29px;
    opacity: 1;
    color: #FFFFFF;
    border:0px !important;
    font-size:1.4em;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const BackToLogin = styled.label`
    text-align: left;
    letter-spacing: -0.34px;
    color: #E27800;
    text-transform: uppercase;
    margin-top:4%;
    opacity: 1;
    width:40%;
    align-self:left;
    margin-bottom:4%;
    :hover
    {
        cursor:pointer;
    }

`
export const MensagemTermosCondicoes = styled.label`
    width:98%;
    text-align: center;
    font-size:0.6rem;
    letter-spacing: -0.28px;
    color: #636363;
    align-self:center;
    height:10%;
    margin-bottom:0px;
`