import { SinistroContext, SinistroContextDefault} from '../../storage/contexts/SinistroContext';
import { ISinistroContext } from '../../interfaces/storage/contexts/ISinistroContext';
import usePersistedState from '../../storage/custom_hooks/PersistedState';
import Home from '../../pages/Sinistro/Home';
import {HeaderSection, Logo, NavigationBar, Simbolos} from "../../components/Header/styles";
import CapemisaLogo from '../../imagens/CAPEMISA---Logotipo-negativo.png';
import CapemisaPortalLogo from '../../imagens/MeuPortalCapemisa_Negativo.png';
import AcompanhamentoSinistro from './../../pages/Sinistro/AcompanhamentoSinistro';
import IdentificarSegurado from './../../pages/Sinistro/IdentificarSeguradoNew';
import IdentificarEvento from '../../pages/Sinistro/IdentificacaoEventoNew';
import EnviarDocumentos from '../../pages/Sinistro/EnviarDocumentos';
import AvisoJaCadastrado from '../../pages/Sinistro/AvisoJaCadastrado';
import ConfirmacaoDocumentos from '../../pages/Sinistro/ConfirmacaoDocumentos';
import {AllContentContainer, Container, ImageContainer} from './style';
import { Router, Route, useRouteMatch, Switch, Redirect, useHistory } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../../storage/contexts/GlobalContext';
import SinistroGeral from '../../pages/Sinistro/SinistroGeral';
import { AuthContext } from '../../storage/contexts/AuthContext';
import { LoaderRotatorMessage } from '../../components/Loaders/LoaderRotator';
import Tips from '../../components/widgets/Tips';
import DetalheAviso from '../../pages/Sinistro/DetalhesAviso';
import PageTracker from '../../services/googleAnalytics/PageTrackerComponent';

const SinistroRoutes : React.FC = (props):React.ReactElement=>
{
    const history = useHistory();
    const [sinistroContext,setSinistroContext] = usePersistedState<ISinistroContext>("sinistro",SinistroContextDefault)
    const { path } = useRouteMatch();
    const isUserLoggedIn : boolean = path.search("inicio")!==-1;
    const informativos = useContext(GlobalContext)?.globalContext?.Informativos ?? [];
    const {state} = useContext(AuthContext).auth;
    useEffect(()=>{
        if(state && !sinistroContext)
            setSinistroContext(JSON.parse(state));
        // eslint-disable-next-line
    },[])
    return(
        <Router history={history}>
            <FakeHeader isNecessary={!isUserLoggedIn} />
            <AllContentContainer isAuth={isUserLoggedIn}>
                <Container isAuth={isUserLoggedIn}>
                    <SinistroContext.Provider value={{sinistroContext,setSinistroContext}}>
                        <Switch>
                            <Route exact path={path} component={Home}/>
                            <Route exact path={`${path}/acompanhamentosinistro`} component={AcompanhamentoSinistro}/> 
                            <Route exact path={`${path}/identificacaosegurado`} component={IdentificarSegurado}/> 
                            <Route exact path={`${path}/avisojacadadastrado`} component={AvisoJaCadastrado}/> 
                            <Route exact path={`${path}/identificacaoevento`} component={IdentificarEvento}/>    
                            <Route exact path={`${path}/geral`} component={SinistroGeral} />    
                            <Route exact path={`${path}/enviardocumentos`} component={EnviarDocumentos} /> 
                            <Route exact path={`${path}/confirmacaodocumentos`} component={ConfirmacaoDocumentos} />           
                            <Route exact path={`${path}/detalheaviso`} component={DetalheAviso} />           
                            <Route exact path={`${path}/errorredirect`} component={()=>{window.location.href=window.location.origin+"/inicio";return(<></>)}}/>
                            <Route exact path={`${path}/loading`} component={()=><div><LoaderRotatorMessage>Aguardando o redirecionamento para o login</LoaderRotatorMessage></div>}/>
                            <Redirect to={`${path}/errorredirect`}/>
                        </Switch>
                        <PageTracker/>
                    </SinistroContext.Provider>
                </Container>
                {isUserLoggedIn?
                <ImageContainer>
                    <Tips data={informativos??[]}/>
                </ImageContainer>:<></>}
            </AllContentContainer>
        </Router>
    )
}
const FakeHeader: React.FC<{isNecessary?:boolean}> = (props) :React.ReactElement =>
{
    return props.isNecessary?
    <NavigationBar>
        <HeaderSection>
            <Simbolos marginLeft="30px" onClick={()=>{sessionStorage.clear();window.open("http://www.capemisa.com.br/")}}>
                <Logo src={CapemisaLogo}/>
            </Simbolos>
            <Simbolos marginLeft="0%">
                <Logo src={CapemisaPortalLogo}/>
            </Simbolos>
        </HeaderSection>
    </NavigationBar>:<></>
}
export default SinistroRoutes;