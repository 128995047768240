import React, { useContext, useEffect, useState } from 'react';
import IcomoonReact , {iconList} from "icomoon-react";
import IconesReservas from '../../icons/selection.json';
import { GlobalContext } from '../../storage/contexts/GlobalContext';
type IcomoonReactType = {
    color?: string;
    size?: string | number;
    icon?: string;
    className?: string;
    style?: React.CSSProperties;
}
const IcomoonIteration : React.FC<IcomoonReactType> = (props) : React.ReactElement =>{
    const [icon,setIcon] = useState<string>(props.icon??"CAPEMISA---Icones-38");
    let IconesContext = useContext(GlobalContext).globalContext.Icones;
    const [icones,setIcones] = useState<any>(IconesContext.length!==0?IconesContext:IconesReservas);
    useEffect(()=>{
        if(IconesContext.length!==0)
        {
            setIcones(IconesContext);
        }
        // eslint-disable-next-line
    },[IconesContext])
    useEffect(()=>{
        if(!(iconList(icones).find((atualIcone:string)=> atualIcone === icon)))
        {
            setIcon("CAPEMISA---Icones-38");
        }
        // eslint-disable-next-line
    },[icones])
    console.log(icones);
    console.log(iconList(icones));
    return(
        <IcomoonReact style={props.style} iconSet={icones} className={props.className} color={props.color} size={props.size} icon={icon} />
    )
}
export default IcomoonIteration;