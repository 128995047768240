import StyledAlert from "../../components/StyledAlert";

function EmptyTest (item:string,mensagemSeVazio:string) : boolean
{
    console.log(item);
    if(item!=="" && item.search("_")===-1)
    return true;
    else
    {
        StyledAlert(mensagemSeVazio);
        return false;
    }
}
export default EmptyTest;