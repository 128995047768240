import {APIUSER} from '../Adresses';
import { QueryConfig } from '../../../interfaces/storage/IReduxQuery';
export function GetIcones(isNotAuth?:boolean) : QueryConfig
{
    return {
        endpoint: APIUSER.Icones,
        method: "GET",
        config: {
            headers: isNotAuth ? null : undefined
        },
        queryName:"GetTokens"
    }
}