import React from 'react';
import { RoundedNews, BackgroundLogo, Titulo, TextSpace, SubTitle, FooterSpace} from './styles'

import { IProps } from '../../interfaces/components/INoticia'

const Noticia: React.FC<IProps> = (props): React.ReactElement => {
    return(
        <div>
            <RoundedNews>
                <BackgroundLogo imagem={props.data?.urlImagemDestaque as string} style={{width:"100%",zIndex:"initial"}}/>
                <TextSpace>
                    <Titulo>{props.data?.titulo}</Titulo>
                    <SubTitle>{new Date(props.data?.dataCriacao as string).toLocaleDateString()}</SubTitle>
                </TextSpace>
                <TextSpace>
                    <div dangerouslySetInnerHTML={{__html: props.data?.texto as string}}/>
                </TextSpace>
                <FooterSpace>

                </FooterSpace>
            </RoundedNews>
        </div>
    )
}

export default Noticia;
