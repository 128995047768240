import React, {  useContext, useEffect, useState } from 'react';
import PageModel from '../../../components/PageModel';
import { useHistory} from 'react-router-dom';
import {AllSpace,SearchSpace, PerguntaText,RoundedInput, RoundedComponentSpace, PerguntasFrequentesComponent, TituloLabel, ColunasSpace, TituloCategoria, ItemCategoria, RoundedSeeMore} from './style';
import { RoundedComponentAtendimentos } from '../../../components/RoundedComponent';
import { GetDuvidasFrequentes, GetDuvidas } from '../../../API/usuario';
import {PerguntasFrequentes,ApiPerguntas} from '../../../interfaces/pages/IAjuda';
import { IColuna, IColunaItem } from '../../../interfaces/components/IColunaItem';
import { RenderList } from '../../../components/DocumentList';
import Pagination from '../../../components/Paginacao';
import { GlobalContext } from '../../../storage/contexts/GlobalContext';
import { LoaderRotatorMessage } from '../../../components/Loaders/LoaderRotator';
const AjudaPage : React.FC = () : React.ReactElement =>
{
    const[perguntas,setPerguntas] = useState<PerguntasFrequentes[]>
    ([
        //{titulo:"Dúvidas sobre o portal", filhos:[{titulo:"Como alterar minha foto?",index:9},{titulo:"Como alterar meus dados cadastrais?",index:1},{titulo:"Como baixar meu certificado?",index:2},{titulo:"Como pagar a 2° via do boleto?",index:3},{titulo:"Como baixar o informe de rendimentos?",index:4}]},
        //{titulo:"Dúvidas sobre a CAPEMISA",filhos:[{titulo:"O que são riscos cobertos e riscos excluidos?",index:0},{titulo:"Qual o valor da minha indenização?",index:7},{titulo:"Como faço para alterar o valor da minha contribuição?",index:4},{titulo:"Como faço para alterar a forma de pagamento do meu plano?",index:5},{titulo:"Como faço para alterar meus beneficiários?",index:3}]},
    ])
    const[allPerguntas,setAllPerguntas]=useState<IColuna[]>([]);
    useEffect(()=>
    {
        GetDuvidas(["sc","sp"]).then((response)=>
        {
            setAllPerguntas(response.data.map((item:ApiPerguntas)=>{
                return({coluna:[{width:"70%",textAlign:"flex-start",message:item.pergunta,title:true,colorTitle:"#E27800"},{width:"30%",textAlign:"flex-start",message:(item.tipo==="sc"?"Dúvidas sobre a Capemisa":(item.tipo==="sp"?"Dúvidas sobre o portal":"??"))},{width:"100%",message:item.resposta,title:false,dontShow:true}]})
            }))
        }
        )
        GetDuvidasFrequentes(["sc","sp"],5).then(
            (response)=>
            {
                let colunaSC:PerguntasFrequentes = {titulo:"Dúvidas sobre a CAPEMISA",codigo:"sc",filhos:[]};
                let colunaSP:PerguntasFrequentes = {titulo:"Dúvidas sobre o Portal",codigo:"sp",filhos:[]};
                response.data.map((item:ApiPerguntas)=>
                {
                    if(item.tipo==="sc")
                    {
                        colunaSC.filhos.push({titulo:item.pergunta,index:item.id});
                    }
                    else if(item.tipo==="sp")
                    {
                        colunaSP.filhos.push({titulo:item.pergunta,index:item.id});
                    }
                    return ""
                })
                setPerguntas([colunaSP,colunaSC]);
            });// eslint-disable-next-line
    },[]);
    return(
    <PageModel>
        <AjudaContent allPerguntas={allPerguntas} perguntas={perguntas} />
    </PageModel>)
}
const DuvidasFrequentesComponent :React.FC<{perguntas:PerguntasFrequentes[]}> = (props) : React.ReactElement =>
{
    const history = useHistory();
    const {perguntas} = props;
    return(
        <PerguntasFrequentesComponent>
            <div style={{display:"flex",flexDirection:"row",alignItems:"center",height:"15%"}}>
                <TituloLabel>Perguntas Frequentes</TituloLabel>
                <hr style={{border:"1px solid #F2C800",overflow:"hidden",flexGrow:1}}/>
            </div>
            <ColunasSpace>
                {
                perguntas.length>0?
                    perguntas.map((item:PerguntasFrequentes)=>{
                        return(
                        <div style={{width:"45%",height:"100%",display:"flex",flexDirection:"column",justifyContent:"space-around"}}>
                            <div style={{height:"15%",display:"flex",justifyContent:"space-between"}}>
                                <TituloCategoria>{item.titulo}</TituloCategoria>
                                <RoundedSeeMore onClick={()=>{history.push("/inicio/falecomagente/ajuda/"+item.codigo)}}>ver mais</RoundedSeeMore>
                            </div>
                            <div style={{display:"flex",flexDirection:"column",justifyContent:"space-around",height:"80%"}}>
                            {
                                item.filhos.map((filho:{titulo:string,index:number},i:number)=>{
                                return(
                                    <ItemCategoria onClick={()=>{history.push("/inicio/falecomagente/ajuda/"+item.codigo+"/"+filho.index)}}>• {filho.titulo}</ItemCategoria>
                                )})
                            }
                            </div>
                        </div>
                        );
                    })
                    :
                    <LoaderRotatorMessage width={"100%"}/>
                }
            </ColunasSpace>
        </PerguntasFrequentesComponent>
    )
}
const AjudaContent : React.FC<{perguntas:PerguntasFrequentes[],allPerguntas:IColuna[]}> = (props) : React.ReactElement =>
{
    const {perguntas,allPerguntas} =props;
    function stringEscape(s:string) {// eslint-disable-next-line
        return s ? s.replace(/\\|\n|\t|\v|'|\[|\]|"/g,'').replace(/[\x00-\x1F\x80-\x9F]/g,hex) : s;
        function hex(c:string) { var v = '0'+c.charCodeAt(0).toString(16); return '\\x'+v.substr(v.length-2); }
    }
    const {globalContext} = useContext(GlobalContext);
    const maxPerPage=5;
    const [pesquisaPergunta,setPesquisaPergunta] = useState<string>("");
    const TestIfPerguntasAreSearched= ():IColuna[]=>{
        let searchedPerguntas:IColuna[] = [];
        if(pesquisaPergunta==="")
        {
            searchedPerguntas=allPerguntas;
        }
        else
        {
                allPerguntas.map((item:IColuna)=>{
                    if(typeof(item.coluna[0].message) === "string"?item.coluna[0].message.search(pesquisaPergunta)>-1:false)
                    {
                        searchedPerguntas.push(item);
                    }
                    return ""    
                })
        }
        if(searchedPerguntas.length>0)
        return searchedPerguntas;
        else
        return [{coluna:[{width:"100%",message:"Nenhuma pergunta foi encontrada"}]}];
    }
    const perguntasToShow = TestIfPerguntasAreSearched();
    const [actualPage,setActualPage] = useState<number>(1);
    return(
        <AllSpace>
            <SearchSpace>
                <PerguntaText>Qual a sua dúvida?</PerguntaText>
                <RoundedInput value={pesquisaPergunta} onChange={(e)=>{setPesquisaPergunta(stringEscape(e.target.value))}} placeholder="Digite aqui a sua dúvida"/>
            </SearchSpace>
            {
                pesquisaPergunta!==""?
                <div>
                    <PerguntaText>Foram encontrados {perguntasToShow[0].coluna[0].message==="Nenhuma pergunta foi encontrada"?0:perguntasToShow.length} resultado(s)</PerguntaText>
                    <RenderList view={{cantView:(perguntasToShow.length>0?perguntasToShow[0].coluna[0].message==="Nenhuma pergunta foi encontrada":false),type:"dropdown"}} cantDownload AditionalViewComponent={perguntasToShow?.map((item:{coluna:IColunaItem[]})=>{return(<div style={{display:"flex",width:"98%",marginLeft:"2%",marginBottom:"10px"}}>{item?.coluna[2]?.message}</div>)})} actualPage={actualPage} columnNames={[{width:"64%",message:"Dúvida",textAlign:"flex-start",title:true},{width:"25%",message:"Categoria",textAlign:"flex-start",title:true}]} contents={perguntasToShow}/>
                    {
                        perguntasToShow.length>maxPerPage?
                        <div style={{marginTop:"2%",marginBottom:"4%"}}>
                        <Pagination actualPage={actualPage} setActualPage={setActualPage} maxPerPage={maxPerPage} length={perguntasToShow.length}/>
                        </div>:""
                    }
                </div>
                :
                <DuvidasFrequentesComponent perguntas={perguntas}/>
            }
            <RoundedComponentSpace>
                <div style={{width:"30%"}}>
                    {
                        globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/")?
                        <RoundedComponentAtendimentos item={{documentIco:"31",documentText:"FALE COM A GENTE",url:"/inicio/falecomagente"}}/>
                        :
                        <></>
                    }
                </div>
                <div style={{width:"30%"}}>
                    {
                        globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/atendimentos/")?
                        <RoundedComponentAtendimentos item={{documentIco:"37",documentText:"MEUS ATENDIMENTOS",url:"/inicio/falecomagente/atendimentos"}}/>
                        :
                        <></>
                    }
                </div>
                <div style={{width:"30%"}}>
                    {
                        globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/novoatendimento/")?
                        <RoundedComponentAtendimentos item={{documentIco:"35",documentText:"NOVO ATENDIMENTO",url:"/inicio/falecomagente/novoatendimento"}}/>
                        :
                        <></>
                    }
                </div>
            </RoundedComponentSpace>
        </AllSpace>
    )
}
export default AjudaPage;