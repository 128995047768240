import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import logoCapemisaSeguradora from '../../../imagens/logo-capemisa-seguradora.png';
import {Link} from 'react-router-dom';
export const PageSpace = styled.div`
    width:fit-content;
    min-height:500px;
    min-width:520px;
    max-width:100%;
    padding-bottom:10px;
`
export const TopRounded = styled.div`
    width:100%;
    border:1px solid #B9B9B9;
    border-radius:9px;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
`
export const TextSuspended = styled.div<{width?:string}>`
    display:flex;
    flex-direction:column;
    width:${props=>props.width??"48%"};
`
export const InsideRowColumnName = styled.div`
    text-align: left;
    font: 0.9em Roboto;
    letter-spacing: -0.34px;
    color: #B9B9B9;
    opacity: 1;
    margin-bottom:0px;
`
export const InsideSelectedText = styled.label`
    margin-bottom:0px;
    margin-top:5px;
    text-align: left;
    font: 1em Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
`
export const TopRoundedRow = styled.div`
    margin-top:5px;
    min-height:40px;
    width:98%;
    display:flex;
    justify-content:space-between;
`
export const TopRoundedFooter = styled.div`
    margin-top:20px;
    padding-left:5px;
    padding-right:5px;
`
export const SingleLine = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    justify-content:space-between;
`
export const AlignSpace = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
`
export const RowsSpacer = styled.div`
    width:100%;
    height:fit-content;
    padding:10px 0px;
    display:flex;
    flex-direction:column;
`
export const Icon = styled(FontAwesomeIcon)`
    font-size:15px;
    margin-right:2px;
    cursor:pointer;
`
export const DropdownSpace = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    padding:10px 0px;
`
export const EnviarDocumentosButton = styled.button<{buttonSize?:"default"|"big",canSend?:boolean}>`
    ${props=>props.canSend?"background: #E27800 0% 0% no-repeat padding-box;letter-spacing: 0px;color: #FFFFFF;cursor:pointer;"
    :"background: #D8D8D8 0% 0% no-repeat padding-box;text-align: left;font: normal normal bold 13px/18px Roboto;letter-spacing: 0px;color: #FFFFFF;opacity: 1;cursor:auto!important;"}
    
    ::after, ::before, :focus{
        outline:0px;
    };
    ${props=>props.buttonSize==="big"?"min-width:300px;max-width:400px;border-radius:7px;":"min-width:200px;max-width:300px;border-radius:5px;"}
    border:0;
    margin-top:10px;
    min-height:30px;
    text-align:center;
`
export const NavigationButtonsSpace = styled.div`
    display:flex;
    flex-direction:row-reverse;
    margin-top:10px;
`

export const OrangeLink=styled(Link)`
   color:#E27800;
`;
export const BtnOutlineLaranja=styled(Link)`
    height:40px;
    border-radius:21px;
    background-color:transparent; 
    border:2px solid #E27800;
    font-size:1em;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    color:#E27800;
    font-family:Roboto;
    width:32%;
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        color:#E27800;
        text-decoration: none;
    }
    ::after, ::before, :focus{
        outline:0px;
    };
`;
export const BtnLaranja=styled.button`
    height: 40px;
    background-color: #E27800;
    border-radius: 21px; 
    border: none; 
    font-size:1em;
    color: #FFFFFF;
    width:32%;
    ::after, ::before, :focus{
        outline:0px;
    }; 
    margin-left:10px;
`;
export const ModalBody = styled.div`
    height:100%;
    min-height:200px;
    width:300px;
    display:flex;
    flex-direction:column;
    padding:10px;
    justify-content:space-between;
`
export const WarningMessage = styled.label`
    text-align: center;
    font: normal normal bold 16px Roboto;
    letter-spacing: 0px;
    color: #E27800;
    opacity: 1;
`
export const BodyText = styled.label`
    text-align: center;
    font: normal normal normal 14px Roboto;
    letter-spacing: 0px;
    color: #636363;
`
export const MiniButtonsSpace = styled.div`
    width:100%;
    display:flex;
    justify-content: space-around;
`
export const MiniGreyButton = styled.button`
    border-radius:21px;
    background-color:#DADADA; 
    border:none;
    font-size:1em;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    color:#707070;
    font-family:Roboto;
    width:32%;
    text-decoration: none;
    height:25px;
    padding:0px 10px;
`
export const MiniOrangeButton = styled.button`
    height: 25px;
    background-color: #E27800;
    border-radius: 21px; 
    border: none; 
    font-size:1em;
    color: #FFFFFF;
    width:54%;
    ::after, ::before, :focus{
        outline:0px;
    }; 
    margin-left:10px;
`
export const PrintDocsLabel = styled.div`
    color:${props=>props.theme.cores.primaria};
    font-size:1em;
    display: inline-block;
    :hover{
        cursor:pointer;
        text-decoration:underline;
    }
    padding-left: 300px;
    font-weight: bold;
`

export const PrintBackdrop = styled.div`
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
`
export const PrintInsideSpace = styled.div`
    width:70%;
    height:max-content;
`

export const CapemisaImage = styled.div`
    background-image:url(${logoCapemisaSeguradora});
    height:100px;
    width:100%;
    margin-top:40px;
    background-size: contain;
    background-repeat: no-repeat;
    border-bottom:2px solid #E2B500;
`