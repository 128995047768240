function CorrigirVariavelMonetaria(valorPuro:string)
{
    const valorComPonto = valorPuro?.toString()??"";
    if(valorComPonto.length>0)
    {
        let valorSemPonto=valorComPonto.toString().replace(".",",");
        let resultado = valorSemPonto.split(",");
        let numeroInteiro=resultado[0].toString();
        let numeroDecimal=(resultado[1] ? resultado[1] : "").toString();
        let valorFinal = "";
        for(let i=numeroInteiro.length;i>0;i--)
        {
            if(i%3===0 && i!==numeroInteiro.length)
            {
                valorFinal+=".";
            }
            valorFinal+=numeroInteiro[numeroInteiro.length-i];
        }
        return valorFinal+","+(numeroDecimal?(numeroDecimal.length<2?numeroDecimal+"0":numeroDecimal):"00");
    }
    else
    {
        return "0"
    }
}
export default CorrigirVariavelMonetaria;