import React, { useState, useEffect } from 'react';
import PageModel from '../../../components/PageModel';
import { ITrilha } from '../../../interfaces/components/IPageModel';
import Noticia from '../../../components/Noticia';
import { useParams, useHistory } from 'react-router-dom';
import { ITip } from '../../../interfaces/components/ITips';
import { GetNovidadeById, GetNovidadeExclusiva } from '../../../API/usuario';
import {TituloLabel} from './style';
import { NoticiaSmallContainer, MensagemCentralNoticiaContainer, MensagemCentralNoticia, MensagemCentralNoticiaText,RoundedNews } from '../CapNEWS/style';
const CapnewsEspecifico :React.FC<{}> = () : React.ReactElement =>
{
    let history = useHistory();
    const trilha:ITrilha[] =
    [
        {
            titulo:"INICIO",
            path:"/inicio/",
        },
        {
            titulo:"CAPNEWS",
            path:"/inicio/CAPNEWS",
        },
    ];
    const [lastNews,setLastNews]= useState<{urlImagemDestaque:string,titulo:string,id:number}[]>([]);
    const [data,setData] =useState<ITip>()
    let { id } = useParams<{id: string}>();
    useEffect(
        ()=>{
            
        GetNovidadeById(parseInt(id??"")).then((response)=>{
            setData(response.data)
        })
        GetNovidadeExclusiva(parseInt(id??"")).then((responseTwo)=>{
            setLastNews(responseTwo.data);
        })
        // eslint-disable-next-line
    },[id])
    return(
        <PageModel titulo="CAPNEWS" trilha={trilha}>
            <Noticia data={data}/>
            <div style={{display:"flex",flexDirection:"column",marginTop:"5%"}}>
                <div style={{display:"flex",flexDirection:"row",alignItems:"center",height:"15%"}}>
                    <TituloLabel>Ultimas Noticias</TituloLabel>
                    <hr style={{border:"1px solid #F2C800",overflow:"hidden",flexGrow:1}}/>
                </div>
                <div style={{display:"flex"}}>
                {
                lastNews.map((item:{urlImagemDestaque:string,titulo:string,id:number})=>{
                    return(<NoticiaSmallContainer onClick={()=>{history.push("/inicio/capnews/"+item.id)}} style={{cursor:"pointer"}}>
                                <RoundedNews backgroundImage={item.urlImagemDestaque}/>
                                <MensagemCentralNoticiaContainer>
                                    <MensagemCentralNoticia>
                                        <MensagemCentralNoticiaText>{item.titulo}</MensagemCentralNoticiaText>
                                    </MensagemCentralNoticia>
                                </MensagemCentralNoticiaContainer>
                        </NoticiaSmallContainer>)
                })
                }
                </div>
            </div>
        </PageModel>
    )
}
export default CapnewsEspecifico;