import styled from 'styled-components';
export const PageContent = styled.div`
    position: absolute;
    z-index: 2;
    width:100vw;
    height:100vh;
    display:flex !important;
    flex-direction:row !important;
    align-items:center !important;
    justify-content:center !important;
`;
export const ModalDialog = styled.div<{height:string,width:string}>`
    background-color:white;
    height: ${props=>props.height};
    width: ${props=>props.width};
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 39px;
    opacity: 1;
    display:flex;
    flex-direction:column;
    min-width:300px;
    min-height:400px;
    align-items:center;
`;
export const BackgroundPage = styled.div`
    height:100vh;
    width:100vw;
    background-image:url('https://capprdstportalorteador.blob.core.windows.net/b2crelacionamento/BG_portal3.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    position:fixed;
    filter: blur(8px);
    -webkit-filter: blur(8px);
`;
