import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft,faChevronRight } from '@fortawesome/free-solid-svg-icons'

import {IProps, ITip} from "../../../interfaces/components/ITips"

import { Container, Title, Description,DotsContainer, Dot, Image, ImageButton, ContainerNonLink } from './styles'

const Tips : React.FC<IProps> = (props): React.ReactElement =>{
    const { data, cantRedirect } = props;
    const [selected , setSelected] = useState<number>(0);
    const [tip, setTip] = useState<ITip>(data[selected]);

    const changeTip = (tipo: "previous" | "next") =>{
        if(tipo === "next")
            selected === data?.length - 1 ? setSelected(0) : setSelected(selected + 1);
        else if( tipo === "previous")
            selected === 0 ? setSelected(data?.length - 1) : setSelected(selected - 1);
    };
    
    useEffect(() => {
        setTip(data[selected]);// eslint-disable-next-line
    }, [selected])

    React.useEffect(() => {
        const timer = setInterval(()=>{changeTip("next")},5000);

        return () => {
            clearInterval(timer);
        };
    })
    const RenderedComponent : React.FC<{to:string}> = (props)=>{
        if(cantRedirect)
        return(
            <ContainerNonLink>
                {
                    props.children
                }
            </ContainerNonLink>
        )
        else
        return(
            <Container to={props.to}>
                {
                    props.children
                }
            </Container>
        )
    }
    return(
        <RenderedComponent to={`/inicio/destaques/${tip?.id}`}>   
            <Image src={tip?.urlImagemLateral}>
                <ImageButton 
                    tipo="previous" 
                    onClick={(ev) => {
                        changeTip("previous");
                        ev.preventDefault();
                    }}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{fontSize:14}}/>
                </ImageButton>

                <DotsContainer onClick={(e)=>{e.stopPropagation()}}> 
                    {data?.map( (e, i )=> {
                        return <Dot key={"tipDotKey"+i} index={i} isSelected={i === selected} onClick={(e)=>{e.stopPropagation();setSelected(i)}}/>
                    })}
                </DotsContainer>

                <ImageButton 
                    tipo="next" 
                    onClick = {(ev) => {
                        changeTip("next");
                        ev.preventDefault();
                    }}>
                    <FontAwesomeIcon icon={faChevronRight} style={{fontSize:14}} />
                </ImageButton>
            </Image>  
            
            { tip?.titulo?<Title>
                {tip?.titulo}
            </Title>:""
            }
            {tip?.subtitulo?  
            <Description>
                {tip?.subtitulo}
            </Description>:"" }
        </RenderedComponent>
    )
}

export default Tips;