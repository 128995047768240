import React, { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LoaderRotatorModal } from '../../../components/Loaders/LoaderRotator';
import Modal from '../../../components/Modal';
import SinistroPageModel from '../../../components/SinistroComponents/SinistroNewPageModel';
import { TextInput } from '../../../components/TextInput';
import { PortalURL } from '../../../env_settings';
import { TestaCPF } from '../../../functions/CpfTest';
import IAvisoSinistro from '../../../interfaces/IAvisoSinistro';
import { IStatePropsDetalhesSinistro } from '../../../interfaces/pages/IDetalhesSinistro';
import { ISinistroContext } from '../../../interfaces/storage/contexts/ISinistroContext';
import { GetAtendimentoByCPFAndProtocolo, PatchSolicitarProtocoloSinistro } from '../../../services/APICallableActions/sinistro';
import APIRequestFunction from '../../../services/APIRequestFunction';
import { SinistroContext } from '../../../storage/contexts/SinistroContext';
import { ButtonContainer, InputsContainer, PrimaryButton, LinkButton } from './styles'

const AvisoJaCadastrado: React.FC = ():React.ReactElement => {
    const [protocolo, setProtocolo] = React.useState("");
    const [cpf, setCpf] = React.useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();
    const { path } = useRouteMatch();
    const { sinistroContext } = useContext(SinistroContext);

    const {
        informacoesSegurado,
        dataEvento,
        codigoEvento,
        grauRelacionamentoComunicante,
    } = sinistroContext;

    const {
        cpfSegurado,
    } = informacoesSegurado??{cpfSegurado:'',dataNascimentoSegurado:'',primeiroNomeSegurado:'',}

    const {
        name:nomeGrauRelacionamentoComunicante,        
    } = grauRelacionamentoComunicante ?? { id:0,name:"" };

    const RedirectToNexPageWithAtendimentoData = async () => {
        const VerificarUserLogado = () => {
            return path.search("inicio")===-1;
        }
        
        const ExecuteAPIRequest = async () => {
            const atendimentoQuery = APIRequestFunction<IAvisoSinistro>(
                GetAtendimentoByCPFAndProtocolo(cpf.replace(/[^\d]/g,''),protocolo)
            );
            atendimentoQuery.catch(()=>{
                alert("Aviso não localizado! Verifique os dados e tente novamente");
            });
            try {
                return await atendimentoQuery;
            }
            catch{}
        }

        let newSinistroContextObj : ISinistroContext = {
            ...sinistroContext,
            id: protocolo
        };

        if(VerificarUserLogado())
        {
            const fixedOptions =  JSON.stringify(({...newSinistroContextObj, id: protocolo, etapa:"acompanhamentoSinistro"} as ISinistroContext));
            const urlToCall = "https://capemisaseguradoradev.b2clogin.com/CapemisaSeguradoraDev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignIn&client_id=f79ecffc-4ccc-4414-b0d6-d46eb2b158ed&nonce=defaultNonce&redirect_uri="+PortalURL+"/auth&scope=openid&response_type=id_token&prompt=login&state="+fixedOptions
            window.location.href = urlToCall;
        }
        else
        {
            try{
                const AtendimentoResult = await ExecuteAPIRequest();
                if(AtendimentoResult?.status === 200)
                    history.push(`detalheAviso`,
                        {
                            userType: "protocolo",
                            data: AtendimentoResult?.data
                        } as IStatePropsDetalhesSinistro
                    );
            }
            catch{}
        }
    }

    const ValidarDados = () =>{
        if(TestaCPF(cpf))
        {
            alert("CPF inválido!");
            return false;
        }
        if(protocolo.length < 19)
        {
            alert("Protocolo inválido!");
            return false;
        }
        return true;
    }

    const handleAcompanharSinistro = async () => {
        setIsLoading(true);
        if(await ValidarDados())
        {
            await RedirectToNexPageWithAtendimentoData();
        }
        setIsLoading(false);
    };

    const handleCancelModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModal = async () => {
        const FixDate = (date?:string)=>{
            const fixedData = date ?? new Date().toLocaleDateString("pt-br").slice(0,10);
            return (fixedData.slice(6,10)+"-"+fixedData.slice(3,5)+"-"+fixedData.slice(0,2))
        };
        setIsLoading(true);
        try{
            await APIRequestFunction(PatchSolicitarProtocoloSinistro(
                cpfSegurado,
                FixDate(dataEvento),
                codigoEvento?.toString() ?? "",
                nomeGrauRelacionamentoComunicante
            ));
            setIsLoading(false);
            setIsModalOpen(true);
        }
        catch{
            setIsLoading(false);
        };
    };

    return (<SinistroPageModel tituloPagina="COMUNICAÇÃO DE SINISTRO - AVISO REALIZADO" >
        <p>O aviso para este evento ja foi realizado anteriormente.</p>

        <p>Caso você queira consultar status e/ou informações sobre sua solicitação, basta acessar a área de inserir o número de protocolo e o CPF do Segurado(a)/Participante nos campos abaixo:</p>
    
        <InputsContainer>
            <TextInput rotulo={'Protocolo de Atendimento'}  type="text" borderRadius={"10px"} valor={protocolo} onChange={(ev:React.FormEvent<HTMLInputElement>) => setProtocolo(ev.currentTarget.value)}/>
            <TextInput rotulo={'CPF do Segurado(a)/Participante'}  type="cpf" borderRadius={"10px"} valor={cpf} onChange={ (ev:React.FormEvent<HTMLInputElement>) => setCpf(ev.currentTarget.value)}/>
        </InputsContainer>

        <ButtonContainer>
            <PrimaryButton onClick={handleAcompanharSinistro}>
                ACOMPANHAR SINISTRO
            </PrimaryButton>
        </ButtonContainer>

        <p>
            Caso não saiba o protocolo de atendimento, <LinkButton
                onClick={handleOpenModal}
            >
                clique aqui para solicitá-lo
            </LinkButton>
        </p>
        <LoaderRotatorModal show={isLoading}/>
        {
            isModalOpen
            &&  <Modal
                    title="SOLICITAÇÃO ENVIADA COM SUCESSO"
                    submitText="OK"
                    alignButtons="space-evenly"
                    hasSubmit
                    onSubmit={handleCancelModal}
                >
                <p style={{textAlign:'center'}}>Analisaremos sua solicitação e retornaremos por email em até 48h.</p>
            </Modal>
        }

    </SinistroPageModel>)
}

export default AvisoJaCadastrado