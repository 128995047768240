import React, { useState, useContext } from 'react';
import {IcoContainer, RoundedComponent, SuperiorPartRounded, InferiorPartRounded, OptionContainer, OptionIco, RoundedComponentText, RoundedComponentDocument, DocumentText, DocumentBody, RoundedComponentDocArea, RoundedComponentAtendimento } from "./styles";
import { IFavorite } from "../../interfaces/components/IFavorite";
import { Redirect } from "react-router-dom";
import { faEllipsisV} from '@fortawesome/free-solid-svg-icons'
import IDocument from '../../interfaces/IDocument';
import IcomoonReact from "icomoon-react";
import { GlobalContext } from '../../storage/contexts/GlobalContext';
import IcomoonIteration from '../IcomoonIteration';
type IProp= {item:IFavorite,cantRedirect?:boolean,onClick?:Function,callBackOptions?:any,width?:string,hideDots?:boolean,height?:number}
type IPropDoc ={item:IDocument,callBackOptions:any,};
export default function RoundedComponentBottom (prop:IProp)
{
    const [redirect,setRedirect]=useState(false);
    let icon = "CAPEMISA---Icones-"+(prop.item.isNotUrl?prop.item.icoReference.toString():TempIco(prop.item.icoReference)).toString();
    return(
    <RoundedComponent width={prop.width} onClick={()=>{if(prop.onClick)prop.onClick();setRedirect(true)}}>
        {
            (!prop.cantRedirect && redirect?
                (
                <Redirect to={prop.item.url}/>):""
            )
        }
        <SuperiorPartRounded>
            {
                (prop.hideDots??false)?(<div style={{height:"14px"}}></div>):(
                <OptionContainer>
                    <div onClick={()=>{if(prop.callBackOptions)prop.callBackOptions()}} style={{width:"8%",display:"flex",justifyContent:"center"}}>
                        <OptionIco icon={faEllipsisV} />
                    </div>
                </OptionContainer>
                )
            }
            <IcoContainer>
                <IcomoonIteration icon={icon} size={prop.height??110} color="#515050"/>
            </IcoContainer>
        </SuperiorPartRounded>
        <InferiorPartRounded>
            <RoundedComponentText>{prop.item.message}</RoundedComponentText>
        </InferiorPartRounded>
    </RoundedComponent>
    );
}
function TempIco (url:string)
{
    const palavraChave =".png";
    const palavraChave2 = "icones/ico";
    return url.slice(url.search(palavraChave2)+palavraChave2.length,url.search(palavraChave));
}
export function RoundedComponentDocuments (prop:IPropDoc)
{
    const Icones = useContext(GlobalContext).globalContext.Icones;
    const [redirect,setRedirect]=useState(false);
    return(
        <RoundedComponentDocArea onClick={()=>{setRedirect(true)}}>
            {
            (redirect?
                <Redirect to={prop.item.url}/>:""
            )
            }
            <RoundedComponentDocument>
                <DocumentBody>
                <IcomoonReact iconSet={Icones} icon={"CAPEMISA---Icones-"+TempIco(prop.item.documentIco)} color="#E27800" size={100} style={{height:"100%"}}/>
                    <DocumentText>{prop.item.documentText}</DocumentText>
                </DocumentBody>
            </RoundedComponentDocument>
        </RoundedComponentDocArea>
        );
}
export const RoundedComponentAtendimentos:React.FC<{item:{documentIco:string,documentText:string,url:string}}> = (prop) :React.ReactElement =>{
    const [redirect,setRedirect]=useState(false);
    const Icones = useContext(GlobalContext).globalContext.Icones;
    return (
        <RoundedComponentAtendimento onClick={()=>{setRedirect(true)}}>
            {
            (redirect?
                <Redirect to={prop.item.url}/>:""
            )
            }
            <DocumentBody>
            <IcomoonReact iconSet={Icones} icon={"CAPEMISA---Icones-"+prop.item.documentIco} color="#E27800" size={70} style={{height:"100%"}}/>
                <DocumentText>{prop.item.documentText}</DocumentText>
            </DocumentBody>
        </RoundedComponentAtendimento>
    )
}