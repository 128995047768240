import React from 'react';
import ReactDOM from 'react-dom';
import { AlertBackdrop, AlertSpace, AlertButton, AlertText, AlertTextSpace } from './style';

const StyledAlert : (texto?: any, customButtonText?:string) => void = (texto?:string,customButtonText?:string)=>{
    if(document.getElementById("renderedBackdrop"))
    {
        const tempTrashDivAlert = document.createElement('div');
        const tempTrashDivButton = document.createElement('div');
        ReactDOM.render(
        <AlertSpace>
            <AlertTextSpace>
                <AlertText className="alertinnerText">
                    {texto??""}
                </AlertText>
            </AlertTextSpace>
        </AlertSpace>,
        tempTrashDivAlert);
        ReactDOM.render(
            <AlertButton id="finalAcceptButton" onClick={()=>{ReactDOM.unmountComponentAtNode((document.getElementById("customAlert") as HTMLElement))}}>{customButtonText??"Aceitar"}</AlertButton>,
            tempTrashDivButton);
        const renderedBackdropReference = document.getElementById("renderedBackdrop");
        renderedBackdropReference?.appendChild(tempTrashDivAlert);
        document.getElementById("finalAcceptButton")?.remove();
        renderedBackdropReference?.appendChild(tempTrashDivButton);
    }
    else
    {
        ReactDOM.render(
            <AlertBackdrop id="renderedBackdrop">
                <AlertSpace>
                    <AlertTextSpace>
                        <AlertText>
                            {typeof(texto)=="string"?texto:""}
                        </AlertText>
                    </AlertTextSpace>
                </AlertSpace>
                <AlertButton id="finalAcceptButton" onClick={()=>{ReactDOM.unmountComponentAtNode((document.getElementById("customAlert") as HTMLElement))}}>{customButtonText??"Aceitar"}</AlertButton>
            </AlertBackdrop>,
        document.getElementById("customAlert"))
    }
    
}
export default StyledAlert;