import React, { useState, useEffect } from 'react';
import Routes from "./routes";
import {ThemeProvider} from 'styled-components';
import DefaultTheme from './styles/themes/default';
import './styles/debug/index.css';
import  GlobalStyle from './styles/global';
import {isIE} from 'react-device-detect';
import packageJson from "../package.json";
import moment from "moment";
import { LoaderError } from './components/Loaders/LoaderDots';
import StartAnalytics from './services/googleAnalytics/InitializeGA';

const buildDateGreaterThan = (latestDate:string, currentDate:string) : boolean => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);
    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
        return true;
    } else {
        return false;
    }
};

function withClearCache(Component:React.FC) {
    const ClearCacheComponent : React.FC = (props) =>{
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
        useEffect(() => {
            const ChamarCacheDeparaeComparar = async () =>{
                try{
                    let response = await fetch("/meta.json");
                    let responseClone = response.clone();
                    if(response.status===200 && (await responseClone.text()).search("<!DOCTYPE html>") === -1)
                    {
                        let meta = await response.json();
                        const latestVersionDate = meta.buildDate;
                        const currentVersionDate = packageJson.buildDate.toString();
                        const shouldForceRefresh = buildDateGreaterThan(
                            latestVersionDate,
                            currentVersionDate
                        );
                        if (shouldForceRefresh) {
                            setIsLatestBuildDate(false);
                            console.log("Cache desatualizado, forçando reload.");
                            refreshCacheAndReload();
                        } else {
                            setIsLatestBuildDate(true);
                        }
                    }
                    else
                    {
                        console.log("Ocorreu um erro ao consultar o depara de cache. Assumindo que a versão atual é a versão mais atualizada devido a falta de informações.");
                        setIsLatestBuildDate(true);
                    }
                }
                catch(ex)
                {
                    console.log(ex)
                    console.log("Ocorreu um erro ao consultar o depara de cache. Assumindo que a versão atual é a versão mais atualizada devido a falta de informações.");
                    setIsLatestBuildDate(true);
                }
            }
            ChamarCacheDeparaeComparar();
        }, []);

        const refreshCacheAndReload = () => {
            if (caches) {
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }
            window.location.reload();
        };

        return (
            <>
                {isLatestBuildDate ? <Component {...props} /> : <></>}
            </>
        );
    }
  return ClearCacheComponent;
}


const App: React.FC = (): React.ReactElement => {
    StartAnalytics();
    try{
        if(isIE)
            return (
                <div>
                    <h3>Não é possível acessar este website utilizando Internet Explorer! Por favor baixe ou <a href="https://www.google.com/intl/pt-BR/chrome/">Chrome</a> ou <a href="https://www.opera.com/pt-br">Opera</a> ou <a href="https://www.mozilla.org/pt-BR/firefox/new/">Firefox</a> para acessar o portal!</h3>
                </div>
            )
        else
            return (
                <ThemeProvider theme={DefaultTheme}>
                    <GlobalStyle/>
                    <Routes/>
                </ThemeProvider>
            );
    }
    catch(exception)
    {
        return(<LoaderError erro={"Ocorreu um erro intratável! Recarregue a página para resolver, se continuar a ocorrer contate o suporte CAPEMISA!"}/>)
    }
}

export default withClearCache(App);
