import styled, { keyframes } from 'styled-components';
import { IListItem } from '../../interfaces/components/ISelect';

const growUp = (value: string) => keyframes`
	from {
		height: 0;
	}

	to {
		height: ${value};
	}
`;

const growDown = (value: string) => keyframes`
	from {
		height:${value};
	}

	to {
		height:  0;
	}
`;

export const Container = styled.div< {size: {width?: string | number, heigth?: string | number,}, listHeigth?: string}>`
    display: flex;
    flex-direction: column;
    width: ${props => props?.size.width ?? "100%" };
    height: ${props => props?.size.heigth ?? "auto" };
    border: solid 1px #707070;
    border-radius: 10px;
    & > #content{
        background: transparent;
        border: none;
        padding:15px;
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        align-items:center;
        pointer-events: all;
        cursor: pointer;
    };

    & > #content:focus {
        border-bottom: solid 1px #707070;
        outline: none;
    }
`;



export const Title = styled.span<{bold?: boolean}>`
    display: flex;
    flex-direction: column;
    color: #707070;
    font-weight: ${props => props?.bold ? "bold" : "normal" };
`; 

export const Arrow = styled.i`

`

export const List = styled.div<{height: string | undefined}>`
    height: 0;
    width: 100%;
    overflow-y: scroll;
    animation: ${props => growDown(props?.height ?? "auto" )} .4s;

    #content:focus ~ &{
        height: ${props => props?.height ?? "auto" };
      animation: ${props => growUp(props?.height ?? "auto" )} .4s;
    }

`; 

export const ListItem = styled.button<IListItem>`
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
    padding:${props => props.isSubitem ? "10px 30px" : " 10px 15px" };

    &:hover{
        background-color: #54555445;
    }
	&:focus {
		outline: none;
	}
`; 


// export const title = styled.span`
    
// `; 

