import axios from 'axios';
import IArgs from '../../interfaces/IArgs';
import {B2C_URL_SIGNIN} from '../../env_settings';
import StyledAlert from '../../components/StyledAlert';
import FindErrorMessageOnAPIReturn from '../../functions/FindErrorMessageOnAPIReturn';
//DEPRECATED! THIS CATEGORY OF API REQUEST IS FROM NOW (22/03/2021) ON CONSIDERED OUT OF STRUCTURE ARQUITECTURE!


//DEPRECADO! ESTA CATEGORIA DE CHAMADA A API NÃO ESTÁ SENDO MAIS UTILIZADA A PARTIR DE 22/03/2021
//QUALQUER USO DESTA CHAMADA É CONSIDERADO FORA DE ARQUITETURA DE PROJETO!
export function Requisicao<ResponseType=any>(url:string,config?:{method?:"GET"|"GETCUSTOMTOKEN"|"GETNOTOKEN"|"POST"|"POSTNOTOKEN"|"PUT"|"PATCH"|"DELETE"|"GETARCHIVE",body?:any,form?:any,aditionalInfo?:any,cantShowError?:boolean})
{
    //teste
    const actualConfig = config ?? {method:"GET"};
    actualConfig.method = config?.method ?? "GET";
    const storageToken:string=sessionStorage.getItem("auth") ?? '{"TOKEN": "", "isAuth": false}';
    const autenticacao =
    {
        Authorization: "Bearer " + JSON.parse(storageToken).TOKEN, 
	};
    let args=actualConfig?.body;
    if(actualConfig.body)
    {
        args = "?";
        actualConfig.body.map((item:IArgs)=>{
            if(item.array)
            {
            item.value.map((actualString:string)=>{
            args+=item.parameter+"="+actualString+"&";
            return "";
            })
            }
            else
            {
                if(typeof(item.value)!=="undefined" || !(typeof(item.value)==="string" && item.value))
                {
                    args+=item.parameter+"="+item.value+"&";
                }
            }
            return 0;
        });
        args = args.slice(0,args.length-1);
    }
    let resposta;
    if(!actualConfig.method)
    {
        resposta = RequisicaoGET<ResponseType>(url,args,actualConfig.aditionalInfo);
    }
    else
    {
        switch(actualConfig.method)
        {
            case "GETCUSTOMTOKEN":
                resposta = RequisicaoGET<ResponseType>(url,args,actualConfig.aditionalInfo);
                break;
            case "GET":
                resposta =RequisicaoGET<ResponseType>(url,args,autenticacao);
                break;
            case "GETNOTOKEN":
                resposta = RequisicaoGETNoToken<ResponseType>(url,args);
                break;
            case "POST":
                resposta = RequisicaoPOST<ResponseType>(url,args,actualConfig.form,autenticacao);
                break;
            case "POSTNOTOKEN":
                resposta = RequisicaoPOSTNoToken<ResponseType>(url,args,actualConfig.form);
                break;
            case "PUT":
                resposta = RequisicaoPUT<ResponseType>(url,args,actualConfig.form,autenticacao);
                break;
            case "PATCH":
                resposta = RequisicaoPATCH<ResponseType>(url,args,actualConfig.form,autenticacao);
                break;
            case "DELETE":
                resposta = RequisicaoDELETE<ResponseType>(url,args,autenticacao);
                break;
            case "GETARCHIVE":
                resposta = RequisicaoGETArchive<ResponseType>(url,args,autenticacao);
                break;
        }
    }
    resposta?.catch((reason)=>{
    console.log(reason);
    if(reason.response?.status===401)
    {
        StyledAlert("Seu tempo de sessão está esgotado! Entre novamente no portal para corrigir.");
        setTimeout(()=>{
            ClearStorage();
        },20000)
    }
    else if(!actualConfig.cantShowError)
        StyledAlert(FindErrorMessageOnAPIReturn(reason));
    }).then((answer:any)=>{
    });
    return resposta;
}
function RequisicaoGETNoToken<ResponseType>(url:string,body:any)
{
    return axios.get<ResponseType>(url+(body??""));
}
function RequisicaoPOSTNoToken<ResponseType>(url:string,body:any,form:any)
{
    return axios.post<ResponseType>(url+(body??""),form);
}
function RequisicaoPUT<ResponseType>(url:string,body:any,form:any,options:any)
{
    return axios.put<ResponseType>(url+(body??""),form,{headers:options});
}
const ClearStorage = ()=>{
	sessionStorage.clear();
	window.location.href = B2C_URL_SIGNIN;
}
function RequisicaoDELETE<ResponseType>(url:string,body:any,options:any)
{
    return axios.delete<ResponseType>(url+(body??""),{headers:options});
}
function RequisicaoPATCH<ResponseType>(url:string,body:any,form:any,options:any)
{
    return axios.patch<ResponseType>(url+(body??""),form,{headers:options});
}

function RequisicaoGETArchive<ResponseType>(url:string,body:string,options:any)
{
    return axios.get<ResponseType>(url+(body??""),{headers:options,responseType:'blob'});
}
function RequisicaoPOST<ResponseType>(url:string,body:any,form:any,options:any)
{
    return axios.post<ResponseType>(url+(body??""),form,{headers:options});
}
function RequisicaoGET<ResponseType>(url:string,body:any,options:any)
{
    return axios.get<ResponseType>(url+(body??""),{headers:options});
}
