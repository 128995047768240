import React, {useCallback, useContext, useEffect, useMemo, useState } from 'react';
import StyledCheckbox from '../../../components/CustomCheckBox';
import SinistroPageModel from '../../../components/SinistroComponents/SinistroNewPageModel';
import { TextInput,TextInputEspecific } from '../../../components/TextInput';
import {PageContent,MainContentSpace,FooterContentSpace,BtnCinza,BtnLaranja,ButtonContainer,CheckBoxContainer, DocumentContent,InputContent, OrangeLink } from './style';
import { Link, useHistory } from 'react-router-dom';
import StyledAlert from '../../../components/StyledAlert';
import { TestaCPF } from '../../../functions/CpfTest';
import { SinistroContext } from '../../../storage/contexts/SinistroContext';
import { LoaderError } from '../../../components/Loaders/LoaderDots';
import { IQueryStatus } from '../../../interfaces/services/IQuery';
import { LoaderRotatorMessage, LoaderRotatorModal } from '../../../components/Loaders/LoaderRotator';
import { SelectedRowTitle } from '../../../components/CustomDropdown/style';
import useAPI from '../../../storage/custom_hooks/API';
import { GetEventosSinistroBySegurado, GetTipoParentesco } from '../../../services/APICallableActions/sinistro';
import APIRequestFunction from '../../../services/APIRequestFunction';
import { IEvento } from '../../../interfaces/storage/contexts/ISinistroContext';
import { LGPDURL } from '../../../env_settings';
interface ParentescoAPIReturn {nome:string,codigo:string}
const IdentificarSegurado:React.FC<{}> = ():React.ReactElement => {
    const {sinistroContext,setSinistroContext} = useContext(SinistroContext);
    const [grauRelacionamento,setGrauRelacionamento] = useState<string>("");
    const [primeiroNome,setPrimeiroNome] = useState<string>("");
    const [cpf,setCpf] = useState<string>("");
    const [dataNascimento,setDataNascimento] = useState<string>("");
    const [termosCondicoes,setTermosCondicoes] = useState<boolean>(false);// eslint-disable-next-line
    const [opcoesParentescoQuery,initQuery] = useAPI<ParentescoAPIReturn[]>(GetTipoParentesco());
    const [dataEvento, setDataEvento] = useState<string>("");
    const [canSeeLoaderModal,setCanSeeLoaderModal] = useState<boolean>(false);
    const history = useHistory();
    const FixDate = useCallback((date?:string)=>{
        const fixedData = date ?? new Date().toLocaleDateString("pt-br").slice(0,10);
        return (fixedData.slice(6,10)+"-"+fixedData.slice(3,5)+"-"+fixedData.slice(0,2))
    },[]);
    const VerificarDadosPreenchidos : ()=>boolean = useCallback(()=>{
        let canContinue : boolean = true;
        const CallAlertAndStop = (message:string) =>{
            StyledAlert(message);
            canContinue=false;
        }
        if(!(grauRelacionamento))
            CallAlertAndStop("Selecione um grau de relacionamento!");
        if(!(primeiroNome))
            CallAlertAndStop("Escreva seu nome!");
        if(cpf.length<=0 || !TestaCPF(cpf.replace(/-|\./g,"")))
            CallAlertAndStop("CPF inválido!");
        const DataTemp : Date = new Date(dataNascimento.slice(3,5)+"/"+dataNascimento.slice(0,2)+"/"+dataNascimento.slice(6,10));
        if(dataNascimento.length<=0 || Object.prototype.toString.call(DataTemp) !== "[object Date]" 
                                    || isNaN(DataTemp.getTime())
                                    || DataTemp > new Date()
          )
            CallAlertAndStop("Data de nascimento inválida!");
        if(!termosCondicoes)
            CallAlertAndStop("Aceite os termos e condições!");
            console.log(grauRelacionamento);
        if(grauRelacionamento === "-1")
            CallAlertAndStop("Ocorreu um erro! Esta opção de evento é inválida. Recarregue a página ou contate o suporte da CAPEMISA!")
        const individualDate=new Date(dataEvento.slice(3,5)+"/"+dataEvento.slice(0,2)+"/"+dataEvento.slice(6,10));
        if( individualDate > new Date() || !(Object.prototype.toString.call(individualDate) === "[object Date]" && !isNaN(individualDate.getTime())))
            CallAlertAndStop("Data inválida!");
        return canContinue;
    },[grauRelacionamento,primeiroNome,cpf,dataNascimento,termosCondicoes,dataEvento])
    useEffect(()=>{
        initQuery();
        // eslint-disable-next-line
    },[])
    const descricao = useMemo(()=>" A comunicação do sinistro pode ser feita pelo Cliente CAPEMISA e/ou pelos beneficiários preenchendo os dados abaixo:",[]);
    return (
        <>
            <PageContent>
                <SinistroPageModel tituloPagina={"COMUNICAÇÃO DE SINISTRO"} descricao={descricao}>
                    <MainContentSpace>
                        <TitleAndOutlinedComponent title={"COMUNICANTE"}>
                            <GrauRelacionamentoPicker queryStatus={opcoesParentescoQuery} grauRelacionamento={grauRelacionamento} setGrauRelacionamento={setGrauRelacionamento} opcoesParentesco={(opcoesParentescoQuery.data??[])}/>
                        </TitleAndOutlinedComponent>
                        <TitleAndOutlinedComponent title={"DADOS DO SEGURADO/PARTICIPANTE"}>
                            <DadosSeguradosOrParticipante primeiroNome={primeiroNome} setPrimeiroNome={setPrimeiroNome} cpf={cpf} setCpf={setCpf} dataNascimento={dataNascimento} setDataNascimento={setDataNascimento} primeiroNomePlaceHolder="Primeiro nome"/>
                        </TitleAndOutlinedComponent>
                        <TitleAndOutlinedComponent title={"EVENTO"}>
                            <DocumentContent direction={"column"}>
                                <TextInput borderRadius={"8px"} rotulo={"Data de evento"} disabled={false} valor={dataEvento} onChange={(e:{target:{value:string}})=>{setDataEvento(e.target.value)}} show={true} type={"date"} width={"95%"}/>
                            </DocumentContent>
                        </TitleAndOutlinedComponent>
                    </MainContentSpace>
                    <FooterContentSpace>
                        <CheckBoxContainer>
                            <div style={{width:"max-content",height:"fit-content",position:"relative",top:"3px"}}>
                                <StyledCheckbox checked={termosCondicoes} onClick={()=>{setTermosCondicoes(!termosCondicoes)}} borderRadius={"0px"} />
                            </div>
                            <InputCheckBoxMessage>
                                Estou ciente e aceito que a CAPEMISA analise meus hábitos de navegação neste portal por meio de 
                                cookies, para se adaptarem a seus gostos e preferências e melhorarem seus serviços, conforme
                                 suas <OrangeLink href={LGPDURL}>Políticas de Privacidade.</OrangeLink>
                            </InputCheckBoxMessage>
                        </CheckBoxContainer>
                        <ButtonContainer>
                            <BtnCinza onClick={()=>{history.location.pathname.endsWith("/")?history.push({pathname:"../"}):history.push({pathname:"./"})}}>CANCELAR </BtnCinza>
                            <BtnLaranja onClick={async ()=>{
                                console.log((history.location.pathname.endsWith("/")?"../":"")+"identificacaoevento")
                                if(VerificarDadosPreenchidos())
                                {
                                    setCanSeeLoaderModal(true);
                                    let APIUniqueReturn : { status:"COMPLETED"|"FAILED", nome?:string, eventos? : IEvento[] } = { status: "FAILED" }; 
                                    const promiseToAwait = APIRequestFunction<IEvento[]>(GetEventosSinistroBySegurado(cpf.replace(/\.|-/g,""),FixDate(dataNascimento),primeiroNome,FixDate(dataEvento)))
                                    promiseToAwait.then((response)=>{
                                        if( Array.isArray(response.data) && response.data.length>0 && response.data[0]?.nomeParticipanteSegurado)
                                        {
                                            APIUniqueReturn = { status: "COMPLETED" , eventos:response.data, nome: response.data[0].nomeParticipanteSegurado };
                                        }
                                    })
                                    try{
                                        await promiseToAwait;
                                    }
                                    finally{
                                        const isGlobal = !(APIUniqueReturn.status === "COMPLETED");
                                        setCanSeeLoaderModal(false);
                                        setSinistroContext({
                                            ...sinistroContext,
                                            eventoOptions:APIUniqueReturn.eventos,
                                            grauRelacionamentoComunicante:{
                                                id:grauRelacionamento,
                                                name:opcoesParentescoQuery.data?.find((parentesco)=>parentesco.codigo.toString()===grauRelacionamento.toString())?.nome??"",
                                            },
                                            informacoesSegurado:
                                            {
                                                cpfSegurado:cpf.replace(/\-|\./g,""),
                                                dataNascimentoSegurado:dataNascimento,
                                                primeiroNomeSegurado:primeiroNome,
                                                nomeCompletoParticipanteSegurado:APIUniqueReturn.nome
                                            },
                                            etapa:!isGlobal?"identificarevento":"sinistroGeralComEvento",
                                            global:isGlobal,
                                            dataEvento,
                                            tipoUsuario:(grauRelacionamento==="1"?"segurado":"comunicante")
                                        });
                                        history.push((history.location.pathname.endsWith("/")?"../":"")+(!isGlobal?"identificacaoevento":"geral"));
                                    }
                                }
                            }} >PRÓXIMO</BtnLaranja>
                        </ButtonContainer>
                    </FooterContentSpace>
                </SinistroPageModel>
            </PageContent>
            <LoaderRotatorModal show={canSeeLoaderModal}/>
        </>
    )
}
export default IdentificarSegurado;

const InputCheckBoxMessage:React.FC<{inputCheckId?:string}> = (props):React.ReactElement => {
    return(<label style={{marginLeft:"7px",marginBottom:"0",color:"#636363",fontSize:"15px" }} >{props?.children} </label>)
}
const GrauRelacionamentoPicker:React.FC<{queryStatus:IQueryStatus,grauRelacionamento:string,setGrauRelacionamento:Function,opcoesParentesco:ParentescoAPIReturn[]}> = (props) : React.ReactElement =>{
    const {setGrauRelacionamento,opcoesParentesco,grauRelacionamento,queryStatus} = props;
    return(
        <DocumentContent direction="column" >
            {
               queryStatus.status==="COMPLETED"?
                <InputContent>
                    <TextInput borderRadius={"8px"} valor={grauRelacionamento} onChange={(e:{target:{value:string}})=>{setGrauRelacionamento(e.target.value)}} show width={"100%"} rotulo="Grau de relacionamento com o Segurado(a)/Participante" type="combotextvalue" options={opcoesParentesco.map((item:ParentescoAPIReturn) : {text?:string,value?:string}=>{return({text:item.nome,value:item.codigo})})} />
                </InputContent>
                :(
                    queryStatus.status==="FAILED"?<LoaderError erro={queryStatus.errorResponse??"Ocorreu um erro ao buscar os graus de relacionamento!"}/>:<LoaderRotatorMessage/>
                )
            }
        </DocumentContent>
    )
}
const DadosSeguradosOrParticipante : React.FC<{cpf:string,setCpf:Function,dataNascimento:string,setDataNascimento:Function,primeiroNomePlaceHolder?:string,primeiroNome:string,setPrimeiroNome:Function}> = (props) : React.ReactElement =>{
    const {cpf,dataNascimento,setCpf,setDataNascimento,primeiroNome,setPrimeiroNome} = props;
    return(
        <DocumentContent direction={"column"}>
            <div style={{display:"flex",justifyContent: "space-around",marginBottom:"10px", width:"100%"}}>
                <TextInputEspecific borderRadius={"8px"} valor={cpf} onChange={(e:{target:{value:string}})=>{setCpf(e.target.value)}} width={"45%"} disabled={false} show rotulo="CPF"/>
                <TextInput valor={dataNascimento} borderRadius={"8px"} type="date" onChange={(e:{target:{value:string}})=>{setDataNascimento(e.target.value)}} width={"45%"} disabled={false} show rotulo="Data de nascimento"/>
            </div>
            <TextInput borderRadius={"8px"} type="text" valor={primeiroNome} onChange={(e:{target:{value:string}})=>{setPrimeiroNome(e.target.value)}} width={"95%"} disabled={false} show rotulo="Primeiro nome" placeHolder={props.primeiroNomePlaceHolder} />
        </DocumentContent>
    )
}
const TitleAndOutlinedComponent : React.FC<{title:string}> = (props) => {
    return(
        <div style={{display:"flex",marginTop:"10px",flexDirection:"column"}}>
            <SelectedRowTitle>{props.title}</SelectedRowTitle>
            {
                props.children
            }
        </div>
    )
}