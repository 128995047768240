import React, { useState } from 'react';
import { Coluna, Label, StyledMaskedInput, StyledInput, StyledSelect, StyledBigInput, RoundedFileInput, StyledMultifileLabel, StyledMultifileInput, StyledCurrencyMaskedInput, BoxRadioSpace, RadioDiv, RadioText, StyledFontAwesomeIcon, InfoText, IcoSpace } from './style';
import StyledCheckBox from '../CustomCheckBox';
import { faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import SuspenseInfo from '../SuspenseInfo';
import TelMaskedInput from '../TelMaskedInput';
interface ITextInput {
    rotulo:string,
    id?: string,
    rotuloCustomCSS?:string,
    width?:string,
    onChange?:Function,
    onBlur?:Function,
    rotuloMargin?:string,
    obligatory?:boolean,
    valor?: any,
    show?:boolean,
    disabled?:boolean,
    margin?:boolean,
    options?:string[]|{text?:string,value?:string}[],
    aditionalInfo?:any,
    placeHolder?:string,
    borderRadius?:string,
    maxLength?:number,
    type?:"file"
    |"currency"|"numeric"|"date"|"cpf"|"combo"
    |"telfixo"|"celular"|"cep"|"celularddd"|"bigtext"
    |"text"|"squareradioquestion"|"prazo"|"celularorfixoddd"
    |"cnpj"|"dddtelfixo"|"combotextvalue"|"textoNumero"
};


export const TextInputEspecific : React.FC<ITextInput> = (props): React.ReactElement => {
    if(props.show)
    {
        switch(props.rotulo)
        {
            case "Anexar Documentos Comprovantes (JPG, PNG, PDF)":
                return(
                    <Coluna margin={props.margin} width={props?.width}>
                        <Label>{props.rotulo}</Label>
                        <RoundedFileInput>
                            <StyledMultifileInput multiple type="file" />
                            <StyledMultifileLabel>Clique aqui para inserir seus arquivos</StyledMultifileLabel>
                        </RoundedFileInput>
                    </Coluna>
                )
            case "Valor Desejado":
                return(
                    <Coluna margin={props.margin} width= {props?.width}>
                        <Label>{props.rotulo}</Label>
                        <StyledCurrencyMaskedInput allowNegative={false} displayType={'input'} thousandSeparator={"."} decimalSeparator={","} value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} prefix={'R$'}/>
                    </Coluna>
                )
            case "Prazo de Pagamento":
                return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledMaskedInput mask="11" type="text" value={props?.valor} onChange={(event)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true}/>
                </Coluna>
                )
            case "Data de Nascimento do Segurado":
            case "Data de Nascimento":
            case "Data do Evento":
                return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledMaskedInput mask="11/11/1111" placeholder="DD/MM/AAAA" type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true}/>
                </Coluna>
                )
            case "CPF":
            case "CPF do Segurado":
                return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledMaskedInput mask="111.111.111-11" type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true} borderRadius={props.borderRadius} />
                </Coluna>
                )
            case "UF":
            case "Estado":
                return(
                    <Coluna margin={props.margin} width= {props?.width}>
                        <div style={{display:"flex"}}>
                            <Label>{props.rotulo}</Label>
                            {props.obligatory?<p style={{color:"red",marginLeft:"2px"}}>*</p>:""}
                        </div>
                        <StyledSelect disabled={props.disabled ?? true} value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}}>
                        {// eslint-disable-next-line
                            props.options?.length?? 0>0?
                            <option disabled selected>Selecione...</option>:""
                        }
                        {// eslint-disable-next-line
                            props.options?.length?? 0>0?
                            (props.options as string[]).map((item:string)=>{
                                return(<option value={item}>{item}</option>)
                            }):<option disabled selected>Não há itens</option>
                        }
                        </StyledSelect>
                    </Coluna>
                    )
            case "Convênio":
            case "Tipo de Evento":
            case "Evento de Sinistro":
            case "Grau de Parentesco":
            case "Grau de Relacionamento com o Segurado":
            case "Parentesco por":
                return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledSelect onChange={(event:any)=>{if(props.onChange)props.onChange(event)}}>
                    {// eslint-disable-next-line
                        props.options?.length?? 0>0?
                        <option disabled selected>Selecione...</option>:""
                    }
                    {// eslint-disable-next-line
                        props.options?.length?? 0>0?
                        (props.options as string[]).map((item:string)=>{
                            return(<option>{item}</option>)
                        }):<option disabled selected>Não há itens</option>
                    }
                    </StyledSelect>
                </Coluna>
                )
            case "Telefone Fixo":
                return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledMaskedInput mask="(11)1111-1111"type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true}/>
                </Coluna>
                )
            case "CEP":
                return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px"}}>*</p>:""}
                    </div>
                    <StyledMaskedInput mask="11111-111"type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true}/>
                </Coluna>
                )
            case "Whatsapp":
            case "Celular":
                return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <TelMaskedInput value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true} placeholder={props.placeHolder} />
                </Coluna>
                )
                
            case "Telefone Celular":
            case "Telefone 1":
            case "Telefone 2":
            case "Telefone 3":
                return(
                    <Coluna margin={props.margin} width= {props?.width}>
                        <div style={{display:"flex"}}>
                            <Label>{props.rotulo}</Label>
                            {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                        </div>
                        <TelMaskedInput value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true} />
                    </Coluna>
                )
            case "Descrição do Evento":
                return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledBigInput type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true}/>
                </Coluna>
                )
            case "Confirme o E-mail":
                return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledInput type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true}/>
                </Coluna>
                )

            default:
            return (
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px"}}>*</p>:""}
                    </div>
                    <StyledInput type="text" value={props.valor} onChange={(event)=>{if(props.onChange)props.onChange(event)}} disabled={props.disabled ?? true} placeholder={props.placeHolder} />
                </Coluna>
            )
        }
    }
    else
    return (<div style={{display:"none"}}></div>)
}
export const TextInput : React.FC<ITextInput> = (props) : React.ReactElement =>
{
    const {show=true,disabled=false} = props
    if(show)
    {
        switch(props.type)
        {
            case "text":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledInput type="text" value={props.valor} borderRadius={props.borderRadius} onBlur={(event)=>{if(props.onBlur){console.log(props.onBlur);props.onBlur(event)}}} onChange={(event)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true} placeholder={props.placeHolder}/>
                </Coluna>
            )
            case "bigtext":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledBigInput type="text" maxLength={props.maxLength} value={props.valor} placeholder={props.placeHolder} onChange={(event:any)=>{if(props.onChange){props.onChange(event)}}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "celularddd":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledMaskedInput mask="(11)11111-1111"type="text" value={props.valor} placeholder={props.placeHolder} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "celular":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledMaskedInput mask="11111-1111"type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "celularorfixoddd":
            return( 
                <Coluna margin={props.margin }width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <TelMaskedInput value={props.valor} placeholder={props.placeHolder??"(11)1111-1111"} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "cep":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledMaskedInput mask="11111-111"type="text" placeholder={props.placeHolder??"XXXXX-XXX"} value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "telfixo":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledMaskedInput mask="1111-1111"type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "dddtelfixo":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledMaskedInput placeholder={props.placeHolder} mask="(11)1111-1111"type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "combo":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label margin={props.rotuloMargin} customCSS={props.rotuloCustomCSS}>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledSelect borderRadius={props.borderRadius} defaultValue={props.valor??(props.placeHolder??"Selecione...")} disabled={disabled ?? true} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}}>
                    {// eslint-disable-next-line
                        ((props.options?.length?? 0)>0) && !(props.valor)?
                        <option>{props.placeHolder??"Selecione..."}</option>:<></>
                    }
                    {// eslint-disable-next-line
                        props.options?.length?? 0>0?
                        (props.options as string[]).map((item:string)=>{
                            return(<option>{item}</option>)
                        }):<option disabled>Não há itens</option>
                    }
                    </StyledSelect>
                </Coluna>
            )
            case "combotextvalue":
            return(
            <Coluna margin={props.margin} width={props?.width}>
                <div style={{display:"flex"}}>
                    <Label margin={props.rotuloMargin} customCSS={props.rotuloCustomCSS}>{props.rotulo}</Label>
                    {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                </div>
                <StyledSelect
                    style={{color:"black",opacity:1}}
                    borderRadius={props.borderRadius}
                    defaultValue={props.valor??(props.placeHolder??"Selecione...")}
                    disabled={disabled ?? true}
                    onChange={(event:any)=>{if(props.onChange)props.onChange(event)}}
                >
                    {// eslint-disable-next-line
                        ((props.options?.length?? 0)>0) && !(props.valor)?
                        <option value={props.placeHolder??"Selecione..."}>{props.placeHolder??"Selecione..."}</option>:""
                    }
                    {// eslint-disable-next-line
                        props.options?.length?? 0>0?
                        (props.options as {text?:string,value?:string}[]).map((item:{text?:string,value?:string})=>{
                            return(<option value={item.value}>{item.text}</option>)
                        }):<option>Não há itens</option>
                    }
                </StyledSelect>
            </Coluna>
            )
            case "cpf":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledMaskedInput mask="111.111.111-11" type="text" borderRadius={props.borderRadius} value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true} placeholder={props.placeHolder}/>
                </Coluna>
            )
            case "date":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledMaskedInput borderRadius={props.borderRadius} mask="11/11/1111" placeholder="DD/MM/AAAA" type="text" value={props.valor} onBlur={(event)=>{if(props.onBlur)props.onBlur(event)}} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "numeric":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledInput type="number" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "currency":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledCurrencyMaskedInput displayType={'input'} thousandSeparator={"."} decimalSeparator={","} value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} prefix={'R$'}/>
                </Coluna>
            )
            case "file":
            return(
                <Coluna margin={props.margin} width={props?.width}>
                    <Label>{props.rotulo}</Label>
                    <RoundedFileInput>
                        <StyledMultifileInput onClick={(event:any)=>{if(props.onChange)props.onChange(event)}} multiple type="file" />
                        <StyledMultifileLabel>Clique aqui para inserir seus arquivos</StyledMultifileLabel>
                    </RoundedFileInput>
                </Coluna>
            )
            case "squareradioquestion":
            return(
                <Coluna margin={props.margin} width={props?.width}>
                    <Label margin={props.rotuloMargin}>{props.rotulo}</Label>
                    <BoxRadioSpace>
                    {
                        (props.options as string[]).map((option:string,i:number)=>
                        {
                            return(<RadioItemComponent onChange={(i)=>{if(props.onChange)props.onChange(i as unknown as number)}} infoText={props.aditionalInfo? (props.aditionalInfo[i]??"Sem informação"):"Sem informação"} radioText={option} i={i} valor={props.valor}/>)
                        })
                    }
                    </BoxRadioSpace>
                </Coluna>
            )
            case "prazo":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <Label>{props.rotulo}</Label>
                    <StyledMaskedInput mask={"11"} placeholder="11" type="text" value={props.valor==="NaN"?"0":props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "cnpj":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledMaskedInput mask={"11.111.111/1111-11"} placeholder="11.111.111/1111-11" type="text" value={props.valor} onChange={(event:any)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            case "textoNumero":
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                    <div style={{display:"flex"}}>
                        <Label>{props.rotulo}</Label>
                        {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                    </div>
                    <StyledInput type="text" value={props.valor} onChange={(event)=>{if(props.onChange && (/^[0-9]+$/g.test(event.target.value) || !(event.target.value)))props.onChange(event)}} disabled={disabled ?? true}/>
                </Coluna>
            )
            default:
            return(
                <Coluna margin={props.margin} width= {props?.width}>
                        <div style={{display:"flex"}}>
                            <Label>{props.rotulo}</Label>
                            {props.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:""}
                        </div>
                    <StyledInput type={"text"} value={props.valor} onBlur={(event)=>{if(props.onBlur)props.onBlur(event)}}  onChange={(event)=>{if(props.onChange)props.onChange(event)}} disabled={disabled ?? true} placeholder={props.placeHolder}/>
                </Coluna>
            )
        }
    }
    else
    return (<div style={{display:"none"}}></div>)
}
export const RadioItemComponent: React.FC<{onChange:(thisId:number)=>void,radioText:string,i:number,valor:string,infoText:string}> = (props) : React.ReactElement =>
{
    const[showSuspenseInfo,setShowSuspenseInfo] = useState<boolean>(false);
    return(
        <RadioDiv>
            <StyledCheckBox onClick={()=>{if(props.onChange){props.onChange(props.i)}}} checked={props.i===parseInt(props.valor)?true:false}/>
            <RadioText>{props.radioText}</RadioText>
            <IcoSpace onMouseLeave={()=>{setShowSuspenseInfo(false)}} onMouseEnter={()=>{setShowSuspenseInfo(true)}}>
                <StyledFontAwesomeIcon height="10px" icon={faQuestionCircle} onMouseEnter={()=>{setShowSuspenseInfo(true)}}/>
            </IcoSpace>
            <div>
                <SuspenseInfo show={showSuspenseInfo} onMouseEnter={()=>{setShowSuspenseInfo(true)}} onMouseLeave={()=>{setShowSuspenseInfo(false)}}>
                    <InfoText>{props.infoText}</InfoText>
                </SuspenseInfo>
            </div>
        </RadioDiv>
    )
}