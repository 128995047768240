import React from 'react';
import {TelMaskedInputComponent} from './style';
type IMaskFixedCharsInstance = { position:number, character:string}
const TelMaskedInput : React.FC<{value?:string,onChange?:Function,disabled?:boolean,isNegado?:boolean,placeholder?:string}> = (props) : React.ReactElement =>
{
    const instances : IMaskFixedCharsInstance[] = [{character:"(",position:0},{character:")",position:2}]
    const ValidateTelMask = (wholeNumber:string) : string =>
    {
        let finalString : string = "";
        for(let i=0;i<wholeNumber.length;i++)
        {
            let foundInstance : IMaskFixedCharsInstance|undefined = instances.find((actualIndex:IMaskFixedCharsInstance)=>actualIndex.position===i);
            if(foundInstance && wholeNumber[i]!==foundInstance.character)
            {
                finalString+=foundInstance.character;
            }
            if(wholeNumber[i]!=="-")
            finalString+=wholeNumber[i];
        }
        if(finalString.length===3)
        {
            finalString+=")";
        }
        if(finalString.length>12)
        {
            finalString=finalString.slice(0,9)+"-"+finalString.slice(9,13);
        }
        else if(finalString.length<=12 && finalString.length>8)
        {
            finalString=finalString.slice(0,8)+"-"+finalString.slice(8);
        }
        return finalString;
    }
    return(
        <TelMaskedInputComponent isNegado={props.isNegado} disabled={props.disabled} value={ValidateTelMask((props.value ?? "").replace(/\D+/g,""))} onChange={(e)=>{if(props.onChange){const evSend = {target:{value:ValidateTelMask((ValidateTelMask((props.value??"").replace(/\D+/g,"")).length===4 && e.target.value.length===3)?e.target.value.replace(/\D+/g,"").slice(0,1):e.target.value.replace(/\D+/g,""))}};props.onChange(evSend)}}} placeholder={props.placeholder}/>
    )
}
export default TelMaskedInput