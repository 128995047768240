import React, { useState, useEffect} from 'react';

import {IProps, ITip} from "../../../interfaces/components/ITips"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft,faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { Container, Title, Description, Image, ImageButton, TextContainer } from './styles'

const TipsLarge : React.FC<IProps> = (props): React.ReactElement =>{
    const { data } = props;
    const [selected , setSelected] = useState<number>(0);
    const [tip, setTip] = useState<ITip>(data[selected]);

    function changeTip(tipo: "previous" | "next"){
        if(tipo === "next")
            selected === data?.length - 1 ? setSelected(0) : setSelected(selected + 1);
        else if( tipo === "previous")
            selected === 0 ? setSelected(data?.length - 1) : setSelected(selected - 1);
    };

    React.useEffect(() => {
        const timer = setInterval(()=>{changeTip("next")},5000);

        return () => {
            clearInterval(timer);
        };
    })

    useEffect(() => {
        setTip(data[selected]);// eslint-disable-next-line
    }, [selected])
    return(
        <Container to={`/inicio/destaques/${tip?.id}`}>
            <Image src={tip?.urlImagemHome}>
                <ImageButton 
                    tipo="previous" 
                    onClick={(ev) => {
                        //resetTimer();
                        changeTip("previous");
                        ev.preventDefault();
                    }}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{fontSize:14}}/>
                </ImageButton>
            {
            tip?.titulo || tip?.subtitulo ?
            <TextContainer>
                {
                tip?.titulo?
                <Title>
                    {tip?.titulo}
                </Title>:""
                }   
                {
                tip?.subtitulo?
                <Description>
                    {tip?.subtitulo}
                </Description>:""
                }
            </TextContainer>:""
            }
                <ImageButton 
                tipo="next" 
                onClick = {(ev) => {
                    //resetTimer();
                    changeTip("next");
                    ev.preventDefault();
                }}>
                    <FontAwesomeIcon icon={faChevronRight} style={{fontSize:14}} />
                </ImageButton>
            </Image>  
        </Container>
    )
}
export default TipsLarge;