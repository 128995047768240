import styled from 'styled-components';
export const SearchSpace = styled.div`
    height:20%;
    display:flex;
    align-items:center;
    justify-content:space-around;
`
export const AllSpace= styled.div`
    width:100%;
    height:400px;
`
export const PerguntaText = styled.label`
    text-align: left;
    font: Bold 1.0rem Roboto;
    letter-spacing: -0.36px;
    color: #636363;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom:0px;
`
export const RoundedInput = styled.input`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 14px;
    opacity: 1;
    height:50%;
    width:68%;
    padding-left:1%;
    padding-right:1%;
    ::after
    {
        outline:0px;
    }
    ::before
    {
        outline:0px;
    }
    :focus{
        outline:0px;
    }
`
export const RoundedComponentSpace = styled.div`
    height:7rem;
    width: 100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-top:2%;
`;
export const PerguntasFrequentesComponent = styled.div`
    height:50%;
`
export const TituloLabel = styled.label`
    text-align: left;
    font: Bold 1rem Roboto;
    letter-spacing: -0.44px;
    color: #E27800;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom:0px;
`
export const CategoriasFrequentes = styled.div`
    width:50%;
`
export const ColunasSpace = styled.div`
    height:80%;
    display:flex;
    justify-content:space-between;
`
export const TituloCategoria = styled.label`
    text-align: left;
    font: Bold 0.9em Roboto;
    letter-spacing: -0.44px;
    color: #636363;
    opacity: 1;
    margin-bottom:0px;
`
export const ItemCategoria = styled.div`
    cursor: pointer;
    text-align: left;
    font: Bold 0.8em Roboto;
    letter-spacing: -0.32px;
    color: #636363;
    opacity: 1;
`
export const RoundedSeeMore = styled.div`
    border: 1px solid #E27800;
    border-radius: 19px;
    height:100%;
    opacity: 1;
    width:20%;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align: left;
    font:Regular Roboto;
    font-size:0.7rem;
    letter-spacing: -0.38px;
    color: #E27800;
    opacity: 1;
    cursor: pointer;
`