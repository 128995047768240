import React, { useContext} from 'react';
import { GlobalContext } from '../../../storage/contexts/GlobalContext'
import Noticia from '../../../components/Noticia';
import PageModel from '../../../components/PageModel';
import { useLocation, useParams } from 'react-router-dom';
import { useGetTrilhas } from '../../../storage/custom_hooks/useGetTrilhas';

export default function Informativo(){
    const {globalContext: {Informativos}} = useContext(GlobalContext);
    let { id } = useParams<{id: string}>();
    const noticia = Informativos?.find( item => item.id.toString() === id );
    const { pathname } = useLocation();
    console.log(pathname);
    const pathnameSemId = pathname.replace(/\d+\/?/, '');
    const { trilhaAtual, tituloAtual } = useGetTrilhas(pathnameSemId);
    return(
        <PageModel titulo={tituloAtual} trilha={[...trilhaAtual, {
            titulo: noticia?.titulo ?? tituloAtual,
            path: pathname,
        }]} Conteudo={<Noticia data={noticia}/> }/>
    )
}