import styled from 'styled-components';
import MaskedInput from 'react-maskedinput';
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; 
`;

export const Linha = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin: 10px 0;
`; 

export const Coluna = styled.div< {width?: string | number}>`
    width: ${props => props?.width ?? "100%" };
    flex-direction: column;
    display: flex;
    margin:  0 15px;
`; 
export const StyledMaskedInput = styled(MaskedInput)`
    width: 100%;
    height: 45px;
    border-radius: 35px;
    border: 1px solid #AAA;
    padding-left: 15px;
    background-color: white;

    &:disabled {   
        background-color: #EEE; 
    }
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const StyledInput = styled.input<{ type: "text" }>`
    width: 100%;
    height: 45px;
    border-radius: 35px;
    border: 1px solid #AAA;
    padding-left: 15px;
    background-color: white;

    &:disabled {   
        background-color: #EEE; 
    }
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`;

export const Label =  styled.span`
    color: #AAA;
    font-size: 13px;
    margin: 0 0 5px 15px;
`;
export const AlterarDadosButton = styled.button`
    background-color:#E27800;
    border-color:#E27800!important;
    width:25%;
    border-radius: 35px;
    opacity: 1;
    text-align: center;
    font: Bold Roboto;
    font-size:1rem;
    letter-spacing: -0.46px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    border-bottom: #E27800!important;
    border-right: #E27800!important;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`;