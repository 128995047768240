import React, { useContext, useEffect, useState } from 'react';
import {GlobalContext} from '../../../storage/contexts/GlobalContext';
import PageModel from '../../../components/PageModel'
import DocumentList from '../../../components/DocumentList'
import {IBoleto, IBoletoEspecifics, IContratoEspecifics} from '../../../interfaces/pages/IInfRend';
import {IColunaItem,IColuna, IOcultDataToSend} from '../../../interfaces/components/IColunaItem';
import { GetDocumentos } from '../../../API/documentos';
import {BoletoTitle, InsideSelectedText, BoletoAditionalComponentSpace, BoletoInnerComponentSpace, LinhaEntreComponentes, BoletoListaInnerComponentSpace, ListaTitle, BoletoListaSpace, Linha, ColumnSpacer, BoletoListaDetalhesBody, BoletoListaDetalhesHead} from './styles';
import { useRouteMatch } from 'react-router-dom';
import CorrigirVariavelMonetaria from '../../../functions/CorrigirValorPontuacao';
import { DocumentType } from '../../../components/DocumentList/DocumentType';
//import {} from './styles'
const Boleto : React.FC<{}> = () :React.ReactElement=>
{
    const { path } = useRouteMatch();
    const fixedpath = path+"/";
    const { globalContext: {PreUser}}=useContext(GlobalContext);
    const [searchTerm,setSearchTerm] = useState<string>();
    // eslint-disable-next-line
    const [registros, setRegistros] = useState<IBoleto[]>([]);
    useEffect(()=>{
        GetDocumentos(fixedpath)?.then((response:any)=>{
            setRegistros(
                (response.data.length!==0?
                response.data.map((item:any,i:number)=>{
                    return{
                        documento:{
                        "competencia":item.competencia,
                        "nome": PreUser?.nome,
                        "documento":item.numeroDocumento,
                        "descricao": item.nomeDocumento,
                        "situacao":item.situacaoDocumento,
                        "dataGeracao":item.dataCriacao,
                        "idDoc":i,
                        "codigoDocumento":item.codigoDocumento,
                        "caminhoJasper":item.caminhoRelatorioJasper,
                        "tipoDocumento":item.tipoDocumento,
                        },
                        boleto:item.boleto,
                    };
                }):()=>{return(false)}
                )
            );
        });
        
        // eslint-disable-next-line
    },[]);
    let InformacoesBoleto:React.ReactElement[] = [];
    const OcultDataToSend:IOcultDataToSend[]=[];
    let Columns: IColuna[]=[]
    let itemsFiltro :string[] = [];
    if(registros)
    {
        registros.map((item:IBoleto,i:number)=>{
            // if(i===1)
            // console.log(item.boleto);
            Columns.push({
                coluna:[
                {width:"13%",message:item.documento.documento,textAlign:"flex-start;margin-left:8px;"},
                {width:"17%",message:item.boleto.tipoNegocio ?? "Desconhecido"},
                {width:"11%",message:new Date(item.boleto.dataVencimentoBoleto ?? "").toLocaleDateString('pt-BR', { timeZone: 'UTC' }),textAlign:"center",typeMessage:"date"},
                {width:"17%",message:(item.boleto.especie??"R$")+" "+CorrigirVariavelMonetaria((item.boleto.valorBoleto??"0,00").toString()),typeMessage:"number"},
                {width:"10%",message:item.boleto.statusPagamento ?? "indefinido"},
                {width:"0%",dontShow:true,message:item.boleto.contratos?.map(
                    (boletoEspecifics:IContratoEspecifics)=>
                    {
                        return(
                        boletoEspecifics.nomeProduto.toString()+"|" +
                        boletoEspecifics.numero.toString()+ "|" +
                        boletoEspecifics.anoMesCompetencia.slice(4,boletoEspecifics.anoMesCompetencia.length)+"/"+boletoEspecifics.anoMesCompetencia.slice(0,4)+ "|"+
                        CorrigirVariavelMonetaria(boletoEspecifics.valorContrato)+ "|"+
                        CorrigirVariavelMonetaria(boletoEspecifics.valorJuros)+ "|"+
                        CorrigirVariavelMonetaria(boletoEspecifics.valorMulta)+ "|"+
                        CorrigirVariavelMonetaria(boletoEspecifics.valorAtualizacaoMonetaria)+ "|"+
                        CorrigirVariavelMonetaria(boletoEspecifics.valorTotal) + "|" +
                        item.boleto.linhaDigitavel + "#"
                        ) 
                    }).reduce((previousString:string,currentString:string)=>{return(previousString+currentString)}) ?? ""
                },
                {width:"0%",dontShow:true,message:item.documento.codigoDocumento}
                ]
            });
            OcultDataToSend.push({
                tipoDocumento:item.documento.tipoDocumento,
                codigoDocumento:item.documento.codigoDocumento,
                caminhoJasper:item.documento.caminhoJasper,
            })
            InformacoesBoleto.push(
                <BoletoExclusiveInfo key={item.boleto.nossoNumeroBanco} boletoInfo={item.boleto}/>
            )
            if(!(itemsFiltro.find((filtro:string)=>{return filtro===item.boleto.tipoNegocio})) && item.boleto.tipoNegocio!==undefined)
            {
                itemsFiltro.push(item.boleto.tipoNegocio);
            }
            return ""
        });
    }
    else
    {
        Columns.push({coluna:[{width:"100%",message:"Não há itens"}]})
    }
    const ColumnNames:IColunaItem[]=[{width:"21%",message:"Nosso Número",hasIco:true,title:true,textAlign:"flex-start"},{width:"16%",message:"Categoria",hasIco:true,title:true,textAlign:"flex-start"},{width:"17%",message:"Vencimento",hasIco:true,textAlign:"flex-start",title:true},{width:"13%",message:"Valor",hasIco:true,title:true,textAlign:"flex-start"},{width:"13%",message:"Status",hasIco:true,title:true},{width:"19%",message:"Ação",title:true}];
    return(
        <PageModel>
            <DocumentList filterList={itemsFiltro} dataSend={OcultDataToSend} searchTerm={searchTerm} onChangeSearchTerm={(e)=>{setSearchTerm(e.target.value)}} AditionalViewComponent={InformacoesBoleto} view={{cantView:false,type:"dropdownkeephead"}} columnNames={ColumnNames} documentsDinamic={Columns} documentType={DocumentType.boleto}/>
        </PageModel>
    )
}
const BoletoExclusiveInfo:React.FC<{boletoInfo:IBoletoEspecifics}> = (props) :React.ReactElement =>
{
    const {boletoInfo} = props;
    console.log(boletoInfo);
    return(
        <BoletoAditionalComponentSpace>
            <LinhaEntreComponentes/>
            <BoletoListaInnerComponentSpace>
                <ListaTitle>Detalhes</ListaTitle>
                <BoletoListaSpace>
                    <BoletoListaDetalhesHead hasNecessityToScrollBar={(boletoInfo?.contratos?.length??0)>=8?true:false}>
                        <Linha>
                            <ColumnSpacer width="18%">
                                <BoletoTitle>Nome do Produto</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="12%">
                                <BoletoTitle>Contrato</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <BoletoTitle>Competência</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <BoletoTitle>Valor</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <BoletoTitle>Juros</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <BoletoTitle>Multa</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="10%">
                                <BoletoTitle>Atualização Monetária</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="10%">
                                <BoletoTitle>Total</BoletoTitle>
                            </ColumnSpacer>
                        </Linha>
                    </BoletoListaDetalhesHead>
                    <BoletoListaDetalhesBody>
                        {
                            boletoInfo.contratos?.map((item:IContratoEspecifics)=>{
                                return(
                                    <Linha>
                                        <ColumnSpacer width={"18%"}>
                                            <InsideSelectedText>{item.nomeProduto}</InsideSelectedText>
                                        </ColumnSpacer>
                                        <ColumnSpacer width="12%">
                                            <InsideSelectedText>{item.numero}</InsideSelectedText>
                                        </ColumnSpacer>
                                        <ColumnSpacer width="11%">
                                            <InsideSelectedText>{item.anoMesCompetencia.slice(4,item.anoMesCompetencia.length)+"/"+item.anoMesCompetencia.slice(0,4)}</InsideSelectedText>
                                        </ColumnSpacer>
                                        <ColumnSpacer width="11%">
                                            <InsideSelectedText>R$ {CorrigirVariavelMonetaria(item.valorContrato)}</InsideSelectedText>
                                        </ColumnSpacer>
                                        <ColumnSpacer width="11%">
                                            <InsideSelectedText>R$ {CorrigirVariavelMonetaria(item.valorJuros)}</InsideSelectedText>
                                        </ColumnSpacer>
                                        <ColumnSpacer width="11%">
                                            <InsideSelectedText>R$ {CorrigirVariavelMonetaria(item.valorMulta)}</InsideSelectedText>
                                        </ColumnSpacer>
                                        <ColumnSpacer width="10%">
                                            <InsideSelectedText>R$ {CorrigirVariavelMonetaria(item.valorAtualizacaoMonetaria)}</InsideSelectedText>
                                        </ColumnSpacer>
                                        <ColumnSpacer width="15%">
                                            <InsideSelectedText>R$ {CorrigirVariavelMonetaria(item.valorTotal)}</InsideSelectedText>
                                        </ColumnSpacer>
                                    </Linha>
                                )
                            })
                        }
                    </BoletoListaDetalhesBody>
                </BoletoListaSpace>
            </BoletoListaInnerComponentSpace>
            <LinhaEntreComponentes borderWidth={"0.25px"}/>
            <BoletoInnerComponentSpace>
                <BoletoTitle>Número do boleto</BoletoTitle>
                <InsideSelectedText>{boletoInfo.linhaDigitavel}</InsideSelectedText>
            </BoletoInnerComponentSpace>
        </BoletoAditionalComponentSpace>
    )
}

export default Boleto