import styled from 'styled-components';
export const Mensagem = styled.label`
    text-align: left;
    font: Regular 19px/23px Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
    width:50%;
`
export const BeneficioIcoAlign = styled.div`
    display:flex;
    flex-direction:row;
    margin-top:1%;
`
export const LinhaEntreComponentes = styled.hr<{width?:string,borderWidth?:string,borderColor?:string}>`
    border:${props=>props.borderWidth??"0.5px"} solid #B9B9B9;
    width:${props=>props.width??"98%"};
    margin-top:0px;
    margin-bottom:0px;
    border-color:${props=>props.borderColor?? "#B9B9B9"};
    height:0px;
`
export const RowItem = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
`
export const ProdutoSpace = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
`