export default function FindErrorMessageOnAPIReturn( apiReturn? : any ) : string
{
    console.log(apiReturn);
    console.log(Object.entries(apiReturn))
    const defaultMessage = "Ocorreu um erro!";
    let response = defaultMessage;
    let comparativeVal : string|undefined = undefined;
    let i : number = 0;
    //as validações a seguir seguem a lista de prioridade de mensagens que a api retorna. Catalogada a mão.
    const validationsToDo : (( apiReturn? : any ) => string|undefined )[] = 
    [
        FindErrorMessageOnPropertyArray,
        FindErrorMessageOnDictArray,
        FindErrorMessageOnDictArrayArray,
        FindErrorOnMessageDefault,
        () => defaultMessage
    ]
    while(!comparativeVal)
    {
        comparativeVal = validationsToDo[i](apiReturn);
        if(comparativeVal)
        {
            response = comparativeVal;
        }
        i++;
    }
    return response;
}

function FindErrorMessageOnPropertyArray(apiReturn? : any) : string|undefined
{
    return ((apiReturn?.response?.data?.errors?.message && Array.isArray(apiReturn?.response?.data?.errors?.message) ? apiReturn?.response?.data?.errors?.message:[undefined])[0]?? undefined);
}

function FindErrorMessageOnDictArrayArray(apiReturn? : any) : string|undefined
{
    const errorArray = apiReturn?.response?.data?.errors;
    const nullableVal = (errorArray && typeof(errorArray) === "object" ? errorArray : {falseKey: undefined}) as Object;
    const errorArrayVals = (Object.values(nullableVal)[0] ?? undefined);
    return Array.isArray(errorArrayVals) && errorArrayVals.length ? errorArrayVals[0] : undefined;
}

function FindErrorMessageOnDictArray(apiReturn? : any) : string|undefined
{
    const errorArray = apiReturn?.response?.data?.errors;
    const nullableVal = (errorArray && typeof(errorArray) === "object" ? errorArray : {falseKey: undefined}) as Object;
    console.log(Object.keys(nullableVal)[0] ?? undefined);
    return Object.values(nullableVal)[0]? `Erro na leitura dos seguintes dados: ${(Object.keys(nullableVal)[0])}` : undefined;
}

function FindErrorOnMessageDefault(apiReturn? : any) : string|undefined
{
    const reason = apiReturn;
    return ( typeof(reason)==="string") ? reason : ( reason.message ?? "Ocorreu um erro!" );
}