import React, {useCallback, useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import {SearchBar,DocumentListSpace, SameLine, GreyText,PageSelector,DocumentsList,Tabela, RoundedRow, InsideContainer, SizedText, LittleSizedText, AlignSpace, PDFText, IcoPDF, UpDownIco, SameLineSmall,TopSelectedRoundedRow, SelectedRoundedRow, SelectedRowTitle, InsideRowColumnName, VisualizarContainer, InsideSelectedText, TitleContainer, SubRoundedRow, FiltroSelect, HeadColumn} from'./styles';
import '../../styles/compontents(DEPRECATED)/Dropdown.css'
import { GetPDFDirect} from '../../API/documentos';
import {Endpoints } from '../../env_settings';
import { faSortNumericUp, faEye,faEyeSlash,faDownload} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import {IColunaItem,IColuna, IOcultDataToSend, IViewType,IViewTypeGetSet} from '../../interfaces/components/IColunaItem';
import Pagination from '../Paginacao';
import { LoaderRotatorMessage } from '../Loaders/LoaderRotator';
import { DocumentType } from './DocumentType';
import BoletoDetails from '../../pages/Portal/Boleto/BoletoDetails';
import { IBoletoEspecifics, IContratoEspecifics } from '../../interfaces/pages/IInfRend';

interface ISortControl{
    column: number;
    isDescending: boolean;//indica se o ordenamento é decrescente
}

const FormarArquivo = (tipoDocumento:number,documento:string,caminhoRelatorioJasper:string)=>{
    return Endpoints.documentodownload+"?TipoDocumento="+tipoDocumento+"&codigoDocumento="+documento+"&caminhoRelatorioJasper="+caminhoRelatorioJasper;
}
const DownloadPDF = (descricao:string,documento:string,caminhoRelatorioJasper:string,setModal:any,tipoDocumento:number) =>{
        setModal(true);
        GetPDFDirect(FormarArquivo(tipoDocumento,documento,caminhoRelatorioJasper))?.then((response:any)=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', descricao+'.pdf');
            setModal(false);
            link.click();
        }).finally(()=>{setModal(false);});
}
const ViewPDF = (documento:string,caminhoRelatorioJasper:string,setModal:any,tipoDocumento:number) =>{
    setModal(true);
    GetPDFDirect(FormarArquivo(tipoDocumento,documento,caminhoRelatorioJasper))?.then((response:any)=>{
        const url = window.URL.createObjectURL(new Blob([response.data],{type:"application/pdf"}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target','_blank');
        link.click();
    }).finally(()=>{setModal(false);});
}
type DocumentListCommonProps = {"documentsDinamic":IColuna[],"columnNames":IColunaItem[],"AditionalViewComponent"?:React.ReactNode[],"dataSend":IOcultDataToSend[],"view"?:IViewType,"cantDownload"?:boolean,"searchTerm"?:string,"onChangeSearchTerm"?:(e:{target:{value:string}})=>void,"filterList"?:string[],"documentType"?:DocumentType};




const DocumentList:React.FC<DocumentListCommonProps> = (props): React.ReactElement =>{

    return <RenderDocumentList 
        searchTerm={props.searchTerm} 
        onChangeSearchTerm={props.onChangeSearchTerm} 
        documentsDinamic={props.documentsDinamic} 
        dataSend={props.dataSend} 
        AditionalViewComponent={props.AditionalViewComponent} 
        columnNames={props.columnNames} 
        filterList={props.filterList} 
        view={props.view} 
        cantDownload={props.cantDownload===undefined?false:props.cantDownload} 
        documentType = {props.documentType}
    />
}
const RenderDocumentList:React.FC<DocumentListCommonProps> = (props): React.ReactElement =>{
    const [actualPage,setActualPage] = useState(1);
    const [occultDataSend,setOccultDataSend] = useState<IOcultDataToSend[]>(props.dataSend);
    const [aditionalComponents,setAditionalComponents] = useState<React.ReactNode[]|undefined>(props.AditionalViewComponent);
    const [foundResults,setFoundResults] = useState<IColuna[]>(props.documentsDinamic);
    const [selectedFilter,setSelectedFilter] = useState<string>("");
    function stringEscape(s:string) {// eslint-disable-next-line
        return s ? s.replace(/\\|\n|\t|\v|'|\[|\]|"/g,'').replace(/[\x00-\x1F\x80-\x9F]/g,hex) : s;
        function hex(c:string) { var v = '0'+c.charCodeAt(0).toString(16); return '\\x'+v.substr(v.length-2); }
    }
    const FiltrarDocumentListByString = useCallback((paramSelectedFilter:string,paramSearchTerm?:string)=>
    {
        if(paramSearchTerm || (paramSelectedFilter.length>0))
        {
            const ProcurarEmTabela = (stringSearch:string,rowAtual:IColuna)=>{
                 return rowAtual.coluna.map((colunaAtual:IColunaItem)=>{
                    if(typeof(colunaAtual.message) == 'string' && colunaAtual.message.toUpperCase().search(stringEscape(stringSearch ?? "").toUpperCase())!==-1)
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                }).reduce((previousValue:boolean,currentValue:boolean)=>{
                    if(previousValue || currentValue)
                    {
                        return true;
                    }
                    else
                    {
                        return false;
                    }
                })
            }
            let tempFoundResults : IColuna[] = [];
            let tempAditionalComponents : React.ReactNode[] | undefined = [];
            let tempOccultDataSend : IOcultDataToSend[] = [];
            props.documentsDinamic.map((rowAtual:IColuna,i:number)=>{
                if((paramSelectedFilter.length>0?ProcurarEmTabela(paramSelectedFilter,rowAtual):true) && (paramSearchTerm?ProcurarEmTabela(paramSearchTerm,rowAtual):true))
                {
                    tempOccultDataSend.push(props.dataSend[i]);
                    tempAditionalComponents?.push((props.AditionalViewComponent??[])[i] ?? <div></div>)
                    tempFoundResults.push(rowAtual);
                }
                return undefined;
            });
            if(tempFoundResults.length===0)
            {
                tempFoundResults = [{coluna:[{width:"100%",message:"Não há itens"}]}];
            }
            setOccultDataSend(tempOccultDataSend);
            setAditionalComponents(tempAditionalComponents);
            setFoundResults(tempFoundResults);
        }
        else
        {
            setOccultDataSend(props.dataSend);
            setAditionalComponents(props.AditionalViewComponent);
            setFoundResults(props.documentsDinamic);
        }
    },[props.documentsDinamic,props.AditionalViewComponent,props.dataSend]);
    useEffect(()=>{
            FiltrarDocumentListByString(selectedFilter,props.searchTerm);
        // eslint-disable-next-line
    },[props.documentsDinamic,props.searchTerm,props.filterList,selectedFilter,FiltrarDocumentListByString])
    const paginationVector:any=new Array(foundResults.length).fill(0);
    const mapToPagination = new Array(Math.ceil(foundResults.length/6)).fill(1);
    return(
    <DocumentListSpace>
        <SameLine>
            <SearchBar width={props.filterList?"HalfSpace":"AllSpace"} type="text" placeholder="Digite aqui para buscar" value={props.searchTerm} onChange={(e)=>{if(props.onChangeSearchTerm){console.warn("está aparecendo dentro do evento searchTerm");setActualPage(1);props.onChangeSearchTerm(e);}}}/>
            {props.filterList?<Filter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} filterList={props.filterList}/>:""}
            <GreyText>Exibir Resultados</GreyText>
            <PageSelector title={actualPage} bsPrefix="dropdownchange">
                {
                    mapToPagination.map((item:number,i:number)=>{
                    return(<Dropdown.Item onClick={()=>{setActualPage(i+1)}}>{i+1}</Dropdown.Item>);
                    })
                }
            </PageSelector>
        </SameLine>
        <RenderList actualPage={actualPage} contents={foundResults} AditionalViewComponent={aditionalComponents} maxPerPage={6} dataSend={occultDataSend} columnNames={props.columnNames} view={foundResults[0]?.coluna[0]?.message==="Não há itens"?{type:"document",cantView:true}:props.view} cantDownload={foundResults[0]?.coluna[0]?.message==="Não há itens"?true:props.cantDownload} documentType={props.documentType}/>
        <SameLineSmall>
                <Pagination actualPage={actualPage} setActualPage={setActualPage} length={paginationVector.length}/>
        </SameLineSmall>
    </DocumentListSpace>);
}
const ArchiveSettings:React.FC<{cantDownload?:boolean,view?:IViewTypeGetSet,dataSend:IOcultDataToSend,setSmShow:any}> = (props):React.ReactElement =>{
    const {dataSend,view,cantDownload,setSmShow}=props;
    const item=dataSend;
    const SelectViewbyType = (type:"dropdown"|"document"|"dropdownkeephead"|"dropdownkeepheadbig") =>
    {
        switch(type)
        {
            case "document":
                ViewPDF(item?.codigoDocumento,item?.caminhoJasper,setSmShow,item?.tipoDocumento);
                break;
            case "dropdownkeepheadbig":
            case "dropdownkeephead":
            case "dropdown":
                view?.setSelected(!view.selected);
                break;
        }
    }
    return (
        <VisualizarContainer width={cantDownload&&!(view?.cantView)?"20%":"fit-content"}>
            <AlignSpace>
                {
                    view?.cantView?"":<AlignSpace extraStyle="padding-right:5px;" onClick={()=>{SelectViewbyType(view?.type ?? "dropdown")}}><PDFText>{view?.selected?"Ocultar":"Visualizar"}</PDFText><IcoPDF icon={view?.selected?faEyeSlash:faEye}/></AlignSpace>
                }
                {
                    !(cantDownload)?"":<AlignSpace onClick={()=>{DownloadPDF(item?.codigoDocumento,item?.codigoDocumento,item?.caminhoJasper,setSmShow,item?.tipoDocumento)}} style={{marginLeft:"30px"}}><PDFText>Download</PDFText><IcoPDF icon={faDownload}/></AlignSpace>
                }
            </AlignSpace>
        </VisualizarContainer>
    )
}
const RenderTableInsides:React.FC<{"dataSend"?:IOcultDataToSend,"columnNames":IColunaItem[],"AditionalViewComponent":React.ReactNode,"index":IColuna,"view":IViewType,"cantDownload":boolean}> = (props): React.ReactElement =>
{
    const [smShow, setSmShow] = useState(false);
    const {index,dataSend,cantDownload,view,columnNames,AditionalViewComponent}=props;
    const [isDown,setIsDown] = useState(index.isOpen??false);
    const newView:IViewTypeGetSet ={cantView:view.cantView,type:view.type,setSelected:setIsDown,selected:isDown};
    const item=dataSend;
            return(
                (
                !isDown || index.coluna[0].message==="Não há itens"?
                <RoundedRow especificStyle={index.especificStyle}>
                    {
                        view.type==="dropdownkeepheadbig"?
                        <TopSelectedRoundedRow>
                            {
                                index.coluna.map((item:IColunaItem)=>
                                {
                                    if(item.title)
                                    return <TitleContainer 
                                                justifyContent={item.textAlign} 
                                                width={item.width}
                                            >
                                                <SelectedRowTitle color={item.colorTitle}>{item.message}</SelectedRowTitle>
                                            </TitleContainer>
                                    return ""
                                })
                            }
                            <ArchiveSettings setSmShow={setSmShow} dataSend={dataSend ?? {tipoDocumento:0,codigoDocumento:"",caminhoJasper:""}} cantDownload={cantDownload} view={newView}/>
                            <Modal size="sm" show={smShow} onHide={() => setSmShow(false)} backdrop={"static"} centered>
                                <LoaderRotatorMessage />
                            </Modal>
                        </TopSelectedRoundedRow>:<></>
                    }
                    {
                        !(view.type==="dropdownkeepheadbig")?
                        index.coluna.map((actualItem:IColunaItem,i:number)=>
                        {
                            if(actualItem.dontShow)
                            {
                                return <></>
                            }
                            else
                            {
                            return (
                                <InsideContainer 
                                    key={"documentListInsideContainer" + i + actualItem.title ?? "titulo"} 
                                    justifyContent={actualItem.textAlign} 
                                    width={actualItem.width}
                                >
                                    <SizedText>{actualItem.message}</SizedText>            
                                </InsideContainer>
                            )
                            }
                        }):<></>
                    }
                    {!(view.type==="dropdownkeepheadbig")?
                        (!view.cantView || cantDownload?
                        <ArchiveSettings 
                            setSmShow={setSmShow} 
                            dataSend={item ?? { tipoDocumento: 0, codigoDocumento: "", caminhoJasper: ""}} 
                            cantDownload={cantDownload} 
                            view={newView}
                        /> : "")
                        :
                        <></>
                    }
                    {
                        !(view.type==="dropdownkeepheadbig")?
                        <Modal size="sm" show={smShow} onHide={() => setSmShow(false)} backdrop="static" centered>
                            <LoaderRotatorMessage />
                        </Modal>:<></>
                    }
                </RoundedRow>
                :
                <SelectedRoundedRow especificStyle={index.especificStyle}>
                    {
                        view.type==="dropdownkeephead"?
                        <SubRoundedRow>
                            {
                                index.coluna.map((actualItem:IColunaItem)=>
                                {
                                    if(actualItem.dontShow)
                                    {
                                        return ""
                                    }
                                    else
                                    {
                                    return (<InsideContainer justifyContent={actualItem.textAlign} width={actualItem.width}><SizedText>{actualItem.message}</SizedText></InsideContainer>)
                                    }
                                })
                            }
                            {
                                !view.cantView || cantDownload?
                                <ArchiveSettings setSmShow={setSmShow} dataSend={item ?? {tipoDocumento:0,codigoDocumento:"",caminhoJasper:""}} cantDownload={cantDownload} view={newView}/>:""
                            }
                            <Modal size="sm" show={smShow} onHide={() => setSmShow(false)} backdrop="static" centered>
                                <LoaderRotatorMessage />
                            </Modal>
                        </SubRoundedRow>
                        :
                        <TopSelectedRoundedRow>
                            {
                                index.coluna.map((item:IColunaItem)=>
                                {
                                    if(item.title)
                                    return <TitleContainer justifyContent={item.textAlign} width={item.width}><SelectedRowTitle color={item.colorTitle}>{item.message}</SelectedRowTitle></TitleContainer>
                                    return ""
                                })
                            }
                            <ArchiveSettings setSmShow={setSmShow} dataSend={dataSend ?? {tipoDocumento:0,codigoDocumento:"",caminhoJasper:""}} cantDownload={cantDownload} view={newView}/>
                            <Modal size="sm" show={smShow} onHide={() => setSmShow(false)} backdrop={"static"} centered>
                                <LoaderRotatorMessage />
                            </Modal>
                        </TopSelectedRoundedRow>
                    }
                    
                    <th style={{width:"97%",display:"flex",flexDirection:"row",marginLeft:"2%",marginRight:"1%"}}>
                        {
                            columnNames.map((itemAtual:IColunaItem,i:number)=>{
                                if(itemAtual.title)
                                {return ""}
                                else
                            return(<div style={{display:"flex",flexDirection:"column",marginRight:"5%"}}><InsideRowColumnName>{itemAtual.message}</InsideRowColumnName><InsideSelectedText>{index.coluna[i].message}</InsideSelectedText></div>);
                            })
                        }
                    </th>
                    <th>
                        {AditionalViewComponent}
                    </th>
                </SelectedRoundedRow>
                )
            );
        
}
export const RenderList:React.FC<{"actualPage"?:number,"contents":IColuna[],"AditionalViewComponent"?:React.ReactNode[],"dataSend"?:IOcultDataToSend[],"columnNames"?:IColunaItem[],"view"?:IViewType,"cantDownload"?:boolean,"maxPerPage"?:number,"style"?:string, "documentType"?:DocumentType}> = (props): React.ReactElement =>
{
    let aditionalComponents:React.ReactNode[] | undefined = [];

    function SetCorrectValueByType(value:string,type:"number"|"date"|"string") :number|Date|string
    {
        switch(type)
        {
            case "number":
                return parseInt(value.replace(/r|R|\$|,|\./g,""))
            case "date":
                return new Date(value.slice(3,5)+"/"+value.slice(0,2)+"/"+value.slice(6,10));
            default:
                return value;
        }
    }
    function ordenarResultados(stringAnteriorToCompare:number|Date|string, stringAtualToCompare:number|Date|string, isDescending: boolean): number{
        if(stringAnteriorToCompare===stringAtualToCompare)
            return 0;
        else
            if(isDescending){
                return(stringAnteriorToCompare>stringAtualToCompare)?-1:1;
            }
        return(stringAnteriorToCompare>stringAtualToCompare)?1:-1;
    }

    const actualPage = (props.actualPage?props.actualPage as number:1)
    const dataToSend = props.dataSend;
    const columnNames = props.columnNames?? [];
    const [colunaControl,setColunaToOrder] = useState<ISortControl>();
    let conteudoPagina = typeof(colunaControl)!=="undefined"? (props.contents.sort(
        (valorAnterior:IColuna,valorAtual:IColuna)=>{
            const stringProximoToCompare:number|Date|string = SetCorrectValueByType(typeof(valorAnterior.coluna[colunaControl.column].message)==="string"?valorAnterior.coluna[colunaControl.column].message as string:"",valorAnterior.coluna[colunaControl.column].typeMessage ?? "string")
            const stringAtualToCompare:number|Date|string = SetCorrectValueByType(typeof(valorAtual.coluna[colunaControl.column].message)==="string"?valorAtual.coluna[colunaControl.column].message as string:"",valorAnterior.coluna[colunaControl.column].typeMessage ?? "string")
            return  ordenarResultados(stringProximoToCompare, stringAtualToCompare, colunaControl.isDescending);
            })
    ):props.contents;

    let dataToSendAux: IOcultDataToSend[] | undefined = [];
    if(props.documentType !== undefined)
    {
        aditionalComponents = [];
        dataToSendAux = [];

        switch(props.documentType){
            case DocumentType.boleto:
                ordenarBoletos(conteudoPagina, aditionalComponents, dataToSend, dataToSendAux);
                break;
            case DocumentType.extratosPrevidenciarios:
                ordenarExtratos(conteudoPagina, dataToSend, dataToSendAux);
                break;
            default:
                aditionalComponents = props.AditionalViewComponent;
                dataToSendAux = props.dataSend;    
                break;
        }
    }
    else{
        aditionalComponents = props.AditionalViewComponent;
        dataToSendAux = props.dataSend;
    }

    return(
        <DocumentsList extraStyle={props.style}>
            <Tabela>
                <HeadColumn>
                    {
                        columnNames.map((actualColumn:IColunaItem,i:number)=>
                        {  
                            if(!actualColumn.dontShow)
                            {
                                return(
                                    <InsideContainer key={"nomeColuna"+i} justifyContent={actualColumn.textAlign} width={actualColumn.width}>
                                        <LittleSizedText>
                                            {actualColumn.message}
                                        </LittleSizedText>
                                            {actualColumn.hasIco?<UpDownIco onClick={()=>{setColunaToOrder({column: i, isDescending: !colunaControl?.isDescending })}} icon={faSortNumericUp}/>:""}
                                    </InsideContainer>
                                )
                            }
                            return <></>
                        })
                    }
                </HeadColumn>
                <tbody>
                        {
                            conteudoPagina.length!==0?
                            conteudoPagina.map((item:IColuna,i:number)=>{
                                const maximoPagina =(props.maxPerPage??conteudoPagina.length);
                                if((actualPage * maximoPagina) - maximoPagina <= i && i <= ((actualPage * maximoPagina) -1)) { 
                                    return <RenderTableInsides 
                                                key={((typeof(item.coluna[0]?.message) === "string" ? item.coluna[0]?.message ?? "tableInsides" : "") + i) + "keyrenderizationInstance"} 
                                                columnNames={columnNames} 
                                                AditionalViewComponent={aditionalComponents ? aditionalComponents[i] : ()=> <div></div>} 
                                                dataSend={dataToSendAux ? dataToSendAux[i] : undefined} 
                                                view={props.view ? props.view : { cantView:false, type:"document" }} 
                                                cantDownload={(props.cantDownload === undefined ? true : !props.cantDownload)} 
                                                index={item}
                                            />
                                }
                                return ""
                            }):
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <LoaderRotatorMessage>Carregando Conteudo da pagina</LoaderRotatorMessage>
                            </div>
                        }
                </tbody>
            </Tabela>
        </DocumentsList>)
}
const Filter : React.FC<{selectedFilter:string,setSelectedFilter:Function,filterList:string[]}> = (props) :React.ReactElement =>
{
    const {selectedFilter,setSelectedFilter,filterList} = props;
    return(
        <FiltroSelect defaultValue={""} value={selectedFilter} onChange={(e)=>{setSelectedFilter(e.target.value)}}>
            <option value="">Nenhum Filtro</option>
            {
                filterList.map((item:string)=>
                    <option value={item}>{item}</option>
                )
            }
        </FiltroSelect>
    )
}
const ordenarBoletos = (conteudoPagina:IColuna[], aditionalComponents:React.ReactNode[] | undefined,dataToSend:IOcultDataToSend[]|undefined, dataToSendAux: IOcultDataToSend[] | undefined) => {
    
    let arrContratos:IContratoEspecifics[] = [];
    
    conteudoPagina.forEach((value:IColuna)=>{
        if(typeof(value.coluna[5].message) == 'string'){
            let arr = value.coluna[5].message.split('#').filter(element=> element);
            arr.forEach((str)=>{
                let stringArray = str.split('|');
                arrContratos.push(
                            {nomeProduto: stringArray[0], 
                            numero: stringArray[1], 
                            anoMesCompetencia: stringArray[2], 
                            valorContrato: stringArray[3], 
                            valorJuros: stringArray[4],
                            valorMulta: stringArray[5],
                            valorAtualizacaoMonetaria: stringArray[6],
                            valorTotal: stringArray[7],
                            numeroProduto:"",
                            valorDesconto:"",
                            linhaDigitavel:stringArray[8]
                        });       
            });
            let boleto: IBoletoEspecifics = {};
            boleto.contratos = arrContratos;
            arrContratos =  [];

            aditionalComponents?.push(
                <BoletoDetails boletoInfo={boleto} />
            )
        }
        let codigoDocumento = value.coluna[6].message;
        ordenarDataToSend(dataToSend, codigoDocumento, dataToSendAux);
    })
}

const ordenarExtratos = (conteudoPagina:IColuna[],dataToSend:IOcultDataToSend[]|undefined, dataToSendAux: IOcultDataToSend[] | undefined ) =>{
    conteudoPagina.forEach((value:IColuna)=>{
        let codigoDocumento = value.coluna[6].message;
        ordenarDataToSend(dataToSend, codigoDocumento, dataToSendAux);
    })
}

const ordenarDataToSend = (dataToSend:IOcultDataToSend[] | undefined, codigoDocumento:string|React.ReactElement, dataToSendAux: IOcultDataToSend[] | undefined) =>{
    let dataItem = dataToSend?.find(e=> e.codigoDocumento === codigoDocumento);
    if(dataItem !== undefined){
            dataToSendAux?.push(dataItem);
    }
}
export default DocumentList;