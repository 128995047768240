import styled from 'styled-components';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import '../../styles/fonts/roboto/index.css';

export const RoundedComponent = styled.div<{width?:string}>`
    width:${props=>props.width??"24%"};
    height: 95%;
    margin-left: 1%;
    cursor:pointer;
    min-height:120px;
    max-height:220px;
`;
export const SuperiorPartRounded = styled.div`
    height:70%;
    width:100%;
    border-bottom: 0px;
    border-top-left-radius:23px;
    border-top-right-radius:23px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    opacity: 1;
`;
export const InferiorPartRounded = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    background-color:#E27800;
    height:30%;
    width:100%;
    border-top:0px;
    background: #E27800 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 0px 0px 23px 23px;
    opacity: 1;
`;
export const OptionContainer = styled.div`
    display:flex;
    flex-direction:row-reverse;
    margin-right:5.5%;
    margin-top:0%;
    position:relative;
    top:5px;
`;
export const OptionIco = styled(FontAwesomeIcon)`
    font-size:14px;
    cursor:pointer;
    top:4px;
    width:30px;
`;
export const IcoContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    height:80%;
`;
export const IcoRoundedComponent = styled(Icon)`
    font-size:300%;
    color:#b9b9b9;
`;
export const RoundedComponentText = styled.p`
    margin-top:5%;
    align-self:center;
    text-align: center;
    font: Bold 12px Roboto;
    font-size:100%;
    letter-spacing: -0.38px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
`;
export const ModalInside = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    height:400px;
`;
export const RoundedComponentDocument = styled.div`
    height:100%;
    width:100%;
    border: 1px solid #636363;
    border-radius: 25px;
    opacity: 1;
`
export const RoundedComponentAtendimento = styled.div`
    height:100%;
    width:100%;
    border: 1px solid #B9B9B9;
    border-radius: 19px;
    opacity: 1;
    cursor:pointer;
`
export const DocumentText = styled.label`
    width:90%;
    text-align:center;
    font: Bold 14px Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    text-transform: uppercase;
    opacity: 1;
`;
export const DocumentBody = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    height:100%;
`;
export const RoundedComponentDocArea = styled.div`
    width:23%;
    height:85%;
    margin-left:2%;
    cursor:pointer;
`;