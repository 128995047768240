import React, { useContext } from 'react';
import { Redirect } from "react-router-dom";
import { AuthContext } from '../../../storage/contexts/AuthContext';
import { Authentication } from '../../../auth';

const AuthB2C: React.FC = (): React.ReactElement => {
    const {setAuth} = useContext(AuthContext);
    setAuth(Authentication());
    return <Redirect to="/load"/>;  
}

export default AuthB2C;