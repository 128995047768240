import styled from 'styled-components';
export const FlexRightItems = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:flex-end;
    align-items:center;
`;
export const ColoredLastPage = styled.div`
    margin-bottom:0px;
    padding: 5px 10px;
    border:1px solid #E27800;
    border-radius:10px;
    background-color:#E27800;
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    text-align: left;
    font: Regular 13px/33px Roboto;
    letter-spacing: -0.26px;
    color: #FFFFFF;
    opacity: 1;
`
export const UncoloredFirstPage = styled.div`
    margin-bottom:0px;
    padding: 5px 10px;
    border:1px solid #E8E8E8;
    border-radius:10px;
    background-color:#E8E8E8;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:1%;
    cursor:pointer;
    text-align: left;
    font: Regular 13px/33px Roboto;
    letter-spacing: -0.26px;
    color: #8B8B8B;
    opacity: 1;
`;
export const UncoloredPrevPage = styled.div`
    margin-bottom:0px;
    padding: 5px;
    border:1px solid #E8E8E8;
    border-radius:10px;
    background-color:#E8E8E8;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:1%;
    cursor:pointer;
    text-align: left;
    font: Regular 13px/33px Roboto;
    letter-spacing: -0.26px;
    color: #8B8B8B;
    opacity: 1;
`;
export const ColoredNextPage = styled.div`
    margin-bottom:0px;
    padding: 5px;
    border:1px solid #E27800;
    border-radius:10px;
    background-color:#E27800;
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:1%;
    cursor:pointer;
    text-align: left;
    font: Regular 13px/33px Roboto;
    letter-spacing: -0.26px;
    color: #FFFFFF;
    opacity: 1;
`
export const ColoredActualPage = styled.div`
    margin-bottom:0px;
    padding: 5px;
    border:1px solid #F2C800;
    border-radius:10px;
    background-color:#F2C800;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:1%;
    text-align: left;
    font: Regular 13px/33px Roboto;
    letter-spacing: -0.26px;
    color: #636363;
    opacity: 1;
`;