import React, { useState, useEffect, useContext} from 'react';
import PageModel from '../../../components/PageModel';
import {IRamalType} from '../../../interfaces/components/IRamalType';
import {RamalContato, TituloNome, SubTitulo, Icone, Numero, Area, Descricao, RoundedComponentSpace, ContatosSpace} from './styles';
import { faPhone,faEnvelope,faQuestion} from '@fortawesome/free-solid-svg-icons';
import { GETContatos } from '../../../API/usuario';
import { RoundedComponentAtendimentos } from '../../../components/RoundedComponent';
import { GlobalContext } from '../../../storage/contexts/GlobalContext';
import { LoaderRotatorMessage } from '../../../components/Loaders/LoaderRotator';
const FaleConosco :React.FC<{}> = (): React.ReactElement=>{

    
    const [canaisAtendimento,setCanaisAtendimento]=useState([{titulo:'',subTitulo:'',contatos:[{tipo:"",valor:'Não há contatos',area:'',descricao:'',disponibilidade:''}]}]);
    useEffect(()=>{
        GETContatos()?.then((response:any)=>{
            setCanaisAtendimento(response.data);
        })
    },[]);
    return(
            <PageModel Conteudo={<FaleConoscoComponent ramaisContatos={canaisAtendimento}/>}/>
        )
}
const FaleConoscoComponent :React.FC<{ramaisContatos:IRamalType[]}> = (props): React.ReactElement=>{
    const {ramaisContatos} = props;
    let nextHasMoreThanOneChild = false;
    const {globalContext} = useContext(GlobalContext);
    return(
        <ContatosSpace>
            <div style={{height:"30%"}}>
                {
                    <div>
                        <RoundedComponentSpace>
                            <div style={{width:"30%"}}>
                            {
                                globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/ajuda/")?
                                <RoundedComponentAtendimentos item={{documentIco:"38",documentText:"AJUDA",url:"/inicio/falecomagente/ajuda"}}/>
                                :
                                <></>
                            }
                            </div>
                            <div style={{width:"30%"}}>
                            {
                                globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/atendimentos/")?
                                <RoundedComponentAtendimentos item={{documentIco:"37",documentText:"MEUS ATENDIMENTOS",url:"/inicio/falecomagente/atendimentos"}}/>
                                :
                                <></>
                            }
                            </div>
                            <div style={{width:"30%"}}>
                            {
                                globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/novoatendimento/")?
                                <RoundedComponentAtendimentos item={{documentIco:"35",documentText:"NOVO ATENDIMENTO",url:"/inicio/falecomagente/novoatendimento"}}/>
                                :
                                <></>
                            }
                            </div>
                        </RoundedComponentSpace>
                    </div>
                }
            </div>
            <div>
                {
                ramaisContatos[0].contatos[0].valor !== 'Não há contatos' ?
                ramaisContatos.map((ramalAtual:IRamalType,i:number)=>
                {
                    console.log(i);
                    let hasMoreThanOneChild=ramalAtual.contatos.length>1;
                    if(!nextHasMoreThanOneChild)
                    return(
                    <RamalContato borderTop={(true?"0px":"1px solid #F2C800")} width={(hasMoreThanOneChild?"100%":"100%")}>
                        <div style={{width:(hasMoreThanOneChild?"100%":"100%")}}>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                    <TituloNome>{ramalAtual.titulo}</TituloNome>
                                    <hr style={{border:"1px solid #F2C800",overflow:"hidden",flexGrow:1}}/>
                                </div>
                                <SubTitulo>{ramalAtual.subtitulo}</SubTitulo>
                            </div>
                            <div style={{display:"flex",flexDirection:"row"}}>
                            {
                                ramalAtual.contatos.map((item:{valor:string,tipo:string,area?:string,descricao?:string,disponibilidade?:string})=>
                                {
                                    return(
                                    <div style={{display:"flex",flexDirection:"column",width:(hasMoreThanOneChild?"50%":"100%")}}>
                                        <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                            <Icone icon={(item.tipo==="telefone"?faPhone:(item.tipo==="email"?faEnvelope:faQuestion))}/>
                                            <Numero>{item.valor}</Numero>
                                        </div>
                                        <Area>{item.area}</Area>
                                        <Descricao>{item.descricao}</Descricao>
                                        <Descricao>{item.disponibilidade}</Descricao>
                                    </div>);
                                })
                            }
                            </div>
                        </div>
                        {
                            (!hasMoreThanOneChild && ramaisContatos[i+1]?.contatos.length<=1?
                                <div style={{width:(hasMoreThanOneChild?"100%":"50%")}}>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                    <TituloNome>{ramaisContatos[i+1].titulo}</TituloNome>
                                    <hr style={{border:"1px solid #F2C800",overflow:"hidden",flexGrow:1}}/>
                                </div>
                                <SubTitulo>{ramaisContatos[i+1].subtitulo}</SubTitulo>
                            </div>
                            <div style={{display:"flex",flexDirection:"row"}}>
                            {
                                ramaisContatos[i+1].contatos.map((item:{valor:string,tipo:string,area?:string,descricao?:string,disponibilidade?:string})=>
                                {
                                    nextHasMoreThanOneChild=true;
                                    return(
                                    <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
                                        <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap"}}>
                                            <Icone icon={(item.tipo==="telefone"?faPhone:(item.tipo==="email"?faEnvelope:faQuestion))}/>
                                            <Numero>{item.valor}</Numero>
                                        </div>
                                        <Area>{item.area}</Area>
                                        <Descricao>{item.descricao}</Descricao>
                                        <Descricao>{item.disponibilidade}</Descricao>
                                    </div>);
                                })
                            }
                            </div>
                        </div>:'')                                    
                        }
                    </RamalContato>)
                    else
                    {
                    nextHasMoreThanOneChild=!nextHasMoreThanOneChild;
                    return "";
                    }
                }):<LoaderRotatorMessage width={"auto;flex-grow:1"}/>
                }
            </div>
        </ContatosSpace>
    )
}
export default FaleConosco;