import styled from "styled-components";

export const Background = styled.div`
    z-index: 10;
    position: fixed;
    top:0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-color: #63636399;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    animation:opac 0.4s;
    @keyframes opac{from{opacity:0} to{opacity:1}}

`;

export const Container = styled.div`
    width: 40%;
    height: 40%;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 4px 0px #363636;
    animation:animatetop 0.5s;
    position: relative;
    @keyframes animatetop{
        from{top:-300px;opacity:0} 
        to{top:0;opacity:1}
    }
`;

export const Title = styled.h3`
    text-transform: uppercase;
    color: #E27800;
    text-align: center;
`;

export const PrimaryButton = styled.button`
    box-sizing: border-box;
    height: 40px;
    align-self: center;
    justify-self: center;
    background-color: #E27800;
    border-radius: 10px; 
    border: none; 
    font-size:1em;
    color: #FFFFFF;
    min-width: 65px;
    ::after, ::before, :focus{
        outline:0px;
    };

    :hover{
        background-color: rgb(226 120 0 / 87%);
    }

    :active{
        background-color: #E27800;
    }
`;

export const DefaultButton = styled.button`
    box-sizing: border-box;
    height: 40px;
    align-self: center;
    justify-self: center;
    background-color: white;
    border: 1px #707070 solid;
    border-radius: 10px; 
    font-size:1em;
    color: #707070;
    
    min-width: 65px;
    ::after, ::before, :focus{
        outline:0px;
    };

    :hover{
        background-color: #f5f5f5;
    }

    :active{
        background-color: white;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

`;
