import { TextInput } from '../../../components/TextInput';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ButtonOrange, ButtonSilver, ButtonSpace, LineInputContainer, PageSpace } from './style';
import SinistroNewPageModel from '../../../components/SinistroComponents/SinistroNewPageModel';
import StyledAlert from '../../../components/StyledAlert';
import CustomDropdown from '../../../components/CustomDropdown';
import { SinistroContext } from '../../../storage/contexts/SinistroContext';
import { InformacoesSegurado } from '../../../interfaces/storage/contexts/ISinistroContext';
import { LoaderRotatorModal } from '../../../components/Loaders/LoaderRotator';

const numeroCaracteresObservacao = 1000;

const SinistroGeral: React.FC<{}> = ():React.ReactElement => {
    function AtualizarValor(evento:{target:{value:string}},setValor:Function){
        setValor(evento.target.value);
    }
    const {sinistroContext,setSinistroContext} = useContext(SinistroContext);
    const {
        etapa,
        informacoesSegurado,
        formularioSinistroGeral,
    } = sinistroContext;
    const {
        acidenteEvento:_acidenteEvento,
        acidenteLoja:_acidenteLoja,
        acidenteOnibus:_acidenteOnibus,
        cnpj:_cnpj,
        contratante:_contratante,
        empregador:_empregador,
        infoEvento:_infoEvento,
        infoLoja:_infoLoja,
        infoOnibus:_infoOnibus,
        infoBilhete:_infoBilhete,
        relato:_relato,
    } = formularioSinistroGeral ?? {
        acidenteEvento:"",
        acidenteLoja:"",
        acidenteOnibus:"",
        cnpj:"",
        contratante:"",
        empregador:"",
        infoEvento:"",
        infoLoja:"",
        infoOnibus:"",
        infoBilhete:"",
        relato:"",
    };
    const {
    } = informacoesSegurado ?? {
        cpfSegurado:"",
        dataNascimentoSegurado:"",
        primeiroNomeSegurado:"",
    };
    const [redirectToSinistro,setRedirectToSinistro] = useState<boolean>(false);
    const [nomeCompleto, setNomeCompleto] = useState("");
    const [contratante,setContratante] = useState<string>("Não");
    const [cnpj,setCnpj] = useState<string>("");
    const [empregador,setEmpregador] = useState<string>("");
    const [acidenteOnibus,setAcidenteOnibus] = useState<string>("Não");
    const [infoOnibus,setInfoOnibus] = useState<string>("");
    const [infoBilhete,setInfoBilhete] = useState<string>("");
    const [acidenteLoja,setAcidenteLoja] = useState<string>("Não");
    const [infoLoja,setInfoLoja] = useState<string>("");
    const [acidenteEvento,setAcidenteEvento] = useState<string>("Não");
    const [infoEvento,setInfoEvento] = useState<string>("");
    const [relato,setRelato] = useState<string>("");
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const VerificarDados = useCallback(()=>{
        let isOk = true;
        const FalharEMostrarMensagem = (mensagem:string) =>{
            isOk = false;
            StyledAlert(mensagem);
        }
        if(nomeCompleto.length<=2)
            FalharEMostrarMensagem("Preencha o nome!");
            
        if(relato.length > numeroCaracteresObservacao)
            FalharEMostrarMensagem("Insira no máximo " + numeroCaracteresObservacao + " caracteres");

        return isOk;
    },[nomeCompleto.length])
    useEffect(()=>{
        if(sinistroContext.etapa==="identificareventogeral")
            history.push("/inicio/sinistro/identificacaoevento");
    },[sinistroContext.etapa]);
    const titulo : string = useMemo(()=>"FORMULÁRIO DE COMUNICAÇÃO DE SINISTRO",[]);
    const descricao : string = useMemo(()=>"Mesmo assim, você pode fazer a comunicação do sinistro por meio do formulário abaixo:",[]);
    return(
        <PageSpace>
            <SinistroNewPageModel tituloPagina={titulo} descricao={descricao} >
                <CustomDropdown status={"open"} bodyIsClickable titulo={"Identificação do(a) Segurado(a)/Participante"} extraStyle="margin-top:10px;padding:5px;">
                    <div style={{display:"flex", flexDirection:"column", alignItems:"space-between", justifyContent:"center"}} >
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"space-between",minHeight:"100px"}} >
                            <LineInputContainer>
                                <TextInput 
                                    obligatory
                                    rotulo="Nome completo" 
                                    valor={nomeCompleto} 
                                    onChange={(e:any)=>{AtualizarValor(e,setNomeCompleto)}} 
                                    show disabled={false} 
                                    placeHolder="Nome completo"/>
                            </LineInputContainer>
                        </div>
                    </div>
                </CustomDropdown>
                <CustomDropdown status={"open"} extraStyle="margin-top:6px;padding:5px;" bodyIsClickable titulo="Questionário">
                    <div style={{display:"flex", flexDirection:"column", alignItems:"space-between", justifyContent:"center", minHeight:"550px"}} >
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"space-between",minHeight:"500px"}} >
                            <LineInputContainer>
                                <TextInput
                                    width="100%" valor={contratante} show disabled={false} type="combo"
                                    rotulo="O Seguro/Plano foi contratado por empresa/empregador?" 
                                    onChange={(e:any)=>{AtualizarValor(e,setContratante)}}
                                    options={[
                                        "Sim",
                                        "Não"
                                    ]}/>
                            </LineInputContainer>
                            {
                                contratante === 'Sim' &&
                                    <LineInputContainer>
                                        <TextInput 
                                            width="49%" valor={empregador} show disabled={false} type="text"
                                            rotulo="Nome da Empresa/Empregador"
                                            onChange={(e:any)=>{AtualizarValor(e,setEmpregador)}} />
                                        <TextInput  
                                            width="49%" type="cnpj" disabled={false}
                                            rotulo="CNPJ da Empresa/empregador" valor={cnpj} show
                                            onChange={(e:any)=>{AtualizarValor(e,setCnpj)}}/>
                                    </LineInputContainer>
                            }
                            <LineInputContainer>
                                <TextInput 
                                    width="100%" valor={acidenteOnibus} show disabled={false} type="combo"
                                    rotulo="O evento foi um acidente em viagem de ônibus?"
                                    onChange={(e:any)=>{AtualizarValor(e,setAcidenteOnibus);}}
                                    options={['Sim','Não']} 
                                />
                            </LineInputContainer>
                            {
                                acidenteOnibus === 'Sim' && 
                                    <LineInputContainer>
                                        <TextInput 
                                            width="49%" show disabled={false} valor={infoOnibus}
                                            rotulo="Nome da empresa de transporte"
                                            onChange={(e:any)=>{AtualizarValor(e,setInfoOnibus);}}
                                        />
                                        <TextInput 
                                            width="49%" show disabled={false} valor={infoBilhete}
                                            rotulo="Numero do bilhete"
                                            onChange={(e:any)=>{AtualizarValor(e,setInfoBilhete);}}
                                        />
                                    </LineInputContainer>
                            }
                            <LineInputContainer>
                                <TextInput 
                                    rotulo="Seguro foi contratado em lojas de varejo ou supermercados?"
                                    width="100%" valor={acidenteLoja} show disabled={false} type="combo"
                                    onChange={(e:any)=>{AtualizarValor(e,setAcidenteLoja)}}
                                    options={['Sim','Não']} 
                                />
                            </LineInputContainer>
                            {
                                acidenteLoja === 'Sim' && 
                                    <LineInputContainer>
                                        <TextInput 
                                            width="100%" show disabled={false} valor={infoLoja}
                                            rotulo="Informe o nome da loja de Varejo ou Supermercado."
                                            onChange={(e:any)=>{AtualizarValor(e,setInfoLoja)}}
                                        />
                                    </LineInputContainer>
                            }
                            <LineInputContainer>
                                <TextInput 
                                    width="100%" valor={acidenteEvento} show disabled={false} type="combo"
                                    rotulo="O segurado estava participando de algum evento ou curso no momento do sinistro?"
                                    onChange={(e:any)=>{AtualizarValor(e,setAcidenteEvento)}}
                                    options={['Sim','Não']} />
                            </LineInputContainer>
                            {
                                acidenteEvento === 'Sim' && 
                                    <LineInputContainer>
                                        <TextInput 
                                            width="100%" show disabled={false} valor={infoEvento}
                                            onChange={(e:any)=>{AtualizarValor(e,setInfoEvento)}}
                                            rotulo="Informe a instituição de ensino do curso ou o nome do evento."
                                        />
                                    </LineInputContainer>
                            }
                            <LineInputContainer>
                                <TextInput 
                                    width="100%" valor={relato} show disabled={false} type="bigtext" maxLength={numeroCaracteresObservacao}
                                    rotulo="Relate o que aconteceu"
                                    onChange={(e:any)=>{AtualizarValor(e,setRelato)}}/>
                            </LineInputContainer>
                            
                        </div>
                        <div style={{display:"flex", justifyContent:"flex-end", paddingRight:"15px"}}>
                            <div style={{float:"right"}}>
                                <span>{numeroCaracteresObservacao - relato.length} caracteres restantes</span>
                            </div>
                        </div>
                    </div>
                </CustomDropdown>
                <LoaderRotatorModal show={isLoading}/>
                <div style={{width:"100%", display:"flex",justifyContent:"center"}}>
                    <ButtonSpace>
                            <ButtonOrange
                                onClick={()=>{
                                    if(VerificarDados()){
                                        setSinistroContext({
                                            ...sinistroContext,
                                            etapa:"identificareventogeral",
                                            informacoesSegurado: {
                                                ...sinistroContext.informacoesSegurado,
                                                nomeCompletoParticipanteSegurado: nomeCompleto,
                                            } as InformacoesSegurado,
                                            formularioSinistroGeral: {
                                                contratante,
                                                cnpj,
                                                empregador,
                                                acidenteOnibus,
                                                infoOnibus,
                                                infoBilhete,
                                                acidenteLoja,
                                                infoLoja,
                                                acidenteEvento,
                                                infoEvento,
                                                relato,
                                            },
                                        });
                                    }
                                }}
                            >
                                Enviar
                            </ButtonOrange>
                            <ButtonSilver
                                onClick={
                                    ()=>setRedirectToSinistro(
                                        !redirectToSinistro
                                    )
                                }
                            >
                                Cancelar 
                            </ButtonSilver>
                            {redirectToSinistro?<Redirect to="./" />:<></>}
                    </ButtonSpace>
                </div>
            </SinistroNewPageModel>
        </PageSpace>
    )
}

export default SinistroGeral;