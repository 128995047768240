import React, { useContext } from 'react';
import {IProps, ITrilhaProps} from "../../interfaces/components/IPageModel"
import Tips from '../../components/widgets/Tips';
import { Container, Header, Title, Linha, StyledLink, Divisor, NonLink, InsideContainer} from './styles';
import { useGetTrilhas } from '../../storage/custom_hooks/useGetTrilhas';
import { GlobalContext } from '../../storage/contexts/GlobalContext';
import { ITip } from '../../interfaces/components/ITips';

const Trilha: React.FC<ITrilhaProps> = (props): React.ReactElement => {
    const { data } = props;
    return(
        <div>  
            {
                data?.map((item, i) => {
                    const { titulo, path} = item;

                    return(
                    <React.Fragment key={"trilhaFragmentKey"+i}>
                        {i> 0 ? <Divisor>&gt;</Divisor> : ''}

                        { (i+1) === data?.length
                            ? <NonLink> {titulo}</NonLink>
                            : <StyledLink to={location => ({ ...location, pathname: path })}> {titulo}</StyledLink>
                        }
                    </React.Fragment>
                    )
                })
            }
        </div>
    )
}

const PageModel: React.FC<IProps> = (props): React.ReactElement => {
    const { titulo, trilha, Conteudo, tips, cantRedirect } = props;
    let actualtips:ITip[];
    const informativosdefault =useContext(GlobalContext).globalContext.Informativos;
    if(!tips)
    {
        actualtips=informativosdefault??[];
    }
    else
    {
        actualtips=tips;
    }
    const { tituloAtual, trilhaAtual } = useGetTrilhas();
    return(
        <Container>
            <InsideContainer>
                <div className="left">
                    <Header>
                        <Title>{titulo ?? tituloAtual}</Title>

                        <Trilha data={trilha ?? trilhaAtual}/>
                    </Header>
                    <Linha width={'50%'}/>
                    {props.children ?? Conteudo}
                </div>
                <div className="right">
                    <Tips cantRedirect={cantRedirect} data={actualtips} />
                </div>
            </InsideContainer>
        </Container>
    )
} 

export default PageModel;