import React, { useCallback, useEffect,useRef,useState } from 'react';
import {RelativeSpace} from './style';
export enum PositionAlternatives {
    positionOnMouse,
    positionOnRef,
    positionOnParent
}
interface SuspenseInfoProps {show?:boolean,referencia?:React.MutableRefObject<HTMLElement|null>,onMouseEnter?:Function,onMouseLeave?:Function}
interface SuspenseInfoCommonProps extends SuspenseInfoProps {position?:PositionAlternatives}
const SuspenseInfo : React.FC<SuspenseInfoCommonProps> = (props) : React.ReactElement =>
{   
    if(props.position===PositionAlternatives.positionOnMouse)
        return <SuspenseInfoOnMouse {...props}/>
    if(props.position===PositionAlternatives.positionOnRef && props.referencia)
        return <SuspenseInfoOnRef {...props}/>
    else
        return <SuspenseInfoDefault {...props}/>
}

const SuspenseInfoOnRef : React.FC<SuspenseInfoProps> = (props) =>{
    const SuspenseInfoReference = useRef<any>(null);
    const [actualComponent,setActualComponent] = useState<{coordinates:{x:string,y:string,refCompWidth:string,thisWidth:string},component:React.ReactElement}>({
        coordinates:{
            x:"",
            y:"",
            refCompWidth:"",
            thisWidth:""
        },
        component:
        <RelativeSpace ref={SuspenseInfoReference}>
            {
                props.children
            }
        </RelativeSpace>,
    });
    const AtualizarComponent = useCallback(()=>{
        const TotalPage = Math.max(window.innerWidth,document.body.scrollHeight,document.body.clientHeight);
        const PageHeight = window.innerHeight;
        const Elemento = (props.referencia?.current ?? (SuspenseInfoReference.current as HTMLElement)) ?? {getBoundingClientRect:()=>{return({x:0,width:0,y:0})},style:{left:0}}
        const DistanciaConforto = 0;
        const XPosition = Elemento.getBoundingClientRect().x;
        const YPosition = Elemento.getBoundingClientRect().y;
        const RefElementWidth = Elemento.getBoundingClientRect().width;
        const ElementHeight = ((SuspenseInfoReference.current as HTMLElement) ?? {getBoundingClientRect:()=>{return({x:0,width:0,height:0,y:0})},style:{left:0}}).getBoundingClientRect().height;
        const ElementWidth = ((SuspenseInfoReference.current as HTMLElement) ?? {getBoundingClientRect:()=>{return({x:0,width:0,y:0})},style:{left:0}}).getBoundingClientRect().width;
        if(XPosition!==0 && ElementWidth!==0 && (XPosition.toString() !== actualComponent.coordinates.x || YPosition.toString() !== actualComponent.coordinates.y || RefElementWidth.toString() !== actualComponent.coordinates.refCompWidth || ElementWidth.toString() !== actualComponent.coordinates.thisWidth))
        {
            const TopDistance = YPosition+window.pageYOffset+ElementHeight;
            if(TotalPage<XPosition+RefElementWidth+ElementWidth)
            {
                const LeftDistance = Math.floor(((XPosition>0?XPosition:0)+window.pageXOffset)-((XPosition>0?(ElementWidth):0)+DistanciaConforto));
                setActualComponent({
                    coordinates:{
                        refCompWidth:RefElementWidth.toString(),
                        thisWidth:ElementWidth.toString(),
                        x: XPosition.toString(),
                        y: YPosition.toString(),
                    },
                    component:
                    <RelativeSpace 
                        onMouseLeave={()=>{if(props.onMouseLeave)props.onMouseLeave()}} 
                        onMouseEnter={()=>{if(props.onMouseEnter)props.onMouseEnter()}} 
                        left={(LeftDistance > 0 ? LeftDistance : 0).toString()+"px"}
                        top={(TopDistance>PageHeight?TopDistance-(2*ElementHeight)+10:(TopDistance>0?TopDistance-ElementHeight:0))+"px"} 
                        ref={SuspenseInfoReference}>
                        {
                            props.children
                        }
                    </RelativeSpace>
                })
            }
            else
            {
                setActualComponent({
                    coordinates:{
                        refCompWidth:RefElementWidth.toString(),
                        thisWidth:ElementWidth.toString(),
                        x: XPosition.toString(),
                        y: YPosition.toString(),
                    },
                    component:
                    <RelativeSpace 
                        onMouseLeave={()=>{if(props.onMouseLeave)props.onMouseLeave()}} 
                        onMouseEnter={()=>{if(props.onMouseEnter)props.onMouseEnter()}} 
                        left={(((XPosition>0?XPosition:0)+DistanciaConforto)+RefElementWidth).toString()+"px"} 
                        top={(TopDistance>PageHeight?TopDistance-(2*ElementHeight)+10:(TopDistance>0?TopDistance-ElementHeight:0))+"px"} 
                        ref={SuspenseInfoReference}
                    >
                        {
                            props.children
                        }
                    </RelativeSpace>
                })
            }
        }
    },[actualComponent.coordinates.refCompWidth,actualComponent.coordinates.thisWidth,actualComponent.coordinates.x,actualComponent.coordinates.y,props]);
    useEffect(()=>{
        window.removeEventListener("resize",AtualizarComponent)
        window.addEventListener("resize",AtualizarComponent);
    },[AtualizarComponent])
    useEffect(()=>{
        AtualizarComponent();
        // eslint-disable-next-line
    });
    if((typeof(props.show)==="boolean" && props.show) || typeof(props.show)==="undefined")
        return actualComponent.component;
    else
    {
        return(
            <></>
        )
    }
}

const SuspenseInfoOnMouse : React.FC<SuspenseInfoProps> = (props) =>{
    const SuspenseInfoReference = useRef<any>(null)
    const [mousePosition, setMousePosition] = useState<{x:number|null,y:number|null}>({ x: null, y: null});
    const updateMousePosition = useCallback((ev:{clientX:any,clientY:any}) => {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
    },[]);
    useEffect(()=>{
        if(props.show)
        {
            window.addEventListener("mousemove", updateMousePosition)
        }
        else
        {
            window.removeEventListener("mousemove", updateMousePosition)
        }
    },[props.show,updateMousePosition])
    const [actualComponent,setActualComponent] = useState<React.ReactElement>(
        <RelativeSpace ref={SuspenseInfoReference} onMouseEnter={()=>{if(props.onMouseEnter)props.onMouseEnter()}} onMouseLeave={()=>{if(props.onMouseLeave)props.onMouseLeave()}}>
            {
                props.children
            }
        </RelativeSpace>
    )
    useEffect(()=>{
        setActualComponent(
            <RelativeSpace maxHeight={"fit-content"} ref={SuspenseInfoReference} left={(mousePosition.x ? mousePosition.x+15+window.pageXOffset : "auto").toString()+"px"} top={(mousePosition.y ? mousePosition.y+window.pageYOffset : "auto").toString()+"px"} onMouseEnter={()=>{if(props.onMouseEnter)props.onMouseEnter()}} onMouseLeave={()=>{if(props.onMouseLeave)props.onMouseLeave()}}>
                {
                    props.children
                }
            </RelativeSpace>
        )
        // eslint-disable-next-line
    },[mousePosition.x,mousePosition.y])
    // eslint-disable-next-line
    useEffect(()=>{
        const TotalPage = window.innerWidth;
        const Elemento = (SuspenseInfoReference.current as HTMLElement) ?? {getBoundingClientRect:()=>{return({x:0,width:0})},style:{left:0}}
        const XPosition = Elemento.getBoundingClientRect().x;
        const ElementWidth = Elemento.getBoundingClientRect().width;
        if(XPosition!==0 && ElementWidth!==0)
        if(TotalPage<XPosition+ElementWidth)
        {
            setActualComponent(
                <RelativeSpace left={Math.floor(XPosition-(334)).toString()+"px"} ref={SuspenseInfoReference}>
                    {
                        props.children
                    }
                </RelativeSpace>
            )
        }
        // eslint-disable-next-line
    });
    if((typeof(props.show)==="boolean" && props.show) || typeof(props.show)==="undefined")
        //style ta sendo usado aqui pois não é possível usar ref como string com styled component
        return actualComponent;
        
    else
    {
        return(
            <></>
        )
    }
}
const SuspenseInfoDefault : React.FC<SuspenseInfoProps> = (props) =>{
    const SuspenseInfoReference = useRef<any>(null)
    const [actualComponent,setActualComponent] = useState<React.ReactElement>(
        <RelativeSpace ref={SuspenseInfoReference}>
            {
                props.children
            }
        </RelativeSpace>
    )
    // eslint-disable-next-line
    useEffect(()=>{
        const TotalPage = window.innerWidth;
        const Elemento = (SuspenseInfoReference.current as HTMLElement) ?? {getBoundingClientRect:()=>{return({x:0,width:0})},style:{left:0}}
        const XPosition = Elemento.getBoundingClientRect().x;
        const ElementWidth = Elemento.getBoundingClientRect().width;
        if(XPosition!==0 && ElementWidth!==0)
        if(TotalPage<XPosition+ElementWidth)
        {
            setActualComponent(
                <RelativeSpace left={Math.floor(XPosition-(334)).toString()+"px"} ref={SuspenseInfoReference}>
                    {
                        props.children
                    }
                </RelativeSpace>
            )
        }
        // eslint-disable-next-line
    });
    if((typeof(props.show)==="boolean" && props.show) || typeof(props.show)==="undefined")
        return actualComponent;
    else
    {
        return(
            <></>
        )
    }
}
export default SuspenseInfo;