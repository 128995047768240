import styled from 'styled-components';
import { lighten} from 'polished'
import {Link} from 'react-router-dom';

export const Container = styled.div`
    height:96%;
    @media screen and (min-width: 1160px) {
        margin-left:6%;
        width:94%;
    }
    @media screen and (max-width: 1159px) {
        margin-left:0%;
        width:100%;
    }
    @media screen and (max-width: 890px) {
        width:980px;
    }
    display: flex;
    flex-direction: row;
` ;
export const InsideContainer = styled.div`
    margin-top:3%;
    width:100%;
    height:100%;
    display: flex;

    @media screen and (max-width: 1159px) {
        flex-direction: column;
        align-items:center;
    }
    @media screen and (min-width: 1160px) {
        flex-direction: row;
        align-items:normal;
    }

    justify-content:space-between;
    div.left, div.right{
        display: flex;
        flex-direction: column;
    }

    div.left{
        @media screen and (min-width: 1160px) {
            width: 70%;
        }
        @media screen and (max-width: 1159px) {
            width:fit-content;
            padding-left:10px;
            margin-bottom:50px;
        }
        height: fit-content;
        
        min-width: 550px;

    }

    div.right{
        @media screen and (max-width: 1159px) {
            padding:0;
        }
        @media screen and (min-width: 1200px) {
            padding: 0 30px;
        }
        min-width:270px;
        width: 30%;
    }
        display: flex;
    flex-direction: row;
`
export const Header = styled.div`
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const Title = styled.span`
    color: ${props => props.theme.cores.primaria};
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight:bold;
`;

export const Linha = styled.hr<{ width: string | number }>`
    width: ${props => props.width};
    background-color: ${props => lighten( 0.2 ,props.theme.cores.secundaria)};
`;

export const Divisor = styled.span`
    font-size:14px;
    color: #666;
    margin: 0 5px;
`;

export  const StyledLink = styled(Link)`
    color: #666;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;

    &:hover{
        color: #666;
    }
`;

export const NonLink = styled.span`
    font-size: 10px;
    color: #AAA;
    text-transform: uppercase;
    font-weight: bold;
`;