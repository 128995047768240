import styled from 'styled-components';
import logoCapemisaSeguradora from '../../../imagens/logo-capemisa-seguradora.png';
export const PageContent = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    height:100%;
    max-width:870px;
    padding: 0px 10px 30px 3px;
`;
export const DataContent = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
`;
export const MarketContent = styled.div`
    width: 788px;
    height: 833px;
    color:blue;
`;

export const ContentContainerBasicMessage = styled.div<{width?:string}>`
    min-width:${props=>props.width??"24px"};
    *{
        margin:0px;
        padding:0px;
    }
`;
export const TitleContainerBasicMessage = styled.div<{titleColor?:string, titleSize?:string, titleMarginBottom?:string}>`
    color: ${props=>props.titleColor??"#E27800"} ;
    font-size: ${props=>props.titleSize??"32px"} ;
    font-weight: bold;
    font-family:"Roboto";
    padding: 0px;
    margin-bottom: ${props=>props.titleMarginBottom??"0px"};
`;
export const BodyContainerBasicMessage = styled.div<{textFontSize?:string, textFontColor?:string,textMarginTop?:string,textMarginBottom?:string}>`
    font-size: ${props=>props.textFontSize??"14px"};
    font-family: "Roboto";
    color: ${props=>props.textFontColor??"#636363"};
    margin-top: ${props=>props.textMarginTop??"0px"};
    margin-bottom: ${props=>props.textMarginBottom??"0px"};
`;
export const HLine =styled.hr<{HorizontalLineColor?:string}>`
    margin: 0px;
    padding: 0px;
    width: 100%;
    border-width: 1px;
    border-color: ${props=>props.HorizontalLineColor??"#E27800"} ;
    border-style: "solid";
`;
export const HeaderContentSpace=styled.div`
width:100%;
`;
export const MainContentSpace=styled.div`
width:100%;
`;
export const FooterContentSpace=styled.div`
    width:100%;
    min-height:160px;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
`;
export const BtnCinza=styled.button`
    height:40px;
    border-radius:21px;
    background-color:#DADADA; 
    border:none;
    font-size:1em;
    color:#707070;
    font-family:"Roboto";
    width:40%;
    ::after, ::before, :focus{
        outline:0px;
    };
`;
export const BtnLaranja=styled.button`
    height: 40px;
    background-color: #E27800;
    border-radius: 21px; 
    border: none; 
    font-size:1em;
    color: #FFFFFF;
    width:40%;
    ::after, ::before, :focus{
        outline:0px;
    };
`;
export const ButtonContainer=styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-around;
`;

export const CheckBoxContainer=styled.div`
    display:flex;
    flex-direction:row;
    align-items:flex-start;
`;

export const DocumentContent=styled.div<{direction?:string, justifyContent?:string}>`
    display:flex;
    flex-direction:${props=>props.direction??"row"};
    justify-content:${props=>props.justifyContent??"flex-start"};
    align-items:center;
    width: 100%;
`;
export const InputContainer=styled.div`
    display:flex;
    flex-direction:column;
    border: 1px solid red;
    width:95%;
`;

export const LabelOfInput=styled.label`
    font-family:"Roboto"; 
    font-size:17px;
    color: #B9B9B9;
    text-align: left;
    margin-left: 20px;  
`;
export const SelectInput=styled.select`
    border-radius: 15px;
    width: 100%;
    height: 45px;
    opacity: 1;    
    margin-bottom: 48px;
    padding-left: 10px;
    font:#707070;
`;
export const InputOfInputWithLabel=styled.select``;
export const CheckboxWithDateContainer=styled.div``;
export const ContainerCheckBoxDate = styled.div`
    border: 1px dashed red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
export const InputCheckBoxCheckBoxDate = styled.input.attrs({type:'checkbox'})`
    margin-right: 10px;
`;
export const InputDateCheckBoxDate = styled.input.attrs({type:'text'})``;
export const ContainerTitlePage = styled.div`
    width: 100%;
`;
export const LabelTitlePage = styled.div`
    text-align: left;
    font: normal normal bold 42px/55px Roboto;
    letter-spacing: 0px;
    color: #E27800;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom:21px;
`;
export const LabelPage = styled.div`
    text-align: left;
    font: normal normal normal 16px/24px Roboto;
    letter-spacing: -0.01px;
    color: #636363;
    opacity: 1;
    margin-top:21px;
    margin-bottom:32px;
`;
export const OptionSelectedInput = styled.select`
    border-radius: 15px;
    width: 100%;
    height: 45px;
    opacity: 1;    
    margin-bottom: 48px;
    padding-left: 10px;
    font:#707070;
`;
export const InputContent = styled.div`
    width:100%;
    padding:10px 0px;
`;

export const LinhaEventoSpace = styled.div`
    width:100%;
    min-height:61px;
    display:flex;
    justify-content:space-between;
    border-bottom:1px solid #707070;
    padding-top:5px;
    padding-bottom:5px;
`;
export const EventoInputSpace = styled.div<{width?:string,alignSelf?:string}>`
    width:${props=>props.width??"30%"};
    display:flex;
    align-items:center;
    align-self:${props=>props.alignSelf??"auto"};
`
export const EventoSpacing = styled.div`
    padding-bottom:20px;
    width:95%;
    display:flex;
    flex-direction:column;
`
export const EventoTitle = styled.label`
    flex-grow:1;
    margin:0 0 0 5px;
    text-align: left;
    font: normal normal normal 1em Roboto;
    letter-spacing: 0px;
    color: #636363;
    opacity: 1;
`
export const DocumentacaoNecessariaSpace = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
`
export const DocumentacaoNecessariaInnerSpace = styled.div`
    width:98%;
    height:fit-content;
`
export const DataEventoLabel = styled.div`
    width:fit-content;
    height:auto;
`
export const SuspenseLabel = styled.div`
    font: normal 1em Roboto;
    margin:0;
`
export const DocumentItem = styled.div`
    cursor:pointer;
    display:flex;
    flex-direction:column;
    align-items:center;
    min-width:60px;
    max-width:160px;
    margin:0px 5px;
`
export const DocumentIconList = styled.div`
    display:flex;
`
export const LineSpace = styled.div`
    padding:10px 0px;
    border-top:1px solid ${props=>props.theme.cores.primaria};
    display:flex;
    align-items:center;
`;
export const DocumentRow = styled.div`
    width:96%;
    display:flex;
    align-self:center;
`
export const BulletPointList = styled.ul`
    display:flex;
    flex-direction:column;
    width:96%;
    align-self: center;
    margin:0;
    margin-top: 25px;
    justify-content:space-between;
    li {
        position: relative;
        left: 25px;
        margin:-5px 0px;
        color: #E27800;
    
    }

`
export const PrintDocsLabel = styled.div`
    color:${props=>props.theme.cores.primaria};
    font-size:0.8em;
    :hover{
        cursor:pointer;
        text-decoration:underline;
    }
    padding-right: 15px;
`
export const PrintBackdrop = styled.div`
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
`
export const PrintInsideSpace = styled.div`
    width:70%;
    height:max-content;
`
export const CapemisaImage = styled.div`
    background-image:url(${logoCapemisaSeguradora});
    height:100px;
    width:100%;
    margin-top:40px;
    background-size: contain;
    background-repeat: no-repeat;
    border-bottom:2px solid #E2B500;
`
export const SubDocHeader = styled.div `
    padding-left: 10px;
`;