import styled from 'styled-components';
import MaskedInput from 'react-maskedinput';
import CurrencyFormat from 'react-number-format';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; 
`;

export const Linha = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin: 10px 0;
`; 

export const Coluna = styled.div< {width?: string | number,margin?: boolean}>`
    width: ${props => props?.width ?? "100%" };
    p{
        margin-bottom:0px;
    }
    flex-direction: column;
    display: flex;
    margin:  ${props => props?.margin?"0 15px":"0"};
`; 
export const Label =  styled.span<{margin?:string,customCSS?:string}>`
    color: #AAA;
    font-size: 13px;
    margin:${props=>props.margin??"0 0 5px 15px"};
    ${props=>props.customCSS??""}
`;
export const StyledMaskedInput = styled(MaskedInput)<{borderRadius?:string}>`
    width: 100%;
    height: 45px;
    //border-radius: 35px;
    border-radius: ${props=>props.borderRadius??"35px"};
    border: 1px solid #AAA;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;

    &:disabled {   
        background-color: #EEE; 
    }
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const StyledCurrencyMaskedInput = styled(CurrencyFormat)`
    width: 100%;
    height: 45px;
    border-radius: 35px;
    border: 1px solid #AAA;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    &:disabled {   
        background-color: #EEE; 
    }
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const StyledInput = styled.input<{type:"text"|"number",borderRadius?:string}>`
    width: 100%;
    height: 45px;
    border-radius: ${props=>props.borderRadius??"35px"};
    //border-radius: 35px;
    border: 1px solid #AAA;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;

    &:disabled {   
        background-color: #EEE; 
    }
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`;
export const StyledBigInput = styled.textarea<{ type: "text" }>`
    width: 100%;
    height: 110px;
    border-radius: 15px;
    border: 1px solid #AAA;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    resize:none;
    &:disabled {   
        background-color: #EEE; 
    }
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`;
export const StyledSelect = styled.select<{borderRadius?:string}>`
    width: 100%;
    height: 45px;
    //border-radius: 35px;
    border-radius: ${props=>props.borderRadius??"35px"};
    border: 1px solid #AAA;
    padding-left: 15px;
    background-color: white;
    padding-right: 15px;
    &:disabled {   
        background-color: #EEE; 
    }
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const RoundedFileInput = styled.div`
    width:100%;
    height:50px;
    position: relative;
    background-color:#dddddd;
    border:1px black solid;
    border-radius:20px;
`;
export const StyledMultifileInput = styled.input`
    width:100%;
    opacity:0%;
    height:50px;
    z-index:100;
`
export const StyledMultifileLabel = styled.label`
    pointer-events:none;
    margin:0;
    position:relative;
    height:0;
    bottom:35px;
    left:24%;
    z-index:1;
`
export const RadioDiv = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:10px;
`
export const RadioText = styled.label`
    margin-bottom:0px;
    margin-left:2%;
    font-size:90%;
    font: Regular Roboto;
    max-width:250px;
`
export const BoxRadioSpace = styled.div`
    height:90%;
    width:100%;
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    justify-content:center;
`
export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color:${props=>props.theme.cores.primaria};
`
export const InfoText = styled.label`
    font-size:100%;
    font: Regular Roboto;
    margin-bottom:0px;
`
export const IcoSpace = styled.div`
    padding-left:10px;
    padding-right:10px;
`