import styled from 'styled-components';
import { lighten } from 'polished';
export const PageSpace = styled.div`
    display:flex;
    width:100%;
    height:100%;
    justify-content:space-between;
`
export const LeftPage = styled.div`
    flex-grow:1;
    height:90vh;
    display:flex;
    flex-direction:column;
`;
export const RightPage = styled.div`
    width: 276px;
    height: 416px;
    margin-left:1%;
`
export const SearchText = styled.label`
    text-align: left;
    letter-spacing: -0.84px;
    color: #636363;
    opacity: 1;
    margin-bottom:0%;
    font-size:2em;
`
export const SearchHighlightedText =styled.label`
    text-align: left;
    font: Black Roboto;
    letter-spacing: -0.84px;
    color: black;
    margin-bottom:0%;
`
export const SearchResultsText = styled.label`
    text-align: left;
    font: Regular 1em Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
    margin-bottom:0%;
`
export const Linha = styled.hr<{ width: string | number }>`
    width: ${props => props.width};
    background-color: ${props => lighten( 0.2 ,props.theme.cores.secundaria)};
`;
export const RoundedResults = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 35px;
    display:flex;
    justify-content:center;
    align-items:center;
    opacity: 1;
`
export const InsideSugestionText = styled.label`
    text-align: left;
    font: Bold 1em Roboto;
    letter-spacing: -0.5px;
    color: #636363;
    opacity: 1;
    width:90%;
    margin-top:2%;
`
export const CentralizeBox = styled.div`
    display:flex;
    width:90%;
    height:90%;
    padding-bottom:3%;
    flex-direction:column;
`
export const DashedDiv = styled.div`
    width:100%;
    border-top:1px dashed #B9B9B9;
`
export const Url = styled.label`
    text-align: left;
    font: Regular 19px/66px Roboto;
    letter-spacing: -0.38px;
    color: #E27800;
    opacity: 1;  
    margin-top:2%;
    margin-bottom:2%;
    cursor:pointer;
`