import { Reducer } from 'redux';
import { SinistroTypes, ISinistroState } from './reduxTypes';

const INITIAL_STATE: ISinistroState = {
    docsNecessariosVariosEventosData: [],
    docsNecessariosVariosEventosLoading: false,
    docsNecessariosVariosEventosError: null,

    avisoSinistroData: null,
    avisoSinistroLoading: false,
    avisoSinistroError: null,
    
    pendenciasAvisoSinistroData: null,
    pendenciasAvisoSinistroLoading: false,
    pendenciasAvisoSinistroError: null,
}

const reducer: Reducer<ISinistroState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SinistroTypes.OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_REQUEST:
            return { ...state, docsNecessariosVariosEventosData: [], docsNecessariosVariosEventosLoading: true, docsNecessariosVariosEventosError: null };

        case SinistroTypes.OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_SUCCESS:
            return { ...state, docsNecessariosVariosEventosData: action.payload, docsNecessariosVariosEventosLoading: true, };

        case SinistroTypes.OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_FAILURE:
            return { ...state, docsNecessariosVariosEventosLoading: true, docsNecessariosVariosEventosError: action.payload, };

        case SinistroTypes.OBTER_AVISO_SINISTRO_REQUEST:
            return { ...state, avisoSinistroData: null, avisoSinistroLoading: true, avisoSinistroError: null };

        case SinistroTypes.OBTER_AVISO_SINISTRO_SUCCESS:
            return { ...state, avisoSinistroData: action.payload, avisoSinistroLoading: false };

        case SinistroTypes.OBTER_AVISO_SINISTRO_FAILURE:
            return { ...state, avisoSinistroLoading: false, avisoSinistroError: action.payload };
            

        case SinistroTypes.OBTER_PENDENCIAS_AVISO_SINISTRO_REQUEST:
            return { ...state, pendenciasAvisoSinistroData: null, pendenciasAvisoSinistroLoading: true, pendenciasAvisoSinistroError: null };

        case SinistroTypes.OBTER_PENDENCIAS_AVISO_SINISTRO_SUCCESS:
            return { ...state, pendenciasAvisoSinistroData: action.payload, pendenciasAvisoSinistroLoading: false };

        case SinistroTypes.OBTER_PENDENCIAS_AVISO_SINISTRO_FAILURE:
            return { ...state, pendenciasAvisoSinistroLoading: false, pendenciasAvisoSinistroError: action.payload };


        case SinistroTypes.CLEANATTEMPTS:
            return {
                ...state,
                docsNecessariosVariosEventosData: [],
                docsNecessariosVariosEventosLoading: false,
                docsNecessariosVariosEventosError: null,
            };

        default:
            return state;
    }
}

export default reducer;