import { IQueryStatus } from '../../interfaces/services/IQuery';
import {Requisicao} from '../requisicao';
import {apiCEP} from '../../services/APICallableActions/Adresses';
//DEPRECATED! THIS CATEGORY OF API REQUEST IS FROM NOW (22/03/2021) ON CONSIDERED OUT OF STRUCTURE ARQUITECTURE!


//DEPRECADO! ESTA CATEGORIA DE CHAMADA A API NÃO ESTÁ SENDO MAIS UTILIZADA A PARTIR DE 22/03/2021
//QUALQUER USO DESTA CHAMADA É CONSIDERADO FORA DE ARQUITETURA DE PROJETO!
function GetDadosByCep(cep:string,setQueryStatus?:(newQueryStatus:IQueryStatus)=>void)
{
    if(setQueryStatus)
        setQueryStatus({status:"PENDING"})
    const pendingQuery= Requisicao(apiCEP+cep,{method:"GETNOTOKEN"});
    pendingQuery.then(()=>{if(setQueryStatus)setQueryStatus({status:"COMPLETED"})}).catch((reason)=>{if(setQueryStatus)setQueryStatus({status:"FAILED",errorResponse:(typeof(reason)==="string")?reason:(reason.message??"Ocorreu um erro!")})});
    return pendingQuery;
}
export default GetDadosByCep;