import axios from 'axios';

export const Sinistro = () => axios.create({
    
    //baseURL: `https://cap-dev-gateway-api.azure-api.net/common`,
    baseURL: `https://cap-dev-gateway-api.azure-api.net`,
    headers:{
        Authorization:  `Bearer ${JSON.parse(sessionStorage.getItem("auth")?? "{}").TOKEN}`
    }
});

export const Endpoints = {
    DocumentoSinistro: "/api/documentosinistro?codigoEvento=",
    AvisoDeSinistro: (id:string) => `/api/atendimento/avs?idAtendimento=${id}`,
    PendenciasAvisoDeSinistro: (id:string) => `/api/atendimento/usuario/avs/listarpendencias?idAtendimento=${id}`,
};
