import React from 'react';
import {LoaderBarSpace, LoaderColoredBar, MaxGreyBar} from './style';
const LoadingBar : React.FC<{barLimit?:number,barStatus?:number}> = (props) =>{
    const {barLimit,barStatus}=props;
    const fixedLimit = barLimit??1;
    const fixedStatus = barStatus??0;
    return(
        <LoaderBarSpace>
            <MaxGreyBar>
                <LoaderColoredBar width={(Math.floor((fixedStatus/(fixedLimit>fixedStatus?fixedLimit:fixedStatus))*100))+"%"}/>
            </MaxGreyBar>
        </LoaderBarSpace>
    )
}
export default LoadingBar