import React from 'react';
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons'
import {LoaderRotatorSpace,LoaderRotatorIcon} from './style';
import Modal from 'react-bootstrap/Modal';
interface RotatorConfig {
    color?:string,
    loaderSize?:string
}
interface RotatorMessageConfig extends RotatorConfig{
    width?:string,
    height?:string
} 
interface RotatorModalConfig extends RotatorMessageConfig{
    show?:boolean,
    setShow?:(newShow:boolean)=>void,
}
export const LoaderRotatorMessage : React.FC<RotatorMessageConfig> = (props) =>{
    return(
        <LoaderRotatorSpace width={props.width} height={props.height}>
            <label>{props.children??"Carregando Informações..."}</label>
            <LoaderRotator {...props}/>
        </LoaderRotatorSpace>
    )
}
export const LoaderRotatorModal : React.FC<RotatorModalConfig> = (props) => {
    return(
        <Modal size={"sm"} centered onHide={()=>{if(props.setShow)props.setShow(false)}} {...props}>
            <Modal.Body>
                <LoaderRotatorMessage width={"100%"} {...props}/>
            </Modal.Body>
        </Modal>
    )
}
const LoaderRotator : React.FC<RotatorConfig> = (props) => <LoaderRotatorIcon color={props.color} loadersize={props.loaderSize} icon={faSyncAlt}/>
export default LoaderRotator;