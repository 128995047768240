import React, { useState, useEffect, ReactNode, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PageModel from '../../../components/PageModel'
import { RenderList} from '../../../components/DocumentList'
import Pagination from '../../../components/Paginacao';
import {IColuna} from '../../../interfaces/components/IColunaItem';
import Modal from 'react-bootstrap/Modal';
import { RoundedComponentAtendimentos } from '../../../components/RoundedComponent';
import {RoundedComponentSpace, AtendimentoItemSpace,InsideSelectedText, InsideSelectedTextAlt, InsideRowColumnName, InsideRowColumnNameRed, InsideReprovadoInput, InsideMaskedReprovadoInput, ReSend, AtendimentoButtonSpace, IcoPDF, PDFText, InsideReprovadoSelect, AtendimentoRow} from './style';
import { GetAtendimentos, GetAtendimentosEspecifico, PutAtendimento } from '../../../API/usuario';
import { faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
import { GetAnexo, ResolveFilesAsync } from '../../../API/file';
import CorrigirVariavelMonetaria from '../../../functions/CorrigirValorPontuacao';
import StyledAlert from '../../../components/StyledAlert';
import { GlobalContext } from '../../../storage/contexts/GlobalContext';
import { LoaderRotatorMessage, LoaderRotatorModal } from '../../../components/Loaders/LoaderRotator';
import Icon from '../../../components/IcomoonIteration';
import { IStatePropsDetalhesSinistro } from '../../../interfaces/pages/IDetalhesSinistro';
import { SinistroContext } from '../../../storage/contexts/SinistroContext';

const DownloadPDF = (descricao:string,guid:string,setShowModal:any) =>{
    setShowModal(true);
    GetAnexo(guid)?.then((response:any)=>{
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', descricao);
        setShowModal(false);
        link.click();
    }).finally(()=>{setShowModal(false);})
}
const ViewPDF = (guid:string,setShowModal:Function,nome:string,setShowImage:Function) =>{
    setShowModal(true);
    GetAnexo(guid)?.then((response:any)=>{
        console.log(response.data);
        if(nome.split(".")[1] && nome.split(".")[1]==="pdf")
        {
            const url = window.URL.createObjectURL(new Blob([response.data],{type:"application/pdf"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target','_blank');
            setShowModal(false);
            link.click();
        }
        else
        {
            setShowImage(window.URL.createObjectURL(new Blob([response.data])));
        }
    }).finally(()=>{setShowModal(false);})
}
interface AVSAPIReturn 
{
    nomeCompletoSegurado?: string,
    dataNascimentoSegurado?: string,
    evento?:{nome?:string},
    dataEvento?:string,
    relacionamento?:{nome?:string},
    dataCriacao?:string,
    codigo?:string,
    listaStatusAtendimento?:{
        dataCriacao:string,
        status:{
            id:number,
        },
    }[],
}
const palavraChave = ".svg";
const palavraChave2 = "/portalrelacionamento/icones/";
export default function MeusAtendimentos(){
    const columnNames=[
        {
            width:"15.5%",
            message:"Cod.",
            hasIco:true,
            textAlign:"center",
            title:true
        },{
            width:"66%",
            message:"Assunto",
            hasIco:true,
            textAlign:"flex-start"
        },{
            width:"10%",
            message:"Estado",
            hasIco:true
        },{
            width:"20%",
            message:"Data de criação",
            title:false,
            dontShow:true
        },{
            width:"10%",
            message:"Ação.",
            title:true
        }];
    const [conteudo,setConteudo]=useState<IColuna[]>([]);
    const [aditionalComponents,setAditionalComponents] = useState<ReactNode[]>([]);
    const [cantView,setCantView] = useState<boolean>(false);
    const [actualPage,setActualPage] = useState<number>(1);
    const {globalContext} = useContext(GlobalContext);
    let conteudoTemp:IColuna[] = [];
    let aditionalComponentsTemp:ReactNode[]=[];
    useEffect(()=>{
        GetAtendimentos()?.then((response)=>{
            // console.log(response.data);
            response.data.map((item:any)=>{
                conteudoTemp.push(
                    {
                        coluna:
                        [
                            {
                                width:"15%",
                                message:item.codigo,
                                title:true
                            },{
                                width:"63%",
                                message:item.tipoAtendimento.assunto,
                                textAlign:"flex-start"
                            },{
                                width:"13%",
                                message:(function(){
                                    const {nome,icone} = (item.listaStatusAtendimento as any[]).reduce(
                                        (prevVal,currentVal)=>(
                                            new Date(prevVal.dataCriacao) >= new Date(currentVal.dataCriacao) ? prevVal : currentVal
                                        )
                                    ).status;
                                    return(
                                        <div style={{display:'flex', alignItems: 'center'}}>
                                            <Icon size={15} style={{marginRight:'4px'}} icon={icone?.slice(icone?.search(palavraChave2)+palavraChave2.length, icone?.search(palavraChave))}/>
                                            {
                                                nome
                                            }
                                        </div>
                                    )
                                }()),
                                textAlign: "flex-start"
                            },{
                                width:"20%",
                                message:new Date(item.dataCriacao).toLocaleDateString('pt-BR')+" "+new Date(item.dataCriacao).toLocaleTimeString('pt-BR').slice(0,5),
                                dontShow:true
                            }
                        ]
                    }
                );
                aditionalComponentsTemp.push(<InformacoesAdicionaisAtendimento idAtendimento={item.idAtendimento} codigo={item.tipoAtendimento.codigo}/>);
                return 0;
            })
            setCantView(conteudoTemp.length>0 ? false : true);
            setAditionalComponents(aditionalComponentsTemp);
            setConteudo((conteudoTemp.length>0 ? conteudoTemp : [{coluna:[{width:"100%",message:"Não há itens"}]}]));
        })// eslint-disable-next-line
    },[])
    const maxPerPage = useMemo(()=>5,[]);
    return(
        <PageModel>
        <div>
            <div style={{width:"100%"}}>
                <RenderList 
                    actualPage={actualPage} 
                    view={{cantView:cantView,type:"dropdown"}} 
                    AditionalViewComponent={aditionalComponents} 
                    cantDownload 
                    columnNames={columnNames} 
                    maxPerPage={maxPerPage} 
                    contents={conteudo}
                />
                {
                    conteudo.length>maxPerPage?
                    <div style={{marginTop:"2%"}}>
                    <Pagination actualPage={actualPage} setActualPage={setActualPage} maxPerPage={maxPerPage} length={conteudo.length}/>
                    </div>
                    :""
                }
            </div>
            <RoundedComponentSpace>
                <div style={{width:"30%"}}>
                    {
                        globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/")?
                        <RoundedComponentAtendimentos item={{documentIco:"31",documentText:"FALE COM A GENTE",url:"/inicio/falecomagente"}}/>
                        :
                        <></>
                    }
                   
                </div>
                <div style={{width:"30%"}}>
                    {
                        globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/novoatendimento/")?
                        <RoundedComponentAtendimentos item={{documentIco:"35",documentText:"NOVO ATENDIMENTO",url:"/inicio/falecomagente/novoatendimento"}}/>
                        :
                        <></>
                    }
                </div>
                <div style={{width:"30%"}}>
                    {
                        globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/ajuda/")?
                        <RoundedComponentAtendimentos item={{documentIco:"38",documentText:"AJUDA",url:"/inicio/falecomagente/ajuda"}}/>
                        :
                        <></>
                    }
                </div>
            </RoundedComponentSpace>
        </div>
        </PageModel>
    )
}
const InformacoesAdicionaisAtendimento :React.FC<{codigo:string,idAtendimento:number}> = (props) : React.ReactElement => {
    const [selectorContent,setSelectorContent] = useState<{selector:string,content:any}>({selector:"none",content:undefined});
    useEffect(()=>{
        GetAtendimentosEspecifico(props.codigo, props.idAtendimento)?.then((response)=>{
            //console.log(response.data);
            switch(props.codigo)
            {
                case "dc":
                case "DC":
                setSelectorContent({content:
                    {
                        userInfo:{
                            nome:response.data.nome,
                            nomePai:response.data.nomePai,
                            nomeMae:response.data.nomeMae,
                            dataNascimento:response.data.dtNascimento,
                            sexo:response.data.sexo,
                            status:response.data.listaStatusAtendimento[0].status.nome,
                            statusCode:response.data.listaStatusAtendimento[0].status.codigo,
                            observacao:response.data.listaStatusAtendimento[0].observacao ?? "",
                            listaAnexos:response.data.listaAnexo,
                            idAtendimento:props.idAtendimento,
                        }   
                    },
                    selector:props.codigo,
                });
                break;
                case "af":
                case "AF":
                setSelectorContent({
                    content:
                    {
                        afInfo:{
                            nome:response.data.nome,
                            numeroDocumento:response.data.numeroDocumento,
                            telefone:response.data.telefone,
                            email:response.data.email,
                            uf:response.data.uf,
                            convenio:response.data.convenio.nome ?? undefined,
                            outroConvenio:response.data.outroConvenio ?? undefined,
                            valor: CorrigirVariavelMonetaria(response.data.valor),
                            prazo: response.data.prazo,
                        }
                    },
                    selector:props.codigo,
                })
                break;
                case "avs":
                case "AVS":
                setSelectorContent({
                    selector:props.codigo,
                    content:(response.data as AVSAPIReturn)
                })
                break;
                
                default:
                setSelectorContent(
                    {
                        content:undefined,
                        selector:""
                    }
                )
            }
        })
    // eslint-disable-next-line
    },[]);
    switch(selectorContent.selector)
    {   
        case "dc":
        case "DC":
            return(<DCComponent userInfo={selectorContent.content.userInfo}/>);
        case "none":
            return(<LoaderRotatorMessage width={"100%"}/>);
        case "af":
        case "AF":
            return(<AFComponent afInfo={selectorContent.content.afInfo}/>);
        case "avs":
        case "AVS":
            return(<AVSComponent {...selectorContent.content}/>)
        default:
            return(<div></div>)
    }
}
const AtendimentoArquivosEnviadosRow : React.FC<{listaAnexos:{nome:string,guid:string}[]}> = (props) : React.ReactElement =>{
    const [showModal,setShowModal] = useState<boolean>(false);
    const [imageUrlText,setImageUrlText] = useState<string>("");
    return(
        <div style={{display:"flex",marginLeft:"2%",height:(props.listaAnexos.length>5?"5.5rem":"3rem"),overflowX:"auto",width:"55vw",flexShrink:1,marginBottom:"2%"}}>
           <LoaderRotatorModal show={showModal} />
            {
            imageUrlText.length>0 ?
            <Modal show size="xl" centered onHide={()=>{setImageUrlText("")}}> 
                <img alt="Imagem descritiva que autentica a mudança de informações" src={imageUrlText}/> 
            </Modal>
            : 
            ""
            }
            {
            props.listaAnexos.map((value:{nome:string,guid:string})=>{
                return(
                    <AtendimentoItemSpace>
                        <InsideRowColumnName>Visualizar ou baixar {value.nome}</InsideRowColumnName>
                        <div style={{display:"flex",width:"100%"}}>
                            <div style={{display:"flex",marginRight:"8%",alignItems:"center",height:"2rem"}} onClick={()=>{ViewPDF(value.guid,setShowModal,value.nome,setImageUrlText)}}>
                                <PDFText>Visualizar</PDFText><IcoPDF style={{marginLeft:"10%"}} icon={faEye}/>
                                </div>
                            <div style={{display:"flex",alignItems:"center",height:"2rem"}} onClick={()=>{DownloadPDF(value.nome,value.guid,setShowModal)}}>
                                <PDFText>Baixar</PDFText><IcoPDF style={{marginLeft:"10%"}} icon={faDownload}/>
                            </div>
                        </div>
                    </AtendimentoItemSpace>)
            })
            }
        </div>
    )
}
const AtendimentoItensModificadosRow : React.FC<{userInfo:{nome?:string,nomePai?:string,nomeMae?:string,dataNascimento?:string,sexo?:string},isChangeable?:boolean,values?:string[],setValues?:any}> = (props):React.ReactElement=>{
    const campos:{nome:string,value?:string}[] = [{nome:"Novo nome informado:",value:props.userInfo.nome},{nome:"Novo nome do pai informado",value:props.userInfo.nomePai},{nome:"Novo nome da mãe informado",value:props.userInfo.nomeMae},{nome:"Novo sexo informado",value:props.userInfo.sexo},{nome:"Nova data de nascimento informada",value:props.userInfo.dataNascimento?new Date(props.userInfo.dataNascimento).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11):undefined}];
    if(props.isChangeable)
    {
        return(<div style={{display:"flex",marginLeft:"2%",height:"2.5rem",alignItems:"center"}}>
            {
            props.userInfo.nome || props.userInfo.nome===""?
            <AtendimentoItemSpace>
                <InsideRowColumnNameRed> Confirme novamente o nome:</InsideRowColumnNameRed>
                <InsideReprovadoInput onChange={(e)=>{props.setValues(SubstituirIndex(0,e.target.value,(props.values as string[])))}} value={(props.values as string[])[0]} placeholder={props.userInfo.nome} />
            </AtendimentoItemSpace>:""
            }
            {
            props.userInfo.nomePai?
            <AtendimentoItemSpace>
                <InsideRowColumnNameRed> Confirme novamente o nome do pai: </InsideRowColumnNameRed>
                <InsideReprovadoInput onChange={(e)=>{props.setValues(SubstituirIndex(1,e.target.value,(props.values as string[]),))}} value={(props.values as string[])[1]} placeholder={props.userInfo.nomePai} />
            </AtendimentoItemSpace>:""
            }
            {
            props.userInfo.nomeMae?
            <AtendimentoItemSpace>
                <InsideRowColumnNameRed> Confirme novamente o nome da mãe: </InsideRowColumnNameRed>
                <InsideReprovadoInput onChange={(e)=>{props.setValues(SubstituirIndex(2,e.target.value,(props.values as string[])))}} value={(props.values as string[])[2]} placeholder={props.userInfo.nomeMae} />
            </AtendimentoItemSpace>:""
            }
            {
            props.userInfo.sexo?
            <AtendimentoItemSpace>
                <InsideRowColumnNameRed> Confirme novamente o sexo: </InsideRowColumnNameRed>
                <InsideReprovadoSelect onChange={(e)=>{props.setValues(SubstituirIndex(3,e.target.value,(props.values as string[])))}}><option selected disabled >Escolha...</option><option value="M">Masculino</option><option value="F">Feminino</option></InsideReprovadoSelect>
            </AtendimentoItemSpace>:""
            }
            {
            props.userInfo.dataNascimento?
            <AtendimentoItemSpace>
                <InsideRowColumnNameRed>Confirme novamente a data de nascimento: </InsideRowColumnNameRed>
                <InsideMaskedReprovadoInput onChange={(e)=>{props.setValues(SubstituirIndex(4,e.target.value,(props.values as string[])))}} value={(props.values as string[])[4]} mask="11/11/1111" placeholder={new Date(props.userInfo?.dataNascimento??"00/00/0000").toLocaleDateString()}/>
            </AtendimentoItemSpace>:""
        }
            </div>);
    }
        else
    return(
        <div style={{display:"flex",marginLeft:"2%",height:"4rem",alignItems:"center"}}>
            {
                campos.map((item:{nome:string,value?:string})=>{
                    if(item.nome==="Novo sexo informado")
                    {
                        return(
                            item.value?
                                <AtendimentoItemSpace>
                                    <InsideRowColumnName>{item.nome} </InsideRowColumnName>
                                    <InsideSelectedText>{item.value==="F"?"Feminino":(item.value==="M"?"Masculino":"Desconhecido")}</InsideSelectedText>
                                </AtendimentoItemSpace>:""
                        )
                    }
                    return(
                        item.value?
                        <AtendimentoItemSpace>
                            <InsideRowColumnName>{item.nome} </InsideRowColumnName>
                            <InsideSelectedText>{item.value}</InsideSelectedText>
                        </AtendimentoItemSpace>:""
                    )
                })
            }
        </div>
    )
}
const SubstituirIndex = (indexParaSubstituir:number,valor:any,vetor:any[])=>{
        let returnvetor = vetor.slice();
        returnvetor[indexParaSubstituir]=valor;
        return returnvetor;
    }
const ReprovadoComponent :React.FC<{userInfo:{nome?:string,nomePai?:string,nomeMae?:string,dataNascimento?:string,sexo?:string,idAtendimento:number,observacao?:string,listaAnexos:{nome:string,guid:string}[]}}> = (props):React.ReactElement=>
{
    const [showLoader,setShowLoader]=useState<boolean>(false);
    const [files,SetFile]=useState<FileList|null>();
    const [inputValues,setValues]=useState<string[]>(["","","","",""]);
    return(
            <div>
                <AtendimentoItensModificadosRow isChangeable userInfo={props.userInfo} values={inputValues} setValues={setValues}/>
                <div style={{display:"flex",marginLeft:"2%",height:"4rem",alignItems:"center",justifyContent:"space-between"}}>
                    <AtendimentoItemSpace>
                        <InsideRowColumnName>Insira novos arquivos:</InsideRowColumnName>
                        <input type="file" multiple onChange={(e)=>{SetFile(e.target.files)}}/>
                    </AtendimentoItemSpace>
                </div>
                <AtendimentoArquivosEnviadosRow listaAnexos={props.userInfo.listaAnexos}/>
                <div style={{display:"flex",marginLeft:"2%",height:"2.5rem",alignItems:"center",justifyContent:"space-between"}}>
                    <AtendimentoItemSpace>
                        <InsideRowColumnName>Observação: </InsideRowColumnName>
                        <InsideSelectedText>{props.userInfo.observacao}</InsideSelectedText>
                    </AtendimentoItemSpace>
                    <AtendimentoButtonSpace>
                            <LoaderRotatorModal show={showLoader}/>
                            <ReSend onClick={()=>{
                                const informacoesUsuario = props.userInfo;
                                const respostasHabilitadas:boolean[] = [(informacoesUsuario.nome?true:false),(informacoesUsuario.nomePai?true:false),(informacoesUsuario.nomeMae?true:false),(informacoesUsuario.sexo?true:false),(informacoesUsuario.dataNascimento?true:false)];
                                const TestaStrings = (previousResult:string,actualResult:string,i:number)=>{return(respostasHabilitadas[i]?((previousResult==="Preenchido" && actualResult!=="")?"Preenchido":"Vazio") :previousResult)}
                                if(inputValues.reduce(TestaStrings,"Preenchido")==="Vazio")
                                StyledAlert("Preencha todos os campos");
                                else if(!files || files?.length<1)StyledAlert("Insira um arquivo!");
                                else 
                                {
                                    if(inputValues[4] && inputValues[4].search("_")!==-1)
                                        StyledAlert("Digite a data de nascimento inteira!");
                                    else
                                    {
                                        setShowLoader(true);
                                        ResolveFilesAsync(files).then(
                                            (result)=>
                                            {
                                                PutAtendimento({idAtendimento:props.userInfo.idAtendimento,nome:inputValues[0],sexo:inputValues[3],nomePai:inputValues[1],nomeMae:inputValues[2],dtNascimento:inputValues[4],listaAnexo:result},"dc").then(()=>{
                                                    StyledAlert("Atendimento enviado!");
                                                }).catch(()=>{
                                                    StyledAlert("Algo errado aconteceu! Contate nossa equipe de suporte se o erro persistir.");
                                                }).finally(()=>{
                                                    setShowLoader(false);
                                                });
                                            }
                                        ).catch((e)=>{
                                                StyledAlert("Algo errado aconteceu! Contate nossa equipe de suporte se o erro persistir.");
                                                setShowLoader(false);
                                        })
                                    }
                                }
                            }}>Re-enviar</ReSend>
                    </AtendimentoButtonSpace>
                </div>
            </div>
    )
}
const DCComponent : React.FC<{userInfo:{nome?:string,nomePai?:string,nomeMae?:string,dataNascimento?:string,sexo?:string,status:string,observacao?:string,idAtendimento:number, listaAnexos:{nome:string,guid:string}[], statusCode: string}}> = (props) : React.ReactElement =>{
    switch(props.userInfo.statusCode)
    {
        case "ea":
            return(
                <div>
                    <AtendimentoItensModificadosRow userInfo={props.userInfo}/>
                    <AtendimentoArquivosEnviadosRow listaAnexos={props.userInfo.listaAnexos}/>
                </div>)
        case "pd":
            return(<ReprovadoComponent userInfo={props.userInfo}/>)
        default:
            return(
                <div>
                    <AtendimentoItensModificadosRow userInfo={props.userInfo}/>
                    <AtendimentoArquivosEnviadosRow listaAnexos={props.userInfo.listaAnexos}/>
                </div>)
    }
}
const AFComponent : React.FC<{afInfo:{nome:string,numeroDocumento:string,telefone:string,email:string,uf:string,convenio?:string,outroConvenio?:string,valor:string,prazo:string}}> = (props) : React.ReactElement =>{
    const { afInfo } = props;
    const afInfoArray:{name:string,value:string}[] = [
        {name:"Nome",value:afInfo.nome},
        {name:"CPF",value:afInfo.numeroDocumento},
        {name:"Telefone Informado",value:afInfo.telefone},
        {name:"E-mail",value:afInfo.email},
        {name:"UF Informado",value:afInfo.uf},
        {name:"Convênio Escolhido",value:afInfo.convenio==="OUTROS"?afInfo.outroConvenio??"":afInfo.convenio??""},
        {name:"Valor",value:"R$ "+afInfo.valor},
        {name:"Prazo",value:afInfo.prazo+" Meses"}
    ]
    return (
        <AtendimentoRow height={"5.5rem"}>
            {
                afInfoArray.map((item:{name:string,value:string})=>{
                    return(item.value?
                        <AtendimentoItemSpace>
                            <InsideRowColumnName>{item.name} </InsideRowColumnName>
                            <InsideSelectedText>{item.value}</InsideSelectedText>
                        </AtendimentoItemSpace>:<></>
                    )
                })
            }
        </AtendimentoRow>
    )
}
const AVSComponent : React.FC<AVSAPIReturn> = (props) =>{
    const {
        dataCriacao,
        dataEvento,
        evento,
        relacionamento,
        nomeCompletoSegurado,
        dataNascimentoSegurado,
        listaStatusAtendimento,
    } = props;

    const {id} = (listaStatusAtendimento??[]).reduce(
        (prevVal,currentVal)=>(
            new Date(prevVal.dataCriacao) >= new Date(currentVal.dataCriacao) ? prevVal : currentVal
        )
    ).status

    const {globalContext:{
        PreUser
    }} = useContext(GlobalContext);
    const history = useHistory();
    const handleSinistroDetails = () => {
        history.replace(
            `/inicio/sinistro/detalheaviso`,
            {
                userType: "protocolo",
                data: props
            } as IStatePropsDetalhesSinistro
        );
    }
    return(
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
            <div style={{display:"flex",width:"96%",minHeight:"2.5em",alignItems:"center",marginTop:"4px"}}>
                
                <AtendimentoItemSpace width="30%">
                    {
                        nomeCompletoSegurado &&
                            <>
                            <InsideRowColumnName>Segurado(a)/Participante</InsideRowColumnName>
                            <InsideSelectedText>{nomeCompletoSegurado}</InsideSelectedText>
                        </>
                    }
                </AtendimentoItemSpace>
                
                <AtendimentoItemSpace width="20%">
                    {
                        dataNascimentoSegurado &&
                        <>
                            <InsideRowColumnName>Data de nascimento</InsideRowColumnName>
                            <InsideSelectedText>{new Date(dataNascimentoSegurado??"").toLocaleDateString("pt-br").slice(0,10)}</InsideSelectedText>
                        </>
                    }
                </AtendimentoItemSpace>
                
                <AtendimentoItemSpace width="18%">
                    {
                        evento?.nome &&
                        <>
                            <InsideRowColumnName>Evento</InsideRowColumnName>
                            <InsideSelectedText>{evento?.nome}</InsideSelectedText>
                        </>
                    }
                </AtendimentoItemSpace>
            
                <AtendimentoItemSpace width="30%">
                    {
                        dataEvento &&
                        <>
                            <InsideRowColumnName>Data do evento</InsideRowColumnName>
                            <InsideSelectedText>{new Date(dataEvento??"").toLocaleDateString("pt-br").slice(0,10)}</InsideSelectedText>
                        </>
                    }
                </AtendimentoItemSpace>
                
            </div>
            <div style={{display:"flex",width:"96%",minHeight:"2.5em",marginTop:"4px"}}>
                <AtendimentoItemSpace width="30%">
                    {
                        PreUser?.nome &&
                        <>
                            <InsideRowColumnName>Comunicante</InsideRowColumnName>
                            <InsideSelectedText>{PreUser?.nome}</InsideSelectedText>
                        </>
                    }
                </AtendimentoItemSpace>
                <AtendimentoItemSpace width="40%">
                    {
                        relacionamento?.nome &&
                        <>
                            <InsideRowColumnName>Grau de relacionamento com Segurado(a)/Participante</InsideRowColumnName>
                            <InsideSelectedText>{relacionamento?.nome}</InsideSelectedText>
                        </>
                    }
                </AtendimentoItemSpace>
                <AtendimentoItemSpace width="20%">
                    {
                        dataCriacao &&
                        <>
                            <InsideRowColumnName>Data da comunicação</InsideRowColumnName>
                            <InsideSelectedText>{new Date(dataCriacao ?? "").toLocaleDateString("pt-br").slice(0,10)}</InsideSelectedText>
                        </>
                    }
                </AtendimentoItemSpace>
                <InsideSelectedTextAlt onClick={handleSinistroDetails}>
                    {
                        id === 3?
                        'Ver detalhes de pendência'
                        :
                        'Ver detalhes'
                    }
                </InsideSelectedTextAlt>
            </div>
        </div>
    )
}