import styled from 'styled-components';
export const PageBackground = styled.div<{height?:string}>`
    height:${props=>props.height??"110vh"};
    width:100vw;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
`
export const PageHeader = styled.div`
    width:100%;
    height:45px;
    background-color:#E27800;
`
export const PageContent = styled.div<{height?:string,flexWrap?:string}>`
    flex-wrap:${props=>props.flexWrap??"nowrap"};
    height:${props=>props.height?? "88%"};
    width: 100;
    display:flex;
    flex-direction:column;
    align-items:center;
`
export const Title = styled.label`
    color: ${props => props.theme.cores.primaria};
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight:bold;
`
export const GreySpace = styled.div<{marginTop?:string}>`
     background-color: #dddddd;
     margin-top:${props=>props.marginTop??"2%"};
     height:18%;
     padding-top:1%;
     padding-bottom:1%;
     width:40%;
     padding-left:1.5%;
     padding-right:1.5%;
     border-radius:20px;
     display:flex;
     flex-direction:column;
     justify-content:space-between;
`
export const GreySpaceItem = styled.div`
    height:24%;
    display:flex;
    align-items:center;
`
export const ItemTitle = styled.label`
    font: Roboto 1rem;
    font-weight:bold;
    margin-bottom:0px;
    margin-right:2px;
`
export const ItemText = styled.label`
    margin-bottom:0px;
`
export const LineSpace = styled.div<{marginTop?:string,width?:string,height?:string,flexDirection?:string,justifyContent?:string}>`
    margin-top:${props=>props.marginTop??"1%"};
    width:${props=>props.width??"40%"};
    height:${props=>props.height??"15%"};
    flex-direction:${props=>props.flexDirection??"row"};
    display:flex;
    justify-content:${props=>props.justifyContent??"space-between"};
`
export const ButtonsSpace = styled.div`
    margin-top:1.5%;
    height:25%;
    width:40%;
    display:flex;
    flex-direction:row-reverse;
`
export const EnviarButton = styled.button`
    height:40px;
    width:25%;
    background-color:${props=>props.theme.cores.primaria};
    color:white;
    border:0px;
    border-radius:18px;
    ::after
    {
        outline:0px;
    }
    ::before
    {
        outline:0px;
    }
    :focus{
        outline:0px;
    }
`
export const CancelarButton = styled.button`
    height:40px;
    width:25%;
    background-color:#dddddd;
    border:0px;
    border-radius:18px;
    margin-right:2%;
    ::after
    {
        outline:0px;
    }
    ::before
    {
        outline:0px;
    }
    :focus{
        outline:0px;
    }
`