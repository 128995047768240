import React, { useState } from 'react';
import Noticia from '../../../components/Noticia';
import { ITip } from '../../../interfaces/components/ITips';
import PageModel from '../../../components/PageModel';
const AjudaSocial : React.FC = () : React.ReactElement =>
{// eslint-disable-next-line
    const [data,setData] = useState<ITip>({
        id:0,
        titulo:"Lar Fabiano de Cristo -  Proteção social e educação transformadora",
        subtitulo:"",
        texto:"<p>A CAPEMISA é um caso raro de Empresa criada para gerar recursos para a manutenção de obras sociais, entre elas o Lar Fabiano de Cristo. Reconhecido internacionalmente pela UNESCO o projeto tem como objetivo a proteção social e a educação transformadora. Anualmente, mais de 25 mil pessoas são beneficiadas por essa obra. Construir um mundo melhor também é o nosso trabalho.</p><p> O Lar Fabiano de Cristo também tem o projeto UM GRÃO, que por meio da de captação de recursos complementares pela adesão de pessoas sensíveis às causas sociais, é possivel realizar doações mensais e, com isso, viabilizar projetos que contribuem para a garantia dos direitos sociais de pessoas que se encontram em situação de vulnerabilidade e/ou risco, inserindo-os na rede de serviços de proteção social oferecida pela Instituição.Quer fazer parte dessa iniciativa?</p><p>Entre <b><a href='https://www.umgrao.com.br/' target='_blank'>aqui</a></b> e saiba mais!</p>",
        dataCriacao:"2020-06-22T03:00:00+00:00",
        urlImagemDestaque:"https://capdevstportalrelaciona.blob.core.windows.net/portalrelacionamento/imagem/Imagem%20Horizontal.jpg",
        urlImagemHome:"",
        urlImagemLateral:""});
    return(
        <PageModel>
            <Noticia data={data}/>
        </PageModel>
    )
}
export default AjudaSocial;