import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { Coluna, Linha } from '../../../components/UserForm/styles';
import { AllPageSpace, ButtonSpace, CancelButton, ConfirmButton, Description, HeaderTextSpace, OrangeStar, PageSpace, Title } from './style';
import { TextInput, TextInputEspecific } from '../../../components/TextInput';
import PageModel from '../../../components/PageModel';
import StyledAlert from '../../../components/StyledAlert';
import { GlobalContext } from '../../../storage/contexts/GlobalContext';
import { PatchUserContatos } from '../../../API/usuario';
import GetDadosByCep from '../../../API/cep';
import { IQueryStatus } from '../../../interfaces/services/IQuery';
import LoaderRotator, { LoaderRotatorMessage, LoaderRotatorModal } from '../../../components/Loaders/LoaderRotator';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Redirect } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          '& > *': {
            margin: theme.spacing(1),
          },
        },
        large: {
          width: theme.spacing(18),
          height: theme.spacing(18),
        },
      }));
const PerfilConfirmaDadosSegurado : React.FC<{}> = () =>{
    type EtapaValidacaoItem = {Componente:React.ReactElement,Titulo:string,Query:()=>Promise<boolean>};
    const {globalContext,setGlobalContext} = useContext(GlobalContext); 
    const {PreUser} = globalContext;
    const classes = useStyles();
    const [nome, setNome] = useState<string>(PreUser?.nome ?? "");
    const [dataNascimento, setDataNascimento] = useState<string>(PreUser?.dataNascimento ? new Date(PreUser?.dataNascimento).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11) : "");
    console.log(dataNascimento);
    const [genero, setGenero] = useState<string>(PreUser?.sexo ?? "");
    const [nomePai, setNomePai] = useState<string>(PreUser?.nomePai ?? "");
    const [nomeMae, setNomeMae] = useState<string>(PreUser?.nomeMae ?? "");
    const [endereco,setEndereco] = useState<string>(PreUser?.endereco ?? "");
    const [uf,setUf] = useState<string>(PreUser?.uf ?? "");
    const [cidade,setCidade] = useState<string>(PreUser?.cidade ?? "");
    const [bairro,setBairro] = useState<string>(PreUser?.bairro ?? "");
    const [cep,setCep] = useState<string>(PreUser?.cep??"");
    const [numeroEndereco,setNumeroEndereco] = useState<string>(PreUser?.numeroEndereco ?? "");
    const [complemento,setComplemento] = useState<string>(PreUser?.complemento ?? "");
    const [allEstados,setAllEstados] = useState<string[]>([]);
    const [etapaIndex,setEtapaIndex] = useState<number>(0);
    const [cepQueryStatus,setCepQueryStatus] = useState<IQueryStatus>({status:"NOTINITIALIZED"});
    const [email,setEmail] = useState<string>(PreUser?.email??"")
    const [telefoneFixo,setTelefoneFixo] = useState<string>(((PreUser?.dddTelefone2 ?? "").toString()+(PreUser?.telefone2??"").toString()));
    const [telefoneCelular,setTelefoneCelular] = useState<string>(((PreUser?.dddTelefone1 ?? "00").toString()+(PreUser?.telefone1 ??"").toString()));
    const [telefoneAdicional,setTelefoneAdicional] = useState<string>(((PreUser?.dddTelefone3 ?? "").toString()+(PreUser?.telefone3 ??"").toString()));
    const [isToAddTelefone3,setIsToAddTelefone3] = useState<boolean>(typeof(PreUser?.dddTelefone3)==="string" || typeof(PreUser?.telefone3)==="string");
    const [isToShowModal,setIsToShowModal] = useState<boolean>(false);
    const isComunicante = useMemo(()=>PreUser?.isComunicante && (!PreUser?.isSegurado) && (!PreUser?.isBeneficiario),[PreUser]);
    function BloquearCaractereEspecial(valor:string)
    {
        const regexSemCaractereEspecial =  /^[a-zA-Z0-9 ]+$/;
        return regexSemCaractereEspecial.test(valor) || valor==="";
    }
    useEffect(()=>{
        setAllEstados(["AC","AL","AP","AM","BA","CE","ES","GO","MA","MT","MS","MG","PA","PB","PR","PE","PI","RJ","RN","RS","RO","RR","SC","SP","SE","TO","DF"]);
    },[])
    const RedirectComponent = useCallback(()=>{
        window.location.pathname="/load";
        return(
            <LoaderRotatorMessage/>
        )
    },[])
    const etapas = useMemo<EtapaValidacaoItem[]>(()=>{
        return([
            {
                Componente:<>
                    <Linha>
                        <Avatar className={classes.large}></Avatar>
                        <Coluna>
                            <Linha>
                                <TextInputEspecific disabled={!isComunicante} obligatory={isComunicante} onChange={(e:any)=>{setNome(e.target.value)}} show margin rotulo="Nome" valor={nome}/> 
                            </Linha>
                            <Linha>
                                <TextInput disabled={!isComunicante} obligatory={isComunicante} onChange={(e:any)=>{setDataNascimento(e.target.value)}} type={"date"} margin rotulo="Data de nascimento" valor={dataNascimento}/>  
                                <TextInput disabled={!isComunicante} obligatory={isComunicante} type={"combotextvalue"} options={[{text:"Feminino",value:"F"},{text:"Masculino",value:"M"}]} placeHolder={"Indefinido"} valor={genero} onChange={(e:any)=>{setGenero(e.target.value)}} rotulo="Gênero" margin /> 
                            </Linha> 
                        </Coluna>
                    </Linha>
                    <div style={{position:"relative",bottom:"14px",width:"98%"}}>
                        <Linha>
                            <TextInputEspecific show margin rotulo="CPF" valor={PreUser?.cpf}/>
                        </Linha> 
                    </div>
                    {/* <Linha>
                        {
                            !isComunicante &&
                            <>
                                <TextInputEspecific  disabled={!isComunicante} obligatory={isComunicante} onChange={(e:any)=>{setNomeMae(e.target.value)}} show margin rotulo="Nome da mãe" valor={nomeMae}/>  
                                <TextInputEspecific  disabled={!isComunicante} obligatory={isComunicante} onChange={(e:any)=>{setNomePai(e.target.value)}} show margin rotulo="Nome do pai" valor={nomePai}/> 
                            </>
                        }
                    </Linha> */}
                </>,
                Titulo:"Dados Pessoais",
                Query:async ()=>{
                    let isOk : boolean = true;
                    const CallAlertAndStop : (text:string)=>void = (text) =>{
                        StyledAlert(text);
                        isOk=false;
                    }
                    if(isComunicante)
                    {
                        if(!nome)
                            CallAlertAndStop("O nome é obrigatório!");
                        const DataTemp : Date = new Date(dataNascimento.slice(3,5)+"/"+dataNascimento.slice(0,2)+"/"+dataNascimento.slice(6,10));
                        if(dataNascimento.length<=0 || Object.prototype.toString.call(DataTemp) !== "[object Date]" || isNaN(DataTemp.getTime()))
                            CallAlertAndStop("Data de nascimento inválida!");
                        if(!genero)
                            CallAlertAndStop("Você precisa escolher uma das opções de generos disponíveis!")
                    }
                    return isOk;
                },
            },
            {
                Componente:<>
                    <Linha>
                        <TextInputEspecific obligatory show margin disabled={false} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setEndereco(e.target.value)}} rotulo="Endereço" valor={endereco}/>
                    </Linha>
                    
                    <Linha>
                        <TextInputEspecific obligatory show margin options={allEstados} disabled={false} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setUf(e.target.value)}} rotulo="UF" valor={uf.replace(" ","")}/>
                        <div style={{display:"flex",width:"100%",alignItems:"flex-end"}}>
                            <TextInputEspecific obligatory show margin disabled={false} onChange={(e:any)=>{
                                setCep(e.target.value)
                                if(e.target.value.search("_")===-1 && e.target.value.length>0)
                                {
                                    GetDadosByCep(e.target.value.replace("-",""),setCepQueryStatus).then((response)=>{
                                        const dadosCEP : {bairro:string,logradouro:string,municipio:string,uf:string,tipoLogradouro:string} = response.data;
                                        setBairro(dadosCEP.bairro.replace(/[^a-zA-Z0-9 ]/g, ""));
                                        setEndereco(dadosCEP.tipoLogradouro + " " + dadosCEP.logradouro.replace(/[^a-zA-Z0-9 ]/g, ""));
                                        setCidade(dadosCEP.municipio.replace(/[^a-zA-Z0-9 ]/g, ""));
                                        setUf(dadosCEP.uf);
                                    }) 
                                }
                            }} rotulo="CEP" valor={cep}/>
                            {
                                cepQueryStatus.status==="PENDING"?
                                <div style={{marginBottom:"10px"}}>
                                  <LoaderRotator/>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </Linha>

                    <Linha>
                        <TextInputEspecific obligatory show margin disabled={false} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setCidade(e.target.value)}} rotulo="Cidade" valor={cidade}/>
                        <TextInputEspecific obligatory show margin disabled={false} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setBairro(e.target.value)}} rotulo="Bairro" valor={bairro}/>
                    </Linha>

                    <Linha>
                        <TextInput type="textoNumero" show margin disabled={false} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setNumeroEndereco(e.target.value)}} rotulo="Número do endereço" valor={numeroEndereco}/>
                        <TextInputEspecific show margin disabled={false} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setComplemento(e.target.value)}} rotulo="Complemento" valor={complemento}/>
                    </Linha>
                </>,
                Titulo:"Endereço",
                Query:async ()=>{
                    let isOk : boolean = true;
                    const CallAlertAndStop : (text:string)=>void = (text) =>{
                        StyledAlert(text);
                        isOk=false;
                    }
                    if(!endereco)
                        CallAlertAndStop("Endereço inválido!");
                    if(!uf)
                        CallAlertAndStop("Estado inválido!");
                    if(cep.replace(/-|_/g,"").length<8)
                        CallAlertAndStop("Cep inválido!");
                    if(!cidade)
                        CallAlertAndStop("Cidade inválida!");
                    if(!bairro)
                        CallAlertAndStop("Bairro inválido!");
                    return isOk;
                }
            },
            {
                Titulo:"Contato",
                Componente:<>
                    <Linha>
                        <TextInputEspecific obligatory margin disabled={false} onChange={(e:any)=>{setEmail(e.target.value)}} show rotulo="E-mail" valor={email}/> 
                    </Linha>
                    <Linha>
                        <TextInputEspecific obligatory margin disabled={false} onChange={(e:any)=>{setTelefoneCelular(e.target.value)}} show rotulo="Telefone 1" valor={telefoneCelular}/>  
                        <TextInputEspecific margin disabled={false} onChange={(e:any)=>{setTelefoneFixo(e.target.value)}} show rotulo="Telefone 2" valor={telefoneFixo}/>
                    </Linha>
                    {
                        !isToAddTelefone3?
                        <Linha>
                            <div style={{fontSize:"15px",color:"#ed7203",display:"flex",alignItems:"center",cursor:"pointer",}} onClick={()=>{setIsToAddTelefone3(!isToAddTelefone3)}}>
                                <Icon iconName="CircleAddition" />
                                <label style={{marginBottom:"0px",marginLeft:"4px",cursor:"pointer"}}> Adicionar mais um telefone</label>
                            </div>
                        </Linha>
                        :
                        <>
                            <Linha>
                                <TextInputEspecific width="46%" margin disabled={false} onChange={(e:any)=>{setTelefoneAdicional(e.target.value)}} show={isToAddTelefone3} rotulo="Telefone 3" valor={telefoneAdicional}/>
                            </Linha>
                            <Linha>
                                <div style={{fontSize:"15px",color:"#ed7203",display:"flex",alignItems:"center",cursor:"pointer",}} onClick={()=>{setIsToAddTelefone3(!isToAddTelefone3)}}>
                                    <Icon iconName="CircleAddition" />
                                    <label style={{marginBottom:"0px",marginLeft:"4px",cursor:"pointer"}}> Não adicionar mais um telefone</label>
                                </div>
                            </Linha>
                        </>
                    }
                    <LoaderRotatorModal show={isToShowModal}/>
                </>,
                Query:async ()=>{
                    const CheckDataIsValid = () =>{
                        let isOk : boolean = true;
                        const CallAlertAndStop : (text:string)=>void = (text) =>{
                            StyledAlert(text);
                            isOk=false;
                        }
                        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)))
                        CallAlertAndStop("Email Inválido");
                        
                        if(((!PreUser?.isSegurado) && (PreUser?.isBeneficiario || PreUser?.isComunicante)) && !email)
                            CallAlertAndStop("O e-mail é obrigatório!");
                        if((telefoneAdicional.replace(/-|\(|\)/g,"").length>2 && isToAddTelefone3?(telefoneAdicional.replace(/-|\(|\)/g,"").slice(0,2).toString()==="00"):false)||(telefoneFixo.replace(/-|\(|\)/g,"").length>2?(telefoneFixo.replace(/-|\(|\)/g,"").slice(0,2).toString()==="00"):false) || (telefoneCelular.replace(/-|\(|\)/g,"").length>2?(telefoneCelular.replace(/-|\(|\)/g,"").slice(0,2).toString()==="00") : false))
                            CallAlertAndStop("O DDD não pode ser 00!")
                        if(telefoneCelular.toString().replace(/\(|\)|-|_/g,"").length<10)
                            CallAlertAndStop("O telefone 1 é necessário");
                        if(!(telefoneFixo.toString().replace(/\(|\)|-|_/g,"").length===2||telefoneFixo.toString().replace(/\(|\)|-|_/g,"").length===0||telefoneFixo.toString().replace(/\(|\)|-|_/g,"").length>=10))
                            CallAlertAndStop("Se for digitar o telefone 2 o digite completo!");
                        if(isToAddTelefone3 && !(telefoneAdicional.toString().replace(/\(|\)|-|_/g,"").length===2||telefoneAdicional.toString().replace(/\(|\)|-|_/g,"").length===0||telefoneAdicional.toString().replace(/\(|\)|-|_/g,"").length>=10))
                            CallAlertAndStop("Se for digitar o telefone 3 o digite completo!")
                        return isOk;
                    }
                    if(CheckDataIsValid())
                    {
                        try{
                            setIsToShowModal(true);
                            await PatchUserContatos(telefoneFixo.replace(/_|-|\(|\)/g,"").slice(2,telefoneFixo.length),telefoneCelular.replace(/_|-|\(|\)/g,"").slice(2,telefoneCelular.length),email,telefoneFixo.replace(/_|-|\(|\)/g,"").slice(0,2),telefoneCelular.replace(/_|-|\(|\)/g,"").slice(0,2),cep.replace(/-|_/g,""),uf,cidade,bairro,endereco,numeroEndereco,complemento,isToAddTelefone3?telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(2,telefoneAdicional.length):undefined,isToAddTelefone3?telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(0,2):undefined,nome,dataNascimento,genero,nomePai,nomeMae);
                            setGlobalContext(
                                {
                                    ...globalContext,
                                    PreUser:{
                                        ...globalContext.PreUser,
                                        ...{
                                            nome,
                                            nomeMae,
                                            nomePai,
                                            dataNascimento,
                                            sexo:genero,
                                            dddTelefone1:telefoneCelular.replace(/_|-|\(|\)/g,"").slice(0,2),
                                            dddTelefone2:telefoneFixo.replace(/_|-|\(|\)/g,"").slice(0,2),
                                            "telefone1":telefoneCelular.replace(/_|-|\(|\)/g,"").slice(2,telefoneCelular.length),
                                            "telefone2":telefoneFixo.replace(/_|-|\(|\)/g,"").slice(2,telefoneFixo.length),
                                            "telefone3":isToAddTelefone3?telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(2,telefoneAdicional.length):undefined,
                                            "dddTelefone3":isToAddTelefone3?telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(0,2):undefined,
                                            "email":email,
                                            "cep":cep.replace(/-|_/g,""),
                                            "uf":uf,
                                            "cidade":cidade,
                                            "bairro":bairro,
                                            "endereco":endereco,
                                            "numeroEndereco":numeroEndereco,
                                            "complemento":complemento
                                        },
                                    },
                                }
                            );
                            return true;
                        }
                        catch{
                            return false;
                        }
                        finally{
                            setIsToShowModal(false);
                        }
                    }
                    return false;
                }
            },
            {
                Titulo:"Contato",
                Componente:<RedirectComponent/>,
                Query:async ()=>false
            }
        ]);
    },[classes.large,PreUser,allEstados,bairro,cep,cidade,complemento,endereco,numeroEndereco,uf,cepQueryStatus.status,email,isToAddTelefone3,telefoneAdicional,telefoneCelular,telefoneFixo,globalContext,setGlobalContext,isToShowModal,dataNascimento,genero,nome,nomeMae,nomePai,isComunicante])
    const [etapaSelecionada,setEtapaSelecionada] = useState<EtapaValidacaoItem>(etapas[etapaIndex]);
    useEffect(()=>{
        setEtapaSelecionada(etapas[etapaIndex]);
    },[etapaIndex,etapas])
    return(
        <AllPageSpace>
            <PageModel titulo="CONFIRME SEUS DADOS">
                <PageSpace>
                    <HeaderTextSpace>
                        <Title>{etapaSelecionada.Titulo}</Title>
                        <Description>
                        <OrangeStar>*</OrangeStar> Para os Seguros Empresariais / Coletivos,
                        sugerimos que ao realizar qualquer alteração cadastral no Meu Portal CAPEMISA,
                        também atualize os seus dados junto ao responsável na sua Empresa / Estipulante.
                        </Description>
                    </HeaderTextSpace>
                    {
                        etapaSelecionada.Componente
                    }
                    <ButtonSpace>
                        <ConfirmButton
                            onClick={
                                ()=>{
                                    etapaSelecionada.Query().then(
                                        (response)=>{
                                            if(response && etapaIndex+1<etapas.length){
                                                setEtapaIndex(etapaIndex+1)
                                            }
                                        }
                                    )
                                }}>Confirmar</ConfirmButton>
                        <CancelButton onClick={()=>{if(etapaIndex>=1){setEtapaIndex(etapaIndex-1)}}}>Cancelar</CancelButton>
                    </ButtonSpace>
                </PageSpace>
            </PageModel>
        </AllPageSpace>
    )
}
export default PerfilConfirmaDadosSegurado;