import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCircle,faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {LoaderSpace,IcoToRotate, LoaderErrorSpace} from './styles';
import Modal from 'react-bootstrap/Modal';
type IDotsConfig= {fontSize?:string | number,show?:boolean,message?:string, width?: string | number }
const LoaderDots: React.FC<IDotsConfig> = (props): React.ReactElement => {
    const {fontSize,message,width,show}=props;
    if(typeof show == "undefined" || (typeof show == "boolean" && show))
    {
    return(
        <LoaderSpace width={width ?? "100%"}>
            <label>{message?message:'Carregando Informações do servidor'} </label>
            <div className="lds-ellipsis" >
                <div><IcoToRotate fontSize={fontSize ?? "1rem"} icon={faCircle}/></div>
                <div><IcoToRotate fontSize={fontSize ?? "1rem"} icon={faCircle}/></div>
                <div><IcoToRotate fontSize={fontSize ?? "1rem"} icon={faCircle}/></div>
                <div><IcoToRotate fontSize={fontSize ?? "1rem"} icon={faCircle}/></div>
            </div>
        </LoaderSpace>
    );
    }
    else
    {
        return (<div></div>)
    }
}
export const LoaderError: React.FC<{erro:string|{message:string}, extraStyle?:string}> = (props): React.ReactElement =>{
    const {erro,extraStyle} = props;
    return(
        <LoaderErrorSpace extraStyle={extraStyle}>
            <label>{(typeof(erro)==="string")?erro:(erro.message??"Ocorreu um erro! Tente recarregar a página.")}</label>
            <FontAwesomeIcon style={{fontSize:"3rem"}} icon={faExclamationTriangle}/>
        </LoaderErrorSpace>
    )
}
export const LoaderModal: React.FC<IDotsConfig> = (props):React.ReactElement =>{
    const {fontSize,message,width,show}=props;
    return(
        <Modal size="sm" show={show ?? true} backdrop={"static"} centered>
            <LoaderDots width={width ?? "300px"}fontSize={fontSize??"100%"} message={message}/>
        </Modal>
    )
}
export default LoaderDots;