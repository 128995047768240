import styled from 'styled-components';
import MaskedInput from 'react-maskedinput';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const RoundedComponentSpace = styled.div`
    height:7rem;
    width: 100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-top:2%;
`;
export const AtendimentoItemSpace = styled.div<{width?:string}>`
    display:flex;
    flex-direction:column;
    margin-right:2%;
    width:${props=>props.width??'100%'};
`
export const AtendimentoButtonSpace = styled.div`
    display:flex;
    flex-direction:column;
    margin-right:2%;
    align-items:flex-end;
    width:15%;
`;
export const InsideRowColumnName = styled.label`
    text-align: left;
    font: 80% Roboto;
    letter-spacing: -0.34px;
    color: #B9B9B9;
    opacity: 1;
    margin-bottom:0px;
`
export const InsideRowColumnNameRed = styled.label`
    text-align: left;
    font: 80% Roboto;
    letter-spacing: -0.34px;
    color: #FF0000;
    opacity: 1;
    margin-bottom:0px;
`
export const InsideSelectedText = styled.label`
    margin-bottom:0px;
    text-align: left;
    font: 90% Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
`
export const InsideSelectedTextAlt = styled.label`
    width:10%;
    cursor: pointer;
    margin-bottom:0px;
    text-align: left;
    font: 90% Roboto;
    letter-spacing: -0.38px;
    color: #E27800;
    opacity: 1;
`
export const InsideReprovadoInput = styled.input`
    text-align: left;
    font: 90% Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
    border-color:#FF0000;
    border-left:#FF0000 2px solid!important;
    border-top:#FF0000 2px solid!important;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const InsideMaskedReprovadoInput = styled(MaskedInput)`
    text-align: left;
    font: 90% Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
    border-color:#FF0000;
    border-left:#FF0000 2px solid!important;
    border-top:#FF0000 2px solid!important;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const InsideReprovadoSelect = styled.select`
    text-align: left;
    font: 90% Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
    border-color:#FF0000;
    border-left:#FF0000 2px solid!important;
    border-top:#FF0000 2px solid!important;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const ReSend = styled.button`
    background-color:#E27800;
    border-color:#E27800!important;
    width:100%;
    border-radius: 35px;
    opacity: 1;
    text-align: center;
    font: Bold Roboto;
    font-size:1rem;
    letter-spacing: -0.46px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    border-bottom: #E27800!important;
    border-right: #E27800!important;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`;
export const IcoPDF = styled(FontAwesomeIcon)`
    color:#E27800;
    font-Size: 0.7rem;
    align-Self:center;
    cursor:pointer;
`
export const PDFText = styled.label`
    font-size:60%;
    color:#636363;
    margin-bottom:0px;
    cursor:pointer;
`
export const AtendimentoRow = styled.div<{height?:string}>`
    display:flex;
    margin-left:2%;
    height:${props=>props.height??"2.5rem"};
    align-items:center;
`