import styled from 'styled-components';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import "../../../styles/fonts/roboto/index.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const DashedAdd = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:24%;
    height:95%;
    margin-left:1%;
    border:2px solid #898989;
    border-style:dashed;
    min-height:120px;
    max-height:220px;
    border-top-left-radius:22px 20px;
    border-top-right-radius:22px 20px;
    border-bottom-left-radius:22px 20px;
    border-bottom-right-radius:22px 20px;
`;
export const AddIcon = styled(Icon)`
    font-size:42px;
    color:#b9b9b9;
    padding-left:30%;
    padding-right:30%;
`;
export const AddText = styled.p`
    color:#898989;
    font-size:14px;
`;
export const ColumnModal = styled.div<{ width: string | number }>`
    width:${props => props.width};
`;
export const Linha = styled.hr`
    margin:0px;
    background-color:#E27800;
`;
export const SetFavorites = styled.button`
    background-color:#E27800;
    border-color:#E27800!important;
    width:100%;
    border-radius: 35px;
    opacity: 1;
    text-align: center;
    font: Bold Roboto;
    font-size:1rem;
    letter-spacing: -0.46px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    border-bottom: #E27800!important;
    border-right: #E27800!important;
    ::after ::before :focus{
        outline:0px;
    };
`;
export const Page = styled.div`
    @media screen and (min-height: 580px)
    {
        height:calc(100vh - 75px);
    }
    @media screen and (max-height: 579px)
    {
        height:580px;
    }
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
`;
export const PageContent = styled.div`
    display:flex;
    width:85%;
    height:95%;
    margin-top:1%;
    flex-direction:column;
    min-height:470px;
`;
export const BeforePage = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    height:25%;
    min-height:120px;
`;
export const ReceptionText = styled.label`
    color:#E27800;
    font-size:160%;
    margin-bottom:0%;
    text-align: left;
    font: Bold 24px/30px Roboto;
    letter-spacing: -0.84px;
    text-transform: uppercase;
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
`;
export const RoundedSpace = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:2%;
    justify-content:space-between;
    height:60%;
    min-height:350px;
`;
export const RoundedColumn = styled.div`
    height:48%;
    width:100%;
    min-height:120px;
    min-width:800px;
    display:flex;
    flex-direction:row;
`;
export const SelectedRoundedComponentRendered = styled.div`
    width:24%;
    height:95%;
    margin-left:1%;
    border: 1px solid #B9B9B9;
    border-radius: 23px;
    opacity: 1;
`;
export const ReturnIcoPlace = styled.div`
    display:flex;
    flex-direction:row-reverse;
    justify-content:space-between;
    margin-right:6%;
    margin-top:1%;
`;
export const SelectedRoundBody = styled.div`
    height:60%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-around;
`;
export const SelectedRoundedIcon= styled(Icon)`
    border:0px;
    background-color:inherit;
    cursor:pointer;
    font-size:250%;
`;
export const SelectedRoundedIconFA = styled(FontAwesomeIcon)`
    border:0px;
    background-color:inherit;
    cursor:pointer;
    font-size:250%;
`;
export const UserMessage = styled.label`
    text-align: left;
    font: Roboto 16px/24px Regular;
    letter-spacing: -0.32px;
    color: #636363;
    opacity: 1;
    width: 50%;
    margin-right: 15px;
    overflow-y:auto;
    min-width:300px;
    margin-bottom:0px;
`;