import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const SelectedRoundedRow = styled.div<{especificStyle?:string,isClickable?:boolean,minHeight?:string}>`
    margin-top:0.4%;
    border:1px solid #B9B9B9;
    border-radius:10px;
    min-height:${props=>props.minHeight??"40px"};
    height:max-content;
    display:flex;
    flex-direction:column;
    min-width:500px;
    cursor: ${props=>props.isClickable?"pointer":"auto"};
    transition:height 2s;
    max-width: 1400px;
    ${props=>props.especificStyle};
`;
export const IcoPDF = styled(FontAwesomeIcon)`
    color:#E27800;
    font-Size: 0.7rem;
    align-Self:center;
    margin-Left:4px;
    cursor:pointer;
`
export const AlignSpace = styled.div`
    display:flex;
    align-items:center;
    margin-right:4px;
`
export const TitleContainer = styled.div<{ width: string | number, textStyle?:string }>`
    width:${props => props?.width ?? "20%" };
    display:flex;
    justify-content:flex-start;
    align-items:center;
    margin-left:1%;
    ${props=>props.textStyle};
`;
export const SelectedRowTitle = styled.label<{color?:string,capitalize?:boolean,isPointer?:boolean}>`
    text-align: left;
    font: Bold 1.2em Roboto;
    letter-spacing: -0.38px;
    color: ${props => props?.color ?? "#636363" };
    opacity: 1;
    margin-bottom:0px;
    text-transform:${props=>props.capitalize?"capitalize":"none"};
    cursor:${props=>props.isPointer?"pointer":"auto"};
    width:100%;
`
export const SizedText = styled.label<{capitalize?:boolean,isPointer?:boolean}>`
    font-size:0.9em;
    color:#636363;
    text-align:left;
    margin-bottom: 0px;
    margin-left:3px;
    text-transform:${props=>props.capitalize?"capitalize":"none"};
    cursor:${props=>props.isPointer?"pointer":"auto"};
    width:100%;
`;
export const TopSelectedRoundedRow = styled.div<{height?:string}>`
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    display:flex;
    width:100%;
    align-items:center;
    min-height:${props=>props.height??"40px"};
    height:max-content;
    justify-content:space-between;
    text-transform:capitalize;
`
export const SubRoundedRow = styled.div<{height?:string}>`
    width:100%;
    flex-direction:row;
    justify-content:space-between;
    display:flex;
    align-items:center;
    min-height:${props=>props.height??"40px"};
    height:max-content;
    text-transform:capitalize;
`