import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageSpace, TextSuspended, TopRounded, InsideRowColumnName, InsideSelectedText, TopRoundedRow, TopRoundedFooter, SingleLine, AlignSpace, RowsSpacer, NavigationButtonsSpace, BtnLaranja, BtnCinza, Icon } from './style';
import SinistroPageModel from '../../../components/SinistroComponents/SinistroNewPageModel';
import { SinistroContext } from '../../../storage/contexts/SinistroContext';
import { IEventoDocumento } from '../../../interfaces/storage/contexts/ISinistroContext';
import { LoaderError } from '../../../components/Loaders/LoaderDots';
import {LoaderRotatorMessage, LoaderRotatorModal} from '../../../components/Loaders/LoaderRotator';
import { GlobalContext } from '../../../storage/contexts/GlobalContext';
import StyledAlert from '../../../components/StyledAlert';
import { UploadRow, UploadRowComponent } from '../../../components/DragAndDropUpload';
import useAPI from '../../../storage/custom_hooks/API';
import { GetDocumentosSinistro, PostAtendimentoSinistro } from '../../../services/APICallableActions/sinistro';
import { useCustomDispatch, useTypedSelector } from '../../../storage/ducks';
import { ATUALIZARROW, ESVAZIARSTATE, POPULARSTATE } from '../../../storage/ducks/EnviarDocumentos';
import {IAnexoSinistroAtendimento, IAtendimentoSinistroData} from '../../../interfaces/pages/IEnviarDocumentos';
import { SelectedRowTitle } from '../../../components/CustomDropdown/style';
import { TextInput } from '../../../components/TextInput';
import { EnviarArquivoIndexadoAsync, ITagsAnexo } from '../../../API/file';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const numeroCaracteresObservacao = 1000;
const validarObservacao = (obsField: string) => {
    if(obsField.length <= numeroCaracteresObservacao)
        return true;
    else{
        StyledAlert("Insira no máximo " + numeroCaracteresObservacao + " caracteres");
        return false;
    }
    
}

const EnviarDocumentos :React.FC<{}> = (): React.ReactElement =>{
    const {sinistroContext} = useContext(SinistroContext);
    const {globalContext} = useContext(GlobalContext);
    const [documentosToSend,initDocumentosQuery] = useAPI<IEventoDocumento[]>(GetDocumentosSinistro(sinistroContext.codigoEvento? (Number(sinistroContext.codigoEvento)):undefined))
    const [observacao,setObservacao] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useHistory();
    const rows = useTypedSelector((selector)=>selector.EnviarDocumentos);
    const dispatch = useCustomDispatch();

    const ReplacePositionArray : (rowAtualizada:UploadRow)=>void = useCallback((rowAtualizada:UploadRow)=>{
        dispatch(ATUALIZARROW(rowAtualizada));
    },[dispatch]);

    const {
        informacoesSegurado,
        codigoEvento,
        dataEvento,
        grauRelacionamentoComunicante,
        formularioSinistroGeral,
        global,
    } = sinistroContext;

    const {
        cpfSegurado,
        dataNascimentoSegurado,
        primeiroNomeSegurado,
        nomeCompletoParticipanteSegurado,
    } = (informacoesSegurado ?? {
        cpfSegurado: '',
        dataNascimentoSegurado: '',
        primeiroNomeSegurado: '',
        nomeCompletoParticipanteSegurado: '',
    });

    const {
        cnpj,
        empregador,
        infoEvento,
        infoLoja,
        infoOnibus,
        infoBilhete,
        relato,
    } = formularioSinistroGeral ?? {
        acidenteEvento:"",
        acidenteLoja:"",
        acidenteOnibus:"",
        cnpj:"",
        contratante:"",
        empregador:"",
        infoEvento:"",
        infoLoja:"",
        infoOnibus:"",
        infoBilhete:"",
        relato:"",
    };

    const FixDate = useCallback((date?:string)=>{
        const fixedData = date ?? new Date().toLocaleDateString("pt-br").slice(0,10);
        return (fixedData.slice(6,10)+"-"+fixedData.slice(3,5)+"-"+fixedData.slice(0,2))
    },[]);
    const arquivoTags = useMemo<any>(() => {
        return {
            nome: nomeCompletoParticipanteSegurado,
        }
    }, [])
    const dadosAtendimento = useMemo<IAtendimentoSinistroData>(() : IAtendimentoSinistroData =>{
        return{
            codigoEventoSinistro: codigoEvento??0,
            cpfSegurado: cpfSegurado.replace(/-|\./g,"")??"",
            dataEvento: FixDate(dataEvento),
            codigoRelacionamento: Number(grauRelacionamentoComunicante?.id??"0"),
            observacao,
            listaAnexo: rows.flatMap((row):IAnexoSinistroAtendimento[]=>{
                return(
                    row.fileAPIReferences?.map((apiReference):IAnexoSinistroAtendimento=>{
                        return{
                            codDocCapemisa:row.tipoDocumento,
                            isObrigatorioDocCapemisa:row.obligatory??false,
                            nomeDocCapemisa:row.nomeColuna,
                            guid:apiReference.guid,
                            nome:apiReference.nome
                        }
                    })??[]
                )
            }),
            global: !!global,
            nomeBuscaSegurado: primeiroNomeSegurado,
            dataNascimentoSegurado: FixDate(dataNascimentoSegurado),
            nomeCompletoSegurado: nomeCompletoParticipanteSegurado ?? "",
            nomeEmpresaEmpregador: empregador ?? "",
            cnpjEmpresaEmpregador: cnpj ?? "",
            nomeEmpresaTransporte: infoOnibus ?? "",
            numeroBilhete: infoBilhete ?? "",
            nomeLojaVarejo: infoLoja ?? "",
            nomeInstituicaoEnsino: infoEvento ?? "",
            relato: relato ?? "",
        }
    },[observacao,rows,codigoEvento,grauRelacionamentoComunicante,informacoesSegurado,dataEvento,FixDate])
    // eslint-disable-next-line
    const [atendimentoQuery,startAtendimentoQuery,disposeAtendimento] = useAPI(PostAtendimentoSinistro(dadosAtendimento));
    useEffect(()=>{
        console.log(sinistroContext);
        if(sinistroContext.codigoEvento)
        {
            dispatch(ESVAZIARSTATE())
            initDocumentosQuery();
        }
        // eslint-disable-next-line
    },[sinistroContext.codigoEvento])
    useEffect(()=>{
        if(documentosToSend.status==="COMPLETED")
        {
            dispatch(POPULARSTATE(documentosToSend.data?.map((item) : UploadRow=>{
                return{
                    files:[],
                    nomeColuna:item.descDocComunicadoTela,
                    status:"editable",
                    tipoDocumento:item.codDocumentoComunicado,
                    obligatory:item.isDocObrigatorio
                }
            })??[]))
        }
        // eslint-disable-next-line
    },[documentosToSend.status])

    useEffect(()=> {
        if(atendimentoQuery.status === "COMPLETED")
        {
            history.push("/inicio/sinistro/confirmacaodocumentos");
            setIsLoading(false);
        }
        else if(atendimentoQuery.status === "FAILED")
        {
            StyledAlert(atendimentoQuery.errorResponse)
            setIsLoading(false);
        }
    }, [atendimentoQuery.status])

    const ValidarLinhas = useCallback(()=>{
        return rows.filter((row)=>row.obligatory).every((row)=>row.status==="completed");
    },[rows])

    return (
        <PageSpace>
            <SinistroPageModel tituloPagina={"COMUNICAÇÃO DE SINISTRO - ENVIAR DOCUMENTOS"}>
                <TitleAndOutlinedComponent title={"Detalhes"}>
                    <TopRounded>
                        <TopRoundedRow>
                            <TextSuspended width={"33%"}>
                                <InsideRowColumnName>Segurado/Participante:</InsideRowColumnName>
                                <InsideSelectedText>{sinistroContext.informacoesSegurado?.nomeCompletoParticipanteSegurado ?? sinistroContext.informacoesSegurado?.primeiroNomeSegurado} - {sinistroContext.informacoesSegurado?.cpfSegurado.slice(0, 3) + "." + sinistroContext.informacoesSegurado?.cpfSegurado.slice(3, 6) + "." + sinistroContext.informacoesSegurado?.cpfSegurado.slice(6, 9) + "-" + sinistroContext.informacoesSegurado?.cpfSegurado.slice(9, 11)}</InsideSelectedText>
                            </TextSuspended>
                            <TextSuspended width={"10%"}>
                                <InsideRowColumnName>Data de nascimento:</InsideRowColumnName>
                                <InsideSelectedText>{sinistroContext.informacoesSegurado?.dataNascimentoSegurado ?? ""}</InsideSelectedText>
                            </TextSuspended>
                            <TextSuspended width={"33%"}>
                                <InsideRowColumnName>Comunicante:</InsideRowColumnName>
                                <InsideSelectedText>{globalContext.PreUser?.nome } ({sinistroContext.grauRelacionamentoComunicante?.name}) - {globalContext.PreUser?.cpf? (globalContext.PreUser?.cpf.slice(0,3)+"."+globalContext.PreUser?.cpf.slice(3,6)+"."+globalContext.PreUser?.cpf.slice(6,9)+"-"+globalContext.PreUser?.cpf.slice(9,11)):"CPF não identificado"}</InsideSelectedText>
                            </TextSuspended>
                        </TopRoundedRow>
                        <TopRoundedRow>
                            <AlignSpace>
                                <SingleLine>
                                    <TextSuspended width={"33%"}>
                                        <InsideRowColumnName>Evento:</InsideRowColumnName>    
                                    </TextSuspended>
                                    <TextSuspended width={"33%"}>
                                        <InsideRowColumnName>Data do evento:</InsideRowColumnName>
                                    </TextSuspended>
                                </SingleLine>
                                <SingleLine>
                                    <TextSuspended width={"33%"}>
                                        <InsideSelectedText>{sinistroContext.nomeEvento??"Evento não identificado"}</InsideSelectedText>   
                                    </TextSuspended>
                                    <TextSuspended width={"33%"}>
                                        <InsideSelectedText>{sinistroContext.dataEvento??"Data não identificada"}</InsideSelectedText>
                                    </TextSuspended>
                                </SingleLine>
                            </AlignSpace>
                        </TopRoundedRow>
                        <TopRoundedFooter>
                            Para enviar a documentação, basta tirar uma foto de cada um dos documentos solicitados abaixo ou digitalizá-los e efetuar o envio clicando em <Icon icon={faUpload}/>. Os documentos podem ser foto formato <b>.jpg</b> ou <b>.png</b> ou arquivos digitalizados (<b>.pdf</b>). Toda a documentação listada abaixo é de suma importância para a regulação do sinistro, entretanto, os documentos marcados com (<b style={{color: 'red'}}>*</b>) são obrigatórios para o prosseguimento da comunicação de sinistro.
                        </TopRoundedFooter>
                    </TopRounded>
                </TitleAndOutlinedComponent>
                {
                    documentosToSend.status==="COMPLETED" ?
                    <>
                        <TitleAndOutlinedComponent title={"Documentação"}>
                            <RowsSpacer>
                                {
                                    rows.map((row:UploadRow)=>{
                                        return(
                                            <UploadRowComponent
                                                setFiles={ReplacePositionArray}
                                                sendFiles={EnviarArquivoIndexadoAsync}
                                                row={row}
                                                sendFilesProps={[arquivoTags]}
                                            />
                                        )
                                    })
                                }
                            </RowsSpacer>
                        </TitleAndOutlinedComponent>
                        <TitleAndOutlinedComponent title={"Observação"}>
                            <TextInput rotulo={""} valor={observacao} maxLength={numeroCaracteresObservacao}
                           onChange={
                            (e:{target:{value:string}})=>{
                                setObservacao(e.target.value);
                                validarObservacao(observacao);
                            }
                        } 
                        onBlur={()=>validarObservacao(observacao)}
                            type={"bigtext"} placeHolder={"Caso haja alguma observação, escreva aqui"}/>
                        <div style={{display:"flex", justifyContent:"flex-end"}}>
                            <span>{numeroCaracteresObservacao - observacao.length} caracteres restantes</span>
                        </div>    
                        </TitleAndOutlinedComponent>
                        <NavigationButtonsSpace>
                            <LoaderRotatorModal show={isLoading} />
                            <BtnLaranja 
                                canSend={ValidarLinhas()}
                                onClick={()=>{
                                    if(ValidarLinhas())
                                    {
                                        if(validarObservacao(observacao)){
                                            disposeAtendimento();
                                            startAtendimentoQuery();
                                            setIsLoading(true);
                                        }
                                    }
                                    else
                                        StyledAlert("É necessário que todos os arquivos obrigatórios sejam enviados!");        
                                    }
                                }
                            >
                                Enviar Documentação
                            </BtnLaranja>
                            <BtnCinza to="/inicio/sinistro/identificacaoevento" >Voltar</BtnCinza>
                        </NavigationButtonsSpace>
                    </>
                    :
                    (
                        documentosToSend.status==="FAILED"?
                        <LoaderError erro={documentosToSend.errorResponse??""}/>
                        :
                        <LoaderRotatorMessage width={"100%"}/>
                    )
                }
            </SinistroPageModel>
        </PageSpace>
    )
}
const TitleAndOutlinedComponent : React.FC<{title:string}> = (props) => {
    return(
        <div style={{display:"flex",marginTop:"10px",flexDirection:"column"}}>
            <SelectedRowTitle>{props.title}</SelectedRowTitle>
            {
                props.children
            }
        </div>
    )
}
export default EnviarDocumentos;