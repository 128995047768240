import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)<{height?: string, width?:string }>`
    width: 276px;
    height: 416px;
    display: flex;
    flex-direction: column;
    border:none;
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;
export const ContainerNonLink = styled.div<{height?: string, width?:string }>`
    width: 276px;
    height: 416px;
    display: flex;
    flex-direction: column;
    border:none;
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;
export const Title = styled.p`
    display: flex;
    background-color: ${ props => props.theme.cores.primaria};
    height: 10%;
    align-items: center;
    padding: 0 5%;
    color: white;
    font-size: 110%;
    text-transform: uppercase;
    font-weight:bold;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const Description = styled.p`
    display: flex;
    background-color: #666;
    height: 20%;
    align-items: center;
    padding: 10px 5%;
    color: white;
    font-size: 80%;
    margin: 0;
    overflow-y:auto;
`;

export const DotsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
`;

export const Dot = styled.button<{index: number,isSelected: boolean}>`
    background-color: ${ props => props.isSelected ? 'white' : 'transparent' };
    border-radius: 50%;
    width: 7px;
    height: 7px;
    border: 1px white solid;
    margin: 2.5px;
    z-index:6;
`;

export const Image = styled.div<{src: string}>`
    background-color: #666;
    background-image: url("${ props => props.src}");
    background-size: cover;
    background-position: center;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const ImageButton = styled.button<{tipo: string}>`
    font-size: 22px;
    background: linear-gradient(to ${props => props.tipo === "previous" ? "right" : "left"}, #00000060 , transparent);
    opacity: 0.2;
    border: none;
    height:100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
        opacity: 1;
        color: white
    }
    &:focus{
        outline:none;
    }
`;

