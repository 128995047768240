import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
    };
    body{
        background-color: white;
        font-size: 14px;
        color: #333;
        font-family: Segoe UI;
    }
`;