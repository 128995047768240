import { action, Reducer } from 'typesafe-actions';
import { UploadRow } from '../../../components/DragAndDropUpload';

//types

export enum EnviarDocumentosActions {
    ESVAZIARSTATE = "@EnviarDocumentos/ESVAZIARSTATE",
    ATUALIZARROW = "@EnviarDocumentos/ATUALIZARROW",
    POPULARSTATE = "@EnviarDocumentos/POPULARSTATE"
}

//actions

export const ESVAZIARSTATE = () => action(EnviarDocumentosActions.ESVAZIARSTATE,{array:[]})
export const ATUALIZARROW = (newRow:UploadRow) => action(EnviarDocumentosActions.ATUALIZARROW,{row:newRow})
export const POPULARSTATE = (rows:UploadRow[]) => action(EnviarDocumentosActions.POPULARSTATE,{rows})

export type EnviarDocumentosDispatchActions = ReturnType<typeof ESVAZIARSTATE> | ReturnType<typeof ATUALIZARROW> | ReturnType<typeof POPULARSTATE>

//reducer

const initialVal:UploadRow[] = [];

const reducer : Reducer<UploadRow[],EnviarDocumentosDispatchActions> = (state=initialVal, action) =>{
    switch(action.type)
    {
        case EnviarDocumentosActions.ATUALIZARROW:
            return(
                state?.map((row)=>{
                    return(
                        row.tipoDocumento===action.payload.row.tipoDocumento?
                        action.payload.row
                        :
                        row
                    )
                })??[]
            )
        case EnviarDocumentosActions.POPULARSTATE:
            return(
                action.payload.rows
            )
        default:
            return state;
    }
}

export default reducer;