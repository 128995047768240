import React, { useContext } from 'react';
import usePersistedState from '../storage/custom_hooks/PersistedState'
import { B2C_URL_SIGNIN } from '../env_settings'
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { IGlobalContext } from '../interfaces/storage/contexts/IGlobalContext';
import { AuthContext } from '../storage/contexts/AuthContext' ;
import { IAuth } from '../interfaces/storage/contexts/IAuthContext';
import { GlobalContext } from '../storage/contexts/GlobalContext';
import ConfirmaDados from '../pages/Portal/ConfirmaDados';
import VerificacaoSegurança from '../pages/Portal/VerificacaoSegurança';
import AuthB2C from '../pages/Portal/AuthB2C'
import Portal from './Portal'
import PreHome from '../pages/Load';
import CanaisAtendimento from'../pages/Portal/CanaisAtendimento';
import Sinistro from './Sinistro';
import AssistenciFinanceira from '../pages/AssistenciaFinanceira';
import { Provider } from 'react-redux';
import Store from '../storage/ducks/store';
import StyledAlert from '../components/StyledAlert';
import PageTracker from '../services/googleAnalytics/PageTrackerComponent';
const AuthRoute:React.FC<{path: string, component: any}> = ({component: Component, ...rest}): React.ReactElement =>{
    const {auth} = useContext(AuthContext);
    const {isAuth} = auth;
    if(isAuth){ 
        return (
            <Route {...rest} render={props => (
                    <Component {...props}/>
            )}/>
        )
    }

    else window.location.href = B2C_URL_SIGNIN+(auth.state?"&state="+JSON.stringify(auth.state):"");

    return <></>
}
export default function Routes(){
    const [auth, setAuth] = usePersistedState<IAuth>('auth',{
        TOKEN: '',
        isAuth: false
    });
    window.alert = StyledAlert;
    const [globalContext,setGlobalContext] = usePersistedState<IGlobalContext>("gc",{});
    return (
        <BrowserRouter>
            <AuthContext.Provider value={{auth, setAuth}}>
                <Provider store={Store}>
                    <Switch>
                        <Route exact path="/" component={() =><div><Redirect to="/inicio"/></div>} />
                        <Route exact path="/auth" component={AuthB2C} />
                        <Route path="/sinistro" component={Sinistro}/>
                        <Route exact path="/assistenciafinanceira" component={AssistenciFinanceira}/>
                        <GlobalContext.Provider value={{globalContext, setGlobalContext}}>
                            <AuthRoute path="/load" component={PreHome} />
                            <AuthRoute path="/confirmaDados" component = {ConfirmaDados}/> 
                            <AuthRoute path= "/inicio" component = {Portal} />
                            <AuthRoute path= "/verificacaoSeguranca" component = {VerificacaoSegurança} />
                            <AuthRoute path= "/canaisAtendimento" component = {CanaisAtendimento}/>
                        </GlobalContext.Provider>
                    </Switch>
                    <PageTracker/>
                </Provider>
            </AuthContext.Provider>
        </BrowserRouter>
    )
}