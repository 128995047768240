import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {DragAndDropSpace, DragMessage, DragMessageOrange, Icon, SuspenseFileInput, DocumentItem, DocumentSpace, DeleteIco, DisabledDiv, DropdownSpace, EnviarDocumentosButton} from './style';
import {faUpload,faTrashAlt, faEdit, faTimes, faEllipsisH, faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import IcomoonIteration from '../IcomoonIteration';
import StyledAlert from '../StyledAlert';
import CustomDropdown from '../CustomDropdown';
import StyledCheckbox from '../CustomCheckBox/v2';
export type UploadRow = {
    status:"completed"|"failed"|"pending"|"editable",
    obligatory?:boolean,
    nomeColuna:string,
    tipoDocumento:number,
    files:File[],
    fileAPIReferences?:{guid:string,nome:string}[],
    isOpen?:"open"|"closed"
}
const DragAndDropUpload :React.FC<{files:File[],setFiles:Function,disabled?:boolean}> = (props): React.ReactElement =>{
    const {files,setFiles,disabled} = props;
    type PosicionamentoType = {width:string|number,height:string|number}
    const [dimensoes,setDimensoes] = useState<PosicionamentoType>({width:"400px",height:"120px"})
    const dragDropRef = useRef<any>(null);
    const acceptedTypes : string[] = ["image/png","image/jpeg","application/pdf"];
    const onDrop = (e:React.DragEvent<HTMLDivElement>)=>{
        e.preventDefault();
        const arquivosRaw : DataTransferItemList = e.dataTransfer.items;
        if(arquivosRaw)
        {   
            let filesToSave : File[] = [];
            for(let i:number = 0;i< arquivosRaw.length;i++)
            {
                if((arquivosRaw[i].getAsFile() as File).size<=20971520)
                {
                    if(acceptedTypes.find((actualType:string)=>actualType===arquivosRaw[i].type) && arquivosRaw[i].kind === "file")
                        filesToSave.push(arquivosRaw[i].getAsFile() as File);
                    else
                        StyledAlert("Tipo de documento não suportado.")
                }    
                else
                    StyledAlert("Documento muito grande!");     
            }
            setFiles([...files,...filesToSave])
        }
    };
    const AtualizarTamanhos = useCallback(()=>{
        const dimensoesEncontradas : PosicionamentoType = (dragDropRef.current as HTMLElement)?.getBoundingClientRect()??{height:"400px",width:"120px"};
        if(dimensoesEncontradas.width!==dimensoes.width || dimensoesEncontradas.height!==dimensoes.height)
        {
            setDimensoes({width:dimensoesEncontradas.width,height:dimensoesEncontradas.height})
        }
    },[dimensoes.height,dimensoes.width])
    useEffect(()=>{
        window.addEventListener("resize",AtualizarTamanhos);
        // eslint-disable-next-line
    },[])
    // eslint-disable-next-line
    useEffect(()=>{
        AtualizarTamanhos();
    })
    
    return (
        <DragAndDropSpace isDisabled={disabled} ref={dragDropRef} onDrop={onDrop}>
            {props.disabled? <DisabledDiv {...dimensoes}/> : <></>}
            <SuspenseFileInput value={[]} {...dimensoes} multiple onChange={(e)=>{
                let fileList : FileList = (e.target.files ?? new FileList())
                let vetorFiles: File[] = [];
                const maxByteSize = 20971520;
                for(let i:number =0;i<fileList.length;i++)
                {
                    if(fileList[i].size <= maxByteSize)
                    {
                        if(acceptedTypes.find((actualType:string)=>actualType===fileList[i].type))
                            vetorFiles.push(fileList[i]);
                        else
                            StyledAlert("Tipo de documento não suportado.")
                    }    
                    else
                        StyledAlert("Documento muito grande!");
                }
                setFiles([...files,...vetorFiles]);
            }} type={"file"} />
            <div style={{minHeight:"120px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                <Icon icon={faUpload}/>
                <DragMessage>Arraste os arquivos para cá ou <DragMessageOrange>clique aqui</DragMessageOrange> para procurá-los.</DragMessage>
            </div>
            {
            files.length <= 0?<></>
            :
            <DocumentSpace width={dimensoes.width} onDragOver={(e)=>{e.preventDefault()}} onDrop={(e)=>{onDrop(e)}}>
                {
                    files.map((file:File,i:number)=>{
                        return(
                            <DocumentItem key={file.name+" "+i} onDrop={onDrop}>
                                <IcomoonIteration size={40} color={"#E27800"} icon={"CAPEMISA---Icones-51"}/>
                                <label style={{marginBottom:"0",marginTop:"5px"}}>{file.name.slice(0,20)+(file.name.length>=20?"...":"")}</label>
                                <label style={{marginBottom:"0",marginTop:"5px"}}>{(file.size/(1024*1024)).toFixed(2)+"MB"}</label>
                                <DeleteIco onClick={()=>{let filesToStay:any[]=[];files.map((_,counter:number)=>{if(counter!==i)filesToStay.push(_);return undefined});setFiles(filesToStay)}} icon={faTrashAlt}/>
                            </DocumentItem>
                        )
                    })
                }
            </DocumentSpace>
            }
        </DragAndDropSpace>
    )
}

export const UploadRowComponent : React.FC<{setFiles:(rowAtualizada:UploadRow)=>void,row:UploadRow, sendFiles: (files:File[]|FileList, ...Args: Array<any>) => Promise<any>, sendFilesProps?: Array<any>}> = (props) : React.ReactElement =>{
    const [files,setFiles] = useState<File[]>([]);
    const{row} = props;
    useEffect(()=>{
        setFiles(row.files);
    },[row.files])
    const togglerPermutations : {[id:string]:React.FC} = useMemo(()=>{
        return({
            UploadDefault:()=><div style={{display:"flex"}}><Icon icon={faUpload}/></div>,
            EditButComplete:()=><div style={{display:"flex"}}><Icon icon={faEdit} onClick={()=>{props.setFiles({...row,status:"editable"})}}/><Icon icon={faCheckCircle} color={"#0BAB00"}/></div>,
            EditButFailed:()=><div style={{display:"flex"}}><Icon icon={faEdit} onClick={()=>{props.setFiles({...row,status:"editable"});}}/><Icon icon={faTimesCircle} color={"#AE0D28"}/></div>,
            Close:()=><div style={{display:"flex"}}><Icon icon={faTimes}/></div>,
            CloseOrEdit:()=><div style={{display:"flex"}}><Icon icon={faEdit} onClick={(e)=>{props.setFiles({...row,status:"editable"});e.stopPropagation();}}/><Icon icon={faTimes}/></div>,
            Loading:()=><div style={{display:"flex"}}><Icon icon={faEllipsisH}/></div>
        })
    },[props,row])
    const SortTogglerPermutations = useCallback((status:"completed"|"failed"|"pending"|"editable",isOpen:boolean)=>{
        switch(status)
        {
            case "completed":
                return isOpen?togglerPermutations["CloseOrEdit"]:togglerPermutations["EditButComplete"]
            case "failed":
                return isOpen?togglerPermutations["CloseOrEdit"]:togglerPermutations["EditButFailed"]
            case "pending":
                return(isOpen?togglerPermutations["Close"]:togglerPermutations["Loading"])
            case "editable":
                return(isOpen?togglerPermutations["Close"]:togglerPermutations["UploadDefault"])
            default:
                return(isOpen?togglerPermutations["Close"]:togglerPermutations["UploadDefault"])
        }
    },[togglerPermutations])
    const handleEnviar = () => {
        props.setFiles({...row,files,isOpen:"closed",status:"pending"});
        props.sendFiles(files, ...(props?.sendFilesProps ?? []))
        .then((response)=>{
            props.setFiles({...row,files,status:"completed",fileAPIReferences:response})})
        .catch(()=>{
            props.setFiles({...row,files,status:"failed"})})
    };
    return(
        <CustomDropdown bodyIsClickable status={row.isOpen} customToggler={{ComponentWhenOpen:SortTogglerPermutations(row.status??"editable",true),ComponentWhenClosed:SortTogglerPermutations(row.status??"editable",false)}} titulo={<div style={{display:"flex"}}>{row.nomeColuna}{row.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:<></>}</div>}>
            <DropdownSpace>
                <DragAndDropUpload disabled={row.status!=="editable"} files={files} setFiles={setFiles}/>
                <EnviarDocumentosButton 
                    disabled={(row.status!=="editable" || !(files.length>0))} 
                    onClick={handleEnviar}
                    canSend={!(row.status!=="editable" || !(files.length>0))}   
                >
                    Enviar
                </EnviarDocumentosButton>
            </DropdownSpace>
        </CustomDropdown>   
    )
}

export const SinistroDetalhesUploadRowComponent : React.FC<{
    setFiles:(rowAtualizada:UploadRow)=>void,
    row:UploadRow,
    sendFiles: (files:File[]|FileList, ...Args: Array<any>) => Promise<any>,
    sendFilesProps?: Array<any>,
    checked?:boolean,
    setChecked:(newValue:boolean) => void,
}> = (props) : React.ReactElement => {
    const [files,setFiles] = useState<File[]>([]);
    const { row, checked, setChecked } = props;
    useEffect(()=>{
        setFiles(row.files);
    },[row.files])
    const togglerPermutations : {[id:string]:React.FC} = useMemo(()=>{
        return({
            UploadDefault:()=><div style={{display:"flex"}}><Icon icon={faUpload}/></div>,
            EditButComplete:()=><div style={{display:"flex"}}><Icon icon={faEdit} onClick={()=>{props.setFiles({...row,status:"editable"})}}/><Icon icon={faCheckCircle} color={"#0BAB00"}/></div>,
            EditButFailed:()=><div style={{display:"flex"}}><Icon icon={faEdit} onClick={()=>{props.setFiles({...row,status:"editable"});}}/><Icon icon={faTimesCircle} color={"#AE0D28"}/></div>,
            Close:()=><div style={{display:"flex"}}><Icon icon={faTimes}/></div>,
            CloseOrEdit:()=><div style={{display:"flex"}}><Icon icon={faEdit} onClick={(e)=>{props.setFiles({...row,status:"editable"});e.stopPropagation();}}/><Icon icon={faTimes}/></div>,
            Loading:()=><div style={{display:"flex"}}><Icon icon={faEllipsisH}/></div>
        })
    },[props,row])
    const SortTogglerPermutations = useCallback((status:"completed"|"failed"|"pending"|"editable",isOpen:boolean)=>{
        switch(status)
        {
            case "completed":
                return isOpen?togglerPermutations["CloseOrEdit"]:togglerPermutations["EditButComplete"]
            case "failed":
                return isOpen?togglerPermutations["CloseOrEdit"]:togglerPermutations["EditButFailed"]
            case "pending":
                return(isOpen?togglerPermutations["Close"]:togglerPermutations["Loading"])
            case "editable":
                return(isOpen?togglerPermutations["Close"]:togglerPermutations["UploadDefault"])
            default:
                return(isOpen?togglerPermutations["Close"]:togglerPermutations["UploadDefault"])
        }
    },[togglerPermutations])
    const handleEnviar = () => {
        props.setFiles({...row,files,isOpen:"closed",status:"pending"});
        props.sendFiles(files, ...(props?.sendFilesProps ?? []))
        .then((response)=>{
            props.setFiles({...row,files,status:"completed",fileAPIReferences:response})})
        .catch(()=>{
            props.setFiles({...row,files,status:"failed"})})
    };
    return(
        <CustomDropdown bodyIsClickable status={row.isOpen} customToggler={{ComponentWhenOpen:SortTogglerPermutations(row.status??"editable",true),ComponentWhenClosed:SortTogglerPermutations(row.status??"editable",false)}} titulo={<div style={{display:"flex"}}>{row.nomeColuna}{row.obligatory?<p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>:<></>}</div>}>
            <DropdownSpace>
                <DragAndDropUpload disabled={row.status!=="editable"} files={files} setFiles={setFiles}/>
                <div style={{
                    width:'100%',
                    display:'flex',
                    flexDirection:'row-reverse',
                }}>
                    <div style={{display:'flex',justifyContent:'center',width:'37%',alignItems:'center',marginTop:'10px'}}>
                        <StyledCheckbox
                            checked={checked??false}
                            onClick={()=>{setChecked(!checked)}}
                        />
                        Não tenho esse documento
                    </div>
                    <EnviarDocumentosButton 
                        style={{width:'20%'}}
                        disabled={(row.status!=="editable" || !(files.length>0))} 
                        onClick={handleEnviar}
                        canSend={!(row.status!=="editable" || !(files.length>0))}   
                    >
                        Enviar
                    </EnviarDocumentosButton>
                </div>
            </DropdownSpace>
        </CustomDropdown>   
    )
}

export default DragAndDropUpload;