import React, { useCallback, useContext, useMemo } from 'react';
import {Route} from 'react-router-dom';
import { GlobalContext } from '../../storage/contexts/GlobalContext';
import { LoaderError } from '../Loaders/LoaderDots';
import {KeepConsistencyDiv} from './style';
interface Props {exact?:boolean,path:string,component:React.FC};
const CustomRoute : React.FC<Props> = (props) : React.ReactElement =>
{
    const favoritoEncontrado = useContext(GlobalContext).globalContext.AllFavorites?.find((favorite)=>{return(favorite.url===props.path+"/")});
    let propsFixed : Props = {...props};
    //const ComponenteWithPopup = (componentProps:any)=><KeepConsistencyDiv><props.component {...componentProps}/></KeepConsistencyDiv>
    const WhiteList : {url:string,exact?:boolean}[] = useMemo(()=>
    [
        {url:"/inicio",exact:true},
        {url:"inicio/falecomagente/ajuda/"},
        {url:"inicio/busca/"},
        {url:"inicio/destaques/"},
        {url:"inicio/capnews/"},
        {url:"/inicio/perfil/confirmardados", exact:true},
        {url:"inicio/sinistro"},
        {url:"inicio/debug"}
    ]
    ,[])
    propsFixed.component = favoritoEncontrado || WhiteList.find((actualPath)=>{
        if(actualPath.exact)
        {
            return(actualPath.url===props.path);
        }
        else
        {
            return(props.path.search(actualPath.url) !== -1)
        }
    }) ? propsFixed.component : ()=><LoaderError erro={"Página não encontrada."}/>;
    return(
        <Route {...propsFixed} />
    )
}
export default CustomRoute