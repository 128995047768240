
import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../../storage/contexts/GlobalContext'
import PageModel from '../../../components/PageModel';
import Select from '../../../components/Select';
import {Titulo, EnviarAtendimento,RoundedInput, AtendimentoSpace, AtendimentoText, AtendimentoCheckbox, AtendimentoPrompt, RoundedMaskedInput, RoundedSelect, ItemSpacing } from './style'
import {GetTipoAtendimento, PostAtendimento} from '../../../API/usuario';
import { RoundedComponentSpace } from '../FaleConosco/styles';
import { RoundedComponentAtendimentos } from '../../../components/RoundedComponent';
import { ResolveFilesAsync } from '../../../API/file';
import { LoaderRotatorModal } from '../../../components/Loaders/LoaderRotator';
import { useHistory } from 'react-router-dom';
import StyledAlert from '../../../components/StyledAlert';
import DragAndDropUpload, { UploadRow, UploadRowComponent } from '../../../components/DragAndDropUpload';
import { TextInput } from '../../../components/TextInput';

const NovoAtendimento: React.FC = (): React.ReactElement => {
    const [selectValue, setSelectValue] = useState<any>("Não selecionado");
    const [myAtendimentos,setAtendimentos]=useState<any>([]);
    const {globalContext} = useContext(GlobalContext);
    useEffect(()=>{
        GetTipoAtendimento()?.then((response)=>{
            let atendimentos:any=[];
            response.data.map((item:any,i:number)=>{
                if(i===0)
                atendimentos.push({ id: (i+1).toString(),title:item.assunto,value:item.codigo,isHeader:true,subitemOf:undefined,isSelectable:true});
                else
                atendimentos.push({ id: (i+1).toString(),title:item.assunto,value:item.codigo,isHeader:undefined,subitemOf:undefined,isSelectable:true});
                return ""
            });
            setAtendimentos(atendimentos);
        })
    },[])
    return(
        <PageModel Conteudo={
            <div>
                <Titulo>Para qual produto/serviço deseja atendimento?*</Titulo>
                <Select 
                    items={myAtendimentos} 
                    selectedIndex='0'
                    listHeight="200px"
                    onClick={(value: any) => setSelectValue(value)}
                />
                <CustomAtendimentoComponent codigoAtendimento={selectValue}/>
                <RoundedComponentSpace>
                    <div style={{width:"30%"}}>
                    {
                        globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/")?
                        <RoundedComponentAtendimentos item={{documentIco:"31",documentText:"FALE COM A GENTE",url:"/inicio/falecomagente"}}/>
                        :
                        <></>
                    }
                    </div>
                    <div style={{width:"30%"}}>
                    {
                        globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/atendimentos/")?
                        <RoundedComponentAtendimentos item={{documentIco:"37",documentText:"MEUS ATENDIMENTOS",url:"/inicio/falecomagente/atendimentos"}}/>
                        :
                        <></>
                    }
                    </div>
                    <div style={{width:"30%"}}>
                    {
                        globalContext.AllFavorites?.find((favorite)=>favorite.url==="/inicio/falecomagente/ajuda/")?
                        <RoundedComponentAtendimentos item={{documentIco:"38",documentText:"AJUDA",url:"/inicio/falecomagente/ajuda"}}/>
                        :
                        <></>
                    }
                    </div>
                </RoundedComponentSpace>
            </div> }
        />
    )
} 
const CustomAtendimentoComponent : React.FC<{codigoAtendimento:"dc"|"Não selecionado"|"dps"|"af"}> = (props) : React.ReactElement=>
{
    console.log(props.codigoAtendimento);
    switch(props.codigoAtendimento)
    {
        case undefined:
        case 'Não selecionado':
            return(<></>)
        case "dc":
            return(<DCComponent/>);
        case "af":
            return(<div>Não implementado</div>)
        case "dps":
            return(<DPSComponent/>);
        default:
            return(<div>Erro Interno</div>)
    }
}
const DCInputComponent : React.FC<{show:boolean,nome:string,itens:{value:string|null}[],i:number,onChange:any,placeholder?:string}> = (props) : React.ReactElement =>
{
    const {nome,itens,i,onChange,placeholder,show} = props;
    if(show)
    {
        switch(nome)
        {
            case "Sexo (Gênero)":
                return(<RoundedSelect onChange={onChange}><option selected disabled>Escolha...</option><option value="M">Masculino</option><option value="F">Feminino</option></RoundedSelect>)
            case "Data de Nascimento":
                return(<RoundedMaskedInput mask="11/11/1111" value={itens[i].value??""} onChange={onChange} placeholder={placeholder}/>);    
            default:
                return(<RoundedInput type="text" value={itens[i].value??""} onChange={(e)=>{if(e.target.value.search(/[0-9]/g)===-1)onChange(e)}} placeholder={placeholder}/>);
        }
    }
    else
    {
        return(<div></div>);
    }
}
const SubstituirIndex = (indexParaSubstituir:number,valor:any,vetor:any[])=>{
    let returnvetor = vetor.slice();
    returnvetor[indexParaSubstituir]=valor;
    return returnvetor;
}
const DCComponent : React.FC = () : React.ReactElement =>
{ 
    const [files,SetFile]=useState<File[]>([]);
    const [checkedOptions,setCheckedOptions]=useState<{itens:{checked:boolean,value:string|null}[]}>({itens:[{checked:true,value:null},{checked:true,value:null},{checked:true,value:null},{checked:true,value:null},{checked:true,value:null}]});
    const user=useContext(GlobalContext).globalContext.PreUser;
    console.log(checkedOptions);
    const [showLoader,setShowLoader] = useState<boolean>(false);
    const history = useHistory();
    const TestFuncChecked = (totalBool:{checked:boolean,value:string|null},actualBool:{checked:boolean,value:string|null})=>{
        return {checked:totalBool.checked && actualBool.checked,value:actualBool.value}
    }
    const TestFuncFilled = (totalResult:{checked:boolean,value:string|null},actualItem:{checked:boolean,value:string|null})=>{
        return {checked:false,value:(!actualItem.checked?(totalResult.value==="Preenchido" && actualItem.value!=null && actualItem.value!=="" ?"Preenchido":"Vazio"):totalResult.value)}
    }
    const opcoes:{nome:string,valor:string}[]=[{nome:"Nome",valor:user?.nome??""},{nome:"Sexo (Gênero)",valor:user?.sexo??""},{nome:"Data de Nascimento",valor:new Date(user?.dataNascimento ?? "").toLocaleDateString('pt-BR', { timeZone: 'UTC' })}]
    return(
        <div style={{marginTop:"3%",display:"flex",justifyContent:"space-around",flexDirection:"column"}}>
            <div>
                <label>Digite a informação que vai ser alterada</label>
                {   
                    <AtendimentoPrompt>
                        {
                            opcoes.map((index:{nome:string,valor?:string},i:number)=>{
                            return(<AtendimentoSpace>
                                    <div style={{display:"flex",alignItems:"center",height:"100%",width:"40%"}}><AtendimentoCheckbox checked={!checkedOptions.itens[i].checked} type="checkbox" onChange={(e)=>{setCheckedOptions({itens:SubstituirIndex(i,{checked:!e.target.checked,value:null},checkedOptions.itens)})}}/><AtendimentoText>{index.nome}</AtendimentoText></div>
                                    {
                                        <DCInputComponent show={!checkedOptions.itens[i].checked} nome={index.nome} itens={checkedOptions.itens} i={i} onChange={(e:any)=>{setCheckedOptions({itens:SubstituirIndex(i,{checked:checkedOptions.itens[i].checked,value:e?.target?.value},checkedOptions.itens)})}} placeholder={index.valor}/>
                                    }
                                </AtendimentoSpace>)
                            })
                        }
                    </AtendimentoPrompt>
                }
            </div>
            <AtendimentoPrompt>
                <label>Insira um (ou mais) documento(s) para alterar os dados</label>
                {
                    //<input type="file" multiple onChange={(e)=>{SetFile(e.target.files)}}/>
                }
                {
                    <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                        <DragAndDropUpload files={files} setFiles={SetFile}/> 
                    </div>
                }
            </AtendimentoPrompt>
            <div style={{width:"15%",marginTop:"4%"}}>
                    <LoaderRotatorModal show={showLoader}/>
                    <EnviarAtendimento onClick={
                        ()=>{
                            if(checkedOptions.itens.reduce(TestFuncChecked).checked)StyledAlert("Escolha uma opção!");
                            else if(checkedOptions.itens.reduce(TestFuncFilled,{checked:true,value:"Preenchido"}).value==="Vazio")StyledAlert("Preencha os campos!")
                            else if(!files || files?.length<1)StyledAlert("Insira um arquivo!");
                            else 
                            {
                                if(checkedOptions.itens[4].value && checkedOptions.itens[4].value.search("_")!==-1)
                                StyledAlert("Digite a data de nascimento inteira!");
                                else
                                {
                                    setShowLoader(true);
                                    ResolveFilesAsync(files).then(
                                        (result)=>
                                        {
                                            PostAtendimento({nome:checkedOptions.itens[0].value,sexo:checkedOptions.itens[1].value,nomePai:null,nomeMae:null,dtNascimento:checkedOptions.itens[2].value,listaAnexo:result},"dc").then(()=>{
                                                StyledAlert("Atendimento criado!");
                                                history.push("/inicio/falecomagente/atendimentos/")
                                            }).catch(()=>{
                                                StyledAlert("Algo errado aconteceu! Contate nossa equipe de suporte se o erro persistir.");
                                            }).finally(()=>{
                                                setShowLoader(false);
                                            });
                                        }
                                    ).catch((e)=>{
                                            StyledAlert("Algo errado aconteceu! Contate nossa equipe de suporte se o erro persistir.");
                                            setShowLoader(false);
                                    })
                                }
                            }
                        }    
                    }>Enviar</EnviarAtendimento>
            </div>
        </div>
    )
}
const DPSComponent : React.FC = () : React.ReactElement =>{
    const [selectedOption,setSelectedOption] = useState<string>("");
    const [row,setFiles] = useState<UploadRow>({files:[],nomeColuna:"Enviar Documentos",status:"editable",tipoDocumento:0,fileAPIReferences:[]});
    return(
        <AtendimentoSpace>
            <ItemSpacing>
                <TextInput onChange={(e:{target:{value:string}})=>{setSelectedOption(e.target.value)}} borderRadius={"10px"} rotuloMargin={"0px"} rotuloCustomCSS={"color:rgb(51, 51, 51);font-weight:bold;"} disabled={false} type={"combo"} options={["33206523 - Plano Natalidade - Assist. Cest. Aliment. Natalidade", "33204256 - Plano Mais Vida - Morte Qualquer Causa","33204321 - Plano Natalidade - Desp. Diversa por Natalidade"]} show obligatory rotulo={"PARA QUAL PRODUTO/SERVIÇO DESEJA ATENDIMENTO?"} />
                <div style={{display:"flex",marginTop:"5px",fontWeight:"bold"}}>
                    <label >ENVIE OS DOCUMENTOS QUE FORAM SOLICITADOS PELA CENTRAL DE RELACIONAMENTO</label><p style={{color:"red",marginLeft:"2px",marginBottom:"0px"}}>*</p>
                </div>
                <UploadRowComponent row={row} sendFiles={ResolveFilesAsync} setFiles={setFiles}/>
                <div style={{width:"10%",marginTop:"20px"}}>
                    <EnviarAtendimento onClick={()=>{
                        let canContinue = true;
                        if(!selectedOption)
                        {
                            StyledAlert("Selecione uma opção de produto/serviço!");
                            canContinue = false;
                        }
                        if(row.files.length<=0)
                        {
                            StyledAlert("Insira ao menos um documento!");
                            canContinue = false;
                        }
                        if((row.fileAPIReferences?.length??0) <=0)
                        {
                            StyledAlert("É necessário que todos os documentos sejam enviados para prosseguir!");
                            canContinue = false;
                        }
                        if(canContinue)
                        {
                            StyledAlert("Documentos enviados!");
                        }                           
                    }}>
                        Enviar
                    </EnviarAtendimento>
                </div>
            </ItemSpacing>
        </AtendimentoSpace>
    )
}
export default NovoAtendimento;