import React from 'react';
import { CheckBoxArea, CheckBoxCheckedMark} from './style';
const StyledCheckbox : React.FC<{checked?:boolean,onClick?:()=>void,borderRadius?:string}> = (props) : React.ReactElement =>
{
    return(
        <CheckBoxArea borderRadius={props.borderRadius} onClick={props?.onClick ?? (() => {})}>
            {props.checked?<CheckBoxCheckedMark borderRadius={props.borderRadius}/>: "" }           
        </CheckBoxArea>
    )
}  
export default StyledCheckbox;