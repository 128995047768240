import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const LoaderSpace = styled.div<{ width: string | number }>`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height:100px;
    width:${props => props.width};
`;
export const IcoToRotate = styled(FontAwesomeIcon)<{ fontSize: string | number }>`
    font-size:${props => props.fontSize};
`
export const LoaderErrorSpace = styled.div<{extraStyle?:string}>`
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:10px;
    ${props=>props.extraStyle??""}
`