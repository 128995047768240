import styled from 'styled-components';
export const DadosCadastraisLabel = styled.label`
    text-align:center;
    color:#444444;
    font-size:2em;
`
export const EspacoModalFinal = styled.div`
    height:200px;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
`
export const MensagemCadastrado = styled.label`
    color:#E27800;
    font-size:2em;
    font-weight:bold;
    font-family:Roboto;
`
export const PageHalfSpace = styled.div`
    display:flex;
    flex-direction:column;
    width:50%;
    height:100%;
`
export const PageTotalSpace = styled.div`
    display:flex;
    width:100%;
    margin-top:4%;
    height:90%;
`
export const LineSpace = styled.div`
    margin-top:1%;
    width:90%;
    align-self:center;
    height:15%;
    display:flex;
    justify-content:space-between;
`
export const CancelarButton = styled.button`
    height:40px;
    width:100px;
    background-color:#dddddd;
    border:0px;
    border-radius:18px;
    margin-right:2%;
    ::after
    {
        outline:0px;
    }
    ::before
    {
        outline:0px;
    }
    :focus{
        outline:0px;
    }
`
export const ButtonsSpace = styled.div<{height?:string}>`
    margin-top:1.5%;
    height:${props=>props.height??"25%"};
    width:90%;
    display:flex;
    flex-direction:row-reverse;
`
export const EnviarButton = styled.button`
    height:40px;
    width:100px;
    background-color:${props=>props.theme.cores.primaria};
    color:white;
    border:0px;
    border-radius:18px;
    ::after
    {
        outline:0px;
    }
    ::before
    {
        outline:0px;
    }
    :focus{
        outline:0px;
    }
`