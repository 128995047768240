import React, { useState, useEffect } from 'react';
import PageModel from '../../../components/PageModel';
import IDocument from '../../../interfaces/IDocument';
import {GetTipoDocumento} from '../../../API/usuario';
import {RoundedComponentDocuments} from '../../../components/RoundedComponent';
import { LoaderRotatorMessage } from '../../../components/Loaders/LoaderRotator';
export default function MyDocuments()
{
    const [Documentos,setDocumentos] = useState<IDocument[]>([]);
    const [isQueryOver,setIsQueryOver] = useState<boolean>(false);
    useEffect(()=>{
    GetTipoDocumento().then((response)=>{
        setDocumentos(response.data.map((item:any,i:number)=>{
        return{
            documentIco:item.urlIcone,
            documentId: item.id,
            documentText: item.titulo,
            url: item.urlRedirecionamento,
            };
        }));
        setIsQueryOver(true);
    })
    },[])
    return(<PageModel Conteudo={<MyDocumentsPage isQueryOver={isQueryOver} docs={Documentos ?? []} /> }/>);
}
function MyDocumentsPage(props:{docs:IDocument[],isQueryOver:boolean})
{
    const documents:IDocument[]=props.docs;
    let quantidade=0;
    documents?.map((item:IDocument,i:number)=>{
        quantidade=i;
    })
    if(documents.length<=0 && !props.isQueryOver)
        return(<LoaderRotatorMessage width={"100%"}/>)
    else if(documents.length<=0 && props.isQueryOver)
        return(<div>Não há itens para exibir</div>)
    else
        return(
            <div>
                <div style={{marginTop:"3%",height:"38vh", display:"flex",flexDirection:"column",justifyContent:"space-around"}}>
                    <div style={{width:"100%",height:"150px",display:"flex",flexDirection:"row",}}>
                    {
                        documents?.map((item:IDocument,i:number)=>{
                            if(i<=3)
                            return(<RoundedComponentDocuments item={item} callBackOptions={()=>{}}/>);
                        })
                    }
                    </div>
                    {
                        (quantidade>=4?
                        <div style={{width:"100%",height:"150px",display:"flex",flexDirection:"row",}}>
                            {documents?.map((item:IDocument,i:number)=>{
                                if(i>=4)
                                return(<RoundedComponentDocuments item={item} callBackOptions={()=>{}}/>)
                            })}
                        </div>:"")
                    }
                </div>
            </div>
        )
}