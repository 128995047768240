import {createContext} from 'react';
import {IProps, IGlobalContext} from '../../interfaces/storage/contexts/IGlobalContext';

export const GCDefault:IGlobalContext = {
    Favorites: [],
    AllFavorites: [],
    Informativos: [],
    Icones:[],
}

export const GlobalContext = createContext<IProps>({globalContext:GCDefault, setGlobalContext:()=>{}});