import React, { useState, ReactElement, useEffect, useRef } from 'react';
import {BilhetePremiavelInsideRowSpace, DoubleTextSpace, DoubleTextItem, InsideRowColumnName, InsideSelectedText, PDFText, IcoPDF, CustomSizeDetails, LinhaEntreComponentes, DetailsRow, DetailsIcoRowTitle, DetailsIcoRow, DetailsIcoTitle, DetailsIcoValueText, DetailsRowColumn, DetailsTitle, BeneficioIcoSpace, InnerDetailsDiv, RoundedInputSpace, RoundedInputRounded, RoundedMaskedInput, NormalText} from './style';
import {faEye,faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import IOneLineExpandContents, { IDownContentRow, ITitleAnswer, IDownContentItem, IDownContentReturnTypes, IDownContentReturnPrePromiseTypes } from '../../interfaces/components/IOneLineExpand';
import { LoaderError } from '../Loaders/LoaderDots';
import SuspenseInfo, { PositionAlternatives } from '../SuspenseInfo';
import StyledAlert from '../StyledAlert';
import { LoaderRotatorMessage } from '../Loaders/LoaderRotator';
const OneLineExpand : React.FC<IOneLineExpandContents> = (props) : React.ReactElement =>
{
    const {downContent} = props;
    const [isSelected,setIsSelected] = useState<boolean>(false);
    return(
        <BilhetePremiavelInsideRowSpace>
            <DoubleTextSpace>
                {
                    props.upperTexts.map((actualColumn:ITitleAnswer)=>{
                        return(
                            <DoubleTextItem>
                                <InsideRowColumnName>{actualColumn.title}{actualColumn.suspenseMessage?<label onClick={()=>{StyledAlert(actualColumn.suspenseMessage,"OK")}} style={{color:"#ED7203",marginLeft:"4px",marginBottom:"0px",cursor:"pointer",fontWeight:"bolder"}}>!</label>:<></>}</InsideRowColumnName>
                                <InsideSelectedText>{actualColumn.answer}</InsideSelectedText>
                            </DoubleTextItem>
                        )
                    })
                }
                <DoubleTextItem onClick={()=>{setIsSelected(!isSelected)}} flexDirection="row">
                    <PDFText>{isSelected?"Ocultar":"Visualizar"}</PDFText>
                    <IcoPDF icon={isSelected?faEyeSlash:faEye}/>
                </DoubleTextItem>
            </DoubleTextSpace>
            {
                isSelected?
                <CustomSizeDetails height={props.size}>
                    <LinhaEntreComponentes borderWidth="0.1px!important" borderColor="#E27800"/>
                        <DownContentRowPromiseDealer resultPromiseFunction={downContent}/>
                </CustomSizeDetails>
                :<></>
            }
        </BilhetePremiavelInsideRowSpace>
    )
}
const DownContentRowPromiseDealer :React.FC<{resultPromiseFunction:()=>IDownContentReturnPrePromiseTypes}> = (props) : React.ReactElement =>
{
    const [returnComponent,setReturnComponent] = useState<ReactElement[]>([<LoaderRotatorMessage width={"100%"}/>])
    
    useEffect(
    ()=>{
        const TryAndLoadComponent = () =>{
            const resultPromise = props.resultPromiseFunction();
            if(resultPromise.type==="Promise")
            {
                (resultPromise.downContentReturn as Promise<IDownContentReturnTypes>).then((resultado)=>{
                    resultado.cacheContextSet();
                    setReturnComponent(
                        (
                            resultado.selector==="DownContentRow"?
                            (resultado.rows as IDownContentRow[]).map((actualRow:IDownContentRow)=>{
                            return(<DownContentRow row={actualRow}/>)
                            })
                            :
                            (resultado.rows as ReactElement[]).map((actualRow:ReactElement)=>{
                            return(actualRow);
                            })
                        )
                    )
                }).catch((e)=>{
                    setReturnComponent([
                        <div style={{width:"100%",justifyContent:"center"}}>
                            <LoaderError erro={e}/>
                            <button style={{border:"0px", background:"inherit",padding:"0x 5px"}} onClick={()=>{TryAndLoadComponent()}}>Clique aqui para tentar carregar novamente</button>
                        </div>
                    ])
                })
            }
            else
            {
                setReturnComponent((resultPromise.downContentReturn as unknown) as ReactElement[])
            }
        }
        TryAndLoadComponent();
    }// eslint-disable-next-line
    ,[])

    return (
        <InnerDetailsDiv>
            {
                returnComponent.map((item:ReactElement)=>{
                    return(
                    <React.Fragment>
                        {item}
                    </React.Fragment>
                    )
                })
            }
        </InnerDetailsDiv>
    )
}
const ImageTextSuspenseInfoComponent : React.FC<{item:IDownContentItem}> = (props) =>{
    const {item} = props;
    const referenciaInformacaoSuspensaItem = useRef<HTMLDivElement>(null);
    const [isToShowItem,setIsToShowItem] = useState<boolean>(false);
    return(
        <>
            {
                item.valueTitleandValue?.answer?
                    <SuspenseInfo onMouseEnter={()=>setIsToShowItem(true)} onMouseLeave={()=>setIsToShowItem(false)} show={isToShowItem} referencia={referenciaInformacaoSuspensaItem} position={PositionAlternatives.positionOnRef}>
                        {item.valueTitleandValue?.answer}
                    </SuspenseInfo>
                :
                <></>
            }
            <BeneficioIcoSpace onMouseEnter={()=>{setIsToShowItem(true)}} onMouseLeave={()=>{setIsToShowItem(false)}} ref={referenciaInformacaoSuspensaItem}>
                <DetailsIcoTitle onClick={()=>{window.open(item.title)}} isLink>{item.valueTitleandValue?.title}</DetailsIcoTitle>
            </BeneficioIcoSpace>
        </>
    )
}
export const DownContentRow : React.FC<{row:IDownContentRow}> = (props) : React.ReactElement =>
{
    const {title,itens,width,hoverMessage,margin}=props.row;
    const referenciaInformacaoSuspensa = useRef<HTMLLabelElement>(null);
    const [canShow,setCanShow] = useState<boolean>(false);
    return(
        <DetailsRow margin={margin} width={width}>
            {
                hoverMessage?
                <SuspenseInfo onMouseEnter={()=>setCanShow(true)} onMouseLeave={()=>setCanShow(false)} show={canShow} referencia={referenciaInformacaoSuspensa} position={PositionAlternatives.positionOnRef}>
                    <p style={{margin:"0",fontSize:"0.9em"}}>
                    {
                        hoverMessage
                    }
                    </p>
                </SuspenseInfo>
                :
                <></>
            }
            <DetailsIcoRowTitle onMouseEnter={()=>{setCanShow(true)}} onMouseLeave={()=>{setCanShow(false)}} ref={referenciaInformacaoSuspensa}>{title}</DetailsIcoRowTitle>
            <DetailsIcoRow>
                {
                    itens.map((item:IDownContentItem,i:number)=>
                    {
                        const SelectorComponent = (props:{item:IDownContentItem})=>{
                            const {item} = props;
                            switch(item.type)
                            {
                                case "textText":
                                    return(
                                        <DetailsRowColumn>
                                            <DetailsTitle>{item.title}</DetailsTitle>
                                            <br/>
                                            <InsideRowColumnName>{item.valueTitleandValue?.title}</InsideRowColumnName>
                                            <DetailsTitle>{item.valueTitleandValue?.answer}</DetailsTitle>
                                        </DetailsRowColumn>
                                    )
                                case "imageTextValue":
                                    return(
                                        <BeneficioIcoSpace>
                                            <DetailsIcoTitle>{item.valueTitleandValue?.title}</DetailsIcoTitle>
                                            <DetailsIcoValueText>{item.valueTitleandValue?.answer}</DetailsIcoValueText>
                                        </BeneficioIcoSpace>
                                    )
                                case "timePickerInput":
                                    return(
                                        <RoundedInputSpace>
                                            <InsideRowColumnName>{item.title}</InsideRowColumnName>
                                            <RoundedInputRounded>
                                                <RoundedMaskedInput mask="11/11/1111" type="text" value={item.valueTitleandValue?.title} onChange={(e)=>{if(item.inputOnchange) item.inputOnchange(e)}}/>
                                            </RoundedInputRounded>
                                        </RoundedInputSpace>
                                    )
                                case "imageText":
                                    return(
                                        <BeneficioIcoSpace>
                                            <DetailsIcoTitle>{item.valueTitleandValue?.title}</DetailsIcoTitle>
                                        </BeneficioIcoSpace>
                                    )
                                case "imageTextSuspenseInfoLink":
                                    return(
                                        <ImageTextSuspenseInfoComponent item={item}/>
                                    )
                                case "twoText":
                                    return(
                                        <DetailsRowColumn>
                                            <InsideRowColumnName>{item.valueTitleandValue?.title}</InsideRowColumnName>
                                            <DetailsTitle>{item.valueTitleandValue?.answer}</DetailsTitle>
                                        </DetailsRowColumn>
                                    )
                                case "textNormal":
                                    return(
                                        <DetailsRowColumn>
                                            <NormalText>{item.title}</NormalText>
                                        </DetailsRowColumn>
                                    )
                                case "bigText":
                                    return(
                                        <DetailsRowColumn style={{width:'100%',marginRight:'0'}}>
                                            <NormalText>{item.title}</NormalText>
                                        </DetailsRowColumn>
                                    )
                                default:
                                    return(
                                        <DetailsRowColumn>
                                            <DetailsTitle>{item.title}</DetailsTitle>
                                            <br/>
                                            <InsideRowColumnName>{item.valueTitleandValue?.title}</InsideRowColumnName>
                                            <DetailsTitle>{item.valueTitleandValue?.answer}</DetailsTitle>
                                        </DetailsRowColumn>
                                    )
                            }
                        }
                        return(
                            <SelectorComponent key={"detailRowComponentOneLineExpand"+i+item.title+item.valueTitleandValue?.answer+item.valueTitleandValue?.title} item={item} />
                        )
                    })
                }
            </DetailsIcoRow>
        </DetailsRow>
    )
}
export default OneLineExpand;