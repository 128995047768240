import styled from 'styled-components';
export const BoletoTitle = styled.label`
    text-align: left;
    font: 70% Roboto;
    letter-spacing: -0.34px;
    color: #B9B9B9;
    opacity: 1;
    margin-bottom:0px;
`;
export const InsideSelectedText = styled.label`
    margin-bottom:0px;
    text-align: left;
    font: 80% Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
`;
export const BoletoSpace = styled.div`
    display:flex;
    width:100%;
    height:3rem;
    align-items:center;
    flex-direction:row;
`
export const BoletoAditionalComponentSpace = styled.div`
    display:flex;
    width:100%;
    align-items:center;
    flex-direction:column;
`
export const BoletoInnerComponentSpace = styled.div`
    margin-top:1%;
    display:flex;
    flex-direction:column;
    margin-left:2%;
    width:96%;
    margin-bottom:1%;
`
export const LinhaEntreComponentes = styled.hr<{borderWidth?:string}>`
    border:${props=>props.borderWidth??"0.5px"} solid #B9B9B9;
    width:95%;
    margin-top:0px;
    margin-bottom:0px;
`
export const BoletoListaInnerComponentSpace = styled.div`
    height:65%;
    margin-top:4px;
    display:flex;
    width:98%;
    margin-left:2%;
    flex-direction:column;
    justify-content:flex-start;
`
export const ListaTitle = styled.label`
    margin-bottom:0px;
    font: Roboto 1.5em bold;
    color:#AAAAAA;
`
export const BoletoListaSpace = styled.table`
    max-height:200px;
    display:flex;
    flex-direction:column;
    margin-left:2%;
    margin-right:2%;
    width:96%;
`
export const Linha = styled.tr`
    width:100%;
    height:auto;
    min-height:20px;
    display:flex;
`
export const ColumnSpacer = styled.td<{width:string}>`
    width:${props=>props.width};
    height:fit-content;
`
export const BoletoListaDetalhesBody = styled.tbody`
    overflow-x:auto;
`
export const BoletoListaDetalhesHead = styled.thead<{hasNecessityToScrollBar:boolean}>`
    width:${props=>props.hasNecessityToScrollBar?"98%":"100%"};
`