import {combineReducers, Dispatch} from 'redux';
import TestRedux, { TestDispatchActions } from './test';
import APIRedux, {APIDispatchActions} from './API';
import EnviarDocumentos, { EnviarDocumentosDispatchActions } from './EnviarDocumentos';
import DetalheSinistroDocumentos, {DetalheSinistroDocumentosDispatchActions} from './DetalheSinistroDocumentos';
import { useDispatch, useSelector } from 'react-redux';
import Sinistro from './Sinistro'
//Ao adicionar um redux ao RootReducer é necessário atualizar as ReduxRootActions.

const RootReducer = combineReducers({
    testRedux:TestRedux,
    APIRedux,
    EnviarDocumentos,
    Sinistro,
    DetalheSinistroDocumentos,
});

export type ReduxRootActions = TestDispatchActions|APIDispatchActions|EnviarDocumentosDispatchActions|DetalheSinistroDocumentosDispatchActions;

export type ReduxRootStorage =  ReturnType<typeof RootReducer>

export type ReduxRootDispatch = Dispatch<ReduxRootActions>

export type ReduxRootTypes = [ReduxRootActions,ReduxRootStorage,ReduxRootDispatch];

export const useTypedSelector = <T=unknown>(selectorCallback:(state:ReduxRootStorage)=>T)=>{
    return useSelector<ReduxRootStorage,T>(selectorCallback);
}

export const useCustomDispatch = ()=>{
    return useDispatch<ReduxRootTypes[2]>()
}

export default RootReducer;
