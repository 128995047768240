import styled from 'styled-components';
export const ModalDialog = styled.div`
    background-color:white;
    height: max-content;
    width: max-content;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 39px;
    opacity: 1;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:20px;
`