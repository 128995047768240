import styled from 'styled-components';
export const Container = styled.div<{isAuth?:boolean}>`
    @media screen and (min-width: 1040px) {
        width: 100%;
        max-width:100%;
    }
    @media screen and (max-width: 1039px) {
        width:100%;
    }
    height:100%;
    display:flex;
    justify-content:center;
    overflow-x:auto;
    min-width:510px;
`
export const AllContentContainer = styled.div<{isAuth?:boolean}>`
    width:100%;
    min-width:980px;
    height:fit-content;
    display:flex;
    @media screen and (min-width: 1040px) {
        flex-direction:row;
    }
    @media screen and (max-width: 1039px) {
        flex-direction:column;
        align-items:center;
    }
    justify-content:space-around;
    padding: 60px;
    gap: 60px
`
export const ImageContainer = styled.div`
    height:90%;
    max-height:500px;
    display:flex;
    justify-content:center;
`