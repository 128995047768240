import {APIUSER} from '../../APICallableActions/Adresses';
import { QueryConfig } from '../../../interfaces/storage/IReduxQuery';
import { IAtendimentoSinistroData } from '../../../interfaces/pages/IEnviarDocumentos';
import { IAPIDocumentoPendencia } from '../../../interfaces/IAvisoSinistro';

export function GetBeneficiarioStatus() : QueryConfig
{
    return(
        {
            endpoint:APIUSER.Beneficiario.GETBeneficiario,
            queryName:"GetBeneficiarioStatusQuery",
        }
    )
}
export function GetBeneficiarioStatusDetalhe(numeroSinistro:number): QueryConfig
{
    return(
        {
            endpoint:APIUSER.Beneficiario.GETBeneficiarioDetalhe,
            config:{
                params:{
                    numeroSinistro
                }
            },
            queryName:"GetBeneficiarioStatusDetalheQuery"
        }
    )
}
export function GetEventosSinistro(): QueryConfig
{
   return {
       endpoint:APIUSER.EventoSinistroPortal.GetEventoSinistroPortal,
       queryName:"GetEventosSinistro",
    //    config:{
    //        headers:null
    //    }
   }
}
export function GetTipoParentesco(): QueryConfig
{
    return{
        endpoint:APIUSER.Relacionamento.GetRelacionamento,
        queryName:"GetTipoParentescoQuery",
        // config:{
        //     headers:null
        // }
    }
}
export function GetDocumentosSinistro(codigoEvento?:number) : QueryConfig
{
    return{
        endpoint:APIUSER.DocumentoEventoCapemisa.GetDocumentoEventoCapemisa,
        queryName:"GetDocumentosSinistroQuery",
        config:{
            params:{
                codigoEvento,
            },
        }
    }
}
export function GetEventosSinistroBySegurado(cpf:string,dtNascimento:string,primeiroNome:string,dtEvento:string) : QueryConfig
{
    return{
        endpoint:APIUSER.EventoSinistroPortal.GetEventoSinistroPortalSegurado,
        queryName:"GetEventoSeguradoQuery",
        config:{
            params:{
                cpf,
                dtNascimento,
                primeiroNome,
                dtEvento
            },
        }
    }
}
export function PostAtendimentoSinistro(dadosAtendimento:IAtendimentoSinistroData) : QueryConfig
{
    return{
        endpoint:APIUSER.Atendimento.PostAtendimentoSinistro,
        method:"POST",
        queryName:"PostAtendimentoSinistroQuery",
        config:{
            data:dadosAtendimento
        }
    }
}

export function GetAtendimentoEspecifico(codAtendimento:string, idAtendimento:number) : QueryConfig 
{
    return {
        endpoint:APIUSER.Atendimento.GetAtendimentoEspecifico+"/"+codAtendimento,
        method:"GET",
        queryName:"GetAtendimentoEspecificoQuery",
        config: {
            params: {
                idAtendimento,
            },
        },
    };
}

export function GetVerificaAtendimentoExiste(cpfSegurado:string,dataEvento:string,codigoEventoSinistro:string) : QueryConfig
{
    return {
        endpoint:APIUSER.Atendimento.GetVerfificaExisteSinistroMesmoSeguradoEvento,
        method:"GET",
        queryName:"GetAtendimentoByCPFAndProtocoloQuery",
        config:{
            params:{
                cpfSegurado,
                dataEvento,
                codigoEventoSinistro
            },
        },
    };
}

export function GetAtendimentoByCPFAndProtocolo(cpfSegurado:string, protocolo:string) : QueryConfig
{
    return {
        endpoint:APIUSER.Atendimento.GetAtendimentoByProtocoloAndCpf,
        queryName:'GetAtendimentoByCPFAndProtocoloQuery',
        config:{
            params:{
                cpfSegurado,
                protocolo,
            },
        },
    };
}

export function GetListarPendenciaSinistro(idAtendimento:string) : QueryConfig
{
    return {
        endpoint:APIUSER.Atendimento.GetListarPendenciasSinistro,
        queryName:'GetListarPendenciaSinistroQuery',
        config:{
            params:{
                idAtendimento,
            },
        },
    };
}

export function GetListarPendenciaSinistroUser(idAtendimento:string) : QueryConfig
{
    return {
        endpoint:APIUSER.Atendimento.GetListarPendenciasSinistroUser,
        queryName:'GetListarPendenciaSinistroUserQuery',
        config:{
            params:{
                idAtendimento,
            },
        },
    };
}

export function PatchSolicitarProtocoloSinistro(
    cpfSegurado:string,dataEvento:string,codigoEventoSinistro:string,relacionamento:string
) : QueryConfig
{
    return {
        endpoint:APIUSER.Atendimento.PatchSolicitarProtocolo,
        queryName:'PatchSolicitarProtocoloSinistroQuery',
        method: 'PATCH',
        config:{
            params:{
                cpfSegurado,
                dataEvento,
                codigoEventoSinistro,
                relacionamento,
            },
        },
    };
}

export function PostInserirDocumentosPendencia(body:IAPIDocumentoPendencia) : QueryConfig
{
    return {
        endpoint:APIUSER.Atendimento.PostInserirDocumentoPendencia,
        queryName:'PostInserirDocumentosPendenciaQuery',
        method: 'POST',
        config: {
            data:body,
        },
    }
}