export default {
    titulo: 'light',

    cores:{
        primaria: '#ed7203',
        secundaria: '#F2C800',
        terciaria: '',
        quaternaria: '',
        quinaria: '',
        
        backgound: '', 
        texto: '',
    }
}