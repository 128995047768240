import styled from 'styled-components';
export const AllPageSpace = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    min-height:500px;
`
export const PageSpace = styled.div`
    width:100%;
    min-width:800px;
    height:max-content;
    display:flex;
    flex-direction:column;
`
export const CancelButton = styled.button`
    background: #DADADA 0% 0% no-repeat padding-box;
    width:110px;
    border-radius: 20px;
    height:40px;
    border:1px solid #DADADA;
    color: #707070;
    ::after, ::before, :focus{
        outline:0px;
    };
    margin:0px 5px;
`
export const ConfirmButton = styled.button`
    background: #E27800 0% 0% no-repeat padding-box;
    border-radius: 20px;
    width:110px;
    height:40px;
    border:1px solid #E27800;
    color: #FFF;
    ::after, ::before, :focus{
        outline:0px;
    };
    margin:0px 5px;
`
export const HeaderTextSpace = styled.div`
    width:100%;
    display:flex;
    justify-content:space-around;
`
export const Title = styled.label`
    font-size:1.6em;
    font-weight:bold;
    width:25%;
    color: #6E6E6E;
    display:flex;
    align-items:center;
`
export const OrangeStar = styled.label`
    text-align: left;
    font-size:1em;
    letter-spacing: 0px;
    color: #E27800;
    margin:0;
`
export const Description = styled.label`
    width:70%;
    text-align: left;
    font-size:1em;
    font-family:Segoe UI;
    letter-spacing: 0px;
    color: #707070;
`
export const ButtonSpace = styled.div`
    width:100%;
    display:flex;
    flex-direction:row-reverse;
    margin-top:10px;
`