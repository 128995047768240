import IArgs from '../../interfaces/IArgs';
import { IEvento, IEventoSelected } from '../../interfaces/storage/contexts/ISinistroContext';
import {Requisicao} from '../requisicao';
import {APIUSER} from '../../services/APICallableActions/Adresses';
import ITipoDocumentoEvento from '../../interfaces/pages/IDocumentoNecessario';
import { AxiosResponse } from 'axios';
import { IQueryStatus } from '../../interfaces/services/IQuery';
import IStatusAnalise, { IStatusDownContent } from '../../interfaces/pages/IStatusSinistro';
//DEPRECATED! THIS CATEGORY OF API REQUEST IS FROM NOW (22/03/2021) ON CONSIDERED OUT OF STRUCTURE ARQUITECTURE!


//DEPRECADO! ESTA CATEGORIA DE CHAMADA A API NÃO ESTÁ SENDO MAIS UTILIZADA A PARTIR DE 22/03/2021
//QUALQUER USO DESTA CHAMADA É CONSIDERADO FORA DE ARQUITETURA DE PROJETO!
export function GetTipoParentesco(setParentescoQueryStatus?:(newQueryStatus:IQueryStatus)=>void)
{
    if(setParentescoQueryStatus)
    setParentescoQueryStatus({status:"PENDING"})
    const reqPromise = Requisicao<{descricao:string,id:string}[]>(APIUSER.Relacionamento.GetRelacionamento,{method:"GETNOTOKEN"});
    reqPromise.then(()=>{if(setParentescoQueryStatus)setParentescoQueryStatus({status:"COMPLETED"})}).catch((reason)=>{if(setParentescoQueryStatus)setParentescoQueryStatus({status:"FAILED",errorResponse:(typeof(reason)==="string")?reason:(reason.message??"Graus de Parentesco não encontrados!")})})
    return reqPromise;
}
export function GetVerificacaoSegurado(cpfSegurado:string,dataNascimento:string,primeiroNome:string)
{
    let dataNascimentoTemp = dataNascimento;
    if(dataNascimentoTemp && (dataNascimentoTemp[2]==="/" || dataNascimentoTemp[2]==="-"))
    {
        dataNascimentoTemp=dataNascimentoTemp.slice(6,10)+"-"+dataNascimentoTemp.slice(3,5)+"-"+dataNascimentoTemp.slice(0,2);
    }
    const body:IArgs[]=[
        {
            parameter:"dataNascimento",
            value:dataNascimentoTemp,
        },
        {
            parameter:"cpfSegurado",
            value:cpfSegurado.replace(/-|\./g,""),
        },
        {
            parameter:"primeiroNome",
            value:primeiroNome,
        }
    ];
    return Requisicao(APIUSER.Usuario.VerificaSegurado,{body:body,cantShowError:true,method:"GETNOTOKEN"})
}
export function GetTipoDocumentosSinistro()
{
    return Requisicao(APIUSER.TipoDocumentosSinistro.GetTipoDocumento,{cantShowError:true,method:"GETNOTOKEN"});
}
export function GetEventosSinistro(tipoEvento:"beneficio"|"sinistro",setQueryStatus?:(queryStatus:IQueryStatus)=>void)
{
    console.log((tipoEvento==="beneficio"?1:0));
    if(setQueryStatus)setQueryStatus({status:"PENDING"});
    const body:IArgs[]=[
        {
            parameter:"tipoGrupo",
            value:(tipoEvento==="beneficio"?1:0)
        },
        {
            parameter:"isAtivo",
            value:true
        }
    ];
    
    const reqPromise = Requisicao<IEvento[]>(APIUSER.EventoSinistroPortal.GetEventoSinistroPortal,{method:"GETNOTOKEN",body:body})
    reqPromise.then(()=>{if(setQueryStatus)setQueryStatus({status:"COMPLETED"})}).catch((reason)=>{if(setQueryStatus)setQueryStatus({status:"FAILED",errorResponse:(typeof(reason)==="string")?reason:(reason.message??"Ocorreu um erro!")})})
    return reqPromise;
}
export function GetLoginComunicante(reqHeaders:{Authorization:string})
{
    return Requisicao(APIUSER.Usuario.GetLoginComunicante,{method:"GETCUSTOMTOKEN",aditionalInfo:reqHeaders})
}
export function PatchAtualizarDadosComunicante(nome:string,dataNascimento:string,nomeMae:string,nomePai:string,sexo:"Masculino"|"Feminino",email:string,telefone1:string,bairro:string,cep:string,cidade:string,dddTelefone1:string,endereco:string,uf:string,numeroEndereco?:string,telefone2?:string,telefone3?:string,complemento?:string,dddTelefone2?:string,dddTelefone3?:string)
{
    let dataNascimentoTemp = dataNascimento;
    if(dataNascimentoTemp && (dataNascimentoTemp[2]==="/" || dataNascimentoTemp[2]==="-"))
    {
        dataNascimentoTemp=dataNascimentoTemp.slice(6,10)+"-"+dataNascimentoTemp.slice(3,5)+"-"+dataNascimentoTemp.slice(0,2);
    }
    //utilizando api fora do gateway. super perigoso! mudar assim que o gateway estiver disponível.
    return Requisicao(APIUSER.Usuario.PatchDadosComunicante,{method:"PATCH",form:{
        nome,
        dataNascimento:dataNascimentoTemp,
        nomeMae,
        nomePai,
        sexo:sexo==="Feminino"?"F":"M",
        email,
        telefone1,
        telefone2:(telefone2?? "").length>0?telefone2:undefined,
        telefone3:(telefone3??"").length>0?telefone3:undefined,
        bairro,
        cep,
        cidade,
        complemento:(complemento??"").length>0?complemento:undefined,
        dddTelefone1,
        dddTelefone2:(dddTelefone2??"").length>0?dddTelefone2:undefined,
        dddTelefone3:(dddTelefone3??"").length>0?dddTelefone3:undefined,
        endereco,
        numeroEndereco:(numeroEndereco??"").length>0?numeroEndereco:undefined,
        uf
    }})
}
export function GetDocumentosNecessariosByEventoId(idEventos:number[],setQueryStatus?:(newQueryStatus:IQueryStatus)=>void)
{
    const body : IArgs[] = 
    [
        {
            parameter:"idEventos",
            value:idEventos,
            array:true
        }
    ]
    if(setQueryStatus)
        setQueryStatus({status:"PENDING"});
        
    let query = Requisicao<ITipoDocumentoEvento[]>(APIUSER.TipoDocumentosSinistro.GetDocumentosByEventos,{body});
    query.then(()=>{if(setQueryStatus)setQueryStatus({status:"COMPLETED"})}).catch((reason)=>{if(setQueryStatus)setQueryStatus({status:"FAILED",errorResponse:(typeof(reason)==="string")?reason:(reason.message??"Ocorreu um erro!")})});
    return query;
}

//esse endpoint verifica se o usuario é valido tentando com cada evento e retornando a resposta. Se for false em qualquer um deles o usuario tem que fazer por email
export async function GetVerificarUsuarioEvento(eventosSelecionados:IEventoSelected[],usuario:{cpfSegurado:string,dataNascimento:string,primeiroNome:string}) : Promise<AxiosResponse<boolean>[]>
{
    let dataNascimentoTemp = usuario.dataNascimento;
    if(dataNascimentoTemp && (dataNascimentoTemp[2]==="/" || dataNascimentoTemp[2]==="-"))
    {
        dataNascimentoTemp=dataNascimentoTemp.slice(6,10)+"-"+dataNascimentoTemp.slice(3,5)+"-"+dataNascimentoTemp.slice(0,2);
    }
    return await Promise.all(eventosSelecionados.map(async (eventoAtual)=>{
        let dataEvento = eventoAtual.dataEvento;
        if(dataEvento && (dataEvento[2]==="/" || dataEvento[2]==="-"))
        {
            dataEvento=dataEvento.slice(6,10)+"-"+dataEvento.slice(3,5)+"-"+dataEvento.slice(0,2);
        }
        const body :IArgs[] = 
        [
            {
                parameter:"cpfSegurado",
                value:usuario.cpfSegurado.replace(/-|\./g,""),
            },
            {
                parameter:"dataNascimento",
                value:dataNascimentoTemp
            },
            {
                parameter:"primeiroNome",
                value:usuario.primeiroNome
            },
            {
                parameter:"dataEvento",
                value:dataEvento
            },
            {
                parameter:"evento",
                value:eventoAtual.codigoEvento
            }
        ]
        return Requisicao<boolean>(APIUSER.Usuario.GetVerificaSeguradoEvento,{body,method:"GETNOTOKEN"})
    }))
}
export function GetBeneficiarioStatus(setQueryStatus?:(newQueryStatus:IQueryStatus)=>void)
{
    if(setQueryStatus)
    setQueryStatus({status:"PENDING"});
    let query = Requisicao<IStatusAnalise[]>(APIUSER.Beneficiario.GETBeneficiario);
    query.then(()=>{if(setQueryStatus)setQueryStatus({status:"COMPLETED"})}).catch((reason)=>{if(setQueryStatus)setQueryStatus({status:"FAILED",errorResponse:(typeof(reason)==="string")?reason:(reason.message??"Ocorreu um erro!")})});
    return query;
}
export function GetBeneficiarioStatusDetalhe(numeroSinistro:number,setQueryStatus?:(newQueryStatus:IQueryStatus)=>void)
{
    const body :IArgs[] = 
    [
        {
            parameter:"numeroSinistro",
            value:numeroSinistro,
        }
    ]
    if(setQueryStatus)
    setQueryStatus({status:"PENDING"});
    let query = Requisicao<IStatusDownContent>(APIUSER.Beneficiario.GETBeneficiarioDetalhe,{body});
    query.then(()=>{if(setQueryStatus)setQueryStatus({status:"COMPLETED"})}).catch((reason)=>{if(setQueryStatus)setQueryStatus({status:"FAILED",errorResponse:(typeof(reason)==="string")?reason:(reason.message??"Ocorreu um erro!")})});
    return query;
}