import React from 'react';
import  { Background,PrimaryButton, DefaultButton, Container,Title, ButtonContainer  } from './styles';
import Modal from 'react-bootstrap/Modal';
import { IModalProps } from './types';


const CustomModal: React.FC<IModalProps> = (props):React.ReactElement => {
    return (
        <Modal centered show size={props.size??'sm'}>
            <div style={{padding:'10px'}}>
                <Title>{props?.title}</Title>
                { props?.children ?? props?.content }
                <ButtonContainer style={{justifyContent: props.alignButtons ?? "center"}}>
                    {props?.hasCancel && <DefaultButton onClick={props?.onCancel ?? (()=>{})}>{props?.canceltText ?? "Cancelar"}</DefaultButton>}
                    {props?.hasSubmit && <PrimaryButton onClick={props?.onSubmit ?? (()=>{})}>{props?.submitText ?? "OK"}</PrimaryButton>}
                </ButtonContainer>
            </div>
        </Modal>
    )
}

export default CustomModal; 