import React, { useState, useEffect, useRef } from 'react';
import { IProps, ISelectItem} from "../../interfaces/components/ISelect"

import { Container, Title, Arrow, ListItem, List } from './styles';

const Select: React.FC<IProps> = (props): React.ReactElement => {

    const { items, listHeight, onClick} = props;
    // eslint-disable-next-line
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<string | number | undefined>(props.selectedIndex);

    const [selectedItem, setSelectedItem] = useState<ISelectItem | undefined>(items?.find( element => element?.id  === selectedIndex));

    const refContainer = useRef<HTMLButtonElement>(null);

    const toggleSelectedIndex = (index: string | undefined) => !!index  ? setSelectedIndex(index) : undefined; 

    const toggleList = () =>{
        refContainer.current?.focus();
    };

    useEffect(() => {
        if(isOpen) 
            refContainer.current?.focus();
        else refContainer.current?.blur();
    }, [isOpen])

    useEffect(() =>{
        setSelectedItem(items?.find( element => element?.id  === selectedIndex));
        // eslint-disable-next-line
    }, [selectedIndex]); 

    useEffect(() =>{
        onClick(selectedItem?.value);
        // eslint-disable-next-line
    }, [selectedItem])

    const itemsOrganizados = SelectSort(items ?? []);

    return (

        <Container size={{width: "100%"}}>
            <button id="content" ref={refContainer} onClick={() => toggleList()}>
                <Title bold={selectedItem?.isHeader ?? !selectedItem?.subitemOf}> { selectedItem?.title ?? '' } </Title>
                <Arrow>&gt;</Arrow>
            </button>

            {
                <List height={listHeight} className='list'>
                    {itemsOrganizados?.map((element:ISelectItem,i:number) => 
                        <ListItem 
                            onMouseDown={(ev) => {
                                if( !element?.isHeader || ( element?.isHeader && element?.isSelectable ))
                                    toggleSelectedIndex(element?.id);
                            }} 
                                isSubitem={!! element.subitemOf ?? false}> 
                            <Title bold={element?.isHeader ?? !element?.subitemOf}> {element?.title} </Title>
                        </ListItem>
                    )}
                </List>
            }
        </Container>
    )
}

export default Select;

type SelectItemWithSubItem = (ISelectItem & { subItems: ISelectItem[] | undefined; })[];

function SelectSort(vetorOriginal: ISelectItem[]){
    //reduce tem uma lógica mais simples que o sort, mas precisa gastar mais processamento usando um filter no array original a cada header
    /*
        return vetorOriginal.slice().sort((a, b) => {
            if(a.isHeader && b.isHeader) return a.id?.toString?.()?.localeCompare?.(b?.id?.toString?.() ?? '') ?? 0;
            if(!a.isHeader && b.isHeader) return a.subitemOf?.toString?.()?.localeCompare?.(b?.id?.toString?.() ?? '') ?? 0;
            if(a.isHeader && !b.isHeader) return a.id?.toString?.()?.localeCompare?.(b?.id?.toString?.() ?? '') ?? 0;

            return 0;
        });
    */
    return vetorOriginal.reduce((novoVetor, item, _, arr) => item.isHeader || !item.subitemOf ? [
         ...novoVetor,
         item,
         ...arr.filter(itemArr => itemArr.subitemOf?.toString?.() === item?.id?.toString?.())
     ] : novoVetor, 
     [] as ISelectItem[]);
}