import { AxiosPromise } from 'axios';
import { QueryConfig } from '../../interfaces/storage/IReduxQuery';
import {APISTARTQUERY} from '../../storage/ducks/API';
import Store from '../../storage/ducks/store';
export default async function APIRequestFunction<AxiosResponseType=any>(queryConfig:QueryConfig)
{
    const {endpoint,method,config,queryName} = queryConfig
    const uniqueID = (queryName??"undefinedQuery")+Object.keys(Store.getState().APIRedux).length
    Store.dispatch(APISTARTQUERY(endpoint,method??"GET",config,uniqueID));
    return (Store.getState().APIRedux[uniqueID].queryPromise as AxiosPromise<AxiosResponseType>);
}