import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const ContatosSpace = styled.div`
    min-height:700px;
    height:auto;
`
export const RamalContato = styled.div<{width:string,borderTop:string}>`
    width:${props => props.width};
    padding-bottom:1%;
    height:38%;
    border-top:${props => props.borderTop};
    display:flex;
    overflow-y:auto;
`
export const Icone = styled(FontAwesomeIcon)`
    text-align: left;
    letter-spacing: 0px;
    color: #E27800;
    opacity: 1;
`;
export const TituloNome = styled.label`
    text-align: left;
    font: Bold 110% Roboto;
    letter-spacing: -0.44px;
    color: #E27800;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom:0px;
`
export const SubTitulo = styled.label`
    text-align: left;
    font: Bold Italic 70% Roboto;
    letter-spacing: -0.28px;
    color: #E27800;
    text-transform: uppercase;
`
export const Numero = styled.label`
    margin-left:4%;
    text-align: left;
    font: Bold 100% Roboto;
    letter-spacing: -0.66px;
    color: #636363;
    opacity: 1;
`
export const Area = styled.label`
    text-align: left;
    font:Roboto;
    font-size:80%;
    font-weight:bold;
    letter-spacing: -0.34px;
    color: #636363;
    text-transform: uppercase;
    opacity: 1;
`
export const Descricao = styled.label`
    text-align: left;
    font: Regular Roboto;
    font-size:80%;
    letter-spacing: -0.34px;
    color: #636363;
    opacity: 1;
    margin-bottom:0px;
`
export const RoundedComponentSpace = styled.div`
    height:7rem;
    width: 100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-top:5%;
`;