import {useState, useEffect} from 'react';

export default function usePersistedState<T>( key: string, initilState: T) {
    const [state, setState] = useState(() => {
        const storageValue = sessionStorage.getItem(key);

        if(storageValue){
            return JSON.parse(storageValue);
        } else {
            return initilState;
        }
    });
    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
}