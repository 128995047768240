import { action, Reducer } from 'typesafe-actions';
import { UploadRow } from '../../../components/DragAndDropUpload';

//types

export enum DetalheSinistroDocumentosActions {
    ESVAZIARSTATE = "@DetalheSinistroDocumentos/ESVAZIARSTATE",
    ATUALIZARROW = "@DetalheSinistroDocumentos/ATUALIZARROW",
    ATUALIZARGRUPO = "@DetalheSinistroDocumentos/ATUALIZARGRUPO",
    ATUALIZARVARIOSGRUPOS = "@DetalheSinistroDocumentos/ATUALIZARVARIOSGRUPOS",
    ESVAZIARTODOSOSGRUPOS = "@DetalheSinistroDocumentos/ESVAZIARTODOSOSGRUPOS",
}

export enum DetalheSinistroTiposDocumento {
  SINISTRO = "Sinistro",
  ADICIONAL = "Adicional"
}

export interface IUploadRowDetalheSinistro extends UploadRow{
  doesNotHaveDoc?:boolean,
}

export interface IDetalheSinistro {
  observacao?:string,
  titulo?:string,
  pendenciaIdList?:number[],
  files:IUploadRowDetalheSinistro[],
}

export interface ICreateDetalheGroupObject {
  detalhe:IDetalheSinistro,
  where:DetalheSinistroTiposDocumento|string
}

type IDetalheSinistroDocumentosState = {
  [key:string]: IDetalheSinistro;
};

//actions

export const ESVAZIARSTATE = (where?:DetalheSinistroTiposDocumento|string) => 
  action(
    DetalheSinistroDocumentosActions.ESVAZIARSTATE,
    {
      where:where??DetalheSinistroTiposDocumento.SINISTRO,
      array:[],
    }
  );
export const ATUALIZARROW = (newRow:IUploadRowDetalheSinistro, where?:DetalheSinistroTiposDocumento|string) =>
  action(
    DetalheSinistroDocumentosActions.ATUALIZARROW,
    {
      where:where??DetalheSinistroTiposDocumento.SINISTRO,
      row:newRow,
    }
  );

export const ATUALIZARVARIOSGRUPOS = (grupos:ICreateDetalheGroupObject[]) =>
  action(
    DetalheSinistroDocumentosActions.ATUALIZARVARIOSGRUPOS,
    {
      grupos,
    }
  )

export const ATUALIZARGRUPO = (grupo:IDetalheSinistro, where?:DetalheSinistroTiposDocumento|string) =>
  action(
    DetalheSinistroDocumentosActions.ATUALIZARGRUPO,
    {
      where:where??DetalheSinistroTiposDocumento.SINISTRO,
      grupo,
    }
  )
export const ESVAZIARTODOSOSGRUPOS = () => 
  action(
    DetalheSinistroDocumentosActions.ESVAZIARTODOSOSGRUPOS
  )

export type DetalheSinistroDocumentosDispatchActions = ReturnType<typeof ESVAZIARSTATE> | ReturnType<typeof ATUALIZARROW> 
| ReturnType<typeof ATUALIZARGRUPO> | ReturnType<typeof ESVAZIARTODOSOSGRUPOS> | ReturnType<typeof ATUALIZARVARIOSGRUPOS>

//reducer

const initialVal:IDetalheSinistroDocumentosState = {
  [DetalheSinistroTiposDocumento.SINISTRO]:{
    files:[],
  },
  [DetalheSinistroTiposDocumento.ADICIONAL]:{
    files:[],
  },
};

const reducer : Reducer<IDetalheSinistroDocumentosState,DetalheSinistroDocumentosDispatchActions> = (state=initialVal, action) =>{
    switch(action.type)
    {
        case DetalheSinistroDocumentosActions.ATUALIZARROW: {
          const {
            where,
          } = action.payload;
          const propertyToChange = state[where]??{files:[]};
          return({
            ...state,
            [where]:{
              ...propertyToChange,
              files:propertyToChange.files.map((row)=>{
                return(
                    row.tipoDocumento===action.payload.row.tipoDocumento?
                    action.payload.row
                    :
                    row
                )
              })??[]
            }
          })
        };
        case DetalheSinistroDocumentosActions.ATUALIZARVARIOSGRUPOS:{
          const {
            grupos
          } = action.payload;
          const newItemsDict : IDetalheSinistroDocumentosState = grupos.map(({where,detalhe})=>({
            [where]:detalhe,
          })).reduce((prev,current)=>({
            ...prev,
            ...current,
          }),{})
          return(
            {
              ...state,
              ...newItemsDict,
            }
          )
        };
        case DetalheSinistroDocumentosActions.ATUALIZARGRUPO:{
          const {
            where,
            grupo,
          } = action.payload;
          return(
            {
              ...state,
              [where]:grupo
            }
          )
        };
        case DetalheSinistroDocumentosActions.ESVAZIARSTATE:{
          const {
            where
          } = action.payload;
          const propertyToChange = state[where]??{files:[]};
          return({
            ...state,
            [where]:{
              ...propertyToChange,
              files:[]
            }
          })
        };
        case DetalheSinistroDocumentosActions.ESVAZIARTODOSOSGRUPOS:
            return initialVal;
        default:
            return state;
    }
}

export default reducer;