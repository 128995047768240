import styled from 'styled-components';

export const PageContent = styled.div`
    min-width:510px;
    height:fit-content;
    padding-bottom:20px;
`
export const HeaderDocumentationSpace = styled.div``
export const MainDocumentationSpace = styled.div``
export const RequiredDocumentationSpace = styled.div``
export const LabelDocumentItem = styled.label`
    margin-top: 10px;
    max-width: 75px;
`
export const ImgDocumentItem = styled.img`
    width:22px;
    height:27px;
`
export const MainDocumentItem = styled.div`
    display:flex; 
    flex-direction:column; 
    margin-right:17px;
    justify-content:flex-start;
    align-items:center;
`
export const SinistroNavigationCallToAction = styled.div`
    width:100%;
    height:150px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: space-between;
`
export const DocumentationOpcoes = styled.div`
    display: flex;
    flex-direction: row;
    width: 350px;
    justify-content:space-between;
`
export const HeaderTitle = styled.label`
    text-align: left;
    font: normal normal bold 29px/38px Roboto;
    letter-spacing: 0px;
    color: #636363;
    margin-bottom: 34px;
`
export const ButtonEvento = styled.button<{isSelected:boolean}>`
    width: 167px;
    height: 37px;
    border-radius: 30px;
    background: ${props=>props.isSelected?"#E27800 0% 0% no-repeat padding-box":"none"};
    color: ${props=>props.isSelected?"#FFFFFF":"#E27800"};
    border: 1px solid #E27800;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    ::after, ::before, :focus{
        outline:0px;
    };
`
export const RenderApiHtml = styled.div`
    p{
        font-size:1em;
        letter-spacing: 0px;
        opacity: 1;
        font-family:Roboto;
        color: #707070;
    }
`;
export const ContainerSection = styled.div`
    display:flex;
    flex-direction:column;
    width: 719px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 4px 6px #00000008;
    border-radius: 30px;
    min-height:67px;
    margin-bottom:23px;
`
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:23px;
    padding-left:32px;
    padding-right:20px;
`
export const Title = styled.label`
    text-align: left;
    font: normal normal bold 19px/25px Roboto;
    letter-spacing: 0px;
    color: #636363;
`
export const BodyContainer = styled.div``
export const HomeContainer = styled.div`
    margin-left:10px;
`
export const TituloContainer = styled.div`
    text-align: left;
    font: normal normal bold 29px/38px Roboto;
    color: #e27800;
    padding: 0;
    margin-bottom: 13px;
`
export const TextContainer = styled.label`
    margin: 0px;
    padding: 0px;
    text-align: left;
    font: normal normal normal 14px/19px Roboto;
    color: #636363;
`
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
    padding-top: 29px;
    padding-bottom: 14px;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    width: 167px;
    height: 161px;
    border-radius: 30px;
    box-shadow: 1px 4px 6px #00000008;
`
export const ImgDiv = styled.img`
    display: block;
    width: 61px;
    height: 79px;
    fill: #E27800;
`
export const Label = styled.label`
    margin:0px;
    padding:0px;
`
export const MainCardInterno = styled.div`
    width:645px;
    margin-left:32px;
    display:flex;
    flex-direction:column;
    padding-bottom:9px;
`
export const HeaderCardInterno = styled.div`
    margin-top:21px;
    text-align: left;
    font: normal normal bold 19px/25px Roboto;
    letter-spacing: 0px;
    color: #636363;
`
export const BodyCardInterno = styled.div`
    font: normal normal normal 12px/21px Roboto;
    color: #707070;
    a{
        font: normal normal normal 12px/21px Roboto;
        color: #E27800;
    }
    ul{
        padding-left: 24px;
        list-style: none;
    }
    ul li{
        color: red;
        margin-bottom:20px;
    }
    ul li ::before {
        content: "\2022";
        color: red;
        display: inline-block;
        width: 10px;
        margin-left: -10px;
    }
`
export const DocumentIconList = styled.div`
    display:flex;
`
export const HeaderTextContainer = styled.label`
    font: normal normal medium 18px/24px Roboto;
    letter-spacing: 0px;
    color: #636363;
`
export const ContantTextContainer = styled.div`
    margin-bottom: 8px;
    height:fit-content;
`
export const AlignBox = styled.div`
    position: relative;
    right: 120px;
    margin-top: 40px;
    margin-bottom: 40px;
    min-height:200px;
    display:flex;
    align-items:center;
    justify-content:center;
`
export const SubAlignBox = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    height:90%;
    min-height:320px;
`
export const DocumentItem = styled.div`
    cursor:pointer;
    display:flex;
    flex-direction:column;
    align-items:center;
    min-width:60px;
`
export const PaddedBottom = styled.div`
    padding-bottom: 10px;
`

export const Header = styled.h6`
    color: #e27800;
    font: Roboto;
    font-weight: bold;
    margin-bottom: 15px;
`;