import {Requisicao} from '../requisicao';
import IArgs from '../../interfaces/IArgs';
import { AxiosResponse } from 'axios';
import {APIFILE} from '../../services/APICallableActions/Adresses';
//DEPRECATED! THIS CATEGORY OF API REQUEST IS FROM NOW (22/03/2021) ON CONSIDERED OUT OF STRUCTURE ARQUITECTURE!


//DEPRECADO! ESTA CATEGORIA DE CHAMADA A API NÃO ESTÁ SENDO MAIS UTILIZADA A PARTIR DE 22/03/2021
//QUALQUER USO DESTA CHAMADA É CONSIDERADO FORA DE ARQUITETURA DE PROJETO!
export function PostAnexo(arquivo:any)
{
    let form = new FormData();
    form.append("arquivo",arquivo);
    return Requisicao<{nome:string,guid:string}>(APIFILE.AnexoAtendimento.GetPostAnexoAtendimento,{method:"POST",form:form});
}

export function PostAnexoIndexado(arquivo:File, tags: Partial<ITagsAnexo>)
{
    let form = new FormData();
    form.append("Arquivo",arquivo);
    const body : IArgs[] = Object.entries({
        ...tags??{},
        nomenclatura: arquivo.name,
    })?.map(([parameter,value])=>({
        parameter,
        value
    }))
    return Requisicao<{nome:string,guid:string}>(APIFILE.AnexoAtendimento.PostAnexoIndexadoAtendimento,{method:"POST",body,form});
}

export function GetAnexo(guid:string)
{
    const body:IArgs[]=[
        {
            parameter:"guid",
            value:guid,
        }
    ]
    return Requisicao(APIFILE.AnexoAtendimento.GetPostAnexoAtendimento,{method:"GETARCHIVE",body:body})
}
export async function ResolveFilesAsync(files:File[]|FileList) : Promise<{nome:string,guid:string}[]>
{
    let promisesToDeal: Promise<AxiosResponse<{nome:string,guid:string}>>[] = [];
    for(let i=0;i<files.length;i++)
    {
        promisesToDeal.push(PostAnexo(files[i]));
    }
    return await Promise.all(await promisesToDeal.map(async (promiseAtual)=>{
        return (await promiseAtual).data ;
    }))
}
export interface ITagsAnexo {
    cpf: string,
    protocolo: string, // só vai conseguir colocar na tela de upload de novos ou no crm
    dataEnvio: string,
    codigoBiblo:string,
    nomeArquivo:string,
    setor: string,
    motivo: string,
    canal: string // se for n tela de novos uploads ou pendencia colocar "retorno", se for no crm colocar "interno"
}

export async function EnviarArquivoIndexadoAsync(files:File[]|FileList, Tags: ITagsAnexo) : Promise<{nome:string,guid:string}[]>
{
    let promisesToDeal: Promise<AxiosResponse<{nome:string,guid:string}>>[] = [];
    for(let i=0;i<files.length;i++)
    {
        promisesToDeal.push(PostAnexoIndexado(files[i], Tags));
    }
    return await Promise.all(await promisesToDeal.map(async (promiseAtual)=>{
        return (await promiseAtual).data ;
    }))
}