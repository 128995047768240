import { FlexRightItems, ColoredActualPage, ColoredNextPage, UncoloredPrevPage, ColoredLastPage, UncoloredFirstPage } from "./style";
import React from 'react';
const Pagination:React.FC<{actualPage:number,setActualPage:any,length:number,maxPerPage?:number}> = (props):React.ReactElement =>{
    const {actualPage,setActualPage,length}=props;
    const vector=new Array(length).fill(null);
    const maximoPorPagina = (props.maxPerPage ?? 6)
    const limiteMostradorPaginasSuperior = maximoPorPagina*(actualPage+4);
    const limiteMostradorPaginasInferior = maximoPorPagina*(actualPage-6);
    return(
        <FlexRightItems>
            <label style={{color:"#afb4b8",marginRight:"2%",marginBottom:"0"}}>Exibindo {((actualPage*maximoPorPagina)-maximoPorPagina)+1} ao {(actualPage!==Math.ceil(vector.length/maximoPorPagina)?(actualPage*maximoPorPagina):vector.length)} dos {vector.length} resultados</label>
            <UncoloredFirstPage onClick={()=>{setActualPage(1)}}>Primeira</UncoloredFirstPage>
            <UncoloredPrevPage onClick={()=>{if(actualPage!==1)setActualPage(actualPage-1)}}>&lt;</UncoloredPrevPage>
            {
                actualPage>5?<UncoloredPrevPage onClick={()=>{setActualPage(1)}}>{1}</UncoloredPrevPage>:""
            }
            {
                actualPage>6?<label style={{marginRight:"1%"}}>...</label>:""
            }
            {
                vector.map((anything:any,i:number)=>{
                    if(i%maximoPorPagina===0 && limiteMostradorPaginasInferior<i && i<limiteMostradorPaginasSuperior)
                    {
                        if((i/maximoPorPagina)<actualPage-1)
                        return(<UncoloredPrevPage onClick={()=>{setActualPage((i/maximoPorPagina)+1)}}>{((i)/maximoPorPagina)+1}</UncoloredPrevPage>)
                        else if((i/maximoPorPagina)===actualPage-1)
                        return (<ColoredActualPage onClick={()=>{setActualPage((i/maximoPorPagina)+1)}}>{((i)/maximoPorPagina)+1}</ColoredActualPage>);
                        else if((i/maximoPorPagina)>actualPage-1)
                        return <ColoredNextPage onClick={()=>{setActualPage((i/maximoPorPagina)+1)}}>{((i)/maximoPorPagina)+1}</ColoredNextPage>
                    }
                    return ""
                })
            }
            {
                vector.length>=(limiteMostradorPaginasSuperior+maximoPorPagina)?<label style={{marginRight:"1%"}}>...</label>:""
            }
            {
                vector.length>=limiteMostradorPaginasSuperior?<ColoredNextPage onClick={()=>{setActualPage(Math.ceil(vector.length/maximoPorPagina))}}>{Math.ceil(vector.length/maximoPorPagina)}</ColoredNextPage>:""
            }
            <ColoredNextPage onClick={()=>{if(actualPage!==Math.ceil(vector.length/maximoPorPagina))setActualPage(actualPage+1)}}>&gt;</ColoredNextPage>
            <ColoredLastPage onClick={()=>{setActualPage(Math.ceil(vector.length/maximoPorPagina))}}>Ultima</ColoredLastPage>
        </FlexRightItems>
    )
}
export default Pagination;