import React, { useState, useContext} from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { initializeIcons } from '@uifabric/icons';
import Modal from 'react-bootstrap/Modal';
import { IFavorite, IFavoriteAdd } from '../../../interfaces/components/IFavorite';
import RoundedComponent from '../../../components/RoundedComponent';
import { DashedAdd, AddIcon, AddText, ColumnModal, Linha, SetFavorites, Page, PageContent, BeforePage, ReceptionText, RoundedSpace, RoundedColumn, SelectedRoundedComponentRendered, ReturnIcoPlace, SelectedRoundBody, UserMessage, SelectedRoundedIconFA } from './styles';
import { ModalInside } from '../../../components/RoundedComponent/styles';
import {  DeleteFavorite,GetFavorites, PutListFavorites, PatchOrdemAtalhos } from '../../../API/usuario';
import TipsLarge from '../../../components/widgets/TipsLarge' ;
import { GlobalContext } from '../../../storage/contexts/GlobalContext';
import { faArrowsAltH,faUndoAlt,faTrashAlt,faArrowCircleRight,faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IcomoonReact from "icomoon-react";
import StyledCheckbox from '../../../components/CustomCheckBox';
import { LoaderRotatorMessage } from '../../../components/Loaders/LoaderRotator';
let allAtalhos: any = [];
type FavoritesList={ checked: boolean, ref: number, ordem:number}
type FavoriteAPIFormat={"idItemMenu": number,"ordem": number}
export const Home: React.FC<{}> = (): React.ReactElement => {
	const [selected, setSelected] = useState(-1);
	const [checkedList, SetcheckedList] = useState();
	allAtalhos = useContext(GlobalContext).globalContext.AllFavorites;
	initializeIcons();
	return (
				<PageRender checkedList={checkedList} setCheckedList={SetcheckedList} selected={selected} setSelected={setSelected} />
	);
}
const RenderRoundedComponent:React.FC<{count: any, selected: number, setSelected: any}> = (props):React.ReactElement => {
	const {count, selected, setSelected} = props;
	const favorites=useContext(GlobalContext).globalContext.Favorites;
	if (!((selected === count)))
		return <RoundedComponent item={(favorites as IFavorite[])[count]} callBackOptions={() => { setSelected(count) }} />
	else
		return <SelectedRoundedComponent count={count} setSelected={setSelected}/>;
}

const SelectedRoundedComponent:React.FC<{setSelected: any,count:number}> = (props) => {
		const {globalContext,setGlobalContext}= useContext(GlobalContext);
		const {Favorites}=globalContext;
	const removeFavorite = (count:number,setSelected: any,setSmShow:any) => {
		setSmShow(true);
		let alteravel = Favorites;
		let resposta: IFavorite[] = [];
		alteravel?.map((thisitem: IFavorite,i:number) => {
			if (!(i===count))
				resposta.push(thisitem);
		});
		DeleteFavorite((Favorites as IFavorite[])[count].favoriteId)?.then(()=>{
			GetFavorites()?.then((response)=>{
				const retorno = response?.data?.map((item:any)=>{
					return {
						message: item.itemMenu.titulo,
						icoReference:item.itemMenu.urlIcone,
						favoriteId:item.itemMenu.id,
						url:item.itemMenu.urlRedirecionamento,
						ordem:item.ordem,
						deleteId:item.id,
					}
				})
				setSmShow(false);
				setGlobalContext({...globalContext, Favorites: retorno})
			}).finally(()=>{setSmShow(false);setSelected(-1);});
		});
		
	}
	const Move = (direction:"right"|"left") =>
	{
		//setGlobalContext({...globalContext, Favorites: retorno})
		if(direction==="right")
		{
			let favoritesTemp= globalContext.Favorites as IFavorite[];
			if(count>=0 && count<favoritesTemp?.length-1)
			{
				if(count+2>=favoritesTemp.length)
				{
					favoritesTemp[count].ordem=favoritesTemp[count+1].ordem+1;
				}
				else
				{
					favoritesTemp[count].ordem=(favoritesTemp[count+1].ordem+favoritesTemp[count+2].ordem)/2
				}
				let oneIndexUp = favoritesTemp[count+1];
				favoritesTemp[count+1]=favoritesTemp[count];
				favoritesTemp[count]=oneIndexUp;
				setGlobalContext({...globalContext,Favorites:favoritesTemp});
				PatchOrdemAtalhos(favoritesTemp[count+1].favoriteId,favoritesTemp[count+1].ordem)
			}
		}
		else if(direction==="left")
		{
			let favoritesTemp= globalContext.Favorites as IFavorite[];
			if(count>0 && count<=favoritesTemp?.length-1)
			{
				if(count===1)
				{
					favoritesTemp[0].ordem=(favoritesTemp[count].ordem+favoritesTemp[count+1].ordem)/2;
					PatchOrdemAtalhos(favoritesTemp[0].favoriteId,favoritesTemp[0].ordem)
				}
				else
				{
					favoritesTemp[count].ordem=(favoritesTemp[count-1].ordem+favoritesTemp[count-2].ordem)/2
					PatchOrdemAtalhos(favoritesTemp[count].favoriteId,favoritesTemp[count].ordem);
				}
				let oneIndexDown = favoritesTemp[count-1];
				favoritesTemp[count-1]=favoritesTemp[count];
				favoritesTemp[count]=oneIndexDown;
				setGlobalContext({...globalContext,Favorites:favoritesTemp});
			}
		}
	}
	const [reorderSelected,setReorderSelected] = useState<boolean>(false);
	const {setSelected,count}=props;
	const [smShow,setSmShow] = useState<boolean>(false); 
	return (
		<SelectedRoundedComponentRendered>
			{
			!reorderSelected?
			<div style={{height:"100%",width:"100%"}}>
				<ReturnIcoPlace>
					<Icon iconName="ChromeClose" style={{ color: "#ced4da",fontSize:"80%",marginRight:"3%",position:"relative",top:"3px",cursor: "pointer" }} onClick={() => { setSelected(-1) }} />
				</ReturnIcoPlace>
				<SelectedRoundBody>
					<SelectedRoundedIconFA onClick={() => {setSmShow(true);removeFavorite(count,setSelected,setSmShow)}} icon={faTrashAlt}/>
					<SelectedRoundedIconFA onClick={()=>{setReorderSelected(true)}} icon={faArrowsAltH}/>
				</SelectedRoundBody>
				<Modal size="sm" show={smShow} onHide={() => setSmShow(false)} backdrop={"static"} centered>
					<LoaderRotatorMessage width="300px">
						Atualizando informações com o servidor
					</LoaderRotatorMessage>
				</Modal>
			</div>
			:
			<div style={{height:"100%",width:"100%"}}>
			<ReturnIcoPlace>
				<Icon iconName="ChromeClose" style={{ color: "#ced4da",fontSize:"80%",marginRight:"3%",position:"relative",top:"3px",cursor: "pointer" }} onClick={() => { setSelected(-1) }} />
				<FontAwesomeIcon icon={faUndoAlt} style={{color: "#ced4da",cursor:"pointer",marginLeft:"5%",position:"relative",top:"3px"}} onClick={()=>{setReorderSelected(false)}}/>
			</ReturnIcoPlace>
			<SelectedRoundBody>
				<SelectedRoundedIconFA onClick={() => {Move("left")}} icon={faArrowCircleLeft}/>
				<SelectedRoundedIconFA onClick={() => {Move("right")}} icon={faArrowCircleRight}/>
			</SelectedRoundBody>
			</div>
			}
		</SelectedRoundedComponentRendered>
	)
}
const ActualItem:React.FC<{item:IFavoriteAdd,checkedList:FavoritesList[],checkedItens:{itensSelecionados:FavoriteAPIFormat[],setItensSelecionados:any},i:any,checkeditem:boolean,SetCheckedList:any}>=(props) => 
{
	const {item,checkedList,i,checkeditem,checkedItens,SetCheckedList}=props;
	// eslint-disable-next-line
	const[action,Act]=useState(checkeditem);
	const icones=useContext(GlobalContext).globalContext.Icones;
	function TempIco (url:string)
	{
		const palavraChave =".png";
		const palavraChave2 = "icones/ico";
		return url.slice(url.search(palavraChave2)+palavraChave2.length,url.search(palavraChave));
	}
	return (
		<div style={{ height: "10%" }}>
			<div style={{ display: "flex",alignItems:"center" }}>
				<IcomoonReact iconSet={icones} size="35px" icon={"CAPEMISA---Icones-"+TempIco(item.icoReference)} />
				<label style={{ margin: "0px",flexGrow:1,fontWeight:(item.parent?"normal":"bold")}}>{item.message}</label>
				<StyledCheckbox onClick={() => 
				{
					let canSet = true;
					if(!checkedList[i].checked)
						if(checkedItens.itensSelecionados.length<8)
						checkedItens.setItensSelecionados([...checkedItens.itensSelecionados,{idItemMenu:checkedList[i].ref,ordem:(checkedItens.itensSelecionados.length*10)}])
						else
						canSet=false;
					else
					{
						if(checkedItens.itensSelecionados.length<9)
						{
							let counter:number=0;
							let toSend:FavoriteAPIFormat[] =[];
							checkedItens.itensSelecionados.map((item:FavoriteAPIFormat)=>{
								if(item.idItemMenu!==checkedList[i].ref)
								{
									toSend.push({idItemMenu:item.idItemMenu,ordem:(counter*10)});
									counter++;
								}
								return ""
							});
							checkedItens.setItensSelecionados(toSend);
						}
						else
						canSet=false;
					}
					if(canSet)
					{
						checkedList[i]={checked:!checkedList[i].checked,ref:checkedList[i].ref,ordem:0};
						SetCheckedList(checkedList);
						Act(checkedList[i].checked);
					}
				}} checked={checkedList[i].checked}/>
			</div><Linha></Linha></div>)
}
const ModalShortcut: React.FC<{countervector: any,itensSelecionados:FavoriteAPIFormat[],setItensSelecionados:any, widthCalculada: any, checkedList: any, opcoes: any, SetcheckedList: any}> = (props) => 
{
	const {countervector, widthCalculada, checkedList, opcoes, SetcheckedList,itensSelecionados,setItensSelecionados} = props;
	console.log(itensSelecionados);
	return (
		<ModalInside>
			{

				countervector.map((numeral: number) => {
					return (<ColumnModal width={widthCalculada}>
						{
							opcoes.map((item: IFavoriteAdd, i: number) => {
								const show = ((((numeral + 1) * 10) - 10) <= i) && i < ((numeral + 1) * 10);
								if (show) {
									if (checkedList.length > 0) {
										return <ActualItem item={item} checkedItens={{itensSelecionados:itensSelecionados,setItensSelecionados:setItensSelecionados}} checkedList={checkedList} i={i} checkeditem={checkedList[i].checked} SetCheckedList={SetcheckedList} />
									}
								}
								return ""
							})
						}
					</ColumnModal>);
				})
			}
		</ModalInside>
	);
}
const AddShortcut :React.FC<{}> = () => {
	const [show, setShow] = useState(false);
	let opcoes = allAtalhos;
	const {globalContext,setGlobalContext} = useContext(GlobalContext);
	let itensSelecionadosRef:FavoriteAPIFormat[] = [];
	function AtualizarPagina(callBack?:any)
	{
		GetFavorites()?.then((response:any)=>{
			const retorno:IFavorite[] = response.data?.map((item:any)=>{
				return {
					message: item.itemMenu.titulo,
					icoReference:item.itemMenu.urlIcone,
					favoriteId:item.itemMenu.id,
					url:item.itemMenu.urlRedirecionamento,
					ordem:item.ordem,
				}
			});
			if(callBack)
			{
				callBack();
			}
			console.log(retorno);
			setGlobalContext({...globalContext, Favorites: retorno});
		});
	}
	const handleClose = () =>{ AtualizarPagina();setShow(false)}
	const handleShow = () => setShow(true);
	let checkedlistref: FavoritesList[] = opcoes.map((item: IFavoriteAdd) => {
		let isChecked = false;
		let ordemAtual = null;
		globalContext.Favorites?.map((favorite: IFavorite) => {
			if (favorite.favoriteId === item.favoriteId) {
				isChecked = true;
				ordemAtual = favorite.ordem;
			}
		});
		return ({ checked: isChecked, ref: item.favoriteId, ordem: (ordemAtual ? ordemAtual : null) });
	});
	globalContext.Favorites?.map((favorite: IFavorite,i:number)=>{
		let ordemAtual = 0;
		checkedlistref.map((item: FavoritesList)=>{
			if (favorite.favoriteId === item.ref) {
				ordemAtual = i*10;
				itensSelecionadosRef.push({idItemMenu:item.ref,ordem:i*10});
			}
			return ""
		});
		if(checkedlistref[i])
		checkedlistref[i].ordem=ordemAtual;
	})
	const [itensSelecionados,setItensSelecionados]= useState<FavoriteAPIFormat[]>(itensSelecionadosRef);
	const [checkedList,SetCheckedList] =useState(checkedlistref);
	let countervector: any = [];
	const [smShow,setSmShow] = useState(false);
	for (let count: number = 0; count < Math.ceil((opcoes.length / 10)); count++)
		countervector.push(count);

	const SendChanges=()=>
	{
		setSmShow(true);
		PutListFavorites(itensSelecionados)?.then(()=>{AtualizarPagina(()=>{setSmShow(false);setShow(false)});});
	}
	return (
		<>
			<Modal show={show} onHide={() =>{handleClose()}} centered size="lg">
				<Modal.Body>
					{(checkedlistref ? <ModalShortcut countervector={countervector} itensSelecionados={itensSelecionados} setItensSelecionados={setItensSelecionados} widthCalculada="32%" checkedList={checkedList} opcoes={opcoes} SetcheckedList={SetCheckedList}/> : "")}
				</Modal.Body>
				<Modal.Footer>
					<div style={{ display: "flex", justifyContent: "flex-end",height:"2rem",width:"25%"}}>
						<SetFavorites onClick={() => {SendChanges()}}>Confirmar</SetFavorites>
					</div>
					<Modal size="sm" show={smShow} onHide={() => setSmShow(false)} backdrop={"static"} centered>
						<LoaderRotatorMessage width="300px">
							Atualizando informações com o servidor
						</LoaderRotatorMessage>
					</Modal>
				</Modal.Footer>
			</Modal>
			<DashedAdd onClick={() => { handleShow() }}>
				<AddIcon iconName="CircleAddition"/>
				<AddText>INSERIR ATALHO</AddText>
			</DashedAdd>
		</>
	);
}
const PageRender:React.FC<{selected: number, setSelected: any,checkedList:any,setCheckedList:any}> = (props):React.ReactElement => {
	const CorrigeNome = (nameToFix:string):string =>
	{
		const nome=nameToFix;
		let hasSpace=false;
		let fixedName = '';
		for(let i=0;i<nome?.length;i++)
		{
			if(nome[i]===' ')
			hasSpace=true;
			if(!hasSpace)
			fixedName+=nome[i];
		}
		return fixedName;
	}
	const {globalContext: {Informativos, Favorites, PreUser}} = useContext(GlobalContext);
	const mensagem = PreUser?.mensagem;
	const [isOpen, setIsOpen] = useState(!!mensagem);
  const handleClose = () => setIsOpen(false);

	const roundedOptions: IFavorite[] = Favorites as IFavorite[];
	return (
		<>
			<Modal
				show={isOpen}
				centered
				size="lg"
			>
				<Modal.Body >
					<div dangerouslySetInnerHTML={{__html: mensagem ?? ''}}/>
				</Modal.Body>
				<Modal.Footer>
					<div style={{ display: "flex", justifyContent: "flex-end",height:"2rem",width:"25%"}}>
						<SetFavorites onClick={handleClose}>Confirmar</SetFavorites>
					</div>
				</Modal.Footer>
			</Modal>
			<Page>
				<PageContent>
					<BeforePage>
						<UserMessage>
							<ReceptionText>OLÁ, {CorrigeNome(PreUser?.nome ?? "")}!</ReceptionText>
							<label style={{ fontSize: "100%",marginBottom:"0px"}}>Seja bem-vindo (a) ao SEU PORTAL CAPEMISA. 
							Aqui você poderá acessar todas as informações que você precisa saber sobre os seus produtos, 
							documentos e serviços. Fique à vontade! A casa é sua!</label>
						</UserMessage>
						<div style={{width:"60%",minWidth:"550px",display:"flex",justifyContent:"flex-end",flexShrink:0}}>
							<TipsLarge data={Informativos ?? []} />
						</div>
					</BeforePage>
					<RoundedSpace>
						<RoundedColumn>
							{
								roundedOptions.map((item: IFavorite, i: any) => {
									if (i <= 3)
										return <RenderRoundedComponent count={i} selected={props.selected} setSelected={props.setSelected}/>
									return ""
								})
							}
							{
								(roundedOptions.length < 4 ?
									<AddShortcut/> : "")
							}
						</RoundedColumn>
						<RoundedColumn>
							{ 
								roundedOptions.map((item: IFavorite, i: any) => {
									if (i >= 4)
									return <RenderRoundedComponent count={i} selected={props.selected} setSelected={props.setSelected}/>
									return ""
								})
							}
							{
								(roundedOptions.length >= 4 && roundedOptions.length < 8 ?
									<AddShortcut/> : "")
							} 
						</RoundedColumn>
					</RoundedSpace>
				</PageContent>
			</Page>
		</>
	);
}
export default Home;
