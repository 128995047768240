
import { ITagsAnexo as ITagsAnexo } from '../../API/file';
import {API_URL_Common, API_URL_Documentos, API_URL_File,API_CEP} from '../../env_settings';
const apiUF = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
const pageUser=API_URL_Common;
const pageDocumentos=API_URL_Documentos;
export const apiCEP=API_CEP;
const pageFile=API_URL_File;
export const APIDOCUMENTOS={
    Documento:{
        Documento:pageUser+"/documento",
        DocumentoUrl:pageUser+"/documento/url",
        downloadDocumento:pageDocumentos+"/download",
    },
    TipoDocumento:{
        DocumentosUser:pageUser+"/itemmenu/tipodocumento",
    }
};
export const APIFILE={
    AnexoAtendimento:{
        GetPostAnexoAtendimento:pageFile+"/anexoatendimento",
        PostAnexoIndexadoAtendimento:`${pageFile}/anexoatendimento/indexado`,
    }
}
export const APIIBGE={
    UF:{
        UF:apiUF,
    }
}
export const APIOUTSIDEGATEWAY={
    Usuario:{
        PatchDadosComunicante: pageUser +"/usuario/comunicante/dados"
    },
    Beneficiario:{
        GETBeneficiario: pageUser+"/beneficiario",
        GETBeneficiarioDetalhe: pageUser+"/beneficiario/detalhe"
    },
}
export const APIUSER={
    Icones:"https://capdevstportalrelaciona.blob.core.windows.net/portalrelacionamento/icones/icones.json",
    Beneficiario:{
        GETBeneficiario:pageUser+"/beneficiario",
        GETBeneficiarioDetalhe:pageUser+"/beneficiario/detalhe"
    },
    Cliente:{
        Cliente:pageUser+"/cliente",
    },
    Convenio:{
        Convenio:pageUser+"/convenio",
    },
    Duvidas:{
        GETDuvida:pageUser+"/duvida",
        GETDuvidasFrequentes:pageUser+"/duvida/frequentes",
    },
    Usuario:{
        GetPreUser:pageUser+"/usuario/login",
        GetUser:pageUser+"/usuario/dados",
        PatchContatos:pageUser+"/usuario/dados",
        VerificaCadastro:pageUser+"/usuario/verificacadastro",
        VerificaSegurado:pageUser+"/usuario/verificasegurado",
        GetLoginComunicante:pageUser+"/usuario/comunicante/login",
        PatchDadosComunicante:pageUser+"/usuario/comunicante/dados",
        GetVerificaSeguradoEvento:pageUser+"/usuario/verificaseguradoevento",
    },
    Novidade:{
        GetNovidades:pageUser+"/novidade",
        GetNovidadeExclusiva:pageUser+"/novidade/exclusiva",
    },
    AtalhoMenu:{
        CRUDAtalhoMenu:pageUser+"/atalhomenu",
        GetItemMenu:pageUser+"/itemmenu",
        POSTAtalhoMenu:pageUser+"/atalhomenu/listaatalhomenu",
    },
    PerguntasSeguranca:{
        GetPerguntas:pageUser+"/usuario/perguntavalidacao",
        POSTPerguntas:pageUser+"/usuario/perguntavalidacao/resposta",
    },
    Informativo:{
        GetInformativo:pageUser+"/informativo",
    },
    InfoAtendimento:{
        GetInfoAtendimento:pageUser+"/infoatendimento",
    },
    TipoAtendimento:{
        GetTipoAtendimento:pageUser+"/tipoatendimento",
    },
    Atendimento:{
        CRUDAtendimento:pageUser+"/atendimento/usuario",
        GetAtendimentoEspecifico:pageUser+"/atendimento",
        PostAtendimentoSinistro:pageUser+"/atendimento/usuario/avs",
        GetListarPendenciasSinistro:pageUser+"/atendimento/avs/pendencia",
        GetListarPendenciasSinistroUser:pageUser+"/atendimento/usuario/avs/pendencia",
        GetVerfificaExisteSinistroMesmoSeguradoEvento:`${pageUser}/atendimento/usuario/avs/verificaexiste`,
        GetAtendimentoByProtocoloAndCpf:`${pageUser}/atendimento/avs/protocolo`,
        PatchSolicitarProtocolo:`${pageUser}/atendimento/usuario/solicitarprotocolo`,
        PostInserirDocumentoPendencia:`${pageUser}/atendimento/usuario/avs/documento`,
    },
    AssistenciaFinanceira:{
        POSTAssistenciaFinanceira:pageUser+"/assistenciafinanceira",
    },
    Produto:{
        GetProdutos:pageUser+"/produto",
        GetContratos:pageUser+"/produto/contrato",
        GetContratoDetalhe:pageUser+"/produto/contrato/detalhe",
    },
    Notificacoes:{
        GetNotificacoes:pageUser+"/notificacao",
    },
    Relacionamento:{
        GetRelacionamento:pageUser+"/relacionamento"
    },
    TipoDocumentosSinistro:{
        GetTipoDocumento:pageUser+"/tipodocumentosinistro",
        GetDocumentosByEventos:pageUser+"/tipodocumentosinistro/evento"
    },
    EventoSinistroPortal:{
        GetEventoSinistroPortal:pageUser+"/eventosinistroportal",
        GetEventoSinistroPortalSegurado:pageUser+"/eventosinistroportal/eventosegurado"
    },
    DocumentoEventoCapemisa:{
        GetDocumentoEventoCapemisa:pageUser+"/documentosinistro",
    },
};