import React, { useState } from 'react';
import { BackgroundPage, PageContent, ModalDialog, PageHeader,Logo, HeaderText, ModalBody, Linha, RoundedInput, RoundedInputSpace, RoundedInputText, DoubleLinha, HalfLinha, EspacoConfirm, MensagemTermosCondicoes, BackToLogin, SubmitLaunch} from './styles';
import { Redirect } from 'react-router';
import { B2C_URL_SIGNIN } from '../../../env_settings';
export const ConfirmaDados: React.FC<{}> = (): React.ReactElement => {
    const[redirect,setRedirect]=useState(false);
return (
    <div>
        {
            redirect?
            <Redirect to="/inicio" />:''
        }
        <BackgroundPage/>
        <PageContent>
            <ModalDialog>
                <PageHeader>
                    <HeaderText>CONFIRMAÇÃO DE DADOS</HeaderText>
                    <Logo src="https://capprdstportalorteador.blob.core.windows.net/b2crelacionamento/logo-capemisa-seguradora.png"/>
                </PageHeader>
                <ModalBody>
                    <Linha>
                        <RoundedInputSpace width="70%">
                            <RoundedInputText>Nome</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                        <RoundedInputSpace width="28%">
                            <RoundedInputText>Data de Nascimento</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                    </Linha>
                    <Linha>
                        <RoundedInputSpace width="40%">
                            <RoundedInputText>CPF</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                        <RoundedInputSpace width="40%">
                            <RoundedInputText>RG</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                        <RoundedInputSpace width="18%">
                            <RoundedInputText>Sexo</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                    </Linha>
                    <Linha>
                        <RoundedInputSpace width="28%">
                            <RoundedInputText>Telefone 1</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                        <RoundedInputSpace width="28%">
                            <RoundedInputText>Telefone 2</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                        <RoundedInputSpace width="40%">
                            <RoundedInputText>E-mail</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                    </Linha>
                    {/* <Linha>
                        <RoundedInputSpace width="48%">
                            <RoundedInputText>Nome da Mãe</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                        <RoundedInputSpace width="48%">
                            <RoundedInputText>Nome do Pai</RoundedInputText>
                            <RoundedInput type="text"/>
                        </RoundedInputSpace>
                    </Linha> */}
                    <EspacoConfirm>
                        <DoubleLinha>
                            <HalfLinha>
                                <RoundedInputSpace width="100%">
                                        <RoundedInputText>Criar Senha</RoundedInputText>
                                        <RoundedInput type="password"/>
                                </RoundedInputSpace>
                            </HalfLinha>
                            <HalfLinha>
                                <RoundedInputSpace width="100%">
                                        <RoundedInputText>Confirmar Senha</RoundedInputText>
                                        <RoundedInput type="password"/>
                                </RoundedInputSpace>
                            </HalfLinha>
                        </DoubleLinha>
                        <DoubleLinha>
                            <MensagemTermosCondicoes>
                                Ao clicar em Enviar, você concorda com nossos <label style={{color:"#E27800"}}>Termos, 
                                Política de Dados</label> e <label style={{color:"#E27800"}}>Política de Cookies.</label> 
                                Você pode receber notificações por SMS e pode 
                                cancelar isso quando quiser
                            </MensagemTermosCondicoes>
                            <SubmitLaunch type="button" value="ENVIAR" onClick={()=>{setRedirect(true)}}/>
                            <BackToLogin onClick={()=>{sessionStorage.clear();window.location.href=B2C_URL_SIGNIN;}}>VOLTAR PARA LOGIN</BackToLogin>
                        </DoubleLinha>
                    </EspacoConfirm>
                </ModalBody>
            </ModalDialog>
        </PageContent>
    </div>
)
}
export default ConfirmaDados;