import React, { useState, useEffect} from 'react';
import {Logo, HeaderText, Linha, RoundedInputSpace, RoundedInputText, RoundedInput, SubmitLaunch,BackToLogin, MensagemTermosCondicoes, RoundedSelect, RoundedMasked } from './styles';
import { B2C_URL_SIGNIN } from '../../../env_settings';
import {IPerguntaSeguranca} from '../../../interfaces/components/IPerguntaSeguranca';
import {GetPerguntasAutenticacao,POSTPerguntasAutenticacao} from '../../../API/usuario';
import { initializeIcons } from '@uifabric/icons';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import 'react-phone-number-input/style.css';
import '../../../styles/compontents(DEPRECATED)/PhoneInput.css';
import { Redirect } from 'react-router-dom';
import BlurredBackgroundComponent from '../../../components/BlurredBackground';
import TelMaskedInput from '../../../components/TelMaskedInput';
import StyledAlert from '../../../components/StyledAlert';
export const VerificacaoSeguranca: React.FC<{}> = (): React.ReactElement => {
    const [perguntas,setPerguntas] = useState<IPerguntaSeguranca[]>([]);
    const [answersRight,isAnswerRight]= useState(false);
    const [errorArray,setErrorArray] = useState<boolean[]>([]);
    useEffect(() =>{
        GetPerguntasAutenticacao()?.then((response:any)=>
        { 
            console.log(response);
            setPerguntas(response.data);
            setErrorArray(new Array(response.data.length).fill(false));
        })
    },[]);
    initializeIcons();
    return (
    <div>
        {
            answersRight?
            <Redirect to="/load" />:''
        }
            <BlurredBackgroundComponent height="90%" width="35%">
                <Logo src="https://capprdstportalorteador.blob.core.windows.net/b2crelacionamento/logo-capemisa-seguradora.png"/>
                <HeaderText>Verificação de Segurança</HeaderText>
                {
                    perguntas.map((item:IPerguntaSeguranca,i:number)=>{
                        console.log(item);
                        //if(i<3)
                        const actualItem:IPerguntaSeguranca = {...item,isNegado:errorArray[i]}; 
                        return(
                            <Linha>
                                <RoundedInputSpace width="90%">
                                    <RoundedInputText>{item.pergunta}</RoundedInputText>
                                    <SetInput i={i} item={actualItem}/>
                                </RoundedInputSpace>
                            </Linha>
                        )
                    })
                }
                <div style={{height:"10%",width:"90%"}}>
                    <SubmitLaunch type="button" onClick={()=>{MandarResposta(respostas,isAnswerRight,setErrorArray)}} value="ENVIAR"/>
                </div>
                <div style={{width:"90%"}}>
                    <BackToLogin onClick={()=>{sessionStorage.clear();window.location.href=B2C_URL_SIGNIN;}}>VOLTAR PARA LOGIN</BackToLogin>
                </div>
                <MensagemTermosCondicoes>
                    Precisa de ajuda para redifinir sua senha? <label style={{color:"#E27800"}}>Entre
                     em contato com nossa equipe</label>
                </MensagemTermosCondicoes>
            </BlurredBackgroundComponent>
    </div>
    );
}
/*const SepararIndex= (vetor:any,index:number,value:any)=>{
    let newVector:any=[];
    vetor.map((item:any,i:number)=>{
        if(i!=index)
        newVector.push(item);
        else
        newVector.push(value);
    });
    return newVector;
}*/
type RoundedInputInfo = {value:string,setValue:any,i:number,item:IPerguntaSeguranca};
const RoundedInputData :React.FC<RoundedInputInfo> = (props) : React.ReactElement =>
{
    const {setValue,value,i,item} = props;
        console.log(value);
    return (<RoundedMasked isNegado={item.isNegado} mask="11/11/1111" placeholder="DD/MM/AAAA" size={11} 
            value={value} onChange={
            (event:any)=>{
                    respostas[i]=
                    {
                        "resposta":event.target.value,
                        "codigoPergunta": item.codigo,
                    };
                    setValue(event.target.value);
            }}/>);
}
const RoundedInputTelefone : React.FC<RoundedInputInfo>  = (props) : React.ReactElement =>
{
    const {setValue,value,i,item} = props;
    console.log(value);
    return (<TelMaskedInput isNegado={item.isNegado} 
            value={value} onChange={
            (event:any)=>{
                console.log(event.target.value);
                    respostas[i]=
                    {
                        "resposta":event.target.value.replace(/-|_/g, ''),
                        "codigoPergunta": item.codigo,
                    };
                    setValue(event.target.value);
            }}/>);
}

const MandarResposta = (respostas:{codigoPergunta: number,resposta: string}[],isAnswerRight:Function,setErrorArray:Function)=>{
    let counter=0;
    const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
    respostas.map((item:{codigoPergunta: number,resposta: string},i:number)=>{
        if(item && item.resposta)
        {
            counter++;
            console.log(dateRegex.test(respostas[i].resposta));
            if(dateRegex.test(respostas[i].resposta))
            {    
                respostas[i].resposta=respostas[i].resposta.slice(6,10)+"-"+respostas[i].resposta.slice(3,5)+"-"+respostas[i].resposta.slice(0,2)
            }
        }
        return ""
    });
    console.log(counter);
    console.log(respostas);
    if(counter>=3)
    {
        type APISegurancaResponse = {codigoPergunta:number,resultado:boolean};
        POSTPerguntasAutenticacao(respostas)?.then((response:{data:APISegurancaResponse[]})=>
        {
            let finalErrorArray :Boolean[] = [];
            if(response.data.reduce((previousValue:APISegurancaResponse,actualValue:APISegurancaResponse)=>{finalErrorArray.push(!actualValue.resultado); if(previousValue.resultado && actualValue.resultado)return previousValue; else return {codigoPergunta:0,resultado:false} },{codigoPergunta:0,resultado:true}).resultado)
                isAnswerRight(true);
            else
            {
                console.log(finalErrorArray);
                setErrorArray(finalErrorArray);
                StyledAlert("Você errou alguma informação.");
            } 
        })
    }
    else
    StyledAlert("Preencha todos os campos, por favor");
}

let respostas:any=[];
const SetInput: React.FC<{"item":IPerguntaSeguranca,"i":number}> = (props): React.ReactElement =>{
    const {item,i} = props;
    const [resposta,setResposta]=useState('');
    console.log(respostas);
    switch(item.tipo)
    {
        case "Texto":
            return (<RoundedInput isNegado={item.isNegado} value={resposta} onChange={(event)=>{console.log(respostas);respostas[i]={"resposta":event.target.value,"codigoPergunta": item.codigo};setResposta(event.target.value)}} type="text"/>);
        case "Telefone":
            return (<RoundedInputTelefone value={resposta} setValue={setResposta} i={i} item={item}/>
            );    
        case "Data":
            return (<RoundedInputData i={i} item={item} setValue={setResposta} value={resposta}/>);
        case "Domínio":
            return (
                <div style={{width:"100%",height:"80%",border:item.isNegado?"1px solid red":"1px solid #B9B9B9",borderRadius:"29px",display:"flex",alignItems:"center"}}>
                    <RoundedSelect isNegado={item.isNegado} onChange={(event)=>{respostas[i]={"resposta":event.target.value,"codigoPergunta": item.codigo};setResposta(event.target.value)}}>
                        <option value="" selected disabled>Selecione...</option>
                        {
                            item.opcoes?.map(
                                (filho:{descricao:string,valor:string})=>
                                {
                                    return(<option value={filho.valor}>{filho.descricao}</option>);
                                }
                            )
                        }
                    </RoundedSelect>
                    <Icon style={{position:"relative",right:"5%",pointerEvents: "none",}}iconName="ChevronUp"/>
                </div>
            );
        default:
            return (<RoundedInput value={resposta} onChange={(event)=>{console.log(respostas);respostas[i]={"resposta":event.target.value,"codigoPergunta": item.codigo};setResposta(event.target.value)}} type="text"/>);
    }
}
export default VerificacaoSeguranca;