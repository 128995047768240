import { B2C_URL_SIGNIN, B2C_URL_PasswordReset} from './env_settings'
import { IAuth } from './interfaces/storage/contexts/IAuthContext';

export const Authentication = () => {
    const authUrl = window.location.href;
    const novaUrl = authUrl.replace("#", "?&");
    let parametros = new URLSearchParams(novaUrl);
    if(authUrl.match(/AADB2C90118/)){
        window.location.href = B2C_URL_PasswordReset+(parametros.get("state")?"&state="+parametros.get("state"):"")
    }
    else if(authUrl.match(/AADB2C90091/)){
        window.location.href = B2C_URL_SIGNIN+(parametros.get("state")?"&state="+parametros.get("state"):"")
    }
    else if(authUrl.match(/id_token=/)){
        return {
            isAuth: true,
            TOKEN: parametros.get("id_token"),
            state: parametros.get("state")
        } as IAuth;
    }
}

export function isAuthenticated() {
    return false
}