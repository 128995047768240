export const B2C_URL_SIGNUP =
  sessionStorage.getItem("REACT_APP_B2C_URL_SIGNUP") ??
  process.env.REACT_APP_B2C_URL_SIGNUP;

export const B2C_URL_SIGNIN =
  sessionStorage.getItem("REACT_APP_B2C_URL_SIGNIN") ??
  process.env.REACT_APP_B2C_URL_SIGNIN;

export const B2C_URL_PasswordReset =
  sessionStorage.getItem("REACT_APP_B2C_URL_PASSWORD_RESET") ??
  process.env.REACT_APP_B2C_URL_PASSWORD_RESET;

export const API_URL_Common =
  sessionStorage.getItem("REACT_APP_API_URL_COMMON") ??
  process.env.REACT_APP_API_URL_COMMON;

export const API_COMMON_OUTSIDE_GATEWAY =
  sessionStorage.getItem("REACT_APP_API_COMMON_OUTSIDE_GATEWAY") ??
  process.env.REACT_APP_API_COMMON_OUTSIDE_GATEWAY;

export const API_URL_File =
  sessionStorage.getItem("REACT_APP_API_URL_FILE") ??
  process.env.REACT_APP_API_URL_FILE;

export const API_URL_Documentos =
  sessionStorage.getItem("REACT_APP_API_URL_DOCUMENTOS") ??
  process.env.REACT_APP_API_URL_DOCUMENTOS;

export const API_CEP =
  sessionStorage.getItem("REACT_APP_API_CEP") ?? process.env.REACT_APP_API_CEP;

export const PortalURL =
  sessionStorage.getItem("REACT_APP_PORTALURL") ??
  process.env.REACT_APP_PORTALURL;

export const Icones =
  sessionStorage.getItem("REACT_APP_ICONES") ?? process.env.REACT_APP_ICONES;

export const APILGPD =
  sessionStorage.getItem("REACT_APP_APILGPD") ?? process.env.REACT_APP_APILGPD;

export const LGPDURL =
  sessionStorage.getItem("REACT_APP_LGPDURL") ?? process.env.REACT_APP_LGPDURL;

export const URL_Sinistro_Redirect =
  sessionStorage.getItem("REACT_APP_URL_SINISTRO_REDIRECT") ??
  process.env.REACT_APP_URL_SINISTRO_REDIRECT;

export const Client_ID =
  sessionStorage.getItem("REACT_APP_CLIENT_ID") ??
  process.env.REACT_APP_CLIENT_ID;

export const Redirect_URI =
  sessionStorage.getItem("REACT_APP_REDIRECT_URI") ??
  process.env.REACT_APP_REDIRECT_URI;

export const GA_TRACKING_ID =
  sessionStorage.getItem("REACT_APP_GA_TRACKING_ID") ??
  process.env.REACT_APP_GA_TRACKING_ID;

export const Endpoints = {
  documento: API_URL_Common + "/documento",
  documentoUrl: API_URL_Common + "/documento/url",
  documentodownload: API_URL_Documentos + "/download",
  tipodocumento: API_URL_Common + "/itemmenu/tipodocumento",
  client: API_URL_Common + "/usuario/login",
  atalhomenu: API_URL_Common + "/atalhomenu",
  usuario: API_URL_Common + "/usuario/dados",
  informativo: API_URL_Common + "/informativo",
  itemmenu: API_URL_Common + "/itemmenu",
};

///Bicalho esteve aqui
