import React, { useState,useEffect } from 'react';
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import {IRamalType} from '../../../interfaces/components/IRamalType';
import { PageHeader, HeaderText, Logo, ModalBody, RamalContato, Icone, TituloNome, SubTitulo, Numero, Area, Descricao } from './styles';
import BlurredBackgroundComponent from '../../../components/BlurredBackground';
const CanaisAtendimentoComponent: React.FC<{ramaisContatos:IRamalType[]}> = (props): React.ReactElement => {
const {ramaisContatos} = props;
return (
    <div>
            {
                <BlurredBackgroundComponent header={
                    <PageHeader>
                        <HeaderText>CANAIS DE ATENDIMENTO</HeaderText>
                        <Logo src="https://capprdstportalorteador.blob.core.windows.net/b2crelacionamento/logo-capemisa-seguradora.png"/>
                    </PageHeader>
                }>
                    <ModalBody>
                    {
                        ramaisContatos.map((ramalAtual:IRamalType,i:number)=>
                        {
                            let isLast=false;
                            console.log(i);
                            if(i===ramaisContatos.length-1)
                            {
                                isLast=true;
                            }
                            return(<RamalContato borderRight={(isLast?"0px":"1px solid #F2C800")}>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <TituloNome>{ramalAtual.titulo}</TituloNome>
                                    <SubTitulo>{ramalAtual.subtitulo}</SubTitulo>
                                </div>
                                {
                                    ramalAtual.contatos.map((item:{valor:string,area?:string,descricao?:string,disponibilidade?:string})=>
                                    {
                                        return(
                                        <div>
                                            <div style={{display:"flex",flexDirection:"row"}}>
                                                <Icone icon={faPhone}/>
                                                <Numero>{item.valor}</Numero>
                                            </div>
                                            <Area>{item.area}</Area>
                                            <Descricao>{item.descricao}</Descricao>
                                            <Descricao>{item.disponibilidade}</Descricao>
                                        </div>);
                                    })
                                }
                            </RamalContato>)
                        })
                    }
                    </ModalBody>
                </BlurredBackgroundComponent>
            }
    </div>);
}
const CanaisAtendimento: React.FC<{}> =():React.ReactElement=>
{
    const [canaisAtendimento,setCanaisAtendimento]=useState([{titulo:'',subTitulo:'',contatos:[{valor:'',tipo:'',area:'',descricao:'',disponibilidade:''}]}]);
    useEffect(()=>{
        setCanaisAtendimento([{titulo:"Central de Relacionamentos",subTitulo:"",contatos:[{tipo:"telefone",valor:"4000 1130",area:"CAPITAIS E REGIÕES METROPOLITANAS",descricao:"",disponibilidade:""},{valor:"0800 723 3030",area:"DEMAIS REGIÕES",descricao:"",tipo:"telefone",disponibilidade:"Nossa área de Central de Relacionamento está disponível de segunda a sexta-feira, das 08h às 20h (exceto feriados)."}]},
        {titulo:"SAC",subTitulo:"SERVIÇO DE ATENDIMENTO AO CONSUMIDOR",contatos:[{tipo:"telefone",valor:"0800 940 1130",area:"",descricao:"",disponibilidade:"Disponível 7 dias por semana, 24 horas por dia, para prestar informações, reclamações e cancelamentos."},{tipo:"telefone",valor:"0800 723 4030",area:"Atendimento exclusivo para deficientes auditivos e de fala.",descricao:"",disponibilidade:"Disponível 7 dias por semana, 24 horas por dia, para prestar informações, reclamações e cancelamentos."}]},
        {titulo:"OUVIDORIA",subTitulo:"",contatos:[{tipo:"telefone",valor:"0800 707 4936",area:"",descricao:"Nossa Ouvidoria atua na defesa dos direitos dos consumidores, com objetivo de assegurar o cumprimento das normas legais regulamentares relativas aos direitos do consumidor.",disponibilidade:"Nossa área de Ouvidoria está disponível de segunda a sexta-feira, das 08h às 17h."}]},
        {titulo:"E-MAIL",subTitulo:"",contatos:[{tipo:"email",valor:"faleconosco@capemisa.com.br",descricao:"",disponibilidade:"",area:""}]}
    ])
    },[]);
    return(<CanaisAtendimentoComponent ramaisContatos={canaisAtendimento}/>)
}
export default CanaisAtendimento;