import { useDispatch } from 'react-redux';
import { IAPIState, APISTARTQUERY, APIDISPOSEQUERYDATA, APICANCELQUERY, IAPIStateInstance } from '../ducks/API';
import { ReduxRootTypes, useTypedSelector } from '../ducks';
import { useCallback, useMemo } from 'react';
import { QueryConfig } from '../../interfaces/storage/IReduxQuery';

interface APITypeWithKey<AxiosDataReturnType> extends IAPIStateInstance<AxiosDataReturnType> {
    key:string
} 
/**
 * A custom Hook that initializes an API Request
 * 
 * The following params are inside the object passed.
 * 
 * @param endpoint A restriction-free param that especifies the listener to the HTTP REST Request.
 * @param method A strongly typed params that especifices the method of request based by REST.
 * @param config Aditional Axios Configs. Example: A parameter you want to have in the request.
 * @param queryName A internal structure usually used to identify requests on the superface level logger and name the AZURE logger category.
 * 
 * @returns it returns a array with 4 itens. the request State, the request init function, the dispose data function and the cancel request function
 * 
 * @example useApi<{returnDataFromListener:yourInterface}>({endpoint:"https://randomUrl.com",method:"GET",queryName:"GETOptionalRequestName");
 */

export function useAPI<AxiosDataReturnType = any> (queryConfig:QueryConfig) : [APITypeWithKey<AxiosDataReturnType>, any, any, any] {
    const {endpoint,method,config,queryName} = queryConfig;
    const APIState = useTypedSelector<IAPIState<AxiosDataReturnType>>(state=>state.APIRedux);
    // eslint-disable-next-line
    const uniqueID = useMemo(()=>(queryName??"undefinedQuery")+Object.keys(APIState).length,[]);
    const dispatch = useDispatch<ReduxRootTypes[2]>();
    const startQuery = useCallback(() => dispatch(APISTARTQUERY(endpoint,method??"GET",config,uniqueID)),[config,dispatch,endpoint,method,uniqueID])
    const disposeQuery = () => dispatch(APIDISPOSEQUERYDATA(uniqueID))
    const cancelQuery =  () => dispatch(APICANCELQUERY(uniqueID))
    return [
        {key:uniqueID,...(APIState[uniqueID]??{status:"NOTINITIALIZED"})},
        startQuery,
        disposeQuery,
        cancelQuery
    ];
}

export default useAPI;
