import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import SinistroPageModel from '../../../components/SinistroComponents/SinistroNewPageModel';
import { TextInput} from '../../../components/TextInput';
import { SinistroContext } from '../../../storage/contexts/SinistroContext';
import { BulletPointList,InputContent,PageContent,MainContentSpace,FooterContentSpace,BtnCinza,BtnLaranja,ButtonContainer, DocumentContent,DocumentRow, PrintDocsLabel, PrintBackdrop, PrintInsideSpace, CapemisaImage} from './style';
import { Redirect, useRouteMatch } from 'react-router-dom';
import StyledAlert from '../../../components/StyledAlert';
import StyledCheckbox from '../../../components/CustomCheckBox';
import { PortalURL } from '../../../env_settings';
import { IEvento, IEventoDocumento, ISinistroContext } from '../../../interfaces/storage/contexts/ISinistroContext';
import { LoaderError } from '../../../components/Loaders/LoaderDots';
import { LoaderRotatorMessage, LoaderRotatorModal } from '../../../components/Loaders/LoaderRotator';
import { SelectedRoundedRow, SelectedRowTitle } from '../../../components/CustomDropdown/style';
import useAPI from '../../../storage/custom_hooks/API';
import { GetDocumentosSinistro, GetEventosSinistro, GetVerificaAtendimentoExiste } from '../../../services/APICallableActions/sinistro';
import { useReactToPrint } from 'react-to-print';
import APIRequestFunction from '../../../services/APIRequestFunction';

const IdentificarEvento:React.FC<{eventoEspecifico?:IEvento,isToPrint?:boolean,documentos?:IEventoDocumento[]}> = (props):React.ReactElement => {
    const { eventoEspecifico, isToPrint, documentos } = props;
    const {sinistroContext,setSinistroContext} = useContext(SinistroContext);
    const [redirectToHome,setRedirectToHome]=useState<boolean>(false);
    const [redirectToNextPage,setRedirectToNextPage] = useState<boolean>(false);
    const [redirectToAvisoJaCadastrado, setRedirectToAvisoJaCadastrado] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eventoSelecionado,setEventoSelecionado] = useState<IEvento|undefined>(eventoEspecifico);
    const [eventNotFound, setEventNotFound] = useState<boolean>(false);
    const [eventosQuery,initEventosQuery] = useAPI<IEvento[]>(GetEventosSinistro())
    const [eventoDocumentos,runDocumentosQuery,,cancelDocumentosQuery] = useAPI<IEventoDocumento[]>(GetDocumentosSinistro(eventoSelecionado?.codigo));
    const printablePageRef = useRef<HTMLDivElement>(null);
    const eventoOptions = (
        (
            sinistroContext.etapa==="identificareventogeral"
            && !isToPrint
            && eventosQuery.status==="COMPLETED"
        ) ?
            eventosQuery.data
        :
            sinistroContext.eventoOptions
    );
    const handlePrint = useReactToPrint({
        content: () => printablePageRef.current,
    })

    const FixDate = useCallback((date?:string)=>{
        const fixedData = date ?? new Date().toLocaleDateString("pt-br").slice(0,10);
        return (fixedData.slice(6,10)+"-"+fixedData.slice(3,5)+"-"+fixedData.slice(0,2))
    },[]);

    useEffect(()=>{
        if(sinistroContext.etapa==="identificareventogeral" && !isToPrint)
        {
            initEventosQuery();
        }
    // eslint-disable-next-line
    },[sinistroContext.eventoOptions,isToPrint]);

    useEffect(()=>{
        if(!isToPrint)
        {
            if(eventoDocumentos.status==="PENDING")
                cancelDocumentosQuery();
            if(eventoSelecionado?.codigo)
                runDocumentosQuery();
        }
        // eslint-disable-next-line
    },[eventoSelecionado]);
    const TitleAndOutlinedComponent : React.FC<{title:React.ReactElement|string}> = useCallback((props) => {
        return(
            <div style={{display:"flex",flexDirection:"column",marginTop:"10px",width:"100%"}}>
                <SelectedRowTitle>{props.title}</SelectedRowTitle>
                {
                    props.children
                }
            </div>
        )
    },[])
    const EventoPicker:React.FC<{eventoSelecionado?:IEvento,setEvento:(setEventoSelecionado:string)=>void,opcoesEvento:{descricao:string,id:string}[], isDisabled?:boolean}> = useCallback((props) : React.ReactElement =>{
        const {opcoesEvento,setEvento,eventoSelecionado, isDisabled} = props;
        return(
            <DocumentContent direction="column">
                <InputContent>
                    <TextInput disabled={isDisabled} borderRadius={"8px"} valor={eventoSelecionado?.codigo} onChange={(e:{target:{value:string}})=>{setEvento(e.target.value)}} show width={"100%"} rotulo="" type="combotextvalue" options={opcoesEvento.map((item:{descricao:string,id:string}) : {text?:string,value?:string}=>{return({text:item.descricao,value:item.id})})} />
                </InputContent>
            </DocumentContent>
        )
    },[]);

    const PrintablePage: React.FC = useCallback(()=>{
        return(
            <PrintBackdrop ref={printablePageRef}>
                <PrintInsideSpace>
                    <CapemisaImage/>
                    <IdentificarEvento documentos={eventoDocumentos.data} isToPrint eventoEspecifico={eventoSelecionado}/>
                </PrintInsideSpace>
            </PrintBackdrop>
        )
    },[eventoSelecionado,eventoDocumentos])
    const PrintableDocuments : React.FC = useCallback(() =>{
        const documentosSource = isToPrint ? documentos : eventoDocumentos.data
        const documentosObrigatorios = documentosSource?.filter((evento)=>evento.isDocObrigatorio) ?? [];
        const documentosNaoObrigatorios = documentosSource?.filter((evento)=>!evento.isDocObrigatorio) ?? [];
        const docComponent = 
        <>
            <DocumentRow>
                <TitleAndOutlinedComponent title={
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        Documentos obrigatórios
                        {
                            !isToPrint?
                            <PrintDocsLabel onClick={handlePrint}>Imprimir listagem</PrintDocsLabel>
                            :
                            <></>
                        }
                    </div>
                }>
                    <BulletPointList>
                        {
                            documentosObrigatorios.map((documento)=>{
                                return(
                                    <li>{documento.descDocComunicadoTela}</li>
                                )
                            })
                        }
                    </BulletPointList>
                </TitleAndOutlinedComponent>
            </DocumentRow>
            <DocumentRow>
                {
                    documentosNaoObrigatorios.length>0?
                        <TitleAndOutlinedComponent title={
                            <>
                                Demais documentos
                            </>
                        }>
                            <BulletPointList>
                                {
                                    documentosNaoObrigatorios.map((documento)=>{
                                        return(
                                            <li>{documento.descDocComunicadoTela}</li>
                                        )
                                    })
                                }
                            </BulletPointList>
                        </TitleAndOutlinedComponent>
                    :
                    <></> 
                }
            </DocumentRow>
        </>
        return(
            <SelectedRoundedRow especificStyle={"margin-top:10px;justify-content:center;"}>
                {
                    isToPrint?
                        docComponent
                    :
                        eventoDocumentos.status!=="NOTINITIALIZED"?
                            eventoDocumentos.status==="COMPLETED"?
                                docComponent
                                :
                                (
                                    eventoDocumentos.status==="PENDING"?
                                    <LoaderRotatorMessage width={"100%"}/>
                                    :
                                    <LoaderError erro={eventoDocumentos.errorResponse ?? "Ocorreu um erro! Recarregue a página."}/>
                                )
                        :
                        <DocumentRow>
                            Selecione um evento para exibir as informações.
                        </DocumentRow>  
                }  
            </SelectedRoundedRow>
        ) 
    },[eventoDocumentos,documentos,handlePrint,isToPrint])
    const VerificarDadosPreenchidos = useCallback(()=>{
        let canContinue : boolean = true;
        const CallAlertAndStop = (message:string) =>{
            StyledAlert(message);
            canContinue=false;
        }
        if(!eventoSelecionado)
        {
            CallAlertAndStop("Selecione um evento para continuar!")
        }
        return canContinue;
    },[eventoSelecionado])

    const DecidirDestino = useCallback(()=>{
        return eventNotFound?"geral":"enviardocumentos";
    },[sinistroContext,eventoSelecionado,setSinistroContext,eventNotFound,eventoOptions]);

    const HandleProximaEtapa = async ()=>{
        setIsLoading(true);
        if(eventNotFound || VerificarDadosPreenchidos())
        {
            let newSinistroContextObj : ISinistroContext = {
                ...sinistroContext,
                codigoEvento:eventoSelecionado?.codigo,
                nomeEvento: eventoOptions?.find((evento)=> evento.codigo === eventoSelecionado?.codigo)?.nome,
                global:sinistroContext.etapa === "identificarevento"? eventNotFound : sinistroContext.global,
                etapa:eventNotFound?"sinistroGeralComEvento":"uploadDocumentos",
            };
            try{
                if(
                    eventNotFound || (
                        !(
                            await APIRequestFunction(
                                GetVerificaAtendimentoExiste(
                                    (sinistroContext.informacoesSegurado?.cpfSegurado??"").replace(/\-|\./g,""),
                                    FixDate(sinistroContext.dataEvento??""),
                                    eventoSelecionado?.codigo?.toString()??""
                                )
                            )
                        ).data
                    )
                )
                {
                    setSinistroContext(newSinistroContextObj);
                    setRedirectToNextPage(true);
                    return;
                }
            }
            finally{
                newSinistroContextObj = {
                    ...newSinistroContextObj,
                    etapa:"avisojacadadastrado",
                };
                setSinistroContext(newSinistroContextObj);
                setRedirectToAvisoJaCadastrado(true);
            }
        }
        setIsLoading(false);
    }

    const descricao = useMemo(()=>"A comunicação do Sinistro pode ser feita pelo Cliente CAPEMISA e/ou pelos(as) Beneficiários(as) preenchendo os dados abaixo:",[]);
    return (
        <PageContent>
            <LoaderRotatorModal show={isLoading}/>
            {
                !isToPrint?
                    <div style={{display:"none"}}>
                        <PrintablePage />
                    </div>
                :
                <></>
            }
            <SinistroPageModel tituloPagina={"COMUNICAÇÃO DE SINISTRO"} descricao={descricao}>
                <MainContentSpace>
                    <TitleAndOutlinedComponent title={"Evento"}>
                        <EventoPicker
                            eventoSelecionado={eventoSelecionado}
                            isDisabled={eventNotFound}
                            opcoesEvento={
                                (isToPrint?[eventoEspecifico??{codigo:-1,descricao:"",nome:""}]:(
                                    eventoOptions
                                ))?.map((evento)=>{return({
                                    descricao:evento.nome,
                                    id:evento.codigo?.toString()
                                })}) ?? []
                            }
                            setEvento={(eventoId:string)=>{
                                setEventoSelecionado(eventoOptions?.find((evento)=>evento.codigo?.toString()===eventoId))
                            }}
                        /> 
                    </TitleAndOutlinedComponent>
                    {
                        sinistroContext?.etapa === "identificarevento" &&
                        <div style={{display:"inline-flex"}}>
                            <StyledCheckbox checked={eventNotFound} onClick={() => {
                                setEventNotFound(!eventNotFound);
                            }} 
                            borderRadius={"0"}/> 
                            <p style={{marginLeft:"10px"}}> Não encontrei o evento na lista.</p>
                        </div>
                    }

                    <TitleAndOutlinedComponent title={"Descrição"}>
                        <SelectedRoundedRow especificStyle={"justify-content:center;margin-top:10px;width:100%;"} >
                            <p style={{margin:"4px 12px"}}>{eventoSelecionado?.descricao??"Selecione um evento para exibir as informações."}</p>
                        </SelectedRoundedRow>
                    </TitleAndOutlinedComponent>
                    <TitleAndOutlinedComponent title={"Documentação"}>
                        <PrintableDocuments/>
                    </TitleAndOutlinedComponent>
                </MainContentSpace>
                {
                    !isToPrint?
                    <FooterContentSpace>
                        <ButtonContainer>
                            {redirectToAvisoJaCadastrado && <Redirect to="/inicio/sinistro/avisojacadadastrado"/>}
                            {redirectToNextPage?<Redirect to={DecidirDestino()}/>:<></>} 
                            {redirectToHome?<Redirect push to={window.location.href[window.location.href.length-1]==="/"?"../":"./"}/>:<></>} 
                            <BtnCinza onClick={()=>{setRedirectToHome(true)}}>CANCELAR</BtnCinza>
                            <BtnLaranja onClick={HandleProximaEtapa}>PRÓXIMO</BtnLaranja>
                        </ButtonContainer>
                    </FooterContentSpace>
                    :
                    <></>
                }
            </SinistroPageModel>
        </PageContent>
    )
}

export default IdentificarEvento;