import React from 'react';
import {Linha, NotificacaoBottom, NotificacaoDetalhes, NotificacaoDiv, NotificacaoSpace,NotificacaoTitle,NotificacaoUpper} from './style';

type INotificacao = {titulo:string,subTitulo:string,url:string};

const Notificacao : React.FC<{notificacoes:INotificacao[]}> = (props) : React.ReactElement=>
{
    let componenteNotificacoes : React.ReactElement[] = props.notificacoes.map(
        (item:INotificacao,i:number)=>
        {
            return(
                <NotificacaoDiv key={item.titulo+i+"notification"} onClick={()=>{window.location.href=item.url}}>
                    <NotificacaoUpper>
                        <NotificacaoTitle>{item.titulo}</NotificacaoTitle>
                    </NotificacaoUpper>
                    <NotificacaoBottom>
                        <NotificacaoDetalhes>{item.subTitulo}</NotificacaoDetalhes>
                    </NotificacaoBottom>
                    <Linha width="80%"/>
                </NotificacaoDiv>
            )
        }
    );
    componenteNotificacoes = componenteNotificacoes.length>0 ? componenteNotificacoes : [<NotificacaoDiv key={"nonotificationskey"}><NotificacaoUpper><NotificacaoTitle>Não há notificações</NotificacaoTitle></NotificacaoUpper></NotificacaoDiv>]
    return(
        <NotificacaoSpace>
            {
                componenteNotificacoes
            }
            
        </NotificacaoSpace>
    )
}
export default Notificacao;