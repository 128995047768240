import {Requisicao} from '../requisicao';
import IArgs from '../../interfaces/IArgs';
import { IAPIProdutosRetorno, IProdutosAPIResponse } from '../../interfaces/pages/IProdutos';
import { ContratoGenerico } from '../../interfaces/pages/IContrato';
import { APIUSER, APIDOCUMENTOS, APIIBGE } from '../../services/APICallableActions/Adresses';
//DEPRECATED! THIS CATEGORY OF API REQUEST IS FROM NOW (22/03/2021) ON CONSIDERED OUT OF STRUCTURE ARQUITECTURE!


//DEPRECADO! ESTA CATEGORIA DE CHAMADA A API NÃO ESTÁ SENDO MAIS UTILIZADA A PARTIR DE 22/03/2021
//QUALQUER USO DESTA CHAMADA É CONSIDERADO FORA DE ARQUITETURA DE PROJETO!
export function GetUser()
{
    return Requisicao(APIUSER.Usuario.GetUser);
}
export function GetPerguntasAutenticacao()
{
    return Requisicao(APIUSER.PerguntasSeguranca.GetPerguntas);
}
export function GetFavorites()
{
    return Requisicao(APIUSER.AtalhoMenu.CRUDAtalhoMenu);
}
export function GetFavoritesCUSTOMTOKEN(reqHeaders:any)
{
    return Requisicao(APIUSER.AtalhoMenu.CRUDAtalhoMenu,{method:"GETCUSTOMTOKEN",aditionalInfo:reqHeaders})
}
export function GetAllFavorites()
{
    return Requisicao(APIUSER.AtalhoMenu.GetItemMenu);
}
export function GetAllFavoritesCUSTOMTOKEN(reqHeaders:any)
{
    return Requisicao<any[]>(APIUSER.AtalhoMenu.GetItemMenu,{method:"GETCUSTOMTOKEN",aditionalInfo:reqHeaders})
}
export function PostFavorites(idItemMenu:number,ordem:number,)
{
    const form={
        idItemMenu:idItemMenu,
        ordem: ordem
      }
    return Requisicao(APIUSER.AtalhoMenu.CRUDAtalhoMenu,{method:"POST",form:form})
}
type FavoriteAPIFormat={"idItemMenu": number,"ordem": number}
export function PutListFavorites(favorites:FavoriteAPIFormat[])
{
    return Requisicao(APIUSER.AtalhoMenu.POSTAtalhoMenu,{method:"PUT",form:favorites})
}  
export function DeleteFavorite(idItemMenu:number)
{
    const body:IArgs[]=[{
        parameter:"idItemMenu",
        value:idItemMenu,},
    ];
    return Requisicao(APIUSER.AtalhoMenu.CRUDAtalhoMenu,{method:"DELETE",body:body})
}
/*export function GetUserIsValidated(cpf:string)
{
    return Requisicao(APIUSER.Usuario.GetVerificaCadastro+"/cpf","GET",null,null,null);
}*/

export function PatchFavorites(idAtalhoMenu:number,ordem:number)
{
    const body:IArgs[]=[{
        parameter:"idAtalhoMenu",
        value:idAtalhoMenu,},
        {
        parameter:"ordem",
        value:ordem,}
    ];
    return Requisicao(APIUSER.AtalhoMenu.CRUDAtalhoMenu,{method:"PATCH",body:body})
}
export function POSTPerguntasAutenticacao(form:any)
{
    /*{
        "codigoPergunta": 0,
        "resposta": "string"
      }*/
    return Requisicao(APIUSER.PerguntasSeguranca.POSTPerguntas,{method:"POST",form:form});
}
export function GETContatos()
{
    return Requisicao(APIUSER.InfoAtendimento.GetInfoAtendimento);
}
export function GetInformativoById(id:number)
{
    return Requisicao(APIUSER.Informativo.GetInformativo+"/"+id);
}
export function GetInformativosCUSTOMTOKEN(reqHeaders:any)
{
    return Requisicao(APIUSER.Informativo.GetInformativo,{method:"GETCUSTOMTOKEN",aditionalInfo:reqHeaders});
}
export function GetIcones()
{
    return Requisicao(APIUSER.Icones);
}
export function GetIconesNOTOKEN()
{
    return Requisicao(APIUSER.Icones,{method:"GETNOTOKEN"});
}
export function GetTipoAtendimento()
{
    return Requisicao(APIUSER.TipoAtendimento.GetTipoAtendimento);
}
interface AtendimentoEnv {idAtendimento?:number,"nome": string|null,"sexo": string|null,"nomePai": string|null,"nomeMae": string|null,"dtNascimento": string|null,"listaAnexo": {"nome": string,"guid": string,}[]}
export function PostAtendimento(form:AtendimentoEnv,codAtendimento:string)
{
    if(form.dtNascimento && (form.dtNascimento[2]==="/" || form.dtNascimento[2]==="-"))
    {
        form.dtNascimento=form.dtNascimento.slice(6,10)+"-"+form.dtNascimento.slice(3,5)+"-"+form.dtNascimento.slice(0,2);
    }
    console.log(form);
    return Requisicao(APIUSER.Atendimento.CRUDAtendimento+"/"+codAtendimento,{method:"POST",form:form});
}
export function GetAtendimentos()
{
    return Requisicao(APIUSER.Atendimento.CRUDAtendimento);
}
export function GetAtendimentosEspecifico(codAtendimento:string,idAtendimento:number)
{
    const body:IArgs[]=[{
        parameter:"idAtendimento",
        value:idAtendimento
        }]
    return Requisicao(APIUSER.Atendimento.GetAtendimentoEspecifico+"/"+codAtendimento,{method:"GET",body:body});
}
export function PatchOrdemAtalhos(idItemMenu:number,ordem:number)
{
    const body:IArgs[]=[
        {
            parameter:"idItemMenu",
            value:idItemMenu,
        },
        {
            parameter:"ordem",
            value:ordem,
        },
    ];
    return Requisicao(APIUSER.AtalhoMenu.CRUDAtalhoMenu,{method:"PATCH",body:body});
}
export function PatchUserContatos(telefoneFixo:string,telefoneCelular:string,email:string,dddTelefoneFixo:string,dddTelefoneCelular:string,cep:string,uf:string,cidade:string,bairro:string,endereco:string,numeroEndereco:string,complemento:string,telefoneAdicional?:string,dddTelefone3?:string,nome?:string,dataNascimento?:string,sexo?:string,nomePai?:string,nomeMae?:string)
{
    let form:any=
    {
        nome,
        dataNascimento,
        sexoSigla:sexo,
        nomePai,
        nomeMae,
        "telefone2":telefoneFixo.length>0?telefoneFixo:undefined,
        "telefone1":telefoneCelular.length>0?telefoneCelular:undefined,
        "telefone3":telefoneAdicional && telefoneAdicional.length>0?telefoneAdicional:undefined,
        "email":email,
        "dddTelefone2":dddTelefoneFixo.length>0?dddTelefoneFixo:undefined,
        "dddTelefone1":dddTelefoneCelular.length>0?dddTelefoneCelular:undefined,
        "dddTelefone3":dddTelefone3 && dddTelefone3.length>0?dddTelefone3:undefined,
        "cep":cep,
        "uf":uf,
        "cidade":cidade,
        "bairro":bairro,
        "endereco":endereco,
        "numeroEndereco":numeroEndereco,
        "complemento":complemento
    }
    if(form.dataNascimento && (form.dataNascimento[2]==="/" || form.dataNascimento[2]==="-"))
    {
        form.dataNascimento=form.dataNascimento.slice(6,10)+"-"+form.dataNascimento.slice(3,5)+"-"+form.dataNascimento.slice(0,2);
    }
    return Requisicao(APIUSER.Usuario.PatchContatos,{method:"PATCH",form:form});
}
export function PutAtendimento(form:AtendimentoEnv,codAtendimento:string)
{
    if(form.dtNascimento && (form.dtNascimento[2]==="/" || form.dtNascimento[2]==="-"))
    {
        form.dtNascimento=form.dtNascimento.slice(6,10)+"-"+form.dtNascimento.slice(3,5)+"-"+form.dtNascimento.slice(0,2);
    }
    form.nome=((typeof form.nome == "string" && form.nome==="")?null:form.nome);
    form.nomePai=((typeof form.nomePai == "string" && form.nomePai==="")?null:form.nomePai);
    form.nomeMae=((typeof form.nomeMae == "string" && form.nomeMae==="")?null:form.nomeMae);
    form.sexo=((typeof form.sexo == "string" && form.sexo==="")?null:form.sexo);
    form.dtNascimento=((typeof form.dtNascimento == "string" && form.dtNascimento==="")?null:form.dtNascimento)

    return Requisicao(APIUSER.Atendimento.CRUDAtendimento+"/"+codAtendimento,{method:"PUT",form:form})
}
export function GetPreUser(customToken:any)
{
    return Requisicao(APIUSER.Usuario.GetPreUser,{method:"GETCUSTOMTOKEN",cantShowError:true,aditionalInfo:customToken});
}
export function GetDuvidas(listaTipoDuvida:string[],quantidade?:number)
{
    let body:IArgs[]=[
        {
            parameter:"listaTipoDuvida",
            value:listaTipoDuvida,
            array:true,
        },
    ]
        if(quantidade)
        body.push({
            parameter:"quantidade",
            value:quantidade
        })
    return Requisicao(APIUSER.Duvidas.GETDuvida,{method:"GET",body:body});
}
export function GetDuvidasFrequentes(listaTipoDuvida:string[],quantidade?:number)
{
    let body:IArgs[]=[
        {
            parameter:"listaTipoDuvida",
            value:listaTipoDuvida,
            array:true,
        },
    ];
        if(quantidade)
        body.push({
            parameter:"quantidade",
            value:quantidade
        });
    return Requisicao(APIUSER.Duvidas.GETDuvidasFrequentes,{method:"GET",body:body});
}
export function GetNovidade()
{
    return Requisicao(APIUSER.Novidade.GetNovidades);
}
export function GetNovidadeById(id:number)
{
    return Requisicao(APIUSER.Novidade.GetNovidades+"/"+id);
}
export function GetNovidadeExclusiva(id:number)
{
    const params : IArgs[] = 
    [
        {
            parameter:"idNovidade",
            value:id,
        },
        {
            parameter:"Quantidade",
            value:2,
        }
    ]
    return Requisicao(APIUSER.Novidade.GetNovidadeExclusiva,{body:params,method:"GET"})
}
export function GetIsUsuarioCadastrado(cpf:string)
{
    return Requisicao(APIUSER.Usuario.VerificaCadastro+"/"+cpf,{method:"GETNOTOKEN",cantShowError:true});
}
export function GetTipoDocumento()
{
    return Requisicao(APIDOCUMENTOS.TipoDocumento.DocumentosUser);
}
export function GetConvenioNOTOKEN()
{
    return Requisicao(APIUSER.Convenio.Convenio,{method:"GETNOTOKEN"})
}
export function GetUF()
{
    return Requisicao(APIIBGE.UF.UF,{method:"GETNOTOKEN",cantShowError:true});
}
export function POSTAssistenciaFinanceira(nome:string,cpf:string,telefone:string,email:string,uf:string,convenio:{id:number,nome:string},outroConvenio:string,valor:number,prazo:number,token?:boolean)
{
    return Requisicao(APIUSER.AssistenciaFinanceira.POSTAssistenciaFinanceira,{method:token?"POST":"POSTNOTOKEN",form:{nome:nome,cpf:cpf,telefone:telefone,email:email,uf:uf,convenio:convenio,outroConvenio:outroConvenio,valor:valor,prazo:prazo}})
}
export function GetProdutos()
{
    return Requisicao<IAPIProdutosRetorno[]>(APIUSER.Produto.GetProdutos,{cantShowError:true});
}
export function GetContratos(codigoProduto:string,tipoProduto:string,sistemaOrigem:string)
{
    let body:IArgs[]=[
        {
            parameter:"codigoProduto",
            value:codigoProduto
        },
        {
            parameter:"tipoProduto",
            value:tipoProduto
        },
        {
            parameter:"sistemaOrigem",
            value:sistemaOrigem
        }
    ]
    return Requisicao<ContratoGenerico[]>(APIUSER.Produto.GetContratos,{body:body});
}
export function GetContratoDetalhes(codigoProduto:string,numeroContrato:string,tipoProduto:string,sistemaOrigem:string,dataSaldoDevedor?:string)
{
    let body:IArgs[]=[
        {
            parameter:"codigoProduto",
            value:codigoProduto
        },
        {
            parameter:"numeroContrato",
            value:numeroContrato
        },
        {
            parameter:"tipoProduto",
            value:tipoProduto
        },
        {
            parameter:"sistemaOrigem",
            value:sistemaOrigem
        },
        {
            parameter:"dataSaldoDevedor",
            value:dataSaldoDevedor ?? null,
        }
    ]
    return Requisicao<IProdutosAPIResponse>(APIUSER.Produto.GetContratoDetalhe,{body:body});
}
export function GetNotificacoes()
{
    return Requisicao(APIUSER.Notificacoes.GetNotificacoes+"?retornaDesativados=false",{cantShowError:true});
}