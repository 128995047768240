import styled from 'styled-components';

export const PageSpace= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    max-width:820px;
`;
export const HeaderMessageContent = styled.div<{height?:string}>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;
export const HeaderMessageTitle = styled.div`
    font: normal normal bold 42px/55px Roboto;
    color: #E27800;
    text-transform: uppercase;
    border-bottom: 1px solid #E2B500;
    height: 60%;
    min-height:70px;
`;
export const BodyMessageTitle = styled.div`
    margin-top: 24px;
    height: 40%;
    min-height:20px;
`;


export const Label = styled.div`
    align-self: center;
    text-align: center;
    font-family: "Roboto";
    font-size: 1em;
    letter-spacing: 0px;
    color: #636363;
    margin-top:10px;
`;

export const CheckBoxContainer=styled.div`
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    margin-top: 17px;
`;
export const CheckBoxArea = styled.span<{borderRadius?:string}>`
    width:18px;
    height:18px;
    display:flex;
    justify-content:center;
    align-items:center;
    border: 2px solid #E27800;
    border-radius: ${props=>props.borderRadius??"9px"};
    cursor:pointer;
`;
export const CheckBoxCheckedMark = styled.span<{borderRadius?:string}>`
    width:10px;
    height:10px;
    background-color:#E27800;
    border-radius:${props=>props.borderRadius??"9px"};
`;


export const ButtonSpace = styled.div`
    display:flex;
    margin-top:50px;
    flex-direction: row-reverse;
    justify-content:space-between;
    align-content: space-between;
    width:70%;
    height:40px;
    margin-bottom:20px;
`;
export const ButtonSilver = styled.button`
    background: #DADADA 0% 0% no-repeat padding-box;
    font-family: "Roboto";
    font-size: 1em;
    border: none;
    border-radius: 21px;
    letter-spacing: 0px;
    text-align: center;
    color: #707070;
    width: 45%;
    ::after, ::before, :focus{
        outline:0px;
    };
`;
export const ButtonOrange = styled.button`
    border: none;
    background: #E27800 0% 0% no-repeat padding-box;
    font-family:"Roboto";
    font-size: 1em;
    border-radius: 21px;
    letter-spacing: 0px;
    text-align: center;
    color: #FFF;
    width: 45%;
    ::after, ::before, :focus{
        outline:0px;
    };
`;


export const LineInputContainer = styled.div`
    width: 95%;
    display: flex;
    height:calc(fit-content + 50px); 
    justify-content: space-between;
    align-items:flex-end;
    align-self: center;
`;
export const FileUploadLabel = styled.label`
    color: var(--unnamed-color-636363);
    text-align: center;
    font: normal normal normal 1em Roboto;
    letter-spacing: 0px;
    color: #636363;
`
export const EnviarDocumentosButton = styled.button<{canSend?:boolean}>`
    ${props=>props.canSend?"background: #E27800 0% 0% no-repeat padding-box;letter-spacing: 0px;color: #FFFFFF;cursor:pointer;"
    :"background: #D8D8D8 0% 0% no-repeat padding-box;text-align: left;font: normal normal bold 13px/18px Roboto;letter-spacing: 0px;color: #FFFFFF;opacity: 1;cursor:auto!important;"}
    min-width:200px;
    ::after, ::before, :focus{
        outline:0px;
    };
    border:0;
    border-radius:5px;
    margin-top:10px;
    min-height:30px;
    text-align:center;
`
export const EventLineSpace = styled.div`
    width:50%;
    display:flex;
    justify-content:space-between;
    font-weight: normal;
    margin-left:10px;
`