import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PDFText } from '../DocumentList/styles';
import { IcoPDF,SubRoundedRow, SelectedRoundedRow, TitleContainer, TopSelectedRoundedRow, AlignSpace, SelectedRowTitle, SizedText} from './style'; 
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Collapse from '@material-ui/core/Collapse';
const CustomDropdown :React.FC<{keepHead?:boolean,height?:string,status?:"open"|"closed",FunctionChildren?:React.FC,bodyIsClickable?:boolean,titulo?:React.ReactNode,customToggler?:{ComponentWhenClosed?:React.FC,ComponentWhenOpen?:React.FC},capitalizeTitle?:boolean,extraStyle?:string}> = (props): React.ReactElement =>{
    const {keepHead,titulo,customToggler,bodyIsClickable,children,status,extraStyle,capitalizeTitle,height,FunctionChildren} = props;
    const [isOpen,setIsOpen] = useState<boolean>(status==="open");
    type Options = "topoComponent"|"textComponent";
    const Components : {[componenteEscolhido in Options]:React.FC} = useMemo(()=>(keepHead || isOpen?{topoComponent:TopSelectedRoundedRow,textComponent:SelectedRowTitle}:{topoComponent:SubRoundedRow,textComponent:SizedText}),[isOpen,keepHead]);
    const FatherComponent : React.FC<{height?:string}> = Components["topoComponent"];
    const TextConfig : React.FC<{isPointer?:boolean,capitalize?:boolean}> = Components["textComponent"];
    const handleOpen = useCallback(()=>{
        setIsOpen(!isOpen);
    },[isOpen,setIsOpen])
    useEffect(()=>{
        setIsOpen(status==="open");
    },[status])
    return (
        <SelectedRoundedRow key={"customDropDownUniqueKey"} minHeight={height} especificStyle={extraStyle} isClickable={bodyIsClickable} onClick={()=>{if(bodyIsClickable)handleOpen()}}>
            <FatherComponent height={height}>
                <TitleContainer width="auto;flex-grow:1;">
                    <TextConfig isPointer={bodyIsClickable} capitalize={capitalizeTitle}>
                        {titulo}
                    </TextConfig>
                </TitleContainer>
                <AlignSpace onClick={handleOpen}>
                    {
                        customToggler?
                        (
                            isOpen?
                            (
                                customToggler.ComponentWhenOpen?
                                <customToggler.ComponentWhenOpen/>
                                :
                                <>
                                    <PDFText>{isOpen?"Ocultar":"Visualizar"}</PDFText>
                                    <IcoPDF icon={isOpen?faEyeSlash:faEye}/>
                                </>
                            )
                            :
                            (
                                customToggler.ComponentWhenClosed?
                                <customToggler.ComponentWhenClosed/>
                                :
                                <>
                                    <PDFText>{isOpen?"Ocultar":"Visualizar"}</PDFText>
                                    <IcoPDF icon={isOpen?faEyeSlash:faEye}/>
                                </>
                            )
                        )
                        :
                        <>
                            <PDFText>{isOpen?"Ocultar":"Visualizar"}</PDFText>
                            <IcoPDF icon={isOpen?faEyeSlash:faEye}/>
                        </>
                    }
                </AlignSpace>
            </FatherComponent>
            <Collapse in={isOpen}>
                <div onClick={(ev)=>{ev.stopPropagation()}} style={{cursor:"auto"}}>
                {
                    isOpen && FunctionChildren ? <FunctionChildren/> : <></>
                }
                {
                    children
                }
                </div>
            </Collapse>
        </SelectedRoundedRow>
    )
}

export default CustomDropdown;
