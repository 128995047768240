import React, { useState, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SinistroContext } from '../../../storage/contexts/SinistroContext';
import { ISinistroContext } from '../../../interfaces/storage/contexts/ISinistroContext';
import { Client_ID, PortalURL, Redirect_URI, URL_Sinistro_Redirect } from '../../../env_settings';
import SinistroPageModel from '../../../components/SinistroComponents/SinistroNewPageModel';
import { TextInput } from '../../../components/TextInput';
import { ButtonContainer, InputsContainer, PrimaryButton } from './styles'
import { TestaCPF } from '../../../functions/CpfTest';
import { LoaderRotatorModal } from '../../../components/Loaders/LoaderRotator';
import IAvisoSinistro from '../../../interfaces/IAvisoSinistro';
import { GetAtendimentoByCPFAndProtocolo } from '../../../services/APICallableActions/sinistro';
import APIRequestFunction from '../../../services/APIRequestFunction';
import { IStatePropsDetalhesSinistro } from '../../../interfaces/pages/IDetalhesSinistro';

const pageText = 'Caso você queira consultar o status e/ou informações sobre os sinistros comunicados, basta inserir o número do protocolo e o CPF do Segurado (a)/Participante nos campos abaixo:'

const AcompanhamentoSinistro: React.FC = (props):React.ReactElement => {
    const [protocolo, setProtocolo] = React.useState("");
    const [cpf, setCpf] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();
    const { path } = useRouteMatch();
    const { sinistroContext } = useContext(SinistroContext);


    const RedirectToNexPageWithAtendimentoData = async () => {
        const VerificarUserLogado = () => {
            return path.search("inicio")===-1;
        }
        
        const ExecuteAPIRequest = async () => {
            const atendimentoQuery = APIRequestFunction<IAvisoSinistro>(
                GetAtendimentoByCPFAndProtocolo(cpf.replace(/[^\d]/g,''),protocolo)
            );
            atendimentoQuery.catch(()=>{
                alert("Aviso não localizado! Verifique os dados e tente novamente");
            });
            try {
                return await atendimentoQuery;
            }
            catch{}
        }

        let newSinistroContextObj : ISinistroContext = {
            ...sinistroContext,
            id: protocolo
        };

        if(VerificarUserLogado())
        {
            const fixedOptions =  JSON.stringify((
                {
                    ...newSinistroContextObj,
                    id: protocolo,
                    cpf,
                    etapa:"acompanhamentoSinistro",
                    tipoUsuario:"comunicante",
                } as ISinistroContext)
            );
            //const urlToCall = "https://capemisaseguradora.b2clogin.com/CapemisaSeguradora.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignIn&client_id=89c517e5-e20a-477e-8345-7137109a365f&nonce=defaultNonce&redirect_uri="+PortalURL+"/auth&scope=openid&response_type=id_token&prompt=login&state="+fixedOptions
            const urlToCall = URL_Sinistro_Redirect.replace('{client_id}',Client_ID).replace('{redirect_uri}', Redirect_URI).replace('{fixedOptions}', fixedOptions);
            window.location.href = urlToCall;
        }
        else
        {
            try{
                const AtendimentoResult = await ExecuteAPIRequest();
                if(AtendimentoResult?.status === 200)
                    history.replace(`/inicio/sinistro/detalheAviso`,
                        {
                            userType: "protocolo",
                            data: AtendimentoResult?.data
                        } as IStatePropsDetalhesSinistro
                    );
            }
            catch{}
        }
    }

    const ValidarDados = () =>{
        if(TestaCPF(cpf))
        {
            alert("CPF inválido!");
            return false;
        }
        if(protocolo.length < 19)
        {
            alert("Protocolo inválido!");
            return false;
        }
        return true;
    }

    const handleAcompanharSinistro = async () => {
        setIsLoading(true);
        if(await ValidarDados())
        {
            await RedirectToNexPageWithAtendimentoData();
        }
        setIsLoading(false);
    };

    return (
        <SinistroPageModel tituloPagina="ACOMPANHAR SINISTRO/BENEFÍCIO" >
            <p>
                {
                    pageText
                }
            </p>
            <InputsContainer>
                <TextInput
                    rotulo="Protocolo de Atendimento"
                    type="text"
                    borderRadius="10px"
                    valor={protocolo}
                    onChange={(ev:React.FormEvent<HTMLInputElement>) => setProtocolo(ev.currentTarget.value)}
                />
                <TextInput
                    rotulo="CPF do Segurado(a)/Participante"
                    type="cpf"
                    borderRadius="10px"
                    valor={cpf}
                    onChange={(ev:React.FormEvent<HTMLInputElement>) => setCpf(ev.currentTarget.value)}
                />
            </InputsContainer>
            <LoaderRotatorModal show={isLoading} />
            <ButtonContainer>
                <PrimaryButton 
                    onClick={handleAcompanharSinistro}
                >
                    ACOMPANHAR SINISTRO
                </PrimaryButton>
            </ButtonContainer>

        </SinistroPageModel>
    )
}

export default AcompanhamentoSinistro