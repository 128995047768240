import React from 'react';
import {BackgroundPage,ModalDialog,PageContent} from './style';
const BlurredBackgroundComponent : React.FC<{height?:string,width?:string,header?:React.ReactNode}> = (props) :React.ReactElement =>
{
    return(
    <div>
        <BackgroundPage/>
        <PageContent>
                <ModalDialog height={props.height?? "70%"} width={props.width?? "90%"}>
                    {
                        props.header
                    }
                    {
                        props.children
                    }
                </ModalDialog>
        </PageContent>
    </div>
    )
} 
export default BlurredBackgroundComponent;