import styled from 'styled-components';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; 
`;

export const Linha = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin: 10px 0;
`; 

export const Coluna = styled.div< {width?: string | number}>`
    width: ${props => props?.width ?? "100%" };
    flex-direction: column;
    display: flex;
    margin:  0 15px;
`; 

export const StyledInput = styled.input<{ type: "text" }>`
    width: 100%;
    height: 45px;
    border-radius: 35px;
    border: 1px solid #AAA;
    padding-left: 15px;
    background-color: white;

    &:disabled {   
        background-color: #EEE; 
    }
`;

export const Label =  styled.span`
    color: #AAA;
    font-size: 13px;
    margin: 0 0 5px 15px;
`;
export const Simbolos = styled.div< {marginLeft?: string | number}>`
    margin-left: ${props => props?.marginLeft ?? "8%" };
    font-size: 12px;
    color: white !important;
    cursor: pointer;
    margin-right:1%;
`;
export const Logo = styled.img`
    height:3.0rem;
    margin-right:15px;
`;
export const NavigationBar = styled.div`
    background-color: #E27800;
    width:100%;
    height: 45px ;
    box-shadow: #54555473 0 0 15px 0;
    display:flex;
    justify-content: space-between;
    padding:0px 20px 0px 20px;
    align-items:center;
    min-width:980px;
    z-index:100;
`

export const HeaderSection = styled.div<{width?: string}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${ props => props.width ? props.width : ''};
    justify-content:space-between;
`;

export const SearchBar = styled(InputGroup)`
    width: 30%;
    height: 28px;
`;
export const TextEnterSearch = styled(FormControl)`
    background-color: #FFFFFF;
    height: 26px;
    border-right: 0px;
    border-top-left-radius: 13px 13px;
    border-bottom-left-radius: 13px 13px;
`;
export const ButtonSearchSpace = styled.div`
    height: 26px;
    width:30px;
    background-color: #FFFFFF;
    border: 1px solid #ced4da;
    border-left:0px;
    border-top-right-radius: 13px 13px;
    border-bottom-right-radius: 13px 13px; 
    display:flex;
    justify-content:center;
    cursor:pointer;
`
export const ButtonEnterSearch = styled(FontAwesomeIcon)`
    height: 26px;
    font-size:20px;
`;
export const UserContainer = styled.div`
    cursor:pointer;
    display: flex;
    flex-direction: row; 
    justify-content:flex-end;
    align-items:center;
    margin-right: 20px;
    width:50%;
`;
export const UserOptions = styled.label`
    background-color: #E27800;
    border: 0px;
    font-size: 14px;
    color: #FFFFFF; 
    margin-bottom:0px;
    align-self:center;
    margin-left:5px;
    cursor:pointer;
`;
export const ExitButton = styled.button`
    font-size: 10px;
    background-color: #E27800;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 9px;
    text-align: center;
    margin-left: 10px;
    ::after
    {
        outline:0px;
    }
    ::before
    {
        outline:0px;
    }
    :focus{
        outline:0px;
    }
`;

export const ArrowDown = styled(Icon)`
    margin-left:12%;
    color:white;
    font-size:70%;
`;
export const SinoSpace = styled.div`
    cursor:pointer;
    height:30px;
`