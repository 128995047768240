import styled, { css } from 'styled-components';

export const LabelAndTextSpace = styled.div<{width:string}>`
    display:flex;
    flex-direction:column;
    width:${props=>props.width};
    height:100%;
    margin:7px;
    justify-content:center;
`;
export const HeaderAnaliseSpace = styled.div`
    width:100%;
    height:72px;
    display:flex;
    align-items:center;
`;
const commonProps = css`
    margin:0;
    font-size:13px;
    line-height:normal;
    color: #636363;
    letter-spacing: 0px;
    font-weight:normal;
    text-align:center;
    max-height:100%;
`
export const StyledTextColor = styled.p`
    ${commonProps}
    color:#B9B9B9;
`
export const StyledText = styled.p<{isLink?:boolean,isBolder?:boolean}>`
    ${commonProps}
    ${props=>props.isLink? css`
    max-width:50%;
    cursor:pointer;
    overflow:hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    :hover{
        color:${props=>props.theme.cores.primaria};
    }
    `:""}
    ${props=>props.isBolder?css`font-weight:bolder`:""}
`;
export const HistoricoRowSpace = styled.div`
    border-bottom:1px solid #B9B9B9;
    display:flex;
    justify-content:space-between;
    width:100%;
    align-items:center;
    height:30px;
`