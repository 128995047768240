import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { AuthContext } from '../../storage/contexts/AuthContext';
import { GlobalContext} from '../../storage/contexts/GlobalContext'  ;
import { GetIcones } from '../../services/APICallableActions/icones';
import {GetPreUser, GetAllFavoritesCUSTOMTOKEN, GetInformativosCUSTOMTOKEN, GetFavoritesCUSTOMTOKEN} from '../../API/usuario';
import {IFavorite, IFavoriteAdd} from '../../interfaces/components/IFavorite'
import { IGlobalContext} from '../../interfaces/storage/contexts/IGlobalContext';
import { LoaderError } from '../../components/Loaders/LoaderDots';
import { BackgroundPage,PageContent} from '../Portal/ConfirmaDados/styles';
import { ModalDialog } from './style';
import { AxiosResponse } from 'axios';
import { GetLoginComunicante } from '../../API/sinistro';
import LoadingBar from '../../components/Loaders/LoadingBar';
import APIRequestFunction from '../../services/APIRequestFunction';
import FindErrorMessageOnAPIReturn from '../../functions/FindErrorMessageOnAPIReturn';
import { IStatePropsDetalhesSinistro } from '../../interfaces/pages/IDetalhesSinistro';
function PreHome(){
    const {auth} = useContext(AuthContext);
    const reqHeaders = useMemo(()=>{return({
        Authorization: "Bearer " + auth?.TOKEN,
    })},[auth]);
    const [clienteIsValido,setClienteIsValido] = useState<"Invalido"|"Valido"|"">("");
    const [hasError,setError] = useState<string>("");
    const tempGlobalContext = useRef<IGlobalContext>({});
    const {globalContext,setGlobalContext} = useContext(GlobalContext);
    const state = useContext(AuthContext).auth.state;
    console.log('state', state);
    const [queryCounter,setQueryCounter] = useState<number>(0);
    const routerHistory = useHistory();
    const queryCounterRef = useRef<number>(0);
    useEffect(()=>{
        tempGlobalContext.current=globalContext;
    },[globalContext]);
    const [redirectDestiny,setRedirectDestiny] = useState<"home"|"enviardocumentos"|"confirmarDadosSeguros"|"comunicacaoSinistro"|"acompanhamentoSinistro"|undefined>();
    const reqsToDo:(()=>Promise<any>)[] = useMemo(()=>{
        return(
        [
            async ()=>
            { 
                console.log("Pegando todos os icones");
                const req = APIRequestFunction(GetIcones(true));
                req.catch((reason)=>{
                    setError(FindErrorMessageOnAPIReturn(reason));
                });
                const response=(await req).data;
                queryCounterRef.current++;
                setQueryCounter(queryCounterRef.current)
                return {Icones:response};
            },
            async ()=>
            {
                console.log("Pegando todos os itens menu")
                const req = GetAllFavoritesCUSTOMTOKEN(reqHeaders);
                try{
                    let reqResponse = await req;
                    let retorno:IFavoriteAdd[]=[];
                    reqResponse.data.map((item:any)=>{
                        retorno.push({
                            message:item.titulo,
                            icoReference:item.urlIcone,
                            favoriteId:item.id,
                            url:item.urlRedirecionamento,
                            exists:false,
                            ordem:item.ordem,
                            parent:null,
                        });
                        item?.subItem?.map( (subItem: any) =>{
                            retorno.push({
                                message:subItem.titulo,
                                icoReference:subItem.urlIcone,
                                favoriteId:subItem.id,
                                url:subItem.urlRedirecionamento,
                                exists:false,      
                                ordem:subItem.ordem,
                                parent:item.id,
                            })
                        });
                        return null;
                    });
                    console.log("Itens menu pegos");
                    queryCounterRef.current++;
                    setQueryCounter(queryCounterRef.current)
                    return {AllFavorites:retorno};
                }
                catch(reason)
                {
                    setError(FindErrorMessageOnAPIReturn(reason));
                    return undefined;
                }
            },
            async ()=>
            {
                console.log("Pegando os Favoritos");
                const req = GetFavoritesCUSTOMTOKEN(reqHeaders);
                try{
                    const response = await req
                    let retorno:IFavorite[] = response.data.map((item:any) => {
                        return {
                            message: item.itemMenu.titulo,
                            icoReference:item.itemMenu.urlIcone,
                            favoriteId:item.itemMenu.id,
                            url:item.itemMenu.urlRedirecionamento,
                            ordem:item.ordem,
                            deleteId:item.id,
                        }
                    }) ?? [];
                    console.log("Favoritos pegos");
                    queryCounterRef.current++;
                    setQueryCounter(queryCounterRef.current)
                    return {Favorites:retorno};
                }
                catch(reason)
                {
                    setError(FindErrorMessageOnAPIReturn(reason))
                    return undefined
                }
            },
            async ()=>
            {
                console.log("Pegando informativos");
                const req = GetInformativosCUSTOMTOKEN(reqHeaders);
                req.catch((reason)=>{
                    setError(FindErrorMessageOnAPIReturn(reason));
                });
                const response = (await req).data
                setQueryCounter(queryCounterRef.current++)
                return {Informativos:response}
            }
        ])
    },[reqHeaders]);

    const DecidirDestino = useCallback(()=>{
        if(clienteIsValido==="Invalido")
            return <Redirect to="/verificacaoSeguranca"/>
        else
            switch(redirectDestiny)
            {
                case "home":
                    return <Redirect to="/inicio/"/>
                case "enviardocumentos":
                    return <Redirect to="/inicio/sinistro/enviardocumentos"/>
                case "confirmarDadosSeguros":
                    return <Redirect to="/inicio/perfil/confirmardados"/>
                case "comunicacaoSinistro":
                    return <Redirect to="/inicio/sinistro/identificacaosegurado"/>
                case "acompanhamentoSinistro":
                    const {
                        cpf,
                        id,
                    } = JSON.parse(state);
                    routerHistory.replace(
                        `/inicio/sinistro/detalheaviso`,
                        {
                            userType:"protocoloNoData",
                            data: {
                                cpfSegurado:cpf.replace(/[^\d]/g,''),
                                codigo:id,
                            }
                        } as IStatePropsDetalhesSinistro
                    );
                    return(
                        <></>
                    )
                default:
                    return <></>
            }
    },[redirectDestiny,clienteIsValido])

    useEffect(()=>{
        async function CheckUserIsValid (isComunicante:boolean)
        {
            const LoginQuery : (requisitionHeader:{Authorization:string}) => Promise<AxiosResponse<any>> =  isComunicante? GetLoginComunicante : GetPreUser;
            await LoginQuery(reqHeaders).then((response)=>
            {
                queryCounterRef.current++;
                setQueryCounter(queryCounterRef.current);
                setGlobalContext({PreUser:response.data});
                setClienteIsValido(
                    (
                        (
                            (
                                response.data.valido
                                && response.data.isSegurado
                            ) || (
                                response.data.isBeneficiario
                                && !(response.data.isSegurado)
                            ) || (
                                response.data.isComunicante
                                && !(response.data.isSegurado)
                            ) || (
                                !response.data.isComunicante
                                && !response.data.isSegurado
                                && !response.data.isBeneficiario
                                && response.data.valido
                            )
                        ) ? "Valido":"Invalido"
                    )
                );
            }).catch((reason)=>{
                setError(FindErrorMessageOnAPIReturn(reason));
            });
        }
        if(!(Object.keys(globalContext ?? {}).length>=reqsToDo.length+1))
        {
            CheckUserIsValid(
                auth?.state
                && JSON.parse(auth?.state)
                && JSON.parse(auth?.state).tipoUsuario
                && JSON.parse(auth?.state).tipoUsuario === "comunicante"
            );
        }
        // eslint-disable-next-line
    },[])
    
    useEffect(()=>{
        if(!redirectDestiny)
        {
            if(clienteIsValido==="Valido")
            {
                const allLoadingPromises = async () : Promise<AxiosResponse<any>[]> =>{
                    return await Promise.all(reqsToDo.map((func:Function) : Promise<AxiosResponse>=>{return func()}));
                }
                allLoadingPromises().then((responses)=>{
                    console.log("All promises ended")
                    let finalContext :IGlobalContext = {};
                    responses.map((response)=>{
                        finalContext = {...finalContext,...response};
                        return undefined;
                    })
                    setGlobalContext({...tempGlobalContext.current,...finalContext})
                });
            }
        }// eslint-disable-next-line
    },[clienteIsValido]);

    const validarDados = ()=>{
        if(!(globalContext.PreUser?.endereco))
            return false;
         
        if(!(globalContext.PreUser?.uf))
            return false;

        if(!(globalContext.PreUser?.cep))
            return false;

        if(!(globalContext.PreUser?.cidade))
            return false;
         
        if(!(globalContext.PreUser?.bairro))
            return false;

        if(!(globalContext.PreUser?.telefone1))
            return false;

        // if(!(globalContext.PreUser?.isSegurado))
        //     return false;

        if(!(globalContext.PreUser?.email))
            return false;

        if(!(globalContext.PreUser?.email?.length > 0))
            return false;

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(globalContext.PreUser?.email)))
            return false;

        return true;
    }

    useEffect(()=>{
        if(Object.keys(globalContext ?? {}).length===reqsToDo.length+1)
        {
            if(!(globalContext.PreUser?.nome) || globalContext.PreUser?.nome.length<=2)
                setRedirectDestiny("confirmarDadosSeguros");
            else
            {
                try{
                    if(!validarDados())
                        setRedirectDestiny("confirmarDadosSeguros");
                    else if(auth?.state && JSON.parse(auth?.state) && JSON.parse(auth?.state).etapa && JSON.parse(auth?.state).etapa==="comunicacaoSinistro")
                        setRedirectDestiny("comunicacaoSinistro");
                    else if(auth?.state && JSON.parse(auth?.state) && JSON.parse(auth?.state).etapa && JSON.parse(auth?.state).etapa==="acompanhamentoSinistro")
                        setRedirectDestiny("acompanhamentoSinistro");
                    else
                        setRedirectDestiny("home");
                }
                catch(exception)
                {
                    setRedirectDestiny("home");
                    console.log(exception);
                }
            }
        }
    },[globalContext,reqsToDo.length,auth])
    return (
        <div style={{display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center",height:"100vh",width:"100vw"}}> 
            <BackgroundPage/>
            <PageContent>
                <ModalDialog>
                    {
                        <DecidirDestino/>
                    }
                    {
                        (hasError===""?
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <label>Carregando informações do sistema. ({queryCounter}/{reqsToDo.length+1})</label>
                                <LoadingBar barStatus={queryCounter} barLimit={reqsToDo.length+1}/>
                            </div>
                            :
                            <LoaderError erro={hasError}/>
                        )
                    }
                </ModalDialog>
            </PageContent>
        </div>
    )
}

export default PreHome;