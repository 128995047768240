import React from 'react';
import {PageBackground, PageHeader,PageContent, Title} from './style';
const SinistroPageModel : React.FC<{titulo:string,height?:string,maxHeight?:string,flexWrap?:string}> = (props) : React.ReactElement =>
{
    return(
        <PageBackground height={props.maxHeight}>
        <PageHeader/>
            <PageContent height={props.height} flexWrap={props.flexWrap}>
                <Title>{props.titulo}</Title>
                {
                    props.children
                }
            </PageContent>
        </PageBackground>
    )
}
export default SinistroPageModel;