import styled from 'styled-components';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const SearchBar = styled.input<{width?:"AllSpace"|"HalfSpace"}>`
    width:${props=>(props.width??"AllSpace")==="AllSpace"?"60%":"30%"};
    height:100%;
    padding-left:5%;
    border:1px solid #707070;
    border-top-right-radius: 10px 8px;
    border-top-left-radius: 10px 8px;
    border-bottom-right-radius: 10px 8px;
    border-bottom-left-radius: 10px 8px;
    opacity: 1;
    text-align: left;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
    text-align: left;
    font: Regular 19px/33px Roboto;
    letter-spacing: -0.38px;
    color: #B9B9B9;
`;
export const DocumentListSpace= styled.div`
    height:auto;
    width:60vw;
    min-width:770px;
    z-index:9;
    background-color:#FFFFFF;
`;
export const SameLine = styled.div`
    min-height:35px;
    height:40px;
    width:100%;
    margin-top:2%;
    display:flex;
    justify-content:space-between;
`;
export const SameLineSmall = styled.div`
    height:auto;
    width:100%;
    margin-top:2%;
    display:flex;
    justify-content:space-between;
`;
export const GreyText = styled.label`
    align-self:center;
    color:grey;
    margin-bottom:0px;
`;
export const PageSelector = styled(DropdownButton)`
    width:10%;
    border-color:#636363 !important;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`;
export const DocumentsList = styled.div<{extraStyle?:string}>`
    margin-top:3%;
    ${props=>props.extraStyle??""}
`;
export const Column = styled.tr`
    display:flex;
    width:98%;
    margin-left:1%;
    margin-right:1%;
`;
export const HeadColumn = styled.thead`
    display:flex;
    width:98%;
    margin-left:1%;
    margin-right:1%;
`
export const Tabela = styled.table`
    width:100%;
    table-layout: fixed; 
`;
export const RoundedRow = styled.tr<{especificStyle?:string}>`
    margin-top:0.4%;
    flex-direction:row;
    border:1px solid #B9B9B9;
    border-radius:10px;
    justify-content:space-between;
    display:flex;
    align-items:center;
    height:40px;
    ${props=>props.especificStyle}
`;
export const SubRoundedRow = styled.div`
    flex-direction:row;
    justify-content:space-between;
    display:flex;
    align-items:center;
    height:40px;
`
export const SelectedRoundedRow = styled.tr<{especificStyle?:string}>`
    margin-top:0.4%;
    border:1px solid #B9B9B9;
    border-radius:10px;
    display:flex;
    flex-direction:column;
    ${props=>props.especificStyle}
`;
export const TopSelectedRoundedRow = styled.th`
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    display:flex;
    width:100%;
    align-items:center;
    height:40px;
    justify-content:space-between;
`
export const UpDownIco = styled(FontAwesomeIcon)`
    cursor:pointer;
    text-align: left;
    font-size:0.7rem;
    margin-left:5px;
    letter-spacing: 0px;
    color: #E27800;
    opacity: 1;
`
export const InsideContainer = styled.th<{ width: string | number, justifyContent?: string}>`
    width:${props => props?.width ?? "20%" };
    display:flex;
    justify-content:${props => props?.justifyContent ?? "center" };
    align-items:center;
`;
export const TitleContainer = styled.th<{ width: string | number, justifyContent?: string}>`
    width:${props => props?.width ?? "20%" };
    display:flex;
    justify-content:${props => props?.justifyContent ?? "center" };
    align-items:center;
    margin-left:1%;
`;
export const SizedText = styled.label`
    font-size:70%;
    color:#636363;
    text-align:left;
    margin-bottom: 0px;
    margin-left:3px;
`;
export const LittleSizedText = styled.label`
    font-size:80%;
    margin-bottom:0px;
`;
export const InsideSelectedText = styled.label`
    margin-bottom:0px;
    text-align: left;
    font: 80% Roboto;
    letter-spacing: -0.38px;
    color: #636363;
    opacity: 1;
`

export const AlignSpace = styled.div<{extraStyle?:string}>`
    display:flex;
    align-items:center;
    ${props=>props.extraStyle??""}
`
export const PDFText = styled.label`
    font-size:60%;
    color:#636363;
    margin-bottom:0px;
    cursor:pointer;
`
export const IcoPDF = styled(FontAwesomeIcon)`
    color:#E27800;
    font-Size: 0.7rem;
    align-Self:center;
    margin-left:4px;
    cursor:pointer;
`
export const SelectedRowTitle = styled.label<{color?:string}>`
    text-align: left;
    font: Bold 90% Roboto;
    letter-spacing: -0.38px;
    color: ${props => props?.color ?? "#636363" };
    opacity: 1;
    margin-bottom:0px;
`
export const InsideRowColumnName = styled.label`
    text-align: left;
    font: 70% Roboto;
    letter-spacing: -0.34px;
    color: #B9B9B9;
    opacity: 1;
    margin-bottom:0px;
`
export const VisualizarContainer = styled.th<{ width: string | number }>`
    width:${props => props?.width ?? "20%" };
    display:flex;
    justify-content:center;
`
export const FiltroSelect = styled.select`
    width:30%;
    height:100%;
    padding-left:5%;
    border:1px solid #707070;
    border-top-right-radius: 10px 8px;
    border-top-left-radius: 10px 8px;
    border-bottom-right-radius: 10px 8px;
    border-bottom-left-radius: 10px 8px;
    opacity: 1;
    text-align: center;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
    font: Regular 19px/33px Roboto;
    letter-spacing: -0.38px;
    color: black;
`