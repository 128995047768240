import styled from 'styled-components';
import MaskedInput from 'react-maskedinput';
export const Titulo = styled.label`
    text-align: left;
    font: Bold 100% Roboto;
    letter-spacing: -0.44px;
    text-transform: uppercase;
    opacity: 1;
` 
export const EnviarAtendimento = styled.button`
    background-color:#E27800;
    border-color:#E27800!important;
    width:100%;
    border-radius: 35px;
    opacity: 1;
    text-align: center;
    font: Bold Roboto;
    letter-spacing: -0.32px;
    color: #FFFFFF;
    text-transform: uppercase;
    border-bottom: #E27800!important;
    border-right: #E27800!important;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`;
export const RoundedMaskedInput = styled(MaskedInput)`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 19px;
    opacity: 1;
    width:50%;
    height:100%;
    padding-right:1%;
    padding-left:2%;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
`
export const RoundedInput = styled.input`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 19px;
    opacity: 1;
    width:50%;
    height:100%;
    padding-right:1%;
    padding-left:2%;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
` 
export const RoundedSelect = styled.select`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 19px;
    opacity: 1;
    width:50%;
    height:100%;
    padding-right:1%;
    padding-left:2%;
    ::after{
        outline:0px;
    };
    ::before{
        outline:0px;
    };
    :focus{
        outline:0px;
    }
` 
export const AtendimentoText = styled.label`
    margin-bottom:0px;
`
export const AtendimentoSpace = styled.div`
    display:flex;
    width:100%;
    align-items:center;
    justify-content:space-between;
`
export const AtendimentoCheckbox = styled.input`
    margin-right:2%;
`
export const AtendimentoPrompt = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-around;
`
export const ItemSpacing = styled.div`
    margin-top:10px;
    display:flex;
    width:100%;
    height:max-content;
    flex-direction:column;
`