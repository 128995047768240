import styled from 'styled-components';
export const RelativeSpace = styled.div<{left?:string,top?:string,maxHeight?:string}>`
    position:absolute;
    background-color:white;
    border: ${props=>props.theme.cores.primaria} 1px solid; 
    border-radius:10px;
    padding:10px;
    max-width:300px;
    max-height:${props=>props.maxHeight??"200px"};
    overflow-y:auto;
    left:${props=>props.left??"auto"};
    top:${props=>props.top??"auto"};
`