import React from 'react';
import {HomeContainer,TituloContainer,TextContainer, PageModelSpace} from './style';
const SinistroNewPageModel :React.FC<{tituloPagina:string,descricao?:string,pageRef?:React.RefObject<HTMLDivElement>}> = (props): React.ReactElement =>{
    const {children,tituloPagina,descricao,pageRef} = props;
    return(
        <PageModelSpace ref={pageRef}>
            <HomeMessage title={tituloPagina}>
                {descricao}
            </HomeMessage>
            {children}
        </PageModelSpace>
    )
}
const HomeMessage:React.FC<{ title?:string }> = (props): React.ReactElement =>{
    return(
        <HomeContainer>
            <TituloContainer>
                {props.title}
            </TituloContainer>
                {props.title !== '' && <div style={{borderTop:"solid 2px#E2B500",marginBottom:"5px"}}></div>}
            <TextContainer >
                {props.children}
            </TextContainer>
        </HomeContainer>
    )
}
export default SinistroNewPageModel;