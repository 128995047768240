import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PrintDocsLabel, PrintBackdrop, PrintInsideSpace, CapemisaImage, PageSpace, TextSuspended, TopRounded, InsideRowColumnName, InsideSelectedText, TopRoundedRow, SingleLine, AlignSpace, NavigationButtonsSpace, OrangeLink, BtnLaranja, BtnOutlineLaranja } from './style';
import SinistroPageModel from '../../../components/SinistroComponents/SinistroNewPageModel';
import { SinistroContext } from '../../../storage/contexts/SinistroContext';
import { GlobalContext } from '../../../storage/contexts/GlobalContext';
import { useReactToPrint } from 'react-to-print';
import { SelectedRowTitle } from '../../../components/CustomDropdown/style';
import { GetAtendimentos } from '../../../API/usuario';

const ConfirmacaoDocumentos :React.FC<{}> = (): React.ReactElement =>{
    const {sinistroContext} = useContext(SinistroContext);
    const {globalContext} = useContext(GlobalContext);
    const [protocol, setProtocol] = useState();
    const history = useHistory();

    const printablePageRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => printablePageRef.current,
    })

    const FixDate = useCallback((date?:string)=>{
        const fixedData = date ?? new Date().toLocaleDateString("pt-br").slice(0,10);
        return (fixedData.slice(0,2)+"/"+fixedData.slice(3,5)+"/"+fixedData.slice(6,10))

    },[]);

    useEffect(() => {
        GetAtendimentos()?.then((response) => {
            setProtocol(response.data[0].codigo)
        })
    })

    const PrintablePage :React.FC<{}> = (): React.ReactElement => {
        return (
            <PrintBackdrop ref={printablePageRef}>
                <PrintInsideSpace>
                    <CapemisaImage/>
                    <SinistroPageModel tituloPagina={"COMUNICAÇÃO DE SINISTRO - CONFIRMAÇÃO"}>
                        <div style={{display: 'inline-flex'}}>
                            <p style={{width: "70%", color:"#636363"}}>
                                Pronto! Sua comunicação de sinistro foi realizada com sucesso. <br />
                                Você pode acompanhar sua solicitação por meio do protocolo: <br />
                            </p>
                            <PrintDocsLabel onClick={handlePrint}>Imprimir</PrintDocsLabel>

                        </div>

                        <h3 style={{color:"#636363", fontWeight: "bold", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"30px", marginBottom:"30px" }}>
                            {protocol}
                        </h3>
                        <TitleAndOutlinedComponent title={"Detalhes"}>
                            <div style={{marginTop:"10px"}}>
                                <TopRounded>
                                    <TopRoundedRow>
                                        <TextSuspended width={"33%"}>
                                            <InsideRowColumnName>Segurado(a)/Participante:</InsideRowColumnName>
                                            <InsideSelectedText>{sinistroContext.informacoesSegurado?.nomeCompletoParticipanteSegurado ?? sinistroContext.informacoesSegurado?.primeiroNomeSegurado} - {sinistroContext.informacoesSegurado?.cpfSegurado.slice(0, 3) + "." + sinistroContext.informacoesSegurado?.cpfSegurado.slice(3, 6) + "." + sinistroContext.informacoesSegurado?.cpfSegurado.slice(6, 9) + "-" + sinistroContext.informacoesSegurado?.cpfSegurado.slice(9, 11)}</InsideSelectedText>
                                        </TextSuspended>
                                        <TextSuspended width={"33%"}>
                                            <InsideRowColumnName>Data de nascimento:</InsideRowColumnName>
                                            <InsideSelectedText>{sinistroContext.informacoesSegurado?.dataNascimentoSegurado ?? ""}</InsideSelectedText>
                                        </TextSuspended>
                                        <TextSuspended width={"33%"}>
                                            <InsideRowColumnName>Comunicante:</InsideRowColumnName>
                                            <InsideSelectedText>{globalContext.PreUser?.nome } ({sinistroContext.grauRelacionamentoComunicante?.name}) - {globalContext.PreUser?.cpf? (globalContext.PreUser?.cpf.slice(0,3)+"."+globalContext.PreUser?.cpf.slice(3,6)+"."+globalContext.PreUser?.cpf.slice(6,9)+"-"+globalContext.PreUser?.cpf.slice(9,11)):"CPF não identificado"}</InsideSelectedText>
                                        </TextSuspended>
                                    </TopRoundedRow>
                                    <TopRoundedRow>
                                        <AlignSpace>
                                            <SingleLine>
                                                <TextSuspended width={"33%"}>
                                                    <InsideRowColumnName>Evento:</InsideRowColumnName>    
                                                </TextSuspended>
                                                <TextSuspended width={"33%"}>
                                                    <InsideRowColumnName>Data do evento:</InsideRowColumnName>
                                                </TextSuspended>
                                                <TextSuspended width={"33%"}>
                                                    <InsideRowColumnName>Data da comunicação:</InsideRowColumnName>
                                                </TextSuspended>
                                            </SingleLine>
                                            <SingleLine>
                                                <TextSuspended width={"33%"}>
                                                    <InsideSelectedText>{sinistroContext.nomeEvento??"Evento não identificado"}</InsideSelectedText>   
                                                </TextSuspended>
                                                <TextSuspended width={"33%"}>
                                                    <InsideSelectedText>{sinistroContext.dataEvento??"Data não identificada"}</InsideSelectedText>
                                                </TextSuspended>
                                                <TextSuspended width={"33%"}>
                                                    <InsideSelectedText>{FixDate()??"Data não identificada"}</InsideSelectedText>
                                                </TextSuspended>
                                            </SingleLine>
                                        </AlignSpace>
                                    </TopRoundedRow>
                                </TopRounded>
                            </div>
                        </TitleAndOutlinedComponent>
                    </SinistroPageModel>
                    {/* <h3 style={{color:"#636363", fontWeight: "bold", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"30px", marginBottom:"30px" }}>
                        {protocol}
                    </h3>
                    <TitleAndOutlinedComponent title={"Detalhes"}>
                        <div style={{marginTop:"10px"}}>
                            <TopRounded>
                                <TopRoundedRow>
                                    <TextSuspended width={"33%"}>
                                        <InsideRowColumnName>Segurado(a)/Participante:</InsideRowColumnName>
                                        <InsideSelectedText>{sinistroContext.informacoesSegurado?.nomeCompletoParticipanteSegurado ?? sinistroContext.informacoesSegurado?.primeiroNomeSegurado} - {sinistroContext.informacoesSegurado?.cpfSegurado}</InsideSelectedText>
                                    </TextSuspended>
                                    <TextSuspended width={"33%"}>
                                        <InsideRowColumnName>Data de nascimento:</InsideRowColumnName>
                                        <InsideSelectedText>{sinistroContext.informacoesSegurado?.dataNascimentoSegurado ?? ""}</InsideSelectedText>
                                    </TextSuspended>
                                    <TextSuspended width={"33%"}>
                                        <InsideRowColumnName>Comunicante:</InsideRowColumnName>
                                        <InsideSelectedText>{globalContext.PreUser?.nome } ({sinistroContext.grauRelacionamentoComunicante?.name}) - {globalContext.PreUser?.cpf? (globalContext.PreUser?.cpf.slice(0,3)+"."+globalContext.PreUser?.cpf.slice(3,6)+"."+globalContext.PreUser?.cpf.slice(6,9)+"-"+globalContext.PreUser?.cpf.slice(9,11)):"CPF não identificado"}</InsideSelectedText>
                                    </TextSuspended>
                                </TopRoundedRow>
                                <TopRoundedRow>
                                    <AlignSpace>
                                        <SingleLine>
                                            <TextSuspended width={"33%"}>
                                                <InsideRowColumnName>Evento:</InsideRowColumnName>    
                                            </TextSuspended>
                                            <TextSuspended width={"33%"}>
                                                <InsideRowColumnName>Data do evento:</InsideRowColumnName>
                                            </TextSuspended>
                                            <TextSuspended width={"33%"}>
                                                <InsideRowColumnName>Data da comunicação:</InsideRowColumnName>
                                            </TextSuspended>
                                        </SingleLine>
                                        <SingleLine>
                                            <TextSuspended width={"33%"}>
                                                <InsideSelectedText>{sinistroContext.nomeEvento??"Evento não identificado"}</InsideSelectedText>   
                                            </TextSuspended>
                                            <TextSuspended width={"33%"}>
                                                <InsideSelectedText>{sinistroContext.dataEvento??"Data não identificada"}</InsideSelectedText>
                                            </TextSuspended>
                                            <TextSuspended width={"33%"}>
                                                <InsideSelectedText>{FixDate()??"Data não identificada"}</InsideSelectedText>
                                            </TextSuspended>
                                        </SingleLine>
                                    </AlignSpace>
                                </TopRoundedRow>
                            </TopRounded>
                        </div>
                    </TitleAndOutlinedComponent> */}
                </PrintInsideSpace>
            </PrintBackdrop>
        )
    }
    return (
        <PageSpace ref={printablePageRef}>
            <div style={{display: "none"}}>
                <PrintablePage />
            </div>
            <SinistroPageModel tituloPagina={"COMUNICAÇÃO DE SINISTRO - CONFIRMAÇÃO"}>
                <div style={{display: 'inline-flex'}}>
                    <p style={{width: "70%", color:"#636363"}}>
                        Pronto! Sua comunicação de sinistro foi realizada com sucesso. <br />
                        Você pode acompanhar sua solicitação por meio do protocolo: <br />
                    </p>
                    <PrintDocsLabel onClick={handlePrint}>Imprimir</PrintDocsLabel>

                </div>



                <h3 style={{color:"#636363", fontWeight: "bold", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"30px", marginBottom:"30px" }}>
                    {protocol}
                </h3>
                <TitleAndOutlinedComponent title={"Detalhes"}>
                    <div style={{marginTop:"10px"}}>
                        <TopRounded>
                            <TopRoundedRow>
                                <TextSuspended width={"33%"}>
                                    <InsideRowColumnName>Segurado(a)/Participante:</InsideRowColumnName>
                                    <InsideSelectedText>{sinistroContext.informacoesSegurado?.nomeCompletoParticipanteSegurado ?? sinistroContext.informacoesSegurado?.primeiroNomeSegurado} - {sinistroContext.informacoesSegurado?.cpfSegurado.slice(0, 3) + "." + sinistroContext.informacoesSegurado?.cpfSegurado.slice(3, 6) + "." + sinistroContext.informacoesSegurado?.cpfSegurado.slice(6, 9) + "-" + sinistroContext.informacoesSegurado?.cpfSegurado.slice(9, 11)}</InsideSelectedText>
                                </TextSuspended>
                                <TextSuspended width={"33%"}>
                                    <InsideRowColumnName>Data de nascimento:</InsideRowColumnName>
                                    <InsideSelectedText>{sinistroContext.informacoesSegurado?.dataNascimentoSegurado ?? ""}</InsideSelectedText>
                                </TextSuspended>
                                <TextSuspended width={"33%"}>
                                    <InsideRowColumnName>Comunicante:</InsideRowColumnName>
                                    <InsideSelectedText>{globalContext.PreUser?.nome } ({sinistroContext.grauRelacionamentoComunicante?.name}) - {globalContext.PreUser?.cpf? (globalContext.PreUser?.cpf.slice(0,3)+"."+globalContext.PreUser?.cpf.slice(3,6)+"."+globalContext.PreUser?.cpf.slice(6,9)+"-"+globalContext.PreUser?.cpf.slice(9,11)):"CPF não identificado"}</InsideSelectedText>
                                </TextSuspended>
                            </TopRoundedRow>
                            <TopRoundedRow>
                                <AlignSpace>
                                    <SingleLine>
                                        <TextSuspended width={"33%"}>
                                            <InsideRowColumnName>Evento:</InsideRowColumnName>    
                                        </TextSuspended>
                                        <TextSuspended width={"33%"}>
                                            <InsideRowColumnName>Data do evento:</InsideRowColumnName>
                                        </TextSuspended>
                                        <TextSuspended width={"33%"}>
                                            <InsideRowColumnName>Data da comunicação:</InsideRowColumnName>
                                        </TextSuspended>
                                    </SingleLine>
                                    <SingleLine>
                                        <TextSuspended width={"33%"}>
                                            <InsideSelectedText>{sinistroContext.nomeEvento??"Evento não identificado"}</InsideSelectedText>   
                                        </TextSuspended>
                                        <TextSuspended width={"33%"}>
                                            <InsideSelectedText>{sinistroContext.dataEvento??"Data não identificada"}</InsideSelectedText>
                                        </TextSuspended>
                                        <TextSuspended width={"33%"}>
                                            <InsideSelectedText>{FixDate()??"Data não identificada"}</InsideSelectedText>
                                        </TextSuspended>
                                    </SingleLine>
                                </AlignSpace>
                            </TopRoundedRow>
                        </TopRounded>
                    </div>
                </TitleAndOutlinedComponent>
            
                <p style={{fontWeight: "bold", color:"#636363", margin:"35px 0 30px 0"}}>
                    Caso você queira consultar o status e/ou informações sobre a sua solicitação, basta
                    acessar a área <OrangeLink to="/inicio/falecomagente/atendimentos/">MEUS ATENDIMENTOS</OrangeLink> ou clicar no botão abaixo.    
                </p>    
                <NavigationButtonsSpace>
                    <BtnLaranja onClick={()=>{ history.push("/inicio/falecomagente/atendimentos/");} }>
                        MEUS ATENDIMENTOS
                    </BtnLaranja>
                    <BtnOutlineLaranja to="/inicio/sinistro/identificacaosegurado" >COMUNICAR NOVO SINISTRO</BtnOutlineLaranja>
                </NavigationButtonsSpace>
            </SinistroPageModel>
        </PageSpace>
    )
}
const TitleAndOutlinedComponent : React.FC<{title:string}> = (props) => {
    return(
        <div style={{display:"flex",marginTop:"10px",flexDirection:"column"}}>
            <SelectedRowTitle>{props.title}</SelectedRowTitle>
            {
                props.children
            }
        </div>
    )
}
export default ConfirmacaoDocumentos;