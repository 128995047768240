import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { initializeIcons } from '@uifabric/icons';
import RoundedComponentBottom from '../../../components/RoundedComponent';
import CustomDropdown from '../../../components/CustomDropdown';
import IdentificarEvento from '../IdentificacaoDocumentos';
import {PageContent,SinistroNavigationCallToAction,AlignBox,PaddedBottom, Header} from './style';
import SinistroPageModel from '../../../components/SinistroComponents/SinistroNewPageModel';
import { SinistroContext } from '../../../storage/contexts/SinistroContext';
import { IEventoDocumento, IGroupedEventoDocumento, ISinistroContext } from '../../../interfaces/storage/contexts/ISinistroContext';
import { Client_ID, PortalURL, Redirect_URI, URL_Sinistro_Redirect } from '../../../env_settings';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../storage/ducks';
import useAPI from '../../../storage/custom_hooks/API';
import { GetDocumentosSinistro } from '../../../services/APICallableActions/sinistro';
import { LoaderRotatorMessage } from '../../../components/Loaders/LoaderRotator';

const Home:React.FC<{}> = () : React.ReactElement => {
    const dispatch = useDispatch();
    const { path } = useRouteMatch();

    const [redirectToNextPage,setRedirectToNextPage] = useState<boolean>(false);

    const { sinistroContext,setSinistroContext } = useContext(SinistroContext);

    const history = useHistory();
    const [{data:documentos,status}, startDocumentosQuery] = useAPI<IEventoDocumento[]>(GetDocumentosSinistro());


    const DocsNecessarios = useTypedSelector( state => state.Sinistro.docsNecessariosVariosEventosData);

    const VerificarUserLogado = () => {
        return path.search("inicio")===-1;
    }

    const DecidirDestino = useCallback(()=>{
        let newSinistroContextObj : ISinistroContext = {
            ...sinistroContext
        };

        if(VerificarUserLogado())
        {
            const fixedOptions =  JSON.stringify(({...newSinistroContextObj, etapa:"comunicacaoSinistro",tipoUsuario:"comunicante"} as ISinistroContext));
            //const urlToCall = "https://capemisaseguradoradev.b2clogin.com/CapemisaSeguradoradev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignIn&client_id=f79ecffc-4ccc-4414-b0d6-d46eb2b158ed&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fcap-dev-portalrelacionamento-www.azurewebsites.net%2Fauth&scope=openid&response_type=id_token&prompt=login&state="+fixedOptions
            const urlToCall = URL_Sinistro_Redirect.replace('{client_id}',Client_ID).replace('{redirect_uri}', Redirect_URI).replace('{fixedOptions}', fixedOptions);
            window.location.href = urlToCall;
            return "loading";
        }
        else
        {
            setSinistroContext({
                ...newSinistroContextObj,
                etapa:"comunicacaoSinistro"
            });
            return "identificacaosegurado";
        }
    },[sinistroContext,path,setSinistroContext]);

    useEffect(()=>{
        if(redirectToNextPage)
        {
            const rotaDestino = DecidirDestino();
            if(rotaDestino !== "loading")
            {
                history.push(rotaDestino);
            }
        }
    },[redirectToNextPage, DecidirDestino, history])

    useEffect(() => {
        initializeIcons();
        startDocumentosQuery();
    }, [])

    const documentosFiltrados = useMemo(()=>
        documentos?.filter(
            (item,i,currentArray) => (!!!currentArray.slice(0,i).find((prevItem)=>prevItem.idEventoSinistro == item.idEventoSinistro))
        ).map((item) =>
            (
                {
                    documentos: documentos.filter(documento => documento.idEventoSinistro == item.idEventoSinistro),
                    idEventoSinistro: item.idEventoSinistro,
                    nomeEvento: item.nomeEvento,
                }
            ) as IGroupedEventoDocumento
        )
    ,[documentos])

    const TextoDescricao = "Agora você pode comunicar um sinistro ou solicitar seu benefício de maneira fácil e rápida. Para isso, basta clicar em um dos botões abaixo."
    return(
        <PageContent>
            <SinistroPageModel tituloPagina={"COMUNICAÇÃO/ACOMPANHAMENTO DE SINISTRO"} descricao={TextoDescricao}>
                <AlignBox>
                    <SinistroNavigationCallToAction>
                        <RoundedComponentBottom
                            width={"34%"}
                            height={70}
                            hideDots
                            cantRedirect
                            item={{url:"",favoriteId:0,message:"comunicar sinistro/benefício",isNotUrl:true,ordem:0,icoReference:"56"}} 
                            onClick={
                                ()=>{
                                    setSinistroContext({...sinistroContext,...{tipoJornada:"sinistro", etapa:"comunicacaoSinistro"}});
                                    setRedirectToNextPage(true);
                                }
                            }
                        />
                        <RoundedComponentBottom
                            width={"34%"}
                            height={70}
                            hideDots
                            cantRedirect
                            item={{url:"",favoriteId:0,message:"acompanhar sinistro/benefício",isNotUrl:true,ordem:0,icoReference:"55"}}
                            onClick={
                                ()=>{
                                    setSinistroContext({...sinistroContext,...{tipoJornada:"sinistro", etapa:"acompanhamentoSinistro"}});
                                    history.replace(VerificarUserLogado()?"/sinistro/acompanhamentosinistro":"/inicio/sinistro/acompanhamentosinistro");
                                }
                            }
                        />
                    </SinistroNavigationCallToAction>
                </AlignBox>
                <Header>DOCUMENTOS</Header>
                {
                    status==="COMPLETED"?
                        documentosFiltrados?.map(item=>{
                            return(
                                <PaddedBottom>
                                    <CustomDropdown bodyIsClickable titulo={`${item.nomeEvento}`}>
                                        <IdentificarEvento isToPrint={false} documentos={item?.documentos}></IdentificarEvento>
                                    </CustomDropdown>
                                </PaddedBottom>
                            )
                        })
                    :
                    <LoaderRotatorMessage/>
                }
            </SinistroPageModel>
        </PageContent>
    );
}
export default Home;
