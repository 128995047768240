import styled from 'styled-components';
 
export const Container = styled.div`
    margin:0;
    width: 100%;
    min-width:980px;
    height:fit-content;
    min-height:500px;
`;
export const PortalLGPDLinkSpace = styled.div`
    display: flex;
    flex-direction: row-reverse;
`
export const PortalLGPDLink = styled.a`
    color: #E27800;
    font-size: 18px;
    margin-right:7.5%;
`;
