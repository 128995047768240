import styled from 'styled-components';

export const RoundedNews = styled.div`
display:flex;
flex-direction:column;
align-items:center;
opacity: 1;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 35px 35px 0px 0px;
margin-bottom:10px;
z-index: 1;
`;
export const BackgroundLogo = styled.div<{imagem:string}>`
background-image:url(${props => props.imagem});
min-width:520px;
height:317px;
z-index:-1;
border:1px solid;
border-radius: 35px 35px 0px 0px;
background-size: cover;
background-position: center;
`
export const Titulo = styled.label`
text-align: left;
font: Bold 200% Roboto;
letter-spacing: -0.7px;
color: #636363;
opacity: 1;
`
export const TextSpace = styled.div`
width:90%;
margin-top:5%;
display:flex;
flex-direction:column;
`
export const SubTitle= styled.label`
text-align: left;
font: Regular 60% Roboto;
letter-spacing: -0.26px;
color: #B9B9B9;
opacity: 1;
`;
export const FooterSpace = styled.div`
width:90%;
margin-top:6%;
display:flex;
flex-direction:column;
`