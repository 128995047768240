import React, { useContext, useEffect, useRef, useState } from 'react';
import PageModel from '../../../components/PageModel';
import {RenderList} from '../../../components/DocumentList';
import {Mensagem, LinhaEntreComponentes, RowItem, ProdutoSpace} from './style';
import IOneLineExpandContents, {IDownContentReturnTypes,ITitleAnswer} from '../../../interfaces/components/IOneLineExpand';
import OneLineExpand from '../../../components/OneLineExpand';
import { GetContratoDetalhes, GetContratos } from '../../../services/APICallableActions/usuario';
import { LoaderError } from '../../../components/Loaders/LoaderDots';
import { LoaderRotatorMessage } from '../../../components/Loaders/LoaderRotator';
import CorrigirVariavelMonetaria from '../../../functions/CorrigirValorPontuacao';
import GeneralProdutoRowsComponent from '../../../components/ProdutosRow';
import {ProdutosContext} from '../../../storage/contexts/ProdutosContext';
import {InsideContratos, IProdutoContratoDealer, IProdutosContext} from '../../../interfaces/storage/contexts/IProdutosContext'
import { ContratoGenerico, ContratosOccultInfo } from '../../../interfaces/pages/IContrato';
import { IAPIProdutosRetorno, IProdutosAPIResponse} from '../../../interfaces/pages/IProdutos';
import useAPI from '../../../storage/custom_hooks/API';
import APIRequestFunction from '../../../services/APIRequestFunction';
import { GetProdutos } from '../../../services/APICallableActions/usuario';
import { useTypedSelector } from '../../../storage/ducks';
const MeusProdutos: React.FC = () : React.ReactElement =>
{   
    return(
        <PageModel>
            <RenderMeusProdutos/>
        </PageModel>
    )
}
const RenderMeusProdutos : React.FC = () :React.ReactElement =>
{
    const [produtosQuery,initProdutosQuery] = useAPI<IAPIProdutosRetorno[]>(GetProdutos());
    const [produtosContext,setProdutosContext] = useState<IProdutosContext>({produtoContratos:[]});
    useEffect(()=>{
        initProdutosQuery();
    },[initProdutosQuery]);
        return(
        <div style={{width:"100%"}}>
            <Mensagem>
                Confira todos os seus produtos. Para saber mais, clique em <strong>Visualizar</strong>.
            </Mensagem>
            {
                produtosQuery?.status==="FAILED"?
                <LoaderError erro={produtosQuery.errorResponse??"Ocorreu um erro na chamada de produtos! Recarregue a página ou chame o suporte CAPEMISA!"}/>
                :
                <ProdutosContext.Provider value={{produtosContext:produtosContext,setProdutosContext:setProdutosContext}} >
                    {
                        produtosQuery.status==="COMPLETED"?
                        (
                            (produtosQuery.data?.length ?? 0)>0?
                            // eslint-disable-next-line
                            <RenderList style="width:100%!important;" columnNames={[]} AditionalViewComponent={produtosQuery.data?.map((item,i)=><ProdutoContratoComponent index={i} contratosInfo={item}/>)} contents={produtosQuery.data?.map((item)=>{return({coluna:[{width:"30%",message:item.nomeProduto,title:true,textAlign:"flex-start"}]})})??[]} view={{cantView:false,type:"dropdown"}} cantDownload />
                            :
                            <div style={{height:"40px",width:"100%",display:"flex",justifyContent:"center"}}>
                                <label>Não foram encontrados produtos.</label>
                            </div>
                        )
                        :
                        <LoaderRotatorMessage width={"100%"} />
                    }
                </ProdutosContext.Provider>
            }
        </div>
    )
}
const ProdutoContratoComponent : React.FC<{contratosInfo:ContratosOccultInfo,index:number}> = (props) : React.ReactElement =>
{
    // eslint-disable-next-line
    const {contratosInfo,index} = props;
    const {produtosContext,setProdutosContext} = useContext(ProdutosContext);
    const [contratoQuery,setContratoQueryStart] = useAPI<ContratoGenerico[]>(GetContratos(contratosInfo.codigoProduto,contratosInfo.tipoProduto,contratosInfo.sistemaOrigem));
    const selector = useTypedSelector(state=>state.APIRedux);
    const produtosContextRef = useRef<IProdutosContext>(produtosContext);
    console.log(produtosContext);
    useEffect(()=>{
        produtosContextRef.current = produtosContext;
    },[produtosContext])
    const ArrangeArray  = <T extends unknown> (position:number,item:T,contratos:T[]) : T[]  => {
        let arrayToSend : T[] = [];
        let paralelCounter = 0;
        for(let i=0;i<(contratos.length+1);i++)
        {
            if(i!==position)
            {
                arrayToSend.push(contratos[paralelCounter]);
                paralelCounter++;
            }
            else
            {
                arrayToSend.push(item);
            }
        }
        return arrayToSend;
    }
    const ContratoDetailsGenericFunction = async (codigoProduto:string,numeroContrato:string,tipoProduto:string,sistemaOrigem:string,numeroCertificado:string,i:number):Promise<IDownContentReturnTypes> =>
    {
        const doRequest = ()=> APIRequestFunction<IProdutosAPIResponse>(GetContratoDetalhes(codigoProduto,numeroContrato,tipoProduto,sistemaOrigem,numeroCertificado)).then((responseDetalhes) : IDownContentReturnTypes =>{
            return{
                rows:GeneralProdutoRowsComponent({...(responseDetalhes?.data as IProdutosAPIResponse),informacoesContrato:{codigoProduto,numeroContrato,numeroCertificado,tipoProduto,sistemaOrigem}}),
                selector:"ReactElement",
                cacheContextSet:()=>{
                    const itemAtual:IProdutosContext=
                    {
                        produtoContratos:[
                            ...RetirarByIndex(index),
                            {
                                status:"COMPLETED",
                                index:index,
                                contratos:ArrangeArray<InsideContratos>(i,
                                    {
                                        index:i,
                                        contratoInfo:FindByIndexContrato(i).contratoInfo,
                                        downContent:GeneralProdutoRowsComponent({...(responseDetalhes?.data as IProdutosAPIResponse),informacoesContrato:{codigoProduto,numeroContrato,numeroCertificado,tipoProduto,sistemaOrigem}}),
                                        status:"COMPLETED",
                                    },RetirarByIndexContrato(i)
                                )
                            }
                        ]
                        
                    };
                    console.log("do request itemAtual");
                    console.log(itemAtual);
                    setProdutosContext(itemAtual);
                }
            }
        })
        if(FindByIndexContrato(i).status==="PENDING"){
            return (FindByIndexContrato(i).promise ?? doRequest());
        }
        else{
            let promiseToAwait = doRequest()
            const itemBeforeSet:IProdutosContext=
            {
                produtoContratos:[
                    ...RetirarByIndex(index),
                    {
                        status:"COMPLETED",
                        index:index,
                        contratos:ArrangeArray<InsideContratos>(i,{
                            index:i,
                            contratoInfo:FindByIndexContrato(i).contratoInfo,
                            status:"PENDING",
                            promise:promiseToAwait,
                        },RetirarByIndexContrato(i))
                    }
                ]
            };
            setProdutosContext(itemBeforeSet);
            return promiseToAwait;
        }
        // eslint-disable-next-line
    }
    const FindByIndex = () :IProdutoContratoDealer =>
    {
        console.log(produtosContextRef.current.produtoContratos);
        return produtosContextRef.current.produtoContratos.find((produtoContratoDealer:IProdutoContratoDealer)=>produtoContratoDealer.index===index)??{contratos:[],index:index,status:"NOTINITIALIZED"}
    }
    function RetirarByIndexContrato(indexRetirarContrato:number)
    {   
        let contratosToSend : InsideContratos[]= [];
        FindByIndex().contratos.map((contratoMapAtual:InsideContratos)=>{
            if(!(contratoMapAtual.index===indexRetirarContrato))
            contratosToSend.push(contratoMapAtual)
            return null;
        })
        return contratosToSend;
    }
    function RetirarByIndex(actualIndexRetirar:number)
    {
        let tempVector:IProdutoContratoDealer[] = [];
        produtosContextRef.current.produtoContratos.map((produtoDealer:IProdutoContratoDealer)=>{
            if(produtoDealer.index!==actualIndexRetirar)
            tempVector.push(produtoDealer);
            return undefined;
        })
        return tempVector;
    }
    const FindByIndexContrato = (numero:number) : InsideContratos =>
    {
        return FindByIndex().contratos.sort((atualIndex:InsideContratos,nextIndex:InsideContratos)=>{if(atualIndex.index===nextIndex.index)return 0; if(atualIndex.index>nextIndex.index)return 1; else return -1})[numero];
    }
    useEffect(
        ()=>{
            if(!(FindByIndex().status==="COMPLETED" || FindByIndex().status==="PENDING"))
            {
                setContratoQueryStart();
                const tempContext : IProdutosContext = { produtoContratos:[...RetirarByIndex(index),{contratos:[],index:index,status:"PENDING", queryIdentifier:contratoQuery.key}]}
                setProdutosContext(tempContext);
            }
        }
        // eslint-disable-next-line
    ,[])
    useEffect(()=>{
        if(selector[FindByIndex().queryIdentifier ?? ""]?.status==="COMPLETED")
        {
            const tempContratos:InsideContratos[] =
            selector[FindByIndex().queryIdentifier ?? ""]?.data?.map((atualContrato:ContratoGenerico,i:number) : InsideContratos =>
            {
                return({
                    index:i,
                    contratoInfo:atualContrato,
                    status:"NOTINITIALIZED"
                })
            }) ?? []
            const tempContext : IProdutosContext = { produtoContratos:[...RetirarByIndex(index),{contratos:tempContratos,index:index,status:"COMPLETED"}]}
            setProdutosContext(tempContext);
        }
        if(selector[FindByIndex().queryIdentifier ?? ""]?.status==="FAILED")
        {
            const tempContext : IProdutosContext = { produtoContratos:[...RetirarByIndex(index),{contratos:[],index:index,status:"FAILED"}]}
            setProdutosContext(tempContext);
        }
        // eslint-disable-next-line
    },[contratoQuery.status,contratoQuery])
    function SortUpperTexts(contrato:ContratoGenerico):ITitleAnswer[]
    {
        if(contrato.sistemaOrigem==="SISCOM")
        {
            switch(contrato.tipoProduto)
            {
                case "1":
                    return[
                        {
                            title:"Contrato",
                            answer:contrato.numeroContrato,
                        },
                        {
                            title:"Contribuição Atual",
                            answer: "R$ " + CorrigirVariavelMonetaria(contrato.valorContratoSegurado),
                        },
                        {
                            title:"Status",
                            answer:contrato.statusContrato,
                        },
                        {
                            title:"Inicio da Vigência",
                            answer:contrato.dataInicioVigencia?new Date(contrato.dataInicioVigencia).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11):"",
                        }
                    ]
                    //PIC
                case "2":
                    return [
                        {
                            title:"Contrato",
                            answer:contrato.numeroContrato,
                        },
                        {
                            title:"Status",
                            answer:contrato.statusContrato,
                        },
                        {
                            title:"Valor da Parcela",
                            answer:"R$ "+CorrigirVariavelMonetaria(contrato.valorParcelaContrato),
                        },
                        {
                            title:"Valor Solicitado",
                            answer:"R$ "+CorrigirVariavelMonetaria(contrato.valorContratoSegurado),
                        }
                    ]
                    //produto af
                case "4":
                    let returnData = [
                        {
                            title:"Certificado",
                            answer:contrato.numeroCertificado.split(".")[0] ?? contrato.numeroCertificado,
                        },
                        {
                            title:"Apólice",
                            answer:contrato.numeroApolice,
                        },
                        {
                            title:"Vigência",
                            answer:(contrato.dataInicioVigencia?new Date(contrato.dataInicioVigencia).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11):"")+" - "+(contrato.dataFimVigencia?new Date(contrato.dataFimVigencia).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11):""),
                            suspenseMessage:"A manutenção do período total de vigência do certificado, está condicionada ao pagamento dos prêmios nas datas devidas."
                        }
                    ]
                    if(contrato.isTributario)
                        returnData = returnData.concat([
                            {
                                title:"Prêmio Mensal Pago",
                                answer:"R$ " + CorrigirVariavelMonetaria(contrato.valorContratoSegurado),
                            },
                            {
                                title:"Status",
                                answer:contrato.statusContrato,
                            }
                        ])
                    else
                        returnData = returnData.concat([
                            {
                                title:"Status",
                                answer:contrato.statusContrato,
                            }
                        ])
                    return returnData
                    //Seguro
                default:
                    return[
                        {
                            title:"Contrato",
                            answer:contrato.numeroContrato,
                        },
                        {
                            title:"Contribuição Atual",
                            answer: "R$ " + CorrigirVariavelMonetaria(contrato.valorContratoSegurado),
                        },
                        {
                            title:"Status",
                            answer:contrato.statusContrato,
                        },
                        {
                            title:"Inicio da Vigência",
                            answer:contrato.dataInicioVigencia?new Date(contrato.dataInicioVigencia).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11):"",
                        }
                    ]
                //Pecúlio
            }
        }
        else if( contrato.sistemaOrigem === "SEG" )
        {
            if(contrato.tipoProduto === "0")
            return [
                {
                    title:"Contrato",
                    answer:contrato.numeroContrato,
                },
                {
                    title:"Certificado",
                    answer:contrato.numeroCertificado,
                },
                {
                    title:"Prêmio",
                    answer:"R$ "+CorrigirVariavelMonetaria(contrato.valorContratoSegurado),
                },
                {
                    title:"Status",
                    answer:contrato.statusContrato,
                },
                {
                    title:"Vigência",
                    answer:(contrato.dataInicioVigencia?new Date(contrato.dataInicioVigencia).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11):"")+" - "+(contrato.dataFimVigencia?new Date(contrato.dataFimVigencia).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11):""),
                }
            ]
            //BPC
            else{
                let returnData = [
                    {
                        title:"Certificado",
                        answer:contrato.numeroCertificado.split(".")[0] ?? contrato.numeroCertificado,
                    },
                    {
                        title:"Apólice",
                        answer:contrato.numeroApolice,
                    },
                    {
                        title:"Vigência",
                        answer:(contrato.dataInicioVigencia?new Date(contrato.dataInicioVigencia).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11):"")+" - "+(contrato.dataFimVigencia?new Date(contrato.dataFimVigencia).toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11):""),
                        suspenseMessage:"A manutenção do período total de vigência do certificado, está condicionada ao pagamento dos prêmios nas datas devidas."
                    }
                ]
                if(contrato.isTributario)
                    returnData = returnData.concat([
                        {
                            title:"Prêmio Mensal Pago",
                            answer:"R$ " + CorrigirVariavelMonetaria(contrato.valorContratoSegurado),
                        },
                        {
                            title:"Status",
                            answer:contrato.statusContrato,
                        }
                    ])
                else
                    returnData = returnData.concat([
                        {
                            title:"Status",
                            answer:contrato.statusContrato,
                        }
                    ])
                return returnData;
                //Seguro coletivo
            }
        }
        else
        {
            return [{title:"Error",answer:"Ocorreu um erro em encontrar o tipo certo da operação!"}]
        }
    }
    const GetContratoInfo = ()=>{
        let contratosFinals:IOneLineExpandContents[] = [];
        contratosFinals = FindByIndex().contratos.map((itemAtual:InsideContratos)=>{
            return(itemAtual.contratoInfo);
        }).map((contratoAtual:ContratoGenerico,i:number)=>{
            return({
                upperTexts:SortUpperTexts(contratoAtual),
                size:"auto;padding-bottom:10px;",
                downContent:()=>{
                    
                    if(FindByIndexContrato(i)?.downContent)
                    {
                        return {type:"ReactElement",downContentReturn:FindByIndexContrato(i).downContent ?? [<></>]}
                    }
                    else
                    {
                        const promiseDetails : Promise<IDownContentReturnTypes> = ContratoDetailsGenericFunction(contratoAtual.codigoProduto,contratoAtual.numeroContrato,contratoAtual.tipoProduto,contratoAtual.sistemaOrigem,contratoAtual.numeroCertificado,i);
                        return {type:"Promise",downContentReturn:promiseDetails}
                    }
                },
            })
        })
        return contratosFinals
    }
    const DetermineComponent = ()=>{
        if(FindByIndex().status==="COMPLETED"){
            return(<ProdutoComponent rows={GetContratoInfo()}/>)
        }
        else if(FindByIndex().status==="FAILED")
            return(<LoaderError erro={"Ocorreu um erro ao tentar carregar os contratos! Tente recarregar a página. Se não funcionar contate o suporte CAPEMISA!"}/>)
        else
            return(<LoaderRotatorMessage width={"100%"}/>)
    }
    const [ComponentToShow,setComponentToShow] = useState<React.ReactElement>(DetermineComponent());
    useEffect(()=>{
        setComponentToShow(DetermineComponent());
        // eslint-disable-next-line
    },[produtosContext,contratoQuery.status])

    return ComponentToShow
}
const ProdutoComponent : React.FC<{rows:IOneLineExpandContents[]}> = (props) : React.ReactElement =>
{
    return (
        <ProdutoSpace>
            <LinhaEntreComponentes borderWidth={"0.3px!important"}/>
            {
                
                props.rows.map((item:IOneLineExpandContents,i:number)=>{
                    return(
                    <RowItem>
                        <OneLineExpand {...item}/>
                        {
                            i!==(props.rows.length-1)?<LinhaEntreComponentes width={"95%"} borderWidth={"0.5px!important"}/>:""
                        }
                    </RowItem>
                    )
                })
            }
        </ProdutoSpace>
    )
}
export default MeusProdutos;