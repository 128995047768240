import {Requisicao} from '../requisicao';
import IArgs from '../../interfaces/IArgs';
import {APIDOCUMENTOS} from '../../services/APICallableActions/Adresses';
//DEPRECATED! THIS CATEGORY OF API REQUEST IS FROM NOW (22/03/2021) ON CONSIDERED OUT OF STRUCTURE ARQUITECTURE!


//DEPRECADO! ESTA CATEGORIA DE CHAMADA A API NÃO ESTÁ SENDO MAIS UTILIZADA A PARTIR DE 22/03/2021
//QUALQUER USO DESTA CHAMADA É CONSIDERADO FORA DE ARQUITETURA DE PROJETO!
export function GetTipoDocumento() :any
{
    return Requisicao(APIDOCUMENTOS.TipoDocumento.DocumentosUser)
}
export function GetPDFDirect(url:string)
{
    return Requisicao(url,{method:"GETARCHIVE"});
}
export function GetDocumentos(url:string)
{
    const body: IArgs[]=[
        {
            parameter:"urlTipoDocumento",
            value:url,
        }
    ];
    return Requisicao(APIDOCUMENTOS.Documento.DocumentoUrl,{method:"GET",body:body});
}