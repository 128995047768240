import styled from 'styled-components';
import IcoMoon from 'icomoon-react';
export const NotificacaoSpace = styled.div`
    width:200px;
    height:fit-content;
    max-height:400px;
    overflow-x:auto;
`
export const NotificacaoDiv = styled.div`
    cursor:pointer;
    min-height:100px;
    height:auto;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-bottom:20px;
`
export const NotificacaoUpper = styled.div`
    height:auto;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
export const NotificacaoBottom = styled.div`
    height:auto;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
`
export const NotificacaoIco = styled(IcoMoon)`
    width:20%;
`
export const NotificacaoTitle = styled.label`
    width:80%;
    font: italic normal 300 100% Roboto;
    text-align:center;
    letter-spacing: -0.32px;
    color: #636363;
    cursor:pointer;
`
export const NotificacaoDetalhes = styled.label`
    width:98%;
    font: normal normal normal 100% Roboto;
    text-align:center;
    letter-spacing: -0.3px;
    color: #636363;
    opacity: 1;
    cursor:pointer;
`
export const Linha = styled.div<{ width: string | number }>`
    width: ${props => props.width};
    height:0px;
    border-top:1px black solid;
`;