import React from "react";
import CorrigirVariavelMonetaria from "../../../functions/CorrigirValorPontuacao";
import { IBoletoEspecifics, IContratoEspecifics } from "../../../interfaces/pages/IInfRend";
import { BoletoAditionalComponentSpace, LinhaEntreComponentes, BoletoListaInnerComponentSpace, ListaTitle, BoletoListaSpace, BoletoListaDetalhesHead, Linha, ColumnSpacer, BoletoTitle, BoletoListaDetalhesBody, InsideSelectedText, BoletoInnerComponentSpace } from "./styles";

const BoletoDetails:React.FC<{boletoInfo:IBoletoEspecifics}> = (props) :React.ReactElement =>
{
    const {boletoInfo} = props;
    return(
        <BoletoAditionalComponentSpace>
            <LinhaEntreComponentes/>
            <BoletoListaInnerComponentSpace>
                <ListaTitle>Detalhes</ListaTitle>
                <BoletoListaSpace>
                    <BoletoListaDetalhesHead hasNecessityToScrollBar={false}>
                        <Linha>
                            <ColumnSpacer width="18%">
                                <BoletoTitle>Nome do Produto</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="12%">
                                <BoletoTitle>Contrato</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <BoletoTitle>Competência</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <BoletoTitle>Valor</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <BoletoTitle>Juros</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <BoletoTitle>Multa</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="10%">
                                <BoletoTitle>Atualização Monetária</BoletoTitle>
                            </ColumnSpacer>
                            <ColumnSpacer width="10%">
                                <BoletoTitle>Total</BoletoTitle>
                            </ColumnSpacer>
                        </Linha>
                    </BoletoListaDetalhesHead>
                    <BoletoListaDetalhesBody>
                    {
                            boletoInfo.contratos?.map((item:IContratoEspecifics)=>{
                                return(
                        <Linha>
                            <ColumnSpacer width="18%">
                                <InsideSelectedText>{item.nomeProduto}</InsideSelectedText>
                            </ColumnSpacer>
                            <ColumnSpacer width="12%">
                                <InsideSelectedText>{item.numero}</InsideSelectedText>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <InsideSelectedText>{item.anoMesCompetencia}</InsideSelectedText>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <InsideSelectedText>R$ {item.valorContrato}</InsideSelectedText>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <InsideSelectedText>R$ {item.valorJuros}</InsideSelectedText>
                            </ColumnSpacer>
                            <ColumnSpacer width="11%">
                                <InsideSelectedText>R$ {CorrigirVariavelMonetaria(item.valorMulta)}</InsideSelectedText>
                            </ColumnSpacer>
                            <ColumnSpacer width="10%">
                                <InsideSelectedText>R$ {CorrigirVariavelMonetaria(item.valorAtualizacaoMonetaria)}</InsideSelectedText>
                            </ColumnSpacer>
                            <ColumnSpacer width="15%">
                                <InsideSelectedText>R$ {CorrigirVariavelMonetaria(item.valorTotal)}</InsideSelectedText>
                            </ColumnSpacer>
                        </Linha>
                         )
                        })
                    }
                    </BoletoListaDetalhesBody>
                </BoletoListaSpace>
            </BoletoListaInnerComponentSpace>
            <LinhaEntreComponentes borderWidth={"0.25px"}/>
            <BoletoInnerComponentSpace>
                <BoletoTitle>Número do boleto</BoletoTitle>
                <InsideSelectedText>{boletoInfo.linhaDigitavel != 'null'?boletoInfo.linhaDigitavel: ""}</InsideSelectedText>
            </BoletoInnerComponentSpace>
        </BoletoAditionalComponentSpace>
    )
}

export default BoletoDetails