import { action } from 'typesafe-actions';
import IAvisoSinistro, { IPendenciasAvisoSinistro } from '../../../interfaces/IAvisoSinistro';
import { IEvento, IEventoDocumento } from '../../../interfaces/storage/contexts/ISinistroContext';
import {  IDocsNecessarios, SinistroTypes } from "./reduxTypes";

export const obterDocsNecessariosVariosEventosRequest = (eventos?: IEvento[]) => action(SinistroTypes.OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_REQUEST, eventos);
export const obterDocsNecessariosVariosEventosSuccess = (response: IDocsNecessarios[]) => action(SinistroTypes.OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_SUCCESS, response);
export const obterDocsNecessariosVariosEventosFailure = (err: any) => action(SinistroTypes.OBTER_DOCS_NECESSARIO_VARIOS_EVENTOS_FAILURE, err);

export const obterDocsNecessariosUmEventoRequest = (codigoEvento: number) => action(SinistroTypes.OBTER_DOCS_NECESSARIOS_UM_EVENTO_REQUEST ,codigoEvento)
export const obterDocsNecessariosUmEventoSuccess = (response: IEventoDocumento[]) => action(SinistroTypes. OBTER_DOCS_NECESSARIOS_UM_EVENTO_SUCCESS,response)
export const obterDocsNecessariosUmEventoFailure = (err: any) => action(SinistroTypes.OBTER_DOCS_NECESSARIOS_UM_EVENTO_FAILURE ,err)

export const obterAvisoSinistroRequest = (idAviso: string) => action(SinistroTypes.OBTER_AVISO_SINISTRO_REQUEST, idAviso)
export const obterAvisoSinistroSuccess = (AvisoSinistro: IAvisoSinistro) => action(SinistroTypes.OBTER_AVISO_SINISTRO_SUCCESS, AvisoSinistro)
export const obterAvisoSinistroFailure = (err: any) => action(SinistroTypes.OBTER_AVISO_SINISTRO_FAILURE,  err)

export const obterPendenciasAvisoSinistroRequest = (idAviso: string) => action(SinistroTypes.OBTER_PENDENCIAS_AVISO_SINISTRO_REQUEST, idAviso)
export const obterPendenciasAvisoSinistroSuccess = (pendencias: IPendenciasAvisoSinistro[]) => action(SinistroTypes.OBTER_PENDENCIAS_AVISO_SINISTRO_SUCCESS, pendencias)
export const obterPendenciasAvisoSinistroFailure = (err: any) => action(SinistroTypes.OBTER_PENDENCIAS_AVISO_SINISTRO_FAILURE,  err)
