import styled from 'styled-components';
export const CheckBoxArea = styled.span<{borderRadius?:string}>`
    width:18px;
    height:18px;
    display:flex;
    justify-content:center;
    align-items:center;
    border: 2px solid #E27800;
    border-radius: ${props=>props.borderRadius??"9px"};
    cursor:pointer;
`
export const CheckBoxCheckedMark = styled.span<{borderRadius?:string}>`
    width:10px;
    height:10px;
    background-color:#E27800;
    border-radius:${props=>props.borderRadius??"9px"};
`