import React, { useState, useContext, useEffect, useMemo} from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Avatar, makeStyles } from '@material-ui/core';
import { Container, Linha,Coluna, AlterarDadosButton} from './styles';
import { IProps } from '../../interfaces/components/IUserForm';
import { GetUF, PatchUserContatos } from '../../API/usuario';
import { GlobalContext } from '../../storage/contexts/GlobalContext';
import {TextInput, TextInputEspecific} from '../TextInput';
import EmptyTest from '../../functions/EmptyTest';
import GetDadosByCep from '../../API/cep';
import StyledAlert from '../StyledAlert';
import { PatchAtualizarDadosComunicante } from '../../API/sinistro';
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
  }));

const UserForm : React.FC<IProps> = (props): React.ReactElement => {
    const { data, pagePurpose } = props;
    const classes = useStyles();
    const [canAlter,setAlter]= useState<boolean>(false);
    const [nome,setNome] = useState<string>(data?.nome ?? "");
    const [dataNascimento,setDataNascimento] = useState<string>(new Date(data?.dataNascimento ?? "").toLocaleDateString('pt-BR', { timeZone: 'UTC' }).slice(0,11));
    const [genero,setGenero] = useState<"Masculino"|"Feminino"|undefined>(data?.sexo==="F"?"Feminino":(data?.sexo==="M"?"Masculino":undefined))
    const [nomePai,setNomePai] = useState<string>(data?.nomePai??"");
    const [nomeMae,setNomeMae] = useState<string>(data?.nomeMae??"");
    const [telefoneFixo,setTelefoneFixo] = useState<string>(((data?.dddTelefone2 ?? "").toString()+(data?.telefone2??"").toString()));
    const [telefoneCelular,setTelefoneCelular] = useState<string>(((data?.dddTelefone1 ?? "00").toString()+(data?.telefone1 ??"").toString()));
    const [telefoneAdicional,setTelefoneAdicional] = useState<string>(((data?.dddTelefone3 ?? "").toString()+(data?.telefone3 ??"").toString()));
    const [email,setEmail] = useState<string>((data?.email??""));
    const [cep,setCep] = useState<string>(data?.cep??"");
    const [uf,setUf] = useState<string>(data?.uf ?? "");
    const [cidade,setCidade] = useState<string>(data?.cidade ?? "");
    const [bairro,setBairro] = useState<string>(data?.bairro ?? "");
    const [endereco,setEndereco] = useState<string>(data?.endereco ?? "");
    const [numeroEndereco,setNumeroEndereco] = useState<string>(data?.numeroEndereco ?? "");
    const [complemento,setComplemento] = useState<string>(data?.complemento ?? "");
    const [isToAddTelefone3,setIsToAddTelefone3] = useState<boolean>(data?.telefone3?true:false);
    const [allEstados,setAllEstados] = useState<string[]>([]);
    const {globalContext,setGlobalContext} = useContext(GlobalContext); 
    const textoBeneficiario = useMemo(()=>"Para quaisquer alterações referentes aos dados cadastrais, favor entrarem em contato com a Central de Relacionamento com Clientes.",[]);
    useEffect(()=>{
        GetUF().then((response)=>{
            setAllEstados(response.data.map((item:{sigla:string})=>{
                return(item.sigla);
            }))
        }).catch(()=>{
            setAllEstados(["AC","AL","AP","AM","BA","CE","ES","GO","MA","MT","MS","MG","PA","PB","PR","PE","PI","RJ","RN","RS","RO","RR","SC","SP","SE","TO","DF"]);
        });
    },[])
    function BloquearCaractereEspecial(valor:string)
    {
        const regexSemCaractereEspecial =  /^[a-zA-Z0-9 ]+$/;
        return regexSemCaractereEspecial.test(valor) || valor==="";
    }
    function TestarFuncaoMensagem(funcao:()=>boolean,mensagem:string) : boolean
    {
        if(typeof(funcao()) === "boolean" && funcao())
        {
            return true;
        }
        else
        {
            StyledAlert(mensagem);
            return false;
        }
    }
    return(
        <Container>
            <div style={{position:"relative"}}>
            {/* <Linha> */}
                {/* <Avatar className={classes.large}></Avatar> */}
                {/* <Coluna> */}
                    <Linha>
                        <TextInputEspecific obligatory={pagePurpose==="insertdata"} disabled={pagePurpose!=="insertdata"} show={true} margin rotulo="Nome" onChange={(e:{target:{value:string}})=>{setNome(e.target.value)}} valor={nome}/> 
                    </Linha>
                    <Linha>
                        <TextInputEspecific disabled={pagePurpose!=="insertdata"} show={true} obligatory={pagePurpose==="insertdata"} margin rotulo="Data de Nascimento" onChange={(e:{target:{value:string}})=>{setDataNascimento(e.target.value)}} valor={dataNascimento}/>  
                        <TextInput obligatory={pagePurpose==="insertdata"} type={pagePurpose!=="insertdata"?"text":"combo"} onChange={(e:{target:{value:string}})=>{setGenero(e.target.value==="Feminino"?"Feminino":(e.target.value==="Masculino"?"Masculino":undefined))}} disabled={pagePurpose!=="insertdata"} show rotulo="Gênero" options={["Masculino","Feminino"]} placeHolder={"Indefinido"} margin valor={genero}/>
                    </Linha> 
                {/* </Coluna> */}
            {/* </Linha> */}
            </div>
            <div style={{position:"relative"}}>
                <Linha>
                    <TextInputEspecific show={true} margin rotulo="CPF" valor={data?.cpf}/>  
                    <TextInputEspecific disabled={pagePurpose!=="insertdata"} rotulo="RG" margin /> 
                </Linha> 
            </div>

            {/* <Linha>
                <TextInputEspecific obligatory={pagePurpose==="insertdata"} disabled={pagePurpose!=="insertdata"} show margin rotulo="Nome da mãe" onChange={(e:any)=>{setNomeMae(e.target.value)}} valor={nomeMae}/>  
                <TextInputEspecific obligatory={pagePurpose==="insertdata"} disabled={pagePurpose!=="insertdata"} show margin rotulo="Nome do pai" onChange={(e:any)=>{setNomePai(e.target.value)}} valor={nomePai}/> 
            </Linha> */}

            <Linha>
                <TextInputEspecific obligatory show margin disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setEndereco(e.target.value)}} rotulo="Endereço" valor={endereco}/>
            </Linha>
            
            <Linha>
                <TextInputEspecific obligatory show margin options={allEstados} disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setUf(e.target.value)}} rotulo="UF" valor={uf.replace(" ","")}/>
                <TextInputEspecific obligatory show margin disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{
                    setCep(e.target.value)
                    console.log(e.target.value.search("_")===-1 && e.target.value.length>0)
                    if(e.target.value.search("_")===-1 && e.target.value.length>0)
                    {
                        GetDadosByCep(e.target.value.replace("-","")).then((response)=>{
                            const dadosCEP : {bairro:string,logradouro:string,municipio:string,uf:string,tipoLogradouro:string} = response.data;
                            setBairro(dadosCEP.bairro.replace(/[^a-zA-Z0-9 ]/g, ""));
                            setEndereco(dadosCEP.tipoLogradouro + " " + dadosCEP.logradouro.replace(/[^a-zA-Z0-9 ]/g, ""));
                            setCidade(dadosCEP.municipio.replace(/[^a-zA-Z0-9 ]/g, ""));
                            setUf(dadosCEP.uf);
                        })
                    }
                }} rotulo="CEP" valor={cep}/>
            </Linha>
            <Linha>
                <TextInputEspecific obligatory show margin disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setCidade(e.target.value)}} rotulo="Cidade" valor={cidade}/>
                <TextInputEspecific obligatory show margin disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setBairro(e.target.value)}} rotulo="Bairro" valor={bairro}/>
            </Linha>

            <Linha>
                <TextInput type={"textoNumero"} show margin disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setNumeroEndereco(e.target.value)}} rotulo="Numero do Endereço" valor={numeroEndereco}/>
                <TextInputEspecific show margin disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{if(BloquearCaractereEspecial(e.target.value))setComplemento(e.target.value)}} rotulo="Complemento" valor={complemento}/>
            </Linha>

            <Linha>
            <TextInputEspecific margin obligatory disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{setEmail(e.target.value)}} show rotulo="E-mail" valor={email}/> 
            </Linha>

            <Linha>
                <TextInputEspecific obligatory margin disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{setTelefoneCelular(e.target.value)}} show rotulo="Telefone 1" valor={telefoneCelular}/>  
                <TextInputEspecific margin disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{setTelefoneFixo(e.target.value)}} show rotulo="Telefone 2" valor={telefoneFixo}/>
            </Linha>

            <Linha>
                <TextInputEspecific width="46%" margin disabled={!(canAlter || (pagePurpose==="insertdata"))} onChange={(e:any)=>{setTelefoneAdicional(e.target.value)}} show={isToAddTelefone3} rotulo="Telefone 3" valor={telefoneAdicional}/>
            </Linha>
            {
                !isToAddTelefone3 && canAlter?
            <Linha>
                <div style={{fontSize:"15px",color:"#ed7203",display:"flex",alignItems:"center",cursor:"pointer",}} onClick={()=>{setIsToAddTelefone3(!isToAddTelefone3)}}>
                    <Icon iconName="CircleAddition" />
                    <label style={{margin:"0px",cursor:"pointer"}}>Adicionar mais um telefone</label>
                </div>
            </Linha>:""
            }
            {
                data?.isBeneficiario && !data?.isSegurado?
                <>
                    {
                        textoBeneficiario
                    }
                </>
                :
                (
                pagePurpose!=="insertdata"?
                <Linha>
                    <div style={{ display: "flex", justifyContent: "flex-end",height:"2rem",width:"100%",paddingRight:"5%"}}>
                        <AlterarDadosButton onClick={()=>
                        {
                            if(canAlter)
                            {
                                if ((TestarFuncaoMensagem(()=>!(telefoneAdicional.replace(/-|\(|\)/g,"").length>2 && isToAddTelefone3?(telefoneAdicional.replace(/-|\(|\)/g,"").slice(0,2).toString()==="00"):false),"O DDD não pode ser 00!") && TestarFuncaoMensagem(()=>!(telefoneFixo.replace(/-|\(|\)/g,"").length>2?(telefoneFixo.replace(/-|\(|\)/g,"").slice(0,2).toString()==="00"):false),"O DDD não pode ser 00!") && TestarFuncaoMensagem(()=>!(telefoneCelular.replace(/-|\(|\)/g,"").length>2?(telefoneCelular.replace(/-|\(|\)/g,"").slice(0,2).toString()==="00") : false),"O DDD não pode ser 00!")) && EmptyTest(endereco,"O endereço está vazio!") && EmptyTest(uf,"Escolha um estado!") && EmptyTest(cep,"O CEP está vazio!") && EmptyTest(cidade,"Digite sua cidade!") && EmptyTest(bairro,"Digite seu Bairro!") && TestarFuncaoMensagem(()=>{return telefoneCelular.toString().replace(/\(|\)|-|_/g,"").length>=10},"O telefone 1 é necessário") && TestarFuncaoMensagem(()=>{return (telefoneFixo.toString().replace(/\(|\)|-|_/g,"").length===2||telefoneFixo.toString().replace(/\(|\)|-|_/g,"").length===0||telefoneFixo.toString().replace(/\(|\)|-|_/g,"").length>=10)},"Se for digitar o telefone 2 o digite completo!") && EmptyTest(email, "O E-mail é obrigatório!") && TestarFuncaoMensagem(()=>{return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))}, "E-mail inválido! ") && TestarFuncaoMensagem(()=>{return (telefoneAdicional.toString().replace(/\(|\)|-|_/g,"").length===2||telefoneAdicional.toString().replace(/\(|\)|-|_/g,"").length===0||telefoneAdicional.toString().replace(/\(|\)|-|_/g,"").length>=10)},"Se for digitar o telefone 3 o digite completo!"))
                                {
                                    if(!(isToAddTelefone3?(telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(2,telefoneAdicional.length)?? undefined):undefined) && !(isToAddTelefone3?(telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(0,2)??undefined):undefined))
                                    {
                                        setIsToAddTelefone3(false);
                                    }
                                    PatchUserContatos(telefoneFixo.replace(/_|-|\(|\)/g,"").slice(2,telefoneFixo.length),telefoneCelular.replace(/_|-|\(|\)/g,"").slice(2,telefoneCelular.length),email,telefoneFixo.replace(/_|-|\(|\)/g,"").slice(0,2),telefoneCelular.replace(/_|-|\(|\)/g,"").slice(0,2),cep.replace(/-|_/g,""),uf,cidade,bairro,endereco,numeroEndereco,complemento,isToAddTelefone3?telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(2,telefoneAdicional.length):undefined,isToAddTelefone3?telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(0,2):undefined,data?.nome,data?.dataNascimento,data?.sexo,data?.nomePai,data?.nomeMae).then(()=>{
                                        setGlobalContext({...globalContext,PreUser:{...globalContext.PreUser,...{
                                            dddTelefone1:telefoneCelular.replace(/_|-|\(|\)/g,"").slice(0,2).length>0 ? telefoneCelular.replace(/_|-|\(|\)/g,"").slice(0,2) : undefined,
                                            dddTelefone2:telefoneFixo.replace(/_|-|\(|\)/g,"").slice(0,2).length>0 ? telefoneFixo.replace(/_|-|\(|\)/g,"").slice(0,2) : undefined,
                                            "telefone1":telefoneCelular.replace(/_|-|\(|\)/g,"").slice(2,telefoneCelular.length).length>0 ? telefoneCelular.replace(/_|-|\(|\)/g,"").slice(2,telefoneCelular.length) : undefined,
                                            "telefone2":telefoneFixo.replace(/_|-|\(|\)/g,"").slice(2,telefoneFixo.length).length>0? telefoneFixo.replace(/_|-|\(|\)/g,"").slice(2,telefoneFixo.length) : undefined,
                                            "telefone3":isToAddTelefone3?(telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(2,telefoneAdicional.length).length? telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(2,telefoneAdicional.length) :undefined):undefined,
                                            "dddTelefone3":isToAddTelefone3?(telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(0,2).length>0? telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(0,2) :undefined):undefined,
                                            "email":email,
                                            "cep":cep.replace(/-|_/g,""),
                                            "uf":uf,
                                            "cidade":cidade,
                                            "bairro":bairro,
                                            "endereco":endereco,
                                            "numeroEndereco":numeroEndereco,
                                            "complemento":complemento
                                        }}});
                                        
                                    })
                                    setAlter(!canAlter);
                                }
                            }
                            else
                            {
                                setAlter(!canAlter);
                            }
                        }}>{canAlter?'Salvar':'Alterar Dados'}</AlterarDadosButton>
                    </div>
                </Linha>
                :
                <Linha>
                    <div style={{ display: "flex", justifyContent: "flex-end",height:"2rem",width:"100%",paddingRight:"5%"}}>
                        <AlterarDadosButton onClick={()=>{
                            if(EmptyTest(nome,"O nome não está preenchido!") && EmptyTest(dataNascimento,"Preencha a data de nascimento com uma data válida!") && EmptyTest(genero??"","Selecione um gênero válido.") && EmptyTest(endereco,"O endereço está vazio!") && EmptyTest(uf,"Escolha um estado!") && EmptyTest(cep,"O CEP está vazio!") && EmptyTest(cidade,"Digite sua cidade!") && EmptyTest(bairro,"Digite seu Bairro!") && TestarFuncaoMensagem(()=>{return telefoneCelular.toString().replace(/\(|\)|-|_/g,"").length>=10},"O telefone 1 é necessário") && TestarFuncaoMensagem(()=>{return (telefoneFixo.toString().replace(/\(|\)|-|_/g,"").length===2||telefoneFixo.toString().replace(/\(|\)|-|_/g,"").length===0||telefoneFixo.toString().replace(/\(|\)|-|_/g,"").length>=10)},"Se for digitar o telefone 2 o digite completo!") && TestarFuncaoMensagem(()=>{return (telefoneAdicional.toString().replace(/\(|\)|-|_/g,"").length===2||telefoneAdicional.toString().replace(/\(|\)|-|_/g,"").length===0||telefoneAdicional.toString().replace(/\(|\)|-|_/g,"").length>=10)},"Se for digitar o telefone 3 o digite completo!"))
                            {
                                PatchAtualizarDadosComunicante(nome,dataNascimento,nomeMae,nomePai,genero ?? "Masculino",email,telefoneCelular.replace(/_|-|\(|\)/g,"").slice(2,telefoneCelular.length),bairro,cep,cidade,telefoneCelular.replace(/_|-|\(|\)/g,"").slice(0,2),endereco,uf,numeroEndereco,telefoneFixo.replace(/_|-|\(|\)/g,"").slice(2,telefoneFixo.length),isToAddTelefone3?telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(2,telefoneAdicional.length):undefined,complemento,telefoneFixo.replace(/_|-|\(|\)/g,"").slice(0,2),isToAddTelefone3?telefoneAdicional.replace(/_|-|\(|\)/g,"").slice(0,2):undefined).then(()=>{
                                    setGlobalContext({...globalContext,PreUser:{...globalContext.PreUser,...{nome,dataNascimento,nomeMae,nomePai,sexo:genero,email,telefone1:telefoneCelular.replace(/_|-|\(|\)/g,"").slice(2,telefoneCelular.length),bairro,cep,cidade,dddTelefone1:telefoneFixo.replace(/_|-|\(|\)/g,"").slice(2,telefoneFixo.length)}}})
                                    setTimeout(()=>{window.location.href=window.location.origin+"/load"},2000);
                                });
                            }
                        }}>Enviar Dados</AlterarDadosButton>
                    </div>
                </Linha>
                )
            }
        </Container>
    )
}

export default UserForm;
