import React from 'react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {RouteComponentProps, withRouter} from 'react-router-dom';
const PageTracking : React.FC<RouteComponentProps> = (props) => {
    const {location} = props;
    const { pathname, search } = location;
    useEffect(() => {
        ReactGA.set({ page: pathname });
        ReactGA.send({ page: pathname + search });
    }, [ pathname, search ]);
    return(<></>);
};
export default withRouter(PageTracking);