import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const Container = styled.div<{ checked: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    font-size: 14px;
    gap: 5px;

    & *{
       cursor:pointer; 
    }

    & > .CheckBoxInput {
        width: max-content;
        height:max-content;
        display:flex;
        justify-content:center;
        background-color: transparent;
        align-items:center;
        border: 2px solid #636363;

          
        ${props =>
            props.checked ? `
                    background-color: #E27800;
                    border-color: #E27800;
                    & > * {
                        visibility: visible;
                    }
                `: ""
        }
    }

    ${props =>
        !props.checked ? `
            :hover > .CheckBoxInput {
            
                border-color: #E27800;
                background-color: #63636350;
            }

            :hover > .CheckBoxInput > * {
                visibility: visible;
            } 
        `:`
        `
    }

    & > label{
        color: #636363;
        font-size: 1em;
        margin: 0;
    }

    
`;

export const Check = styled(FontAwesomeIcon)`
    font-size: 1em;
    color: white;
`;