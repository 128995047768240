import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export const LoaderRotatorSpace = styled.div<{width?:string,height?:string}>`
    display:flex;
    min-width:100px;
    min-height:100px;
    height: ${props=>props.height??"max-content"};
    width: ${props=>props.width??"max-content;max-width:800px"};
    align-items:center;
    flex-direction:column;
    justify-content:center;
`
export const LoaderRotatorIcon = styled(FontAwesomeIcon)<{loadersize?:string}>`
    @keyframes rotate360{
        100%{
            transform:rotate(360deg)
        }
    }
    font-size:${props=> props.loadersize??"20px"};
    animation: rotate360 1s linear infinite;
`