import { Reducer } from 'redux';
import { action } from 'typesafe-actions';
//REDUX DEMONSTRATIVO

//ESTRUTURA PADRÃO DE DUCKS.

//TYPES DO REDUX
export enum TesteActions {
    ADICIONARCAPALAVRA = "@testRedux/ADICIONARCAPALAVRA",
    REMOVERCDAPALAVRA = "@testRedux/REMOVERCDAPALAVRA",
    ZERARPALAVRA = "@testRedux/ZERARPALAVRA"
}
export type TesteData = {
    palavra:string,
}


//ACTIONS DO REDUX
export const adicionarCAPalavra = () => action(TesteActions.ADICIONARCAPALAVRA);

export const removerCDaPalavra = () => action(TesteActions.REMOVERCDAPALAVRA);

export const zerarPalavra = () => action(TesteActions.ZERARPALAVRA);

//const adicionarCAPalavraType =  ReturnType<typeof adicionarCAPalavra>

export type TestDispatchActions = ReturnType<typeof adicionarCAPalavra>| ReturnType<typeof removerCDaPalavra> | ReturnType<typeof zerarPalavra>

//REDUCER E CONFIGS DO REDUCER

const initialVal : TesteData = {
    palavra: ""
}

const reducer : Reducer<TesteData,TestDispatchActions> = (state = initialVal, action) =>{
    switch(action.type)
    {
        case TesteActions.ADICIONARCAPALAVRA:
            return {palavra:state.palavra+"c"};
        case TesteActions.REMOVERCDAPALAVRA:
            return {palavra:state.palavra.replace("c","")};
        case TesteActions.ZERARPALAVRA:
            return initialVal;
        default:
            return state;
    }
}
export default reducer;